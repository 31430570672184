import { useStore } from '@mirai/data-sources';
import { Icon, Pressable, styles, Text as TextBase, Tooltip, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Card, Environment, Typewriter } from './components';
import { Action } from './Message.Action';
import { INTENT_COMPONENT } from './Message.constants';
import * as style from './Message.module.css';
import { Requirement } from './Message.Requirement';
import { ICON } from '../../../helpers';

// ! TODO: Refacto use of intent/response vs lisa.intent/response
const Message = ({ auto, busy = false, disabled, intent, response = {}, text, onRetry, ...others }) => {
  const { value: { urlParams = {} } = {} } = useStore();

  const [cardResponse, setCardResponse] = useState();

  const { actions = [] } = response;
  const { testId, onAction } = others;
  const Text = auto && !disabled ? Typewriter : TextBase;

  return (
    <View testId={testId} wide>
      <View row wide className={styles(style.message, auto && style.auto)}>
        <View className={styles(style.content, auto && style.auto, busy && style.busy)}>
          {busy ? (
            <View className={style.spinner} />
          ) : INTENT_COMPONENT[intent] ? (
            React.createElement(INTENT_COMPONENT[intent], { disabled, intent, response, ...others })
          ) : text || response.text ? (
            <Text className={style.text}>{text || response.text}</Text>
          ) : null}

          {cardResponse ? (
            <Card disabled={disabled} option={false} {...cardResponse} />
          ) : (
            <>
              {actions.map(({ requirement = {}, ...action }, index) =>
                requirement.type ? (
                  <Requirement
                    {...{ disabled, requirement, onRetry }}
                    onResponse={setCardResponse}
                    onSubmit={onAction}
                    key={`chat.message:requirement:${index}`}
                  />
                ) : (
                  <Action
                    {...{ ...action, disabled }}
                    onPress={onAction}
                    onResponse={setCardResponse}
                    onRetry={onRetry}
                    key={`chat.message:action:${index}`}
                  />
                ),
              )}
            </>
          )}
        </View>

        {onRetry && auto && (
          <Tooltip text="Retry">
            <Pressable onPress={onRetry}>
              <Icon light value={ICON.REFRESH} />
            </Pressable>
          </Tooltip>
        )}
      </View>

      {!busy && auto && intent && urlParams.lisa === 'environment' && (
        <Environment {...{ intent, ...others, ...response }} />
      )}
    </View>
  );
};

Message.propTypes = {
  auto: PropTypes.bool,
  busy: PropTypes.bool,
  disabled: PropTypes.bool,
  intent: PropTypes.string,
  response: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    requirements: PropTypes.shape({}),
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  }),
  text: PropTypes.string,
  timestamp: PropTypes.number,
  onAction: PropTypes.func,
  onRetry: PropTypes.func,
};

export { Message };
