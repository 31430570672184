import { useStore } from '@mirai/data-sources';
import { Notification, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { OTAPrices } from './components/OTAPrices';
import * as style from './Rates.module.css';
import { BannerLoyalty } from '../BannerLoyalty';

const Content = ({ dangerousChildren = [], increments, ...others }) => {
  const refBanner = useRef();

  const { value: { club: { banner = {} } = {}, finder: { place: { isHotel } = {} } = {} } = {} } = useStore();

  const [bannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    const { current: el } = refBanner || {};

    const callback = () => setBannerVisible(true);
    const observer = new MutationObserver(callback);
    observer.observe(el, { childList: true, subtree: true });

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refBanner]);

  const isRates = isHotel === undefined || isHotel;

  return !!banner || dangerousChildren.length > 0 || increments ? (
    <View {...others} className={style.extraContent}>
      {increments && (
        <Notification warning wide>
          {increments}
        </Notification>
      )}

      {dangerousChildren.length ? (
        <View>
          {dangerousChildren.map((child, index) => (
            <View key={index} dangerouslySetInnerHTML={{ __html: child.outerHTML }} />
          ))}
        </View>
      ) : null}

      <View ref={refBanner} id="external-banner" style={!bannerVisible ? { display: 'none' } : undefined} />
      <View id="123-compare-plugin" style={{ display: 'none' }} />
      {isRates && <OTAPrices />}

      <BannerLoyalty {...others} />
    </View>
  ) : null;
};

Content.displayName = 'Mirai:Core:Rates:Content';

Content.propTypes = {
  bannerLoyalty: PropTypes.bool,
  dangerousChildren: PropTypes.arrayOf(PropTypes.any),
  increments: PropTypes.string,
};

export { Content };
