const CACHE_LIMIT = 1000 * 60 * 1; // 1 minute

const ENGINE_STORAGE_KEY = 'MIRAI:SERVICE:ENGINE';

const TYPE = {
  CHAIN: 'chain',
  HOTEL: 'hotel',
  GUEST: 1,
  ADULT: 2,
  CHILD: 3,
};

export { CACHE_LIMIT, ENGINE_STORAGE_KEY, TYPE };
