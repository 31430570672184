import { camelcase, palette } from './helpers';
import { DEBOUNCE_TIMEOUT_CACHE, DIRECTION, DIRECTION_TYPE, DOMAIN } from './theme.constants';

export const Theme = {
  get: (domain = DOMAIN) => {
    const { Mirai: { theme = {} } = {} } = window || {};
    if (theme[domain]) return theme[domain];

    const style = getComputedStyle(document.querySelector(':root'));
    const variables = {};

    const keys = Array.from(document.styleSheets)
      .filter((stylesheet) => {
        try {
          return (
            (stylesheet.href === null || stylesheet.href.startsWith(window.location.origin)) &&
            (stylesheet.cssRules ?? {})
          );
        } catch {
          return false;
        }
      })
      .reduce(
        (stylesheet, { cssRules } = {}) => [
          ...stylesheet,
          ...Array.from(cssRules).reduce(
            (value, { selectorText = '', style = [] } = {}) =>
              selectorText === ':root'
                ? [...value, ...Array.from(style).filter((name) => name.startsWith(domain))]
                : value,
            [],
          ),
        ],
        [],
      );

    keys.forEach((key) => (variables[camelcase(key.replace(domain, ''))] = style.getPropertyValue(key)?.trim()));

    window.Mirai = { ...window.Mirai, theme: { ...theme, [domain]: variables } };
    setTimeout(() => Theme.clearCache(domain), DEBOUNCE_TIMEOUT_CACHE);

    return variables;
  },

  setVariable: (variable, value, domain = DOMAIN) => {
    const { style } = document.querySelector(':root');

    style.setProperty(`${domain}${variable}`, value);
    Theme.clearCache(domain);
  },

  generatePalette: (variable, value) => {
    const valuePalette = palette(value);
    if (!valuePalette) return undefined;

    Object.entries(valuePalette).forEach(([key, value]) => {
      Theme.setVariable(key !== 'base' ? `${variable}-${key}` : variable, value);
    });
    Theme.clearCache();
  },

  getDirection: () => {
    const { textDirection } = Theme.get();

    return textDirection !== DIRECTION[DIRECTION_TYPE.RIGHT]['text-direction']
      ? DIRECTION_TYPE.LEFT
      : DIRECTION_TYPE.RIGHT;
  },

  setDirection: (direction = DIRECTION_TYPE.LEFT) => {
    if (!Object.values(DIRECTION_TYPE).includes(direction)) return;

    const variables = DIRECTION[direction] || {};
    Object.entries(variables).map(([key, value]) => Theme.setVariable(key, value));

    return direction;
  },

  clearCache: (domain) => {
    if (!window?.Mirai) return;

    const { theme = {} } = window.Mirai;
    window.Mirai.theme = { ...theme, [domain]: undefined };
  },
};
