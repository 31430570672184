import { currencyFormat, dateCalc, dateFormat } from '@mirai/locale';
import { ServicePricing } from '@mirai/services';

import { CALENDAR_FORMAT } from '../../../../helpers';

const MONTHS = 1;

export const fetchPrices = async ({ currency, from, id, locale, rangeMonths = 2 } = {}) => {
  const responses = await Promise.all(
    Array.from(Array(rangeMonths).keys()).map((index) =>
      ServicePricing.getStatic(
        id,
        dateFormat(index ? dateCalc(new Date(from?.getFullYear(), from?.getMonth(), 1), index, 'months') : from, {
          format: CALENDAR_FORMAT,
        }),
        MONTHS,
        currency,
      ).catch(() => ({})),
    ),
  );

  const next = { currency, values: {} };

  responses.forEach(({ currency, prices = {} } = {}) => {
    const common = { currency, fancy: true, locale, symbol: true };

    if (currency) next.currency = currency;

    Object.entries(prices).forEach(([date, price]) => {
      if (price) next.values[date] = currencyFormat({ ...common, value: price });
    });
  });

  return next;
};
