import { isDate, parseDate } from '@mirai/locale';

const sanitizePrices = (prices = {}) => {
  if (typeof prices !== 'object' || Array.isArray(prices)) return undefined;
  const value = {};

  Object.keys(prices).forEach((date) => {
    if (isDate(parseDate(date))) value[date] = prices[date];
  });

  return value;
};

export { sanitizePrices };
