import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import { styles } from '../../helpers';
import { Primitive, ScrollView } from '../../primitives';
import { select, Storage } from './helpers';
import { L10N_SHAPE, PAGINATION_DISPATCHER } from './Table.constants';
import { Filter } from './Table.Filter';
import style from './Table.module.css';
import { Row } from './Table.Row';

export const Table = ({
  dataSource = [],
  filter: propFilter,
  inline = true,
  l10n,
  pagination,
  schema,
  search,
  selected = [],
  sort: propSort = true,
  store,
  onPress,
  onScroll = () => {},
  onSelect,
  ...others
}) => {
  const [filter, setFilter] = useState([]);
  const [limit, setLimit] = useState(pagination);
  const [scrollTo, setScrollTo] = useState();
  const [sort, setSort] = useState({});

  useEffect(() => {
    let nextFilter = propFilter;
    let nextSort = propSort;
    if (store) {
      const storeFilter = Storage.get(`${store}:filter`);
      if (storeFilter?.length) nextFilter = storeFilter;

      const storeSort = Storage.get(`${store}:sort`);
      if (storeSort) nextSort = storeSort;
    }

    setFilter(nextFilter);
    setSort(nextSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!store) return;
    Storage.set(`${store}:filter`, filter);
  }, [filter, store]);

  const handleAddFilter = (item) => {
    setFilter([...filter, item]);
    setScrollTo(0);
  };

  const handleRemoveFilter = (index) => {
    filter?.splice(index, 1);
    setFilter([...filter]);
    setScrollTo();
  };

  const handleHeadSelect = () => {
    const next = select({ dataSource, filter, schema, search, selected, sort });
    onSelect(selected.length !== next.length ? next.map(({ id }) => id) : []);
  };

  const handleScroll = (event) => {
    if (pagination && event.percentY > PAGINATION_DISPATCHER && limit < dataSource.length) setLimit(limit + pagination);
    onScroll(event);
  };

  const handleSelect = (rowId) => {
    onSelect(selected.includes(rowId) ? selected.filter((selectId) => selectId !== rowId) : [...selected, rowId]);
  };

  const handleSort = (field) => {
    const nextSort = sort[field] === undefined ? { [field]: true } : sort[field] ? { [field]: false } : {};

    Storage.set(`${store}:sort`, nextSort);
    setSort(nextSort);
  };

  const { testId } = others;
  const fields = Object.keys(schema);
  const { length: filterFields } = filter?.filter(({ field } = {}) => fields.includes(field)) || {};

  return React.createElement(
    ScrollView,
    {
      ...others,
      tag: 'table-container',
      scrollTo: scrollTo,
      onScroll: handleScroll,
      className: styles(style.container, !inline && style.outlined, others.className),
    },
    <>
      {filterFields > 0 && <Filter filter={filter} schema={schema} onRemove={(index) => handleRemoveFilter(index)} />}
      <Primitive tag="table" className={style.table}>
        <Primitive tag="thead">
          <Row
            checked={dataSource.length > 0 ? selected.length === dataSource.length : undefined}
            indeterminate={dataSource.length > 0 && selected.length > 0}
            l10n={l10n}
            schema={schema}
            sort={sort}
            onFilter={propFilter ? handleAddFilter : undefined}
            onSelect={onSelect ? handleHeadSelect : undefined}
            onSort={propSort ? handleSort : undefined}
            testId={testId ? `${testId}-head` : undefined}
          />
        </Primitive>
        <Primitive tag="tbody">
          {select({ dataSource, filter, limit, schema, search, sort }).map((row, index) => (
            <Row
              checked={selected.includes(row.id)}
              dataSource={row}
              id={row.id}
              key={row.id}
              schema={schema}
              onPress={onPress}
              onSelect={onSelect ? handleSelect : undefined}
              testId={testId ? `${testId}-${index}` : undefined}
            />
          ))}
        </Primitive>
      </Primitive>
    </>,
  );
};

Table.displayName = 'Component:Table';

Table.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  filter: PropTypes.arrayOf(PropTypes.shape({})),
  inline: PropTypes.bool,
  l10n: L10N_SHAPE,
  pagination: PropTypes.number,
  schema: PropTypes.shape({}).isRequired,
  search: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.shape()),
  sort: PropTypes.bool,
  store: PropTypes.string,
  onPress: PropTypes.func,
  onScroll: PropTypes.func,
  onSelect: PropTypes.func,
};
