import { filterItem } from './filterItem';

export const select = (
  items = [],
  { amenities: filterAmenities = [], bedType, board, freeCancellation, priceRange = {}, sizeRange = {} } = {},
  { priceFactor = 1 } = {},
) =>
  items.filter(({ amenities = [], availability = 0, bedTypes = [], size: rawSize = '', ...item } = {}) => {
    const hasAmenities =
      filterAmenities.length === 0 || filterAmenities.every((item) => amenities.map(({ id }) => id).includes(item));

    const hasBedTypes = !bedType || bedTypes.includes(bedType);

    let hasSize = true;
    let [size] = rawSize.split(' ');
    if (size) {
      const { max: maxSize, min: minSize } = sizeRange;

      size = parseInt(size);
      hasSize = (!minSize || size >= minSize) && (!maxSize || size <= maxSize);
    }

    const {
      boards = [],
      cancellations = [],
      rates = [],
    } = filterItem(
      item,
      {
        board,
        freeCancellation,
        priceRange: { max: priceRange.max, min: priceRange.min },
      },
      priceFactor,
    );
    const hasOptions = availability === 0 || (boards.length > 0 && (cancellations.length > 0 || rates.length > 0));

    return hasAmenities && hasBedTypes && hasSize && hasOptions;
  });
