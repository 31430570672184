import { dateDiff, UTC } from '@mirai/locale';

import { METHOD } from '../../../../Payment/Payment.constants';
import { L10N } from '../FormAddon.l10n';

const { TPV } = METHOD;
const { ACTION_BOOK, ACTION_PROCEED_TO_PAYMENT } = L10N;

const getCtaKey = (date, method) =>
  method === TPV
    ? date && dateDiff(UTC(new Date()), date).days > 0
      ? ACTION_BOOK
      : ACTION_PROCEED_TO_PAYMENT
    : ACTION_BOOK;

export { getCtaKey };
