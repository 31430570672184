export const L10N = {
  ACTION_LOGIN: { id: 'common.action.login' },
  ACTION_BOOK_AND_JOIN: { id: 'user.action.book_and_join' },
  ACTION_SELECT_ANOTHER_FARE: { id: 'user.action.select_another_fare' },

  LABEL_FREE_REGISTRATION_CLUB: { id: 'user.label.free_registration_club' },
  LABEL_AUTOMATICALLY_JOIN_CLUB: { id: 'user.label.automatically_join_club' },
  LABEL_SAVE_JOINING_CLUB: { id: 'user.label.save_join_club' },
  LABEL_FARE_EXCLUSIVE_MEMBERS: { id: 'user.label.fare_exclusive_members' },
  LABEL_UNLOCK_EXCLUSIVE_MEMBER: { id: 'user.label.unlock_exclusive_member' },
};
