const DEV = 'development';
const LOCALHOST = 'localhost';

export const loadEnvironment = (env = DEV) => {
  const src = env === DEV ? process.env.BUNDLE_DEV : env === LOCALHOST ? process.env.BUNDLE_LOCALHOST : undefined;
  if (!src) return;

  const script = document.createElement('script');
  script.defer = true;
  script.src = src;
  script.type = 'module';
  script.onreadystatechange = () => {};
  script.onload = () => {};

  delete window.Mirai.core;
  delete window.Mirai.environment;
  delete window.Mirai.version;

  document.body.appendChild(script);

  return script;
};
