import { InputOption, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './InputRadio.module.css';

const DEFAULTS = { small: true, type: 'radio' };

export const InputRadio = ({ label, name, options = [], value: propValue, onChange = () => {}, ...others }) => (
  <View {...others} wide className={style.container}>
    <Text bold>{label}</Text>
    {options.map(({ label, value }, index) => (
      <InputOption checked={propValue === value} key={index} {...{ ...DEFAULTS, label, name, value, onChange }} />
    ))}
  </View>
);

InputRadio.displayName = 'Mirai:Core:Checkout:InputRadio';

InputRadio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
