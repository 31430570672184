import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { styles } from '../../helpers';
import { Icon, ICON } from '../Icon';
import { Primitive } from '../Primitive';
import { View } from '../View';
import style from './ScrollView.module.css';

const ScrollView = ({
  behavior = 'smooth',
  children,
  defaultScrollIndicator = false,
  height,
  horizontal,
  scrollEventThrottle = 16, // * 60fps
  scrollIndicator = false,
  scrollTo,
  snap = true,
  tag = 'scrollview',
  width,
  onScroll = () => {},
  ...others
}) => {
  const ref = useRef();

  const [value, setValue] = useState();

  let timeout = null;

  useEffect(() => {
    const { current: el } = ref || {};
    if (!scrollIndicator || !el) return;

    const callback = () => setValue(calcValue(el));
    const observer = new MutationObserver(callback);
    observer.observe(el, { childList: true, subtree: true });

    if (horizontal ? el.clientWidth !== el.scrollWidth : el.clientHeight !== el.scrollHeight) {
      setValue(calcValue(el));
    }

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    const { current = {} } = ref || {};
    if (!current.scrollTo || scrollTo === undefined) return;

    current.scrollTo({ [horizontal ? 'left' : 'top']: scrollTo, behavior });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    if (!value) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = setTimeout(() => onScroll(value), scrollEventThrottle);

    return () => clearTimeout(timeout);
  }, [value]);

  const handleScroll = ({ target } = {}) => setValue(calcValue(target));

  const calcValue = ({ clientHeight, clientWidth, scrollHeight, scrollWidth, scrollTop, scrollLeft } = {}) => {
    const x = parseInt(scrollLeft, 10);
    const y = parseInt(scrollTop, 10);
    const offsetX = scrollWidth - clientWidth;
    const offsetY = scrollHeight - clientHeight;

    return (horizontal ? offsetX : offsetY) > 0
      ? {
          x,
          y,
          percentX: offsetX ? parseInt((x * 100) / offsetX, 10) : 0,
          percentY: offsetY ? parseInt((y * 100) / offsetY, 10) : 0,
        }
      : undefined;
  };

  const percent =
    scrollIndicator && !defaultScrollIndicator && value ? (horizontal ? value.percentX : value.percentY) : undefined;

  return React.createElement(
    Primitive,
    {
      ...others,
      ref,
      tag,
      onScroll: handleScroll,
      className: styles(
        style.scrollview,
        snap && style.snap,
        horizontal ? style.horizontal : style.vertical,
        (!scrollIndicator || !defaultScrollIndicator) && style.notIndicator,
        others.className,
      ),
      style: { ...others.style, height, width },
    },
    <>
      {children}
      {percent >= 0 && (
        <View
          tag="scrollview-indicator"
          className={styles(
            style.indicator,
            horizontal ? style.horizontal : style.vertical,
            percent > 95 && style.hide,
          )}
        >
          <View
            className={style.progress}
            style={
              horizontal ? { height: '100%', width: `${percent || 0}%` } : { height: `${percent || 0}%`, width: '100%' }
            }
          />
          <Icon headline level={2} value={horizontal ? ICON.RIGHT : ICON.DOWN} className={style.icon} />
        </View>
      )}
    </>,
  );
};

ScrollView.displayName = 'Primitive:ScrollView';

ScrollView.propTypes = {
  behavior: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultScrollIndicator: PropTypes.bool,
  height: PropTypes.number,
  horizontal: PropTypes.bool,
  scrollEventThrottle: PropTypes.number,
  scrollIndicator: PropTypes.bool,
  scrollTo: PropTypes.number,
  snap: PropTypes.bool,
  tag: PropTypes.string,
  width: PropTypes.number,
  onScroll: PropTypes.func,
};

export { ScrollView };
