import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceUser } from '@mirai/services';
import {
  Action,
  Button,
  Form,
  InputText,
  Modal as ModalBase,
  Notification,
  ScrollView,
  styles,
  useDevice,
  Text,
  View,
} from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getExternalToken, getSignupUrl } from './helpers';
import { MODE, URL_PARAMS } from './Session.constants';
import { L10N } from './Session.l10n';
import * as style from './Session.module.css';
import { AUTH_TYPE } from '../../Core.constants';
import { EVENT } from '../helpers';

const { ACTIVATE, ACTIVATE_ERROR, FINISH_SIGNUP } = MODE;

const Modal = ({
  children,
  showSignup = true,
  title,
  visible,
  onClose,
  onSuccess = () => {},
  onVisible = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    set,
    value: { club = {}, cookies = {}, forwarder, id, language, session, urlParams = {} },
  } = useStore();

  const [busy, setBusy] = useState(false);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [mode, setMode] = useState();
  const [resetPassword, setResetPassword] = useState(false);
  const [response, setResponse] = useState();
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    activate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (visible) {
      setResetPassword(false);
      setForm({});
      setFormError({ touched: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    setResponse();
    setResponseError();
  }, [resetPassword]);

  useEffect(() => {
    setResponseError();
    set({ login: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const activate = () => {
    const params = {};

    Object.keys(URL_PARAMS).forEach(
      (key) => URL_PARAMS[key].includes(urlParams[key]) && (params[key] = urlParams[key]),
    );

    if (params.login === FINISH_SIGNUP) {
      setMode(params.login);
    }

    if (urlParams.userActivated !== undefined) {
      setMode(urlParams.userActivated === 'true' ? ACTIVATE : ACTIVATE_ERROR);
      onVisible();
    }
    const { authentication: { type = AUTH_TYPE.MIRAI } = {} } = club;
    type !== AUTH_TYPE.MIRAI && externalLogin(type);
  };

  const externalLogin = async (type) => {
    const token = getExternalToken({ cookies, type });

    if (session) {
      !token && set({ session: undefined });
      return;
    }

    const response =
      token &&
      (await ServiceUser.externalLogin(token, type).catch((error) => {
        setResponseError(error);
      }));

    if (!response) return;

    set({ session: response });
  };

  const handleClose = () => {
    setMode();
    onClose();
  };

  const handleSubmit = async (value) => {
    setBusy(true);
    setResponseError(undefined);

    const props = { ...value, idClub: club.id, idHotel: id };
    const method = resetPassword ? 'resetPassword' : 'login';
    const response = await ServiceUser[method](props).catch((error) => {
      setResponseError(error);
      Event.publish(EVENT.METRICS, { id: `LOGIN:${resetPassword ? 'RESET_PASSWORD:' : ''}ERROR` });
    });

    if (response) {
      setResponse(response);
      if (!resetPassword) {
        set({ session: response });
        onSuccess();

        Event.publish(EVENT.LOGGED, { event: EVENT.LOGGED, response });
      }
      Event.publish(EVENT.METRICS, { id: `LOGIN:${resetPassword ? 'RESET_PASSWORD:' : ''}SUCCESS` });
    }
    setBusy(false);
  };

  const handleSignup = () => {
    const url = getSignupUrl({ forwarder, id, language, urlParams });
    if (!url) return;

    document.location.href = url;
  };

  const handleResetPassword = () => {
    setResetPassword(!resetPassword);

    if (!resetPassword) Event.publish(EVENT.METRICS, { id: 'LOGIN:FORGOT_PASSWORD' });
  };

  const hasChildren = !!children;

  return (
    <ModalBase
      fit={!isMobile && hasChildren}
      visible={visible}
      title={title || translate(!resetPassword ? (showSignup ? L10N.TITLE_WITH_SIGNUP : L10N.TITLE) : L10N.TITLE_RESET)}
      onClose={handleClose}
      className={style.modal}
    >
      <ScrollView className={style.scrollview}>
        <View row={!isMobile} wide className={styles(hasChildren && style.children)}>
          {children}
          <View wide className={style.form}>
            {!(response && resetPassword) ? (
              <Form
                {...others}
                validateOnMount
                onChange={setForm}
                onError={setFormError}
                onSubmit={handleSubmit}
                className={style.offset}
              >
                {[ACTIVATE, FINISH_SIGNUP].includes(mode) && !resetPassword && (
                  <Notification info className={style.info}>
                    <Text bold>{translate(L10N.NOTIFICATION_FINISH_SIGNUP_TITLE)}</Text>
                    <Text>{translate(L10N.NOTIFICATION_FINISH_SIGNUP_DESCRIPTION, { clubName: club.name })}</Text>
                  </Notification>
                )}

                {mode === ACTIVATE_ERROR && (
                  <Notification error className={style.info}>
                    <Text bold>{translate(L10N.NOTIFICATION_FINISH_SIGNUP_ERROR)}</Text>
                  </Notification>
                )}

                {(mode !== FINISH_SIGNUP || resetPassword) && (
                  <Text className={styles(style.description, style.wide)}>
                    {translate(!resetPassword ? L10N.FORM_DESCRIPTION : L10N.FORM_RESET_DESCRIPTION)}
                  </Text>
                )}
                <InputText
                  autoComplete="true"
                  name="email"
                  label={translate(L10N.EMAIL_LABEL)}
                  required
                  type="email"
                  value={form.email}
                />

                {!resetPassword && (
                  <InputText
                    autoComplete="true"
                    name="password"
                    label={translate(L10N.PASSWORD_LABEL)}
                    required
                    type="password"
                    value={form.password}
                  />
                )}

                {showSignup && !resetPassword && (
                  <View wide className={style.actions}>
                    <Action tag="div" disabled={busy} onPress={handleResetPassword} testId="login-action">
                      {translate(!resetPassword ? L10N.ACTION_RESET : L10N.ACTION_BACK)}
                    </Action>
                  </View>
                )}

                {!busy && responseError && (
                  <Notification error className={style.notification} testId="login-notification">
                    {translate(L10N.NOTIFICATION_ERROR)}
                  </Notification>
                )}

                <Button
                  busy={busy}
                  disabled={Object.keys(formError).length !== 0}
                  large
                  type="submit"
                  wide
                  onPress={() => {}}
                  className={style.button}
                  testId="login-button"
                >
                  {translate(resetPassword ? L10N.CTA_RESET : L10N.CTA)}
                </Button>
              </Form>
            ) : (
              <Notification large success className={style.offset}>
                {translate(L10N.NOTIFICATION_SUCCESS_RESET)}
              </Notification>
            )}

            {showSignup ? (
              (mode !== FINISH_SIGNUP || resetPassword) && (
                <Button
                  disabled={busy}
                  large
                  transparent
                  onPress={resetPassword ? handleResetPassword : handleSignup}
                  className={style.buttonTransparent}
                  testId="login-button-secondary"
                >
                  {translate(resetPassword ? L10N.ACTION_BACK : L10N.ACTION_SIGNUP)}
                </Button>
              )
            ) : (
              <Button
                disabled={busy}
                large
                transparent
                onPress={handleResetPassword}
                className={style.buttonTransparent}
                testId="login-button-secondary"
              >
                {translate(!resetPassword ? L10N.ACTION_RESET : L10N.ACTION_BACK)}
              </Button>
            )}
          </View>
        </View>
      </ScrollView>
    </ModalBase>
  );
};

Modal.displayName = 'Mirai:Core:Session:Login:Modal';

Modal.propTypes = {
  activate: PropTypes.bool,
  children: PropTypes.node,
  showSignup: PropTypes.bool,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onVisible: PropTypes.func,
  onSuccess: PropTypes.func,
};

export { Modal };
