/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { normalizePrice } from '../helpers';
import { getSession } from './helpers';
import { ERROR } from './User.constants';

export const bookings = ({ session: propSession } = {}) =>
  new Promise(async (resolve, reject) => {
    const session = propSession || getSession();
    if (!session) return reject(ERROR.NOT_SESSION);

    const response = await request({
      hostname: process.env.SERVICE_USER,
      endpoint: '/club/v1/get_reservations',
      headers: { authorization: `Bearer ${session.authorization}` },
    }).catch(reject);

    if (response)
      resolve(
        response.reservations?.map(
          ({
            cancelDate,
            cancellationCode,
            cancellationPolicy,
            cancellationPolicySortText,
            checkin,
            checkout,
            hotelCurrency,
            hotelInfo: { address, image, latitude, location, longitude, name, province, url } = {},
            idReservation,
            manageReservationUrl,
            reservationStatus,
            taxesDescription,
            totalPriceWithoutTaxes,
            totalPriceWithTaxes,
            totalTaxes,
          }) => ({
            // place
            hotel: name,
            location: {
              address,
              city: location,
              province,
              latitude,
              longitude,
            },
            url: {
              site: url,
              manage: manageReservationUrl,
            },
            image,
            // booking
            booking: idReservation,
            status: reservationStatus,
            checkIn: checkin,
            checkOut: checkout,
            cancellation: {
              text: cancellationPolicy,
              summary: cancellationPolicySortText,
              code: cancellationCode,
              date: cancelDate,
            },
            // finance
            currency: hotelCurrency,
            price: {
              base: normalizePrice(totalPriceWithoutTaxes, hotelCurrency),
              taxes: normalizePrice(totalTaxes, hotelCurrency),
              text: taxesDescription,
              total: normalizePrice(totalPriceWithTaxes, hotelCurrency),
            },
          }),
        ),
      );
    else reject(ERROR.UNKNOWN);
  });
