import { useDevice } from '@mirai/ui';
import { View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../Skeleton';

export default () => {
  const { isMobile } = useDevice();

  return (
    <View
      row
      style={{
        gap: 'var(--mirai-ui-space-XXS)',
        height: 'var(--mirai-ui-button-height)',
        padding: '0 calc(var(--mirai-ui-button-padding-x) / 2)',
      }}
    >
      <Skeleton icon />
      {!isMobile && <Skeleton action words={1} />}
    </View>
  );
};
