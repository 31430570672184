import { ScrollView, View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../../../__shared__';

const ModalTwinSkeleton = () => {
  return (
    <View
      style={{
        backgroundColor: 'var(--mirai-ui-content-background)',
        height: '100%',
        padding: 'var(--mirai-ui-space-M)',
      }}
    >
      <ScrollView style={{ gap: 'var(--mirai-ui-space-M)', maxHeight: '90svh' }}>
        {Array.from({ length: 8 }).map((_, index) => (
          <View
            key={index}
            row
            style={{
              boxShadow: 'inset 0 0 0 1px var(--mirai-ui-content-border)',
              backgroundColor: 'var(--mirai-ui-base)',
              minHeight: '120px',
            }}
          >
            <Skeleton style={{ borderRadius: 0, height: '120px', width: '100px' }} />
            <View style={{ borderRadius: 0, height: '120px', width: '220px' }}>
              <View style={{ height: '100%', padding: '0.75rem 1rem', justifyContent: 'space-between' }}>
                <Skeleton headline level={2} words={2} />
                <View>
                  <Skeleton action words={1} />
                  <Skeleton action words={1} />
                </View>
              </View>
            </View>
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

ModalTwinSkeleton.displayName = 'Mirai:Core:Rates:ModalTwin:Skeleton';

export { ModalTwinSkeleton };
