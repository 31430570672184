import { useLocale } from '@mirai/locale';
import { Text } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from '../Booking.l10n';

const FormEdit = (others) => {
  const { translate } = useLocale();

  return <Text {...others}>{translate(L10N.LABEL_EDIT_BOOKING_TEXT)}</Text>;
};

FormEdit.displayName = 'Mirai:Core:Booking:FormEdit';

FormEdit.propTypes = {
  onChange: PropTypes.func,
};

export { FormEdit };
