import { Event } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Menu as MenuBase, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { EVENT, ICON } from '../../helpers';
import { ACTION, STATE } from '../Booking.constants';
import { L10N } from '../Booking.l10n';
import * as style from '../Booking.module.css';

const Menu = ({ dataSource = {}, onPress = () => {}, ...others }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const refContainer = useRef();

  const [visible, setVisible] = useState(isDesktop);

  useEffect(() => {
    setVisible(isDesktop || false);

    !isDesktop &&
      refContainer?.current &&
      Event.publish(EVENT.FOOTER_HEIGHT_CHANGE, { height: refContainer?.current.offsetHeight });
  }, [isDesktop]);

  const handlePress = (action) => {
    if (!isDesktop) setVisible(false);
    if (!action) return;

    onPress(action);
  };

  const { isPostponable = false, state = STATE.CONFIRMED } = dataSource;
  const is = {
    cancelled: state === STATE.CANCELLED,
    confirmed: state === STATE.CONFIRMED,
    postponable: state === STATE.CONFIRMED && isPostponable,
  };

  return (
    <View ref={refContainer} {...others} className={styles(style.menu, style.noPrint, others.className)}>
      <MenuBase
        options={[
          { icon: ICON.PRINT, label: translate(L10N.ACTION_PRINT), value: ACTION.PRINT, divider: true },
          { icon: ICON.MAIL, label: translate(L10N.ACTION_RESEND_BOOKING), value: ACTION.RESEND, divider: true },
          is.postponable
            ? {
                icon: ICON.CALENDAR_EDIT,
                label: translate(L10N.ACTION_EDIT_BOOKING),
                value: ACTION.EDIT,
                divider: true,
              }
            : undefined,
          is.confirmed
            ? {
                icon: ICON.CALENDAR_CANCEL,
                label: translate(L10N.ACTION_CANCEL_BOOKING),
                value: ACTION.CANCEL,
              }
            : undefined,
        ]}
        visible={visible}
        onPress={handlePress}
        testId="booking-menu"
      />

      {!isDesktop && (
        <Button large wide onPress={() => setVisible(!visible)} testId="show-booking-menu">
          {translate(L10N.ACTION_SHOW_OPTIONS)}
        </Button>
      )}
    </View>
  );
};

Menu.displayName = 'Mirai:Core:Booking:Menu';

Menu.propTypes = {
  dataSource: PropTypes.shape({
    state: PropTypes.oneOf(Object.values(STATE)),
  }),
  onPress: PropTypes.func,
};

export { Menu };
