import { shell } from './shell';

const SHELL_TITLE = 'FormData ()';

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-Requested-With': 'XMLHttpRequest',
};

const AbortControllerProxy = () =>
  typeof AbortController === 'undefined'
    ? {
        abort: () => {},
        signal: undefined,
      }
    : new AbortController();

export const formData = async ({ endpoint, hostname, headers, method = 'POST', timeout, ...props } = {}) => {
  const controller = AbortControllerProxy();
  const { Mirai: { version } = {} } = window || {};

  if (timeout) setTimeout(() => controller.abort(), timeout);

  return new Promise((resolve, reject) => {
    const url = `${hostname}${endpoint}`;
    fetch(url, {
      headers: {
        ...(version ? { client: `@mirai/core v${version}` } : {}),
        ...HEADERS,
        ...headers,
      },
      method,
      signal: timeout ? controller.signal : undefined,
      body: Object.entries(props)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&'),
    })
      .then(async (response) => {
        let value = await response.text();

        if (value.length > 0) value = JSON.parse(value);

        if (response.status >= 400) {
          shell.error(SHELL_TITLE, 'EXECUTE:: ', url, { response });
          reject({ code: response.status, message: (value || {}).message, payload: value });
        } else {
          shell.info(SHELL_TITLE, url, 'EXECUTE:: ', { response: JSON.stringify(value) });
          resolve(value);
        }
      })
      .catch(({ message = 'Something wrong happened. Try again.', response } = {}) => {
        shell.error(SHELL_TITLE, url, 'EXECUTE:: ', { response, message });
        reject({
          code: response ? response.status : 500,
          message,
        });
      });
  });
};
