export const getScriptUrl = ({ clientId, region, SellerId }) => {
  let url;
  if (region === 'UK') url = process.env.METHOD_AMAZON_SCRIPT_UK;
  else if (region === 'US') url = process.env.METHOD_AMAZON_SCRIPT_US;
  else url = process.env.METHOD_AMAZON_SCRIPT_DE;

  if (clientId !== process.env.AMAZON_PROVIDER_CLIENT_ID && SellerId !== process.env.AMAZON_PROVIDER_SELLER_ID)
    url = url.replace('/sandbox', '');

  return url;
};
