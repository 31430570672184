import { Storage, DomainAdapter } from '../Storage';
import { HOSTNAME_STORE_KEY } from './request.definition';

export const setGlobalHostname = (value) => {
  const store = new Storage({ adapter: DomainAdapter });

  store.set(HOSTNAME_STORE_KEY, value);

  return store.get(HOSTNAME_STORE_KEY);
};
