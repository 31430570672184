export class DomainAdapter {
  constructor() {
    window.Mirai = window.Mirai || {};
    return this;
  }

  get(key) {
    return window.Mirai[key];
  }

  set(key, value) {
    if (value) window.Mirai[key] = value;
    else delete window.Mirai[key];
  }
}
