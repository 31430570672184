import { METHOD } from '../Payment.constants';

export const getImage = ({ method } = {}) => {
  let image;

  if (method === METHOD.AMAZON_PAY) image = 'amazonpay.png';
  else if (method === METHOD.APLAZAME) image = 'aplazame.png';
  else if (method === METHOD.BIZUM) image = 'bizum.png';
  else if (method === METHOD.CRIPTAN) image = 'criptan.png';
  else if (method === METHOD.OXXO) image = 'oxxo.png';

  return image;
};
