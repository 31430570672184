import { Event, useStore } from '@mirai/data-sources';
import { dateCalc, dateFormat, getDateFormat, parseDate, useLocale } from '@mirai/locale';
import { ServiceCountry, ServiceUser } from '@mirai/services';
import { Button, Form, InputDate, InputOption, InputSelect, InputText, Notification, Text } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACCOUNT_FIELDS } from './Settings.constants';
import { L10N } from './Settings.l10n';
import * as style from './Settings.module.css';
import { NotificationRequiredFields } from '../../../__shared__';
import { EVENT, getCountryCode } from '../../../helpers';

const Account = ({ lisa = false, onSubmit = () => {} } = {}) => {
  const {
    set,
    value: { locale, session = {} },
  } = useStore();
  const { translate } = useLocale();

  const [busy, setBusy] = useState(false);
  const [countries, setCountries] = useState({});
  const [form, setForm] = useState(session);
  const [formError, setFormError] = useState({});
  const [requiredFields, setRequiredFields] = useState();
  const [responseError, setResponseError] = useState();

  const formatDate = getDateFormat(locale);

  useEffect(() => {
    setResponseError();
    set({ profile: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    (async () => {
      const next = (await ServiceCountry.list(locale)) || {};

      setCountries(next);
      setForm({ ...form, country: next[session.country] });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleSubmit = async () => {
    const hasErrors = Object.keys(formError).length !== 0;
    if (hasErrors) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return setRequiredFields(formError);
    }

    setBusy(true);
    setRequiredFields();
    setResponseError(undefined);

    const parameters = {
      ...form,
      country: getCountryCode(form.country, countries),
      dateOfBirth: dateFormat(parseDate(form.dateOfBirth, formatDate), { format: 'DD/MM/YYYY' }),
    };

    const response = await ServiceUser.update(parameters).catch((error) => {
      setResponseError(error);
    });

    if (response) {
      Event.publish(EVENT.NOTIFICATION, { success: true, ...L10N.SUCCESS_ACCOUNT });
      const nextSession = { ...session, ...parameters };
      set({ session: nextSession });
      onSubmit();
    }
    setBusy(false);
  };

  const countryCode = getCountryCode(form.country, countries);
  const has = {
    changes: ACCOUNT_FIELDS.some((key) => form[key] !== session[key]) || countryCode !== session.country,
    dateOfBirth: form.dateOfBirth?.length,
  };
  const hasRequiredFields = Object.keys(requiredFields || {}).length > 0;
  const inputRequiredProps = { required: true, requiredText: translate(L10N.LABEL_REQUIRED), showRequired: true };

  return (
    <Form
      validateOnMount
      onChange={setForm}
      onError={setFormError}
      onSubmit={handleSubmit}
      className={lisa ? style.lisa : undefined}
    >
      {hasRequiredFields && <NotificationRequiredFields form="account" values={requiredFields} />}

      {!lisa && (
        <Text wide className={style.descriptionAccount}>
          {translate(L10N.DESCRIPTION_ACCOUNT)}
        </Text>
      )}

      <InputText
        id="account-firstName"
        name="firstName"
        error={formError.firstName}
        label={translate(L10N.FIRSTNAME_LABEL)}
        {...inputRequiredProps}
        success={form.firstName !== session.firstName}
        value={form.firstName}
      />
      <InputText
        id="account-lastName"
        name="lastName"
        error={formError.lastName}
        label={translate(L10N.LASTNAME_LABEL)}
        {...inputRequiredProps}
        success={form.lastName !== session.lastName}
        value={form.lastName}
      />
      <InputText
        id="account-email"
        name="email"
        disabled
        label={translate(L10N.EMAIL_LABEL)}
        {...inputRequiredProps}
        type="email"
        value={form.email}
      />
      <InputDate
        id="account-dateOfBirth"
        name="dateOfBirth"
        error={has.dateOfBirth && !!formError.dateOfBirth}
        hint={has.dateOfBirth && formError.dateOfBirth?.max ? translate(L10N.DATEOFBIRTH_ERROR) : undefined}
        format={formatDate}
        label={translate(L10N.DATEOFBIRTH_LABEL)}
        max={dateFormat(dateCalc(new Date(), -18, 'years'), { format: formatDate })}
        {...inputRequiredProps}
        success={form.dateOfBirth !== session.dateOfBirth}
        type="inputDate"
        value={form.dateOfBirth}
      />
      <InputSelect
        id="account-country"
        name="country"
        label={translate(L10N.COUNTRY_LABEL)}
        placeholder={translate(L10N.COUNTRY_PLACEHOLDER)}
        options={Object.values(countries)}
        success={countryCode !== session.country}
        value={form.country}
      />
      <InputOption
        name="subscribed"
        checked={form.subscribed}
        type="checkbox"
        label={translate(L10N.SUBSCRIBED_LABEL)}
        small
        value={form.subscribed}
      />

      {responseError && (
        <Notification error className={style.notification}>
          {translate(L10N.ERROR)}
        </Notification>
      )}

      <Button busy={busy} wide onPress={handleSubmit} testId="button-save">
        {translate(L10N.CTA_ACCOUNT)}
      </Button>
    </Form>
  );
};

Account.displayName = 'Mirai:Core:Profile:Account';

Account.propTypes = {
  lisa: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export { Account };
