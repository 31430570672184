import { useDevice, View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../../../__shared__';

const SPACE = 'var(--mirai-ui-space-M)';

export default () => {
  const inputProps = { wide: true, style: { height: 'var(--mirai-ui-input-min-height)' } };
  const { isMobile } = useDevice();

  return (
    <View row={!isMobile} style={{ alignItems: 'flex-start', gap: SPACE }}>
      <Skeleton base wide style={{ width: isMobile ? undefined : 'var(--mirai-ui-breakpoint-S)' }}>
        <Skeleton button large wide words={2} />
        <Skeleton button large wide words={1} />
      </Skeleton>

      <View wide style={{ gap: SPACE }}>
        <Skeleton headline level={2} words={2} style={{ marginBottom: SPACE }} />
        <View>
          <Skeleton words={9} />
          <Skeleton small words={4} />
        </View>

        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} {...inputProps} />
        ))}

        <View row style={{ gap: SPACE }}>
          <Skeleton icon />
          <Skeleton small words={11} />
        </View>
      </View>
    </View>
  );
};
