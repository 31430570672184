import { Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import * as style from './Typewriter.module.css';

const BASE_DELAY = 60;
const WORD_LENGTH_FACTOR = 5;
const PUNCTUATION_DELAY = 80;
const VARIANCE = 0.2;

const Typewriter = ({ children = '', disabled = false, onTyped, ...others }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const wordsRef = useRef(children.split(' '));
  const timeoutRef = useRef(null);

  const getWordDelay = (word) => {
    let delay = BASE_DELAY;
    delay += word.length * WORD_LENGTH_FACTOR;

    const lastChar = word[word.length - 1];
    if (['.', ',', '!', '?', ':', ';'].includes(lastChar)) delay += PUNCTUATION_DELAY;

    const variance = 1 + (Math.random() * VARIANCE * 2 - VARIANCE);
    return Math.round(delay * variance);
  };

  useEffect(() => {
    wordsRef.current = children.split(' ');
    setDisplayedText('');
    setWordIndex(0);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [children]);

  useEffect(() => {
    if (disabled) return;

    if (wordIndex < wordsRef.current.length) {
      const currentWord = wordsRef.current[wordIndex];
      const delay = getWordDelay(currentWord);

      timeoutRef.current = setTimeout(() => {
        setDisplayedText((prev) => (prev ? prev + ' ' + currentWord : currentWord));
        setWordIndex((prevIndex) => prevIndex + 1);

        if (wordIndex === wordsRef.current.length - 1 && onTyped) onTyped();
      }, delay);

      return () => clearTimeout(timeoutRef.current);
    }
  }, [disabled, wordIndex, onTyped]);

  return disabled ? (
    <Text {...others}>{children}</Text>
  ) : (
    <View className={[style.typewriter, others.className]}>
      <Text {...others} className={[style.scaffold, others.className]}>
        {children}
      </Text>

      <Text {...others} className={[style.animated, others.className]}>
        {displayedText}
      </Text>
    </View>
  );
};

Typewriter.displayName = 'Typewriter';

Typewriter.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  onTyped: PropTypes.func,
};

export { Typewriter };
