const DATA_ATTRIBUTE = 'data-content';

export const getContents = (el = {}) => {
  const children = el.querySelectorAll ? el.querySelectorAll(`[${DATA_ATTRIBUTE}]`) : [];
  const contents = Array.from(children).map((child) => child.cloneNode(true));

  children.forEach((child) => child.remove());

  return contents;
};
