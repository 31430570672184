import { ACCOMMODATION_TYPE } from './constants';

const { APARTMENT, ACCOMMODATION, PITCH, ROOM, VILLA } = ACCOMMODATION_TYPE;

const DEFAULT_PLACE = 'ROOMS';

const PLACE_INDIVIDUAL = {
  APARTMENTS: APARTMENT,
  LODGINGS: ACCOMMODATION,
  PLOTS: PITCH,
  ROOMS: ROOM,
  VILLA: VILLA,
};

export const getAccommodationType = ({ dataSource, hotels = [], individual = DEFAULT_PLACE } = {}) => {
  let result;
  if (dataSource) {
    const types =
      Object.entries(dataSource)
        .filter(([id]) => hotels.length === 0 || hotels.includes(id))
        .map(([, { lodgingDenomination: type }]) => type) || [];

    if (types.includes(ROOM)) result = ROOM;
    else if (types.includes(ACCOMMODATION)) result = ACCOMMODATION;
    else if (types.includes(APARTMENT)) result = APARTMENT;
    else if (types.includes(PITCH)) result = PITCH;
    else if (types.includes(VILLA)) result = VILLA;
  }

  return result || PLACE_INDIVIDUAL[individual];
};
