import { useLocale } from '@mirai/locale';
import { Icon, styles, Text, Tooltip, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './Amenities.l10n';
import * as style from './Amenities.module.css';
import { ICON } from '../../../../../helpers';

const Amenities = ({ amenities = [], highlight = false, preview = false }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  const values = amenities
    .filter((item = {}) => (!highlight && !item.highlight) || (highlight && item.highlight))
    .slice(0, preview ? 3 : undefined);

  const oversized = !isMobile && highlight && !preview;

  return values.length ? (
    <View row={oversized} className={styles(oversized ? style.oversized : preview ? style.list : style.columns)}>
      {values
        .sort((a = {}, b = {}) => {
          if (a.exclusive === b.exclusive) return 0;
          return a.exclusive ? -1 : 1;
        })
        .map(({ exclusive, id, text } = {}, index) => (
          <View key={index} row={!oversized} className={style.content}>
            {highlight || preview ? (
              <Icon
                accent={oversized}
                value={exclusive ? ICON.PREMIUM : ICON[id] || ICON.AMENITY}
                headline
                level={oversized ? 2 : 3}
              />
            ) : (
              <Text small>•</Text>
            )}
            <Text
              action
              className={styles(style.text, (oversized || !preview) && style.textDoubleLine)}
              aria-label={text}
            >
              {text}
            </Text>
            {!highlight && exclusive && (
              <Tooltip text={translate(L10N.LABEL_ONLY_FOR_ROOM)}>
                <Icon value={ICON.PREMIUM} />
              </Tooltip>
            )}
          </View>
        ))}
    </View>
  ) : null;
};

Amenities.propTypes = {
  amenities: PropTypes.arrayOf(
    PropTypes.shape({
      exclusive: PropTypes.bool,
      highlight: PropTypes.bool,
      id: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
  highlight: PropTypes.bool,
  preview: PropTypes.bool,
};

Amenities.displayName = 'Mirai:Core:Rates:Item:Amenities';

export { Amenities };
