import { dateDiff } from '@mirai/locale';

import { parseCustomData, sendData } from './helpers';
import { formatPrice } from '../helpers/legacy';

export const checkoutResponse = async ({
  instances = [],
  response: { countryCode: country, tracking = {} } = {},
  store: { finder: { calendar = [] } = {} } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    hotelInfo: { currency: hotelCurrency = {}, propertyName } = {},
    reservedRooms = [],
    totalPriceWithTaxes,
  } = tracking;

  const { days } = dateDiff(...calendar);
  let contents = [];
  let numItems = 0;
  reservedRooms.forEach(({ averagePriceWithoutTaxes = 0, numRoomsToReserve = 1, rateId } = {}) => {
    const quantity = days * numRoomsToReserve;

    contents.push({ id: rateId, itemPrice: formatPrice(averagePriceWithoutTaxes, hotelCurrency), quantity });
    numItems += quantity;
  });

  const commonData = {
    customData: parseCustomData({
      contents,
      country,
      hotelCurrency,
      numItems,
      propertyName,
      value: formatPrice(totalPriceWithTaxes, hotelCurrency),
    }),
    eventName: 'InitiateCheckout',
  };

  const responses = await Promise.all(
    instances.map(({ idTracking, pixelId }) => sendData({ ...commonData, idTracking, pixelId })),
  ).catch(() => {});

  return responses;
};
