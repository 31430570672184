import { toParties } from '../__shared__/Occupation/helpers/toParties';

export const parseToParties = (occupation = []) => {
  let value;

  try {
    value = window.btoa(JSON.stringify(toParties(occupation)));
  } catch {
    value = undefined;
  }

  return value;
};
