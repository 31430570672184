import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { styles } from '../../helpers';
import { Icon, ICON, Primitive, View } from '../../primitives';
import { InputOption } from '../InputOption';
import { Tooltip } from '../Tooltip';
import { ColumnFilter } from './Table.ColumnFilter';
import { L10N_SHAPE } from './Table.constants';
import style from './Table.module.css';

const CUSTOM_EVENT_TYPES = ['button', 'checkbox', 'input', 'radio', 'submit'];

const Row = ({
  checked,
  dataSource,
  id,
  indeterminate,
  l10n,
  schema,
  sort = {},
  onFilter,
  onPress,
  onSelect,
  onSort,
  ...others
}) => {
  const [focus, setFocus] = useState();

  const isHead = dataSource === undefined;
  const { testId } = others;

  const handlePress = (field, event) => {
    const { target: { type } = {} } = event;
    if (CUSTOM_EVENT_TYPES.includes(type)) return;

    isHead && onSort ? onSort(field) : onPress ? onPress(dataSource, event) : undefined;
  };

  const handleFocus = (field) => {
    setFocus(field);
  };

  const handleSelect = (value, event) => {
    event.preventDefault();
    onSelect(id, event);
  };

  const cacheField = useCallback((value) => value, []);

  return (
    <Primitive tag="tr" {...others} className={styles(checked && style.selected, onPress && style.onPress)}>
      {Object.keys(schema)
        .filter((field) => !schema[field].hidden)
        .map((field, index) => {
          const { filter: hasFilter = true, label, sort: hasSort = true, tooltip, type = 'text' } = schema[field];
          const fieldFilter = schema[field]?.bind || field;
          const selectable = onSelect && index === 0;

          return React.createElement(
            Primitive,
            {
              key: `${id}-${field}`,
              tag: isHead ? 'th' : 'td',
              testId: testId ? `${testId}-${field}` : undefined,
              onClick: !isHead || hasSort ? (event) => handlePress(field, event) : undefined,
            },
            <View
              forceRow
              className={styles(style.column, selectable && style.selectable, !isHead && !selectable && style[type])}
            >
              {selectable && (
                <InputOption
                  checked={checked}
                  indeterminate={indeterminate}
                  name="checkbox"
                  onChange={handleSelect}
                  className={style.checkbox}
                  testId={testId ? `${testId}-checkbox` : undefined}
                />
              )}

              {isHead ? (
                tooltip ? (
                  <Tooltip text={tooltip}>
                    <Primitive className={style.tooltip}>{label}</Primitive>
                  </Tooltip>
                ) : (
                  label
                )
              ) : (
                cacheField(dataSource[field])
              )}

              {isHead && ((onSort && hasSort) || (onFilter && hasFilter)) && (
                <View row className={style.icons}>
                  {onSort && hasSort && (
                    <Icon
                      value={sort[field] === false ? ICON.UP : ICON.DOWN}
                      className={styles(style.icon, sort[field] === undefined && style.disabled)}
                    />
                  )}
                  {onFilter && hasFilter && (
                    <ColumnFilter
                      field={fieldFilter}
                      l10n={l10n}
                      schema={schema}
                      visible={fieldFilter === focus}
                      onFocus={handleFocus}
                      onSubmit={onFilter}
                    />
                  )}
                </View>
              )}
            </View>,
          );
        })}
    </Primitive>
  );
};

Row.displayName = 'Component:Table:Row';

Row.propTypes = {
  checked: PropTypes.bool,
  dataSource: PropTypes.shape({}),
  id: PropTypes.any,
  indeterminate: PropTypes.bool,
  l10n: L10N_SHAPE,
  schema: PropTypes.shape({}).isRequired,
  sort: PropTypes.shape({}),
  onFilter: PropTypes.func,
  onPress: PropTypes.func,
  onSelect: PropTypes.func,
  onSort: PropTypes.func,
};

export { Row };
