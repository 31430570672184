import { DEFAULT_LOCALE } from '@mirai/locale';

const [DEFAULT_LANGUAGE] = DEFAULT_LOCALE.split('-');

export const getPlacesOptions = (items = [], language) => {
  let options = [];

  const parseItems = (items = [], level = 0) => {
    items.forEach((item = {}) => {
      const { visible, isHotel, tags = [] } = item;

      if (visible || isHotel === false) {
        const { ids: id = [], name: title = {}, tagId: value } = item;
        options.push({
          id,
          isHotel,
          level,
          title: title[language] || title[DEFAULT_LANGUAGE],
          value,
        });
      }

      if (tags.length > 0) parseItems(tags, level + 1);
    });
  };

  parseItems(items);

  return options;
};
