import { SessionAdapter, Storage } from '@mirai/data-sources';

import { getClubRate } from './getClubRate';
import { CLUB_RATE_STORAGE_VIEWED } from '../Rates.constants';

const storage = new Storage({ adapter: SessionAdapter });

export const showClubRate = ({ cart, clubDiscount, dataSource, isPestana, session }) => {
  if (
    !isPestana &&
    clubDiscount &&
    !session &&
    !storage.get(CLUB_RATE_STORAGE_VIEWED) &&
    cart.filter((board) => getClubRate({ board, dataSource })).length > 0
  ) {
    storage.set(CLUB_RATE_STORAGE_VIEWED, true);

    return true;
  }
  return false;
};
