const MANDATORY_HEIGHT = 420;

export const getFieldPosition = (ref) => {
  if (!ref?.current) return;

  const { bottom, top } = ref.current.getBoundingClientRect();
  const spaceAbove = top;
  const spaceBelow = window.innerHeight - bottom;

  return spaceAbove >= MANDATORY_HEIGHT && spaceBelow < MANDATORY_HEIGHT ? { top: true } : { bottom: true };
};
