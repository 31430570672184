import { dataLayerPush } from '../../../helpers';
import { formatPrice } from '../../helpers/legacy';

export const bookingResponse = ({ checkin, checkout, hotelCurrency, idReservation, reservedRooms } = {}) => {
  dataLayerPush({
    'duetto.data': reservedRooms.map(({ occupation, rateName, roomTypeName, totalPriceWithTaxes } = {}) => ({
      b: idReservation,
      r: formatPrice(totalPriceWithTaxes, hotelCurrency),
      cc: hotelCurrency.code,
      rc: rateName,
      rt: roomTypeName,
      na: occupation.numAdults,
      nc: occupation.totalChildren,
      sd: checkin,
      ed: checkout,
    })),
    event: 'duetto.Booking',
  });
};
