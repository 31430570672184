export const sanitizeComponent = (el) => {
  if (!el.getAttribute('data-ready')) {
    try {
      el.innerHTML = '';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`WARNING: element has an issue using innerHTML.`, { el });
    }
    el.setAttribute('data-ready', 'true');
  }
};
