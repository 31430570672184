if (!Object.fromEntries) {
  Object.fromEntries = function (iterable) {
    if (iterable == null) {
      throw new TypeError('Object.fromEntries() requires a single iterable argument');
    }
    const obj = {};
    for (const pair of iterable) {
      if (Object(pair) !== pair) {
        throw new TypeError('Iterator value ' + pair + ' is not an entry object');
      }
      const [key, value] = pair;
      obj[key] = value;
    }
    return obj;
  };
}

if (!Array.prototype.flat) {
  Array.prototype.flat = function (depth = 1) {
    const flatten = (arr, depth) => {
      return depth > 0
        ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatten(val, depth - 1) : val), [])
        : arr.slice();
    };

    return flatten(this, depth);
  };
}
