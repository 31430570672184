const EVENT_DOMAIN = `mirai:event`.toUpperCase();

const handlers = {};

export const Event = {
  publish: (name = 'event', props = {}) => {
    document.dispatchEvent(new CustomEvent(`${EVENT_DOMAIN}:${name}`, { detail: props }));

    return;
  },

  subscribe: (name = 'event', callback) => {
    const eventName = `${EVENT_DOMAIN}:${name}`;
    const handleEvent = ({ detail }) => callback(detail);
    if (!handlers[eventName]) handlers[eventName] = [];

    handlers[eventName].push({ callback, handler: handleEvent });
    document.addEventListener(eventName, handleEvent);

    return;
  },

  unsubscribe: (name = 'event', callback) => {
    const eventName = `${EVENT_DOMAIN}:${name}`;
    const { handler } =
      (handlers[eventName] || []).find(({ callback: propCallback }) => propCallback === callback) || {};

    if (handler) {
      document.removeEventListener(eventName, handler);
      handlers[eventName] = handlers[eventName].filter(({ callback: propCallback }) => propCallback !== callback);
    }

    return;
  },
};
