const BUTTON_PROPS = {
  id: 'button-sipay',
  ['data-notab']: 1,
  ['data-cardholdername']: 'true',
  ['data-theme']: 'blue',
  ['data-hiddenprice']: 'true',
  ['data-template']: 'embedded',
  ['data-autosave']: 'true',
  type: 'button',
};

const SCRIPT_ID = 'script-sipay';

export { BUTTON_PROPS, SCRIPT_ID };
