/* eslint-disable react/no-unknown-property */
import { useLocale } from '@mirai/locale';
import { Button, Notification, styles, Text, Theme, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { SCRIPT_ID } from './FormPaycomet.constants';
import { L10N } from './FormPaycomet.l10n';
import * as style from './FormPaycomet.module.css';
import { useMutationObserver } from './helpers';
import { InputField } from '../InputField';

export const FormPaycomet = ({
  currencyDecimals = 2,
  info: { amount } = {},
  publicKey = process.env.PROVIDER_PAYCOMET_PUBLICKEY,
  onSuccess = () => {},
  ...others
}) => {
  const { locale, translate } = useLocale();

  const formRef = useRef();
  const tokenRef = useRef();
  const errorRef = useRef();

  useMutationObserver(
    tokenRef.current,
    () => {
      const { value: nextToken = '' } = document.getElementById('paytpvToken') || {};
      if (nextToken.length > 0) setToken(nextToken);
    },
    { attributes: true },
  );

  useMutationObserver(errorRef.current, () => {
    const { textContent: nextError = '' } = document.getElementById('paymentErrorMsg') || {};
    if (nextError?.length > 0) {
      setError(nextError);
      setBusy(false);
    }
  });

  const [busy, setBusy] = useState(false);
  const [token, setToken] = useState();
  const [error, setError] = useState();
  const [expire, setExpire] = useState({});

  useEffect(() => {
    const exists = document.getElementById(SCRIPT_ID);
    if (!exists) {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src = `${process.env.PROVIDER_PAYCOMET_SCRIPT}?lang=${locale.split('-')[0]}`;

      document.head.appendChild(script);
    }
    return () => {
      const el = document.getElementById(SCRIPT_ID);
      if (el) el.parentNode.removeChild(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) return;

    setBusy(false);
    setError();
    onSuccess({ token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleExpireChange = (event) => {
    const { name, value = '' } = event.target || {};
    setExpire({ ...expire, [name]: value.replace(/[^\d]/g, '') });
  };

  const handleCardTokenized = (event) => {
    event.preventDefault();
    setBusy(true);
  };

  const handlePress = (event) => {
    event.stopPropagation();
    formRef.current.submit();
  };

  const getInlineStyle = () => {
    const { inputColor, inputFontSize, font } = Theme.get();

    return {
      card_number: `border:0px;background-image:none;color:${inputColor};font-family:${font};font-size:${inputFontSize};outline: 0;padding:0!important;`,
      cvv: `border:0px;color:${inputColor};font-family:${font};font-size:${inputFontSize};padding:0!important;`,
    };
  };
  const { card_number, cvv } = getInlineStyle();

  return (
    <View {...others} className={styles(style.container, others.className)}>
      <Text action>{translate(L10N.LABEL_SECURE_PAGE)}</Text>
      <form ref={formRef} id="paycometPaymentForm" onSubmit={handleCardTokenized} action="javascript:void(0)">
        <input type="hidden" name="amount" value={(amount * Math.pow(10, currencyDecimals)).toFixed()} />
        <input type="hidden" data-paycomet="jetID" value={publicKey} />
        <input ref={tokenRef} type="hidden" id="paytpvToken" name="paytpvToken" value="" />
        <InputField label={translate(L10N.LABEL_CARD_NAME)} value="value">
          <input data-paycomet="cardHolderName" className={style.inputName} />
        </InputField>
        <InputField label={translate(L10N.LABEL_CARD_NUMBER)} value="value">
          <div id="paycomet-pan" className={style.inputName}></div>
          <input
            paycomet-style={card_number}
            paycomet-name="pan"
            paycomet-placeholder={translate(L10N.LABEL_CARD_NUMBER_PLACEHOLDER)}
          />
        </InputField>
        <View row>
          <InputField label={translate(L10N.LABEL_EXPIRATION)} value="value">
            <View row className={style.inputName}>
              <input
                onChange={handleExpireChange}
                value={expire.month}
                name="month"
                data-paycomet="dateMonth"
                maxLength={2}
                className={style.inputExpire}
              />
              /
              <input
                onChange={handleExpireChange}
                value={expire.year}
                name="year"
                data-paycomet="dateYear"
                maxLength={2}
                className={style.inputExpire}
              />
            </View>
          </InputField>

          <InputField label={translate(L10N.LABEL_CVV)} className={style.inputCVV} value="value">
            <div id="paycomet-cvc2" data-paycomet="cvc2" className={style.inputName}></div>
            <input paycomet-name="cvc2" paycomet-style={cvv} />
          </InputField>
        </View>
        <Button busy={busy} large nativeTag preventDefault={false} wide onPress={handlePress} className={style.button}>
          {translate(L10N.ACTION_PAY)}
        </Button>
      </form>
      {error && (
        <Notification error wide>
          {error}
        </Notification>
      )}
      <div ref={errorRef} id="paymentErrorMsg" style={{ display: 'none' }}></div>
    </View>
  );
};

FormPaycomet.displayName = 'Mirai:Payments:ButtonPayment:FormPaycomet';

FormPaycomet.propTypes = {
  currencyDecimals: PropTypes.number,
  info: PropTypes.shape({
    amount: PropTypes.number,
  }),
  publicKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};
