/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { getSession } from './helpers';
import { ERROR } from './User.constants';

export const signout = () =>
  new Promise(async (resolve, reject) => {
    const session = getSession();

    if (!session) return reject(ERROR.NOT_SESSION);

    const response = await request({
      method: 'DELETE',
      hostname: process.env.SERVICE_USER,
      endpoint: '/club/v1/leave',
      headers: { authorization: `Bearer ${session.authorization}` },
    }).catch(reject);

    response ? resolve(response.result?.toLowerCase() === 'success') : reject(ERROR.UNKNOWN);
  });
