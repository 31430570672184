import { DEFAULT_CURRENCY } from '@mirai/locale';

export const getCurrency = ({ cookies = {}, currency, urlParams = {} } = {}) => {
  const { forceUri, currency: urlCurrency } = urlParams;

  return (
    (forceUri ? urlCurrency : undefined) ||
    cookies.currency ||
    urlCurrency ||
    (currency !== DEFAULT_CURRENCY ? currency : undefined)
  );
};
