import { CURRENCIES_WITHOUT_DECIMALS } from '@mirai/locale';

export const getCheckoutParams = ({
  cart = [],
  clubDiscount,
  currency,
  dataSource: { currency: hotelCurrency } = {},
  extras = [],
  session,
  // eslint-disable-next-line no-unused-vars
  urlParams: { rates, ...urlParams } = {}, // !TODO: find best solution for discard rates url param
  ...others
} = {}) => {
  const {
    deals = [],
    hotelverse,
    items,
    totalPrice,
  } = cart.reduce(
    (result, { id, checkoutPrice = 0, deals = [], idHotelverse, roomNumber, subjectToAvailability } = {}) => {
      const idRR = id.replace(/--/g, '|');
      const pushed = result.deals.find((item) => item.idRR === idRR);
      if (pushed) return result;

      const { length: amount } = cart.filter((item) => item.id === id);

      result.deals.push({ idRR, offersApplied: deals.join() });
      roomNumber && result.hotelverse.push({ idRR, idHotelverse, roomNumber: [roomNumber], subjectToAvailability });
      result.items += `${result.items ? ',' : ''}${idRR}:${amount}`;
      result.totalPrice += +(
        amount *
        checkoutPrice *
        (CURRENCIES_WITHOUT_DECIMALS.includes(hotelCurrency) ? 1 : 100)
      ).toFixed();

      return result;
    },
    { deals: [], hotelverse: [], items: '', totalPrice: 0 },
  );

  const totalExtras = +(
    extras.filter(({ included } = {}) => !included).reduce((total, { value = 0 } = {}) => total + value, 0) *
    (CURRENCIES_WITHOUT_DECIMALS.includes(hotelCurrency) ? 1 : 100)
  ).toFixed();

  return {
    ...urlParams,
    applyClubDiscount: !!session || clubDiscount,
    currency,
    extras: JSON.stringify(
      extras.map(({ id: idExtra, included = false, amount: numSelected = 0, nights: nightsToApply = 0 }) => ({
        idExtra,
        included,
        nightsToApply,
        numSelected,
      })),
    ),
    offersAppliedBySelectedRoom: JSON.stringify(deals),
    roomNumberBySelectedRoom: JSON.stringify(hotelverse),
    rooms: items,
    totalPrice: totalPrice + totalExtras,
    ...others,
  };
};
