import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { useDevice } from '../../hooks';
import { Layer, LayerContent } from '../../primitives';
import { Modal } from '../Modal';
import style from './Menu.module.css';
import { Option } from './Menu.Option';

const Menu = ({ children, options = [], Template = Option, title, visible, onPress = () => {}, ...others }) => {
  const { isMobile } = useDevice();

  const { testId } = others;

  const renderOptions = () =>
    options
      .filter((option) => !!option)
      .map((option = {}, index) => (
        <Template
          {...option}
          key={index}
          onPress={(event) => onPress(option.value, event)}
          testId={testId ? `${testId}-${index}` : undefined}
        />
      ));

  return !isMobile ? (
    <Layer
      forceRender={false}
      {...others}
      tag="menu"
      visible={visible}
      className={styles(style.menu, visible && style.visible, others.className)}
    >
      {children}
      <LayerContent>{renderOptions()}</LayerContent>
    </Layer>
  ) : (
    <>
      {children}
      <Modal
        title={title}
        visible={visible}
        onClose={(event) => onPress(undefined, event)}
        onOverflow={(event) => onPress(undefined, event)}
        className={styles(style.menu, others.className)}
        testId={testId ? `${testId}-modal` : undefined}
      >
        {renderOptions()}
      </Modal>
    </>
  );
};

Menu.displayName = 'Component:Menu';

Menu.propTypes = {
  children: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.node,
      disabled: PropTypes.bool,
      divider: PropTypes.bool,
      icon: PropTypes.func,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  Template: PropTypes.any,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onPress: PropTypes.func,
};

export { Menu };
