export const getDomain = (url) => {
  if (!url) return;

  const hostname = new URL(url).hostname;
  const parts = hostname.split('.');

  if (parts.length > 2) return parts.slice(-2).join('.');

  return hostname;
};
