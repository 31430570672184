import { useLocale } from '@mirai/locale';
import { Button, Icon, Input, styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { ROLE } from './FieldPromocode.constants';
import { L10N } from './FieldPromocode.l10n';
import * as style from './FieldPromocode.module.css';
import { ICON } from '../../helpers';
import { Field } from '../Field';

const FieldPromocode = ({ compacted, hasPlaces, inline, text, variant, visible, onPress, onSubmit, ...others }) => {
  const { translate } = useLocale();

  const ref = useRef();

  const handlePress = () => {
    ref?.current?.querySelector('[name="mirai:finder:promocode"]')?.focus();
    onPress && onPress();
  };

  const { bottom, top, ...rest } = others;

  return (
    <Field
      {...{ bottom, compacted, hasPlaces, inline, top, variant, visible }}
      icon={ICON.DISCOUNT}
      inputMode={!compacted}
      label={translate(L10N.LABEL_PROMOTION)}
      onPress={handlePress}
      className={(style.container, others.className)}
      style={others.style}
      testId={others.testId ? others.testId : undefined}
    >
      <View ref={ref} row className={styles(compacted && style.content)}>
        {compacted && <Icon value={ICON.DISCOUNT} />}
        <Input
          name="mirai:finder:promocode"
          {...rest}
          role={ROLE}
          placeholder={translate(L10N.LABEL_CODE)}
          className={styles(style.input, compacted && style.compacted)}
          testId={others.testId ? `${others.testId}-input` : undefined}
        />
        {onSubmit && <Button onPress={onSubmit}>{text || translate(L10N.ACTION_SEARCH)}</Button>}
      </View>
    </Field>
  );
};

FieldPromocode.displayName = 'Mirai:Core:FieldPromocode';

FieldPromocode.propTypes = {
  compacted: PropTypes.bool,
  hasPlaces: PropTypes.bool,
  inline: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.shape({}),
  visible: PropTypes.bool,
  onPress: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { FieldPromocode };
