import { MemoryAdapter } from './memory';

export class SessionAdapter {
  constructor() {
    try {
      this.session = sessionStorage;
    } catch {
      this.memory = new MemoryAdapter();
    }

    return this;
  }

  get(key) {
    return this.session ? this.session.getItem(key) : this.memory.get(key);
  }

  set(key, value) {
    if (!this.session) {
      this.memory.set(key, value);
      return;
    }

    if (value) this.session.setItem(key, value);
    else this.session.removeItem(key);
  }
}
