import { Event, useStore } from '@mirai/data-sources';
import { currencyFormat, dateDiff, dateFormat, parseDate, useLocale } from '@mirai/locale';
import { Action, Button, Icon, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CardHotel } from './components';
import { getLabelTaxes } from './helpers';
import { DATE_FORMAT } from './Rates.constants';
import { L10N } from './Rates.l10n';
import * as style from './Rates.module.css';
import { Card, Cards } from '../__shared__';
import { EVENT, OCCUPATION_TYPE } from '../helpers';
import { getAccommodationLabel, getOccupationLabel, ICON } from '../helpers';

const Unavailability = ({
  calendar = {},
  hotel: { email, phone } = {},
  hotels = [],
  nights = 1,
  priceFactor,
  suggestions: { calendar: calendarSuggestions, description, occupation: occupationSuggestions } = {},
  taxesType,
  onMapView,
}) => {
  const { isMobile, width } = useDevice();
  const { locale, translate } = useLocale();
  const { value: store } = useStore();

  useEffect(() => {
    Event.publish(EVENT.FINDER, { calendar });
    Event.publish(EVENT.METRICS, { id: 'UNAVAILABILITY:RENDER' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { currency, finder = {}, hotel: { accommodationType: hotelAccommodationType, variants = {} } = {} } = store;

  const handleCalendar = (from, to) => {
    Event.publish(EVENT.FINDER, { finder: { ...finder, calendar: [from, to] } });
    Event.publish(EVENT.METRICS, { id: 'UNAVAILABILITY:CALENDAR' });
  };

  const handleOccupation = (rooms = []) => {
    Event.publish(EVENT.FINDER, { finder: { ...finder, occupation: rooms.map(({ occupation } = {}) => occupation) } });
    Event.publish(EVENT.METRICS, { id: 'UNAVAILABILITY:OCCUPATION' });
  };

  const handleFinder = () => {
    Event.publish(EVENT.FINDER, occupationSuggestions ? { focus: 'occupation' } : { calendar, focus: 'calendar' });
    Event.publish(EVENT.METRICS, { id: 'UNAVAILABILITY:REFINE_SEARCH' });
  };

  const renderPrice = ({ price: propPrice, onPress } = {}) => {
    const price = propPrice / priceFactor;

    return (
      <View row className={style.price}>
        <View>
          <Text small>{translate(L10N.LABEL_FROM)}</Text>
          <Text bold headline level={price >= 10000 ? 3 : 2}>
            {currencyFormat({ currency, locale, maximumFractionDigits: 0, minimumFractionDigits: 0, value: price })}
          </Text>
          {!!taxesType && (
            <Text light small>
              {translate(getLabelTaxes(taxesType))}
            </Text>
          )}
        </View>

        <Button onPress={onPress}>{translate(L10N.ACTION_VIEW_MORE)}</Button>
      </View>
    );
  };

  const renderContactInfo = () => (
    <Text action className={style.contactInfo}>
      {translate(L10N.LABEL_CONTACT_INFO, {
        email: (
          <Action
            href={`mailto:${email}`}
            target="_blank"
            underline
            onPress={() => Event.publish(EVENT.METRICS, { id: 'UNAVAILABILITY:MAILTO' })}
            className={style.action}
          >
            {email}
          </Action>
        ),
        phone: (
          <Action
            href={`tel:${phone}`}
            target="_blank"
            underline
            onPress={() => Event.publish(EVENT.METRICS, { id: 'UNAVAILABILITY:TEL' })}
            className={style.action}
          >
            {phone}
          </Action>
        ),
      })}
    </Text>
  );

  const { place: { id: [id] = [] } = {} } = finder;
  const { accommodationType = hotelAccommodationType } = variants[id] || {};

  const hasSuggestions = calendarSuggestions?.length > 0 || occupationSuggestions?.length > 0;

  return (
    <View className={style.unavailability}>
      <View row={!isMobile} className={style.banner}>
        <Icon accent value={occupationSuggestions ? ICON.PERSON_OFF : ICON.CALENDAR_BUSY} className={style.icon} />
        <View wide className={style.texts}>
          <Text accent bold headline className={style.title}>
            {occupationSuggestions
              ? translate(L10N.LABEL_NO_AVAILABILITY_OCCUPATION)
              : description || translate(L10N.LABEL_NO_AVAILABILITY_CALENDAR)}
          </Text>
          {!isMobile && email && phone && renderContactInfo()}
        </View>
        <Button onPress={handleFinder} className={style.button}>
          {translate(L10N.ACTION_REFINE_SEARCH)}
        </Button>
        {isMobile && renderContactInfo()}
      </View>

      {hasSuggestions && (
        <Text bold headline>
          {translate(L10N.LABEL_AVAILABLE_ALTERNATIVES)}
        </Text>
      )}

      {calendarSuggestions?.length > 0 && (
        <Cards>
          {calendarSuggestions.map(({ price = 0, taxes, ...others } = {}, index) => {
            const from = parseDate(others?.from);
            const to = parseDate(others?.to);

            return (
              <Card key={`calendar:${index}`}>
                <View className={style.card}>
                  <View>
                    <View row>
                      <Text bold headline>
                        {dateFormat(from, { locale, ...DATE_FORMAT })}
                      </Text>
                      <Text className={style.anchorDates}>—</Text>
                      <Text bold headline>
                        {dateFormat(to || focus, { locale, ...DATE_FORMAT })}
                      </Text>
                    </View>
                    <Text action>{`${dateDiff(from, to).days} ${translate(L10N.LABEL_NIGHTS)}`}</Text>
                  </View>

                  {renderPrice({ price, taxes, onPress: () => handleCalendar(from, to) })}
                </View>
              </Card>
            );
          })}
        </Cards>
      )}

      {occupationSuggestions?.length > 0 && (
        <Cards>
          {occupationSuggestions.map(({ rooms = [], price = 0, taxes }, index) => (
            <Card key={`rooms:${index}`}>
              <View className={style.card}>
                <Text bold capitalize headline>
                  {`${rooms.length} ${getAccommodationLabel(
                    { plural: rooms.length > 1, type: accommodationType },
                    translate,
                  )}`}
                </Text>

                <View className={style.distribution}>
                  {rooms.map(({ name, occupation = [] } = {}, roomIndex) => (
                    <View key={`occupation:${index}:room:${roomIndex}`} className={style.room}>
                      <Text action capitalize>
                        {name}
                      </Text>
                      {occupation.map((item = {}, itemIndex) => (
                        <Text key={`occupation:${index}:room:${roomIndex}:${itemIndex}`} light small>
                          {`${getOccupationLabel({ ...item, ages: [] }, translate)} ${
                            item.type === OCCUPATION_TYPE.CHILD
                              ? `(${translate(L10N.LABEL_YEARS_OLD, { amount: item.ages?.sort().join('-') })})`
                              : ''
                          }`}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>

                {renderPrice({ price, taxes, onPress: () => handleOccupation(rooms) })}
              </View>
            </Card>
          ))}
        </Cards>
      )}

      {hasSuggestions && hotels.length > 0 && (
        <View row={!isMobile} className={style.spaceBetween}>
          <Text bold headline>
            {translate(L10N.LABEL_ALTERNATIVE_HOTEL_SUGGESTIONS, { value: hotels.length })}
          </Text>
          {hotels.length > 1 && (
            <Button secondary wide={isMobile} onPress={() => onMapView(hotels)}>
              {translate(L10N.ACTION_SHOW_ON_MAP)}
            </Button>
          )}
        </View>
      )}
      <Cards>
        {hotels.map((item, index) => (
          <CardHotel
            key={`${item.index || index}:${width}`}
            {...item}
            nights={nights}
            priceFactor={priceFactor}
            unavailability
          />
        ))}
      </Cards>
    </View>
  );
};

Unavailability.displayName = 'Mirai:Core:Rates:Unavailability';

Unavailability.propTypes = {
  calendar: PropTypes.shape({}),
  hotel: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  hotels: PropTypes.arrayOf(PropTypes.shape({})),
  nights: PropTypes.number,
  priceFactor: PropTypes.number,
  suggestions: PropTypes.shape({
    calendar: PropTypes.arrayOf(PropTypes.shape({})),
    occupation: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  taxesType: PropTypes.number,
  onMapView: PropTypes.func,
};

export { Unavailability };
