import { LocalAdapter, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT } from './constants';
import { getStorageKey } from './helpers';

const IGNORE_CACHE = true;

export const get = (id, hotelId) => {
  if (!id) return undefined;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });

  const { variants: { [hotelId]: variant = {} } = {}, ...features } = storage.get(getStorageKey(), IGNORE_CACHE) || {};

  return variant[id] !== undefined ? variant[id] : features[id] !== undefined ? features[id] : undefined;
};
