/* eslint-disable no-async-promise-executor */
import { LocalAdapter, Storage, request } from '@mirai/data-sources';

import { getSession } from './helpers';
import { CACHE_LIMIT, ERROR, USER_STORAGE_KEY } from './User.constants';

export const update = ({ country, currentPassword, dateOfBirth, firstName, lastName, password, subscribed } = {}) =>
  new Promise(async (resolve, reject) => {
    const session = getSession();
    if (!session) return reject(ERROR.NOT_SESSION);

    const form = {
      country,
      currentPassword,
      dateOfBirth,
      firstname: firstName,
      lastname: lastName,
      password,
      subscribed,
    };

    const response = await request({
      method: 'PUT',
      hostname: process.env.SERVICE_USER,
      endpoint: '/club/v1/modify_member',
      headers: { authorization: `Bearer ${session.authorization}` },
      ...form,
    }).catch(reject);

    if (!response) return reject(ERROR.UNKNOWN);

    const nextSession = { ...session, country, dateOfBirth, firstName, lastName, subscribed };
    const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
    storage.set(`${USER_STORAGE_KEY}:session`, nextSession);

    resolve(nextSession);
  });
