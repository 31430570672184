import PropTypes from 'prop-types';
import React from 'react';

import { RatesExplorer } from '../__shared__/RatesExplorer';

const Deals = ({ id: offerId, ...others }) => <RatesExplorer {...{ offerId, ...others }} />;

Deals.displayName = 'Mirai:Core:Deals';

Deals.propTypes = {
  id: PropTypes.string,
};

export { Deals };
