import PropTypes from 'prop-types';
import React from 'react';

import { getIconState, styles } from '../../../helpers';
import { Icon } from '../../../primitives';
import style from '../InputText.module.css';

const IconState = ({ error, success, warning }) => (
  <Icon
    value={getIconState({ error, success, warning })}
    className={styles(style.icon, error ? style.error : warning ? style.warning : success ? style.success : undefined)}
  />
);

IconState.displayName = 'Component:InputText.IconState';

IconState.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
};

export { IconState };
