import { TYPE } from './Occupation.constants';

const { ADULT, CHILD, GUEST } = TYPE;

export const L10N = {
  ACTION_ADD_PLACE_TYPE: { id: 'finder.action.add_place_type' }, // ! TODO: Should bind type
  ACTION_REMOVE: { id: 'finder.action.remove' },
  ACTION_SEARCH: { id: 'common.action.search' },

  LABEL_AGE: { id: 'common.label.age' },
  LABEL_AGE_HINT: { id: 'common.label.age_hint' },
  LABEL_OCCUPATION_TYPE: {
    [ADULT]: { id: 'common.label.occupation_type_adult', defaultMessage: '　　　　' },
    [CHILD]: { id: 'common.label.occupation_type_child' },
    [GUEST]: { id: 'common.label.occupation_type_guest', defaultMessage: '　　　　' },
  },
  LABEL_PLACE_TYPE: { id: 'finder.label.place_type' }, // ! TODO: Should bind type
  LABEL_PLACE_TYPE_COUNT: { id: 'finder.label.place_type_count' }, // ! TODO: Should bind type

  NOTIFICATION_ERROR_UNFILLED_FIELDS: { id: 'common.notification.error_unfilled_fields' },
  NOTIFICATION_WARNING_OCCUPATION: { id: 'finder.notification.warning_occupation' },
};
