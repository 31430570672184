import { UTC } from './UTC';

const YEAR = 'Y';
const MONTH = 'M';

const MONTHS_31 = [0, 2, 4, 6, 7, 9, 11];
const MONTHS_30 = [3, 5, 8, 10];

const DELIMITERS = ['.', '/', '-', '. '];

export const parseDate = (value = '', format = `DD/MM/YYYY`) => {
  if (typeof value !== 'string') return undefined;

  const today = UTC(new Date());
  const delimiter = DELIMITERS.find((item) => format.includes(item)) || undefined;
  const formatParts = format.toUpperCase().match(/(D{1,2})|(M{1,2})|(Y{1,4})/gi);
  const valueParts = delimiter
    ? value.split(delimiter).map((part) => parseInt(part, 10))
    : formatParts.map((part) => parseInt(value.substring(format.indexOf(part), format.indexOf(part) + part.length)));

  let date = {};

  formatParts.forEach((part, index) => {
    const key = part.substring(0, 1);
    date[key] = valueParts[index];
    if (part.includes(MONTH)) date[key] -= 1;
    else if (part.includes(YEAR) && date[key] < 100) date[key] += date[key] < 70 ? 2000 : 1900;
  });

  return date.M > 11 ||
    (MONTHS_31.includes(date.M) && date.D > 31) ||
    (MONTHS_30.includes(date.M) && date.D > 30) ||
    (date.M === 1 && date.Y % 4 === 0 && date.D > 29) ||
    (date.M === 1 && date.Y % 4 > 0 && date.D > 28)
    ? undefined
    : UTC(new Date(date.Y || today.getFullYear(), date.M >= 0 ? date.M : today.getMonth(), date.D || today.getDate()));
};
