export const checkRoom = (rooms = [], dataSource = []) => {
  if (!rooms.length || !dataSource.length) return false;

  let valid = true;

  rooms.every((room = []) => {
    room
      .filter((individual) => !!individual)
      .every(({ ages = [], type: key } = {}) => {
        const found = dataSource.find(({ type }) => parseInt(key) === type);
        const { fulfill } = found || {};

        if (!found || (fulfill && ages.filter((age) => age === undefined).length)) valid = false;
        return valid;
      });

    return valid;
  });

  return valid;
};
