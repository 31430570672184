export const getPrices = ({ cart = [], extras = [] }) => {
  const taxesExtras = extras.reduce(
    (total, { amount, nights, valueAmount, valueNights, value = {} } = {}) =>
      total + (value[valueAmount || amount][valueNights || nights]?.taxes || 0),
    0,
  );
  const totalBaseExtras = extras.reduce(
    (total, { amount, nights, valueAmount, valueNights, value = {} } = {}) =>
      total + (value[valueAmount || amount][valueNights || nights]?.basePrice || 0),
    0,
  );

  let basePrice = 0;
  let fees = 0;
  let taxes = taxesExtras;
  let breakdown = [];

  cart.forEach((cartItem) => {
    if (!cartItem) return;

    basePrice += cartItem.basePrice;
    fees += cartItem.fees || 0;
    taxes += cartItem.taxes || 0;
    cartItem.discount.breakdown.forEach((discount) => {
      const current = breakdown.find(({ type } = {}) => type === discount.type);

      if (!current) breakdown.push({ ...discount });
      else current.value += discount.value;
    });
  });

  return { basePrice, discount: { breakdown }, fees, taxes, totalBaseExtras };
};
