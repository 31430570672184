import { getCookie } from '@mirai/data-sources';

const JWT = '_jwt';

export const parseExternalToken = ({ uid } = {}) => {
  const cookie = getCookie(JWT);
  let result;

  if ((cookie?.match(/\./g) || []).length !== 2) result = { uid };
  else
    result = {
      uid: JSON.parse(window.atob(cookie.slice(cookie.indexOf('.') + 1, cookie.lastIndexOf('.'))))?.sub || uid,
    };

  return result;
};
