const ID_SEPARATOR = '--';

export const getMetaRates = (value) => {
  if (!value) return;

  let rates;

  try {
    rates = value
      .split('_')
      .map((id) => window.atob(id).replace(/[|]/g, ID_SEPARATOR))
      .filter((id) => id.includes(ID_SEPARATOR));
  } catch (error) {
    return;
  }
  if (rates?.length) return rates;
};
