import { DISCOUNT_TYPE } from '../Rates.constants';

export const getClubRate = ({
  board: { discount: { breakdown = [] } = {}, rateId, roomId } = {},
  dataSource: { items = [] } = {},
  rate: propRate,
} = {}) => {
  const rate =
    propRate || items.find(({ id } = {}) => id === roomId)?.rates?.find(({ id } = {}) => id === rateId) || {};

  const discount = !!breakdown.find(({ type }) => type === DISCOUNT_TYPE.CLUB);
  if (!discount && !rate.isClub) return;

  return { discount, rate: rate.isClub };
};
