import { Event } from '@mirai/data-sources';

export const trace = (state) => {
  if (!state) return;

  const { Mirai: { trackingReady } = {} } = window;
  const publish = () => Event.publish('setInformationTracking', state);

  if (!trackingReady) Event.subscribe('trackingReady', publish);
  else publish();
};
