import { getCheckoutParams } from './getCheckoutParams';
import { toUrlParams } from '../../helpers';

export const getForwarderUrl = ({
  cart = [],
  clubDiscount,
  currency,
  extras = [],
  forwarder: { checkout: url } = {},
  urlParams = {},
  session,
  ...others
} = {}) =>
  `${url}${toUrlParams(getCheckoutParams({ cart, clubDiscount, currency, extras, session, urlParams, ...others }))}`;
