import { dateCalc, dateDiff, parseDate } from '@mirai/locale';

import { MONTHS_IN_MOBILE } from '../../../../helpers';

export const getVisibleDate = (scrollPercentage, maxDate) => {
  if (scrollPercentage === undefined) return;

  const today = new Date();
  const maxMonths = (maxDate && dateDiff(today, parseDate(maxDate))?.months) || MONTHS_IN_MOBILE;
  const months = Math.round((scrollPercentage * maxMonths - 1) / 100);

  return months ? dateCalc(today, months, 'months') : today;
};
