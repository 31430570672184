import { ACCOMMODATION_TYPE, TYPE } from '../../../../../Core.constants';

const ACCOMMODATION_TYPE_NAME = Object.keys(ACCOMMODATION_TYPE);

export const getInitEvent = ({
  mobile,
  step,
  hotel: { accommodationType, id: hotelId, variants = {} } = {},
  id,
  type,
}) => ({
  chainId: type === TYPE.CHAIN ? id : undefined,
  device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
  event: 'init',
  home: window.location.host,
  hotelId,
  lodgingDenomination: ACCOMMODATION_TYPE_NAME[variants[hotelId]?.accommodationType || accommodationType],
  step,
});
