import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceFeatures, ServiceLisa } from '@mirai/services';
import { Modal, ScrollView, styles, Text } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { BANNED_COMPONENTS, DELAY_RESPONSE } from './Chat.constants';
import { L10N } from './Chat.l10n';
import * as style from './Chat.module.css';
import { ButtonDispatcher, Header, Input, Message, Welcome } from './components';
import { audioNotification } from './helpers';
import { EVENT } from '../helpers';

let connectionInterval;
let session;

const Chat = ({ ...others }) => {
  const { translate } = useLocale();
  const {
    set,
    value: {
      calendar,
      club,
      components = {},
      currency,
      hotel,
      id,
      locale,
      occupation,
      origin,
      session: stateSession,
      skeleton = false,
      tags,
      type,
    },
  } = useStore();

  const [busy, setBusy] = useState(false);
  const [config, setConfig] = useState();
  const [, setError] = useState();
  const [messages, setMessages] = useState([]);
  const [online, setOnline] = useState(false);
  const [visible, setVisible] = useState(false);
  const [welcome, setWelcome] = useState();

  useEffect(() => {
    if (skeleton || Object.keys(components).some((item) => BANNED_COMPONENTS.includes(item))) return;

    const nextConfig = process.env.NODE_ENV === 'production' ? ServiceFeatures.get('lisa', id) : {};
    if (!nextConfig) return;
    setConfig(nextConfig);

    (async () => {
      if (!(await handleOnline())) return;

      setWelcome(await ServiceLisa.welcome({ context: { club, hotel }, locale, session }).catch(handleError));
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skeleton]);

  useEffect(() => {
    if (!welcome) return;

    if (!messages.length) return addMessage({ ...welcome, timestamp: new Date().getTime() }, messages);
    set({ lisa: messages[messages.length - 1] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcome]);

  useEffect(() => {
    if (stateSession) session = stateSession;
  }, [stateSession]);

  useEffect(() => {
    if (!visible) return clearInterval(connectionInterval);

    connectionInterval = setInterval(handleOnline, online ? 60000 : 10000);
    return () => clearInterval(connectionInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, visible]);

  let storeContext = {
    calendar,
    club,
    currency,
    hotel,
    messages: messages
      .filter(({ storage, intent, text }) => !storage && (intent || text))
      .slice(-4)
      .map(({ intent, text, response: { form } = {} }) => ({ form, intent, text })),
    occupation,
    origin,
    tags,
    type,
  };

  const handleOnline = async () => {
    let next = false;
    const response = await ServiceLisa.status().catch(() => setOnline(next));
    if (response && !online) {
      next = true;
      setOnline(next);
    }
    return next;
  };

  const handleInput = (input, auto = false) => {
    const nextMessages = [...messages, { text: input, timestamp: new Date().getTime() }];
    setMessages(nextMessages);

    setTimeout(async () => {
      setBusy(true);
      const currentForm = messages.filter(({ storage }) => !storage).slice(-1)[0]?.response?.form;

      storeContext = { ...storeContext, form: currentForm };
      const response = await ServiceLisa.message({ auto, context: storeContext, input, locale, session }).catch(
        handleError,
      );
      if (response) addMessage(response, nextMessages, true);
      setBusy(false);
    }, DELAY_RESPONSE);
  };

  const handleAction = ({ context, form, intent, text } = {}) => {
    setBusy(true);

    setTimeout(async () => {
      const nextContext = { ...storeContext, ...context, form };
      const nextMessages = [...messages, { auto: false, text }];

      const response = await ServiceLisa.action({ context: nextContext, intent, locale, session }).catch(handleError);
      if (response) addMessage(response, nextMessages, true);
      setBusy(false);
    }, DELAY_RESPONSE);
  };

  const handleRetry = async () => {
    setBusy(true);

    const nextMessages = [...messages];
    nextMessages.pop();

    setMessages(nextMessages);
    const { text: input, response: { form } = {} } = nextMessages[nextMessages.length - 1] || {};
    storeContext = { ...storeContext, form };
    const response = await ServiceLisa.message({ context: storeContext, input, locale, session }).catch(handleError);
    if (response) addMessage(response, nextMessages, true);
    setBusy(false);
  };

  const handleError = (error) => {
    setError(error);
    setOnline(false);
  };

  const addMessage = (response = {}, messages = [], notify = false) => {
    if (!response) return;

    set({ lisa: response });
    setMessages([...messages, { auto: true, ...response }]);

    if (response.locale !== locale) Event.publish(EVENT.INTENT_LOCALE, { locale: response.locale });
    if (notify) audioNotification();
  };

  const { response: { form } = {} } = messages.filter(({ auto = false } = {}) => auto).pop() || [];
  const [welcomeMessage = {}, ...chatMessages] = messages.filter(({ auto, text }) => !(!auto && !text));

  return !skeleton && !!config ? (
    <>
      <Modal {...others} fit visible={visible} className={styles(style.container, others.className)}>
        <Header config={config} onClose={() => setVisible(false)} />

        {!chatMessages.length ? (
          <Welcome {...welcomeMessage.response} onInput={handleInput} />
        ) : (
          <>
            <ScrollView scrollTo={new Date().getTime()} snap={false} className={style.messages}>
              {chatMessages.map((message, index) => (
                <Message
                  {...message}
                  key={`message:${index}`}
                  disabled={busy || index < chatMessages.length - 1}
                  onAction={handleAction}
                  onRetry={index === chatMessages.length - 1 && chatMessages[index - 1]?.text ? handleRetry : undefined}
                />
              ))}
              {busy && <Message auto busy />}
            </ScrollView>
            <Input disabled={busy} form={form} onValue={handleInput} className={style.input} />
          </>
        )}
        <Text light tiny className={style.warning}>
          {translate(L10N.LABEL_WARNING_RESPONSES, { name: 'Lisa' })}
        </Text>
      </Modal>

      <ButtonDispatcher
        {...{ config, welcomeMessage }}
        ready={!!messages.length}
        visible={visible}
        onPress={() => setVisible(true)}
      />
    </>
  ) : null;
};

Chat.displayName = 'Mirai:Core:Chat';

Chat.propTypes = {
  name: PropTypes.string,
};

export { Chat };
