import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceBooking } from '@mirai/services';
import { Button, Modal as ModalBase, Theme, View } from '@mirai/ui';
import { Notification } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FormCancel } from './Booking.FormCancel';
import { FormEdit } from './Booking.FormEdit';
import { FormResend } from './Booking.FormResend';
import { EVENT } from '../../helpers';
import { ACTION, STATE } from '../Booking.constants';
import { L10N } from '../Booking.l10n';
import * as style from '../Booking.module.css';

const { CANCEL, EDIT, RESEND } = ACTION;

const Modal = ({ action, dataSource: { id, pinCode, token } = {}, onChange = () => {}, onClose, ...others }) => {
  const { translate } = useLocale();

  const {
    value: { urlParams: { idHotel } = {} },
  } = useStore();

  const [busy, setBusy] = useState();
  const [error, setError] = useState();
  const [form, setForm] = useState({});
  const [responseError, setResponseError] = useState();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (action) return setVisible(true);

    setForm({});
    setResponseError();
  }, [action]);

  const handleChange = (next) => {
    setForm({ ...form, ...next });
  };

  const handleError = (error) => {
    setError(error);
  };

  const handleSubmit = async () => {
    setBusy(true);
    const method = action === CANCEL ? 'cancel' : action === EDIT ? 'postpone' : 'email';
    const props = { id, idHotel, pinCode, token, ...form };
    const response = await ServiceBooking[method](props).catch(setResponseError);
    setBusy(false);

    if (response) {
      Event.publish(EVENT.NOTIFICATION, {
        success: true,
        ...(action === CANCEL
          ? L10N.NOTIFICATION_CANCEL_BOOKING_SUCESS
          : action === EDIT
          ? L10N.NOTIFICATION_EDIT_BOOKING_SUCCESS
          : L10N.NOTIFICATION_EMAIL_SUCCESS),
      });
      handleClose();
    }
    onChange({ response, state: action === CANCEL ? STATE.CANCELLED : action === EDIT ? STATE.POSTPONED : undefined });
  };

  const handleClose = () => {
    const { motionExpand = '0.3s' } = Theme.get();

    setVisible(false);
    setTimeout(onClose, parseFloat(motionExpand.replace('s')) * 1000);
  };

  return (
    <ModalBase
      {...others}
      title={translate(
        action === CANCEL
          ? L10N.ACTION_CANCEL_BOOKING
          : action === EDIT
          ? L10N.ACTION_EDIT_BOOKING
          : L10N.ACTION_RESEND_BOOKING,
      )}
      preventDefault={false}
      visible={visible}
      onClose={handleClose}
    >
      <View className={style.modal}>
        {!responseError ? (
          <>
            {React.createElement(action === CANCEL ? FormCancel : action === EDIT ? FormEdit : FormResend, {
              form,
              onChange: handleChange,
              onError: handleError,
            })}
            <View row className={style.buttons}>
              <Button large secondary wide onPress={handleClose} testId="cancel-button">
                {translate(L10N.ACTION_BACK)}
              </Button>
              <Button
                busy={busy}
                disabled={action === RESEND && !!error}
                large
                wide
                onPress={handleSubmit}
                testId="submit-button"
              >
                {action === CANCEL
                  ? translate(L10N.ACTION_CANCEL)
                  : action === EDIT
                  ? translate(L10N.ACTION_POSTPONE)
                  : translate(L10N.ACTION_SEND)}
              </Button>
            </View>
          </>
        ) : (
          <Notification large error wide>
            {translate(L10N.LABEL_SERVICE_ERROR)}
          </Notification>
        )}
      </View>
    </ModalBase>
  );
};

Modal.displayName = 'Mirai:Core:Booking:Modal';

Modal.propTypes = {
  action: PropTypes.oneOf(Object.values(ACTION)),
  dataSource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pinCode: PropTypes.number,
    token: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export { Modal };
