import { dateDiff, parseDate } from '@mirai/locale';

import { parseRate } from './parseRate';
import { TYPE } from '../../../../../Core.constants';
import { formatPrice } from '../../helpers/legacy';

export const parseItems = ({
  finder: { calendar = [], place: { id: [hotelId] = [] } = {}, promocode } = {},
  hotelInfo = {},
  id: instanceId,
  reservedExtras = {},
  reservedRooms = [],
  tracking: { bookingValues: { checkin, checkout, coupon } = {} } = {},
  type,
} = {}) => {
  const { currency: hotelCurrency, propertyName } = hotelInfo;
  const { days } = dateDiff(...(calendar.length ? calendar : [parseDate(checkin), parseDate(checkout)]));

  const rooms = reservedRooms.map(
    ({
      averagePriceWithoutTaxes: totalPriceWithoutTaxes,
      defaultBoardDescription: boardName,
      numRoomsToReserve = 1,
      rateId: id,
      rateName,
      roomTypeName,
    } = {}) =>
      parseRate({
        hotelId: type === TYPE.HOTEL ? instanceId : hotelId,
        hotelInfo,
        id,
        promocode: promocode || coupon || '',
        rate: { boardName, rateName, roomTypeName, totalPriceWithoutTaxes },
        quantity: numRoomsToReserve * days,
      }),
  );

  const extras = Object.keys(reservedExtras)
    .map((key) => reservedExtras[key])
    .map(({ id, name, multiplierPrice, priceWithoutTaxes, type }) => ({
      coupon: promocode || coupon || '',
      currency: hotelCurrency.code,
      item_brand: propertyName,
      item_category: 'Extra',
      item_category2: type,
      item_name: name,
      price: formatPrice(priceWithoutTaxes, hotelCurrency),
      quantity: multiplierPrice,
      item_id: `${id}`,
    }));

  return [...rooms, ...extras];
};
