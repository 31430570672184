import { request } from '@mirai/data-sources';

import { getFingerprint } from './helpers';

export const welcome = ({ context, locale, session = {} } = {}) =>
  request({
    method: 'POST',
    hostname: process.env.SERVICE_LISA,
    endpoint: '/welcome',
    headers: { authorization: session.authorization },
    context,
    locale,
    session,
    fingerprint: getFingerprint(session),
    time: new Date().getHours(),
    timezoneOffset: new Date().getTimezoneOffset(),
  });
