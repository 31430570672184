import { booking } from './booking';
import { bookings } from './bookings';
import { check } from './check';
import { externalLogin } from './externalLogin';
import { login } from './login';
import { logout } from './logout';
import { profile } from './profile';
import { resetPassword } from './resetPassword';
import { signout } from './signout';
import { signup } from './signup';
import { update } from './update';

export { ERROR as SERVICE_USER_ERRORS } from './User.constants';

export const ServiceUser = {
  booking,
  bookings,
  check,
  externalLogin,
  login,
  logout,
  profile,
  resetPassword,
  signout,
  signup,
  update,
};
