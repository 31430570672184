const LANGUAGES = [
  'English',
  'Español',
  'Français',
  'Deutsch',
  '日本語',
  'Italiano',
  'Português',
  'Dansk',
  'Suomi',
  'Norsk Bokmål',
  'Nederlands',
  'Polski',
  'Русский',
  'Svenska',
  'Türkçe',
  '中文',
  'Čeština ',
  'Ελληνικά',
  'العربية',
  'Magyar',
  'Català',
  'Euskara',
  'Tiếng Việt',
];

export { LANGUAGES };
