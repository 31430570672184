import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Pressable, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { L10N } from './List.l10n';
import * as style from './List.Room.module.css';

const ListRoom = ({ options = [], url, ...others }) => {
  const [selected, setSelected] = useState();
  const { currencyFormat, translate } = useLocale();
  const { value: { currency } = {} } = useStore();

  const handlePress = (id) => {
    setSelected(id);
    window.location = `${url}&roomTypeId=${id}`;
  };

  const currencyFormatProps = { currency, maximumFractionDigits: 0, minimumFractionDigits: 0 };

  return (
    <View {...others} className={styles(style.list, others.className)}>
      {options
        .filter((item) => (selected ? item.id === selected : true))
        .map((item) => {
          const { id, name, description, boards: [{ dayPrice, price, originalPrice } = {}] = [], images = [] } = item;

          return (
            <Pressable key={id} onPress={id ? () => handlePress(id) : undefined} className={style.item}>
              <View className={style.image} style={{ backgroundImage: `url(${images[0]?.thumbnail})` }} />
              <View className={style.content}>
                <View>
                  <Text bold brand headline>
                    {name}
                  </Text>
                  <Text light small>
                    {description.length > 56 ? `${description.substr(0, 56)}...` : description}
                  </Text>
                </View>

                <View>
                  <View row className={style.price}>
                    <Text bold headline level={price >= 10000 ? 3 : 2}>
                      {currencyFormat({ ...currencyFormatProps, value: price })}
                    </Text>

                    {price !== originalPrice && (
                      <Text action light className={style.lineThrough}>
                        {currencyFormat({ ...currencyFormatProps, value: originalPrice })}
                      </Text>
                    )}
                  </View>

                  <Text light small>
                    {`${currencyFormat({ ...currencyFormatProps, value: dayPrice })} ${translate(
                      L10N.LABEL_PER_NIGHT,
                    )}`}
                  </Text>
                </View>
              </View>
            </Pressable>
          );
        })}
    </View>
  );
};

ListRoom.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.label,
    }),
  ),
  url: PropTypes.string,
};

export { ListRoom };
