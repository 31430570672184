import { dateFormat, isDate, translate as translateBase } from '@mirai/locale';

import { calcMinStay } from './calcMinStay';
import { CALENDAR_FORMAT as format } from '../../../../helpers';
import { L10N } from '../Calendar.l10n';

export const calcTooltips = ({
  from,
  minStayDates,
  minStayType,
  rangeMinDays: propRangeMinDays,
  to,
  translate = translateBase,
} = {}) => {
  if (!isDate(from) || (to && !isDate(to))) return;

  const value = {};
  const rangeMinDays = calcMinStay({ from, minStayDates, minStayType, rangeMinDays: propRangeMinDays, to });

  if (minStayType !== undefined && from && !to && rangeMinDays > 1) {
    value[dateFormat(from, { format })] = {
      text: translate(L10N.LABEL_MIN_NIGHTS, { value: rangeMinDays }),
      pressable: false,
    };
  }

  return value;
};
