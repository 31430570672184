import { getPlacesOptions } from './getPlacesOptions';
import { TYPE } from '../Finder.constants';

export const getDataSource = ({
  config: {
    accomodation: { calendar = {}, occupation, rooms: { maxOccupation: maxRooms } = {}, variants = [] } = {},
    chainSelector,
  } = {},
  language,
  promo,
  type = TYPE.HOTEL,
} = {}) => {
  let places;
  let placesSource;
  if (type === TYPE.CHAIN && chainSelector) {
    const { placeHolder = {}, trees = [] } = chainSelector;
    places = { options: getPlacesOptions(trees, language), placeholder: placeHolder[language] };
    placesSource = trees;
  }

  return {
    calendar,
    occupation,
    places,
    placesSource,
    promo: promo ? { code: promo.code } : undefined,
    maxRooms,
    variants,
  };
};
