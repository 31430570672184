import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Primitive } from '../Primitive';
import { KEY_ENTER } from './Pressable.constants';
import style from './Pressable.module.css';

const Pressable = ({
  children,
  disabled,
  preventDefault = true,
  tabIndex = 0,
  tag = 'pressable',
  onEnter,
  onLeave,
  onPress = () => {},
  ...others
}) =>
  React.createElement(
    Primitive,
    {
      ...others,
      disabled,
      tag,
      ...(!disabled
        ? {
            tabIndex,
            onClick: (event) => {
              if (preventDefault) {
                event.preventDefault();
                event.stopPropagation();
              }
              onPress(event);
            },
            onKeyPress: (event) => {
              if (event.key === KEY_ENTER) {
                if (preventDefault) {
                  event.preventDefault();
                  event.stopPropagation();
                }
                onPress(event);
              }
            },
            onMouseEnter: onEnter,
            onMouseLeave: onLeave,
          }
        : {}),
      className: styles(style.pressable, disabled && style.disabled, others.className),
    },
    children,
  );

Pressable.displayName = 'Primitive:Pressable';

Pressable.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  preventDefault: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tag: PropTypes.string,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onPress: PropTypes.func,
};

export { Pressable };
