import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, DIRECTION_TYPE, Icon, Pressable, styles, Text, Theme, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { FIELD } from './Finder.constants';
import { TYPE, VIEW } from './Finder.constants';
import { L10N as L10N_FINDER } from './Finder.l10n';
import * as style from './Finder.module.css';
import { getHotels, getPlaceCaption } from './helpers';
import { Skeleton } from '../__shared__';
import { L10N } from '../__shared__/BookingDates/BookingDates.l10n';
import { ICON } from '../helpers';
import { getAccommodationType, getOccupationSummary } from '../helpers';

const { CHAIN, HOTEL } = TYPE;
const DATE_FORMAT_COMPACT = { month: 'short', day: 'numeric' };
const { LEFT } = DIRECTION_TYPE;

const Compact = ({
  dataSource: { places = {}, placesSource } = {},
  value: { calendar: [from, to] = [], occupation = [], place: { value: place } = {}, promocode } = {},
  onExpand = () => {},
  onSubmit,
  ...others
} = {}) => {
  const { isDesktop, isMobile } = useDevice();
  const { dateFormat, translate } = useLocale();
  const {
    value: {
      config: { hotels: dataSource, lodgingDenomination: individual } = {},
      locale,
      skeleton = false,
      type = HOTEL,
    },
  } = useStore();

  const direction = Theme.getDirection();
  const hasPlaces = !!(place || places.options) || (skeleton && type === CHAIN);
  const hotels = getHotels(placesSource, place);
  const captionProps = { action: !isDesktop, medium: isMobile, className: style.caption };
  const iconProps = { light: true, className: styles(style.icon, skeleton && style.skeleton) };

  return (
    <View
      row
      tag="finder-compact"
      className={styles(
        style.finder,
        style.compact,
        hasPlaces && style.hasPlaces,
        direction === LEFT ? style.directionLeft : style.directionRight,
      )}
      testId={others.testId ? `${others.testId}-compact` : undefined}
    >
      {hasPlaces && (
        <Pressable onPress={!skeleton ? () => onExpand(VIEW.PLACE) : undefined} className={style.pressable}>
          <Icon {...iconProps} value={FIELD.PLACE.icon} />
          {!isMobile && <Text {...captionProps}>{getPlaceCaption(place, places.options)}</Text>}
        </Pressable>
      )}

      <Pressable
        onPress={!skeleton ? () => onExpand(VIEW.CALENDAR) : undefined}
        className={[style.pressable, style.calendar]}
        testId={others.testId ? `${others.testId}-calendar-field` : undefined}
      >
        {(!isMobile || (isMobile && !hasPlaces)) && <Icon {...iconProps} value={FIELD.CALENDAR.icon} />}
        {!skeleton ? (
          <Text {...captionProps}>
            {`${from ? dateFormat(from, { locale, ...DATE_FORMAT_COMPACT }) : translate(L10N.LABEL_CHECK_IN)} — ${
              to ? dateFormat(to, { locale, ...DATE_FORMAT_COMPACT }) : translate(L10N.LABEL_CHECK_OUT)
            }`}
          </Text>
        ) : (
          <Skeleton {...captionProps} words={2} />
        )}
      </Pressable>

      <Pressable
        onPress={!skeleton ? () => onExpand(VIEW.OCCUPATION) : undefined}
        className={style.pressable}
        testId={others.testId ? `${others.testId}-occupation-field` : undefined}
      >
        <Icon {...iconProps} value={FIELD.OCCUPATION.icon} />
        {!skeleton ? (
          <Text {...captionProps}>
            {getOccupationSummary(
              occupation,
              translate,
              !isMobile ? getAccommodationType({ dataSource, hotels, individual }) : undefined,
            )}
          </Text>
        ) : (
          <Skeleton {...captionProps} words={1} />
        )}
      </Pressable>

      <Pressable
        onPress={!skeleton ? () => onExpand(VIEW.PROMOCODE) : undefined}
        className={style.pressable}
        testId={others.testId ? `${others.testId}-promocode` : undefined}
      >
        <Icon {...iconProps} value={FIELD.PROMOCODE.icon} />
        {promocode && !isMobile && <Text {...captionProps}>{promocode}</Text>}
      </Pressable>

      {onSubmit ? (
        skeleton ? (
          <Skeleton button small squared className={styles(style.button, style.skeleton)} />
        ) : (
          <Button
            small
            squared
            onPress={onSubmit}
            className={style.button}
            aria-label={translate(L10N_FINDER.ACTION_SEARCH)}
            testId={others.testId ? `${others.testId}-submit` : undefined}
          >
            <Icon value={ICON.SEARCH} />
          </Button>
        )
      ) : null}
    </View>
  );
};

Compact.propTypes = {
  dataSource: PropTypes.any,
  value: PropTypes.any,
  onExpand: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { Compact };
