import { getUrlBooking } from './getUrlBooking';
import { getUrlCriptan } from './getUrlCriptan';
import { getUrlTpv } from './getUrlTpv';
import { TYPE } from '../ButtonPayment.constants';

const CALLBACKS = {
  criptanNumOrder: getUrlCriptan,
  reservationCode: getUrlBooking,
  tpvNumOrder: getUrlTpv,
};

export const getUrl = ({ response: { type, url, ...response } = {}, ...props } = {}) => {
  const params = { ...response, ...props };
  const [callback] = Object.keys(params)
    .map((key) => CALLBACKS[key])
    .filter((value) => !!value);

  return callback ? callback(params) : type === TYPE.REDIRECT ? url : undefined;
};
