import { dateFormat, useLocale } from '@mirai/locale';
import { Icon, Text, Tooltip, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { DATE_FORMAT } from './TooltipDates.constants';
import * as style from './TooltipDates.module.css';
import { ICON } from '../../../../../helpers';

const TooltipDates = ({ captions = [], values = [], ...others }) => {
  const { locale } = useLocale();

  return values.length ? (
    <Tooltip
      pressable
      Template={() => (
        <>
          {values.map((date, index) => (
            <View key={index} row className={style.row}>
              <Text bold small>
                {dateFormat(date, { locale, ...DATE_FORMAT })}
              </Text>
              <Text small>{captions[index]}</Text>
            </View>
          ))}
        </>
      )}
      className={style.tooltip}
    >
      <Icon {...others} paragraph value={ICON.INFO} />
    </Tooltip>
  ) : null;
};

TooltipDates.displayName = 'Mirai:Core:Rates:Item:TooltipDates';

TooltipDates.propTypes = {
  captions: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.any),
};

export { TooltipDates };
