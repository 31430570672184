export const logged = ({ instances = [], response: { country, email, firstName, lastName, level } = {} } = {}) => {
  const value = {
    name: firstName?.split(' ').join('_'),
    lastName: lastName?.split(' ').join('_'),
    country,
    email,
    level: level?.split(' ').join('_'),
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
    step: 'LOGIN',
  };

  window.gtag('event', 'login', value);

  return value;
};
