import { parseItems } from './helpers';
import { formatPrice } from '../helpers/legacy';

export const bookingCancel = ({
  instances = [],
  response: { tracking = {} } = {},
  store: { finder = {}, id, type } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { hotelInfo = {}, idReservation, reservedExtras = {}, reservedRooms = [], totalPriceWithoutTaxes } = tracking;

  const { currency: hotelCurrency = {} } = hotelInfo;

  const value = {
    currency: hotelCurrency.code,
    items: parseItems({ finder, hotelInfo, id, reservedExtras, reservedRooms, tracking, type }),
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
    transaction_id: idReservation,
    value: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
  };

  window.gtag('event', 'refund', value);

  return value;
};
