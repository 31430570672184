const CARD_WIDTH = 320;

const CURRENCY_FORMAT = {
  fancy: false,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  symbol: false,
};

const SCROLL_DELAY = 100;

export { CARD_WIDTH, CURRENCY_FORMAT, SCROLL_DELAY };
