import { Calendar, InputText } from '@mirai/ui';

import { List } from './components';
import { ListRoom } from './components/List/List.Room';
import { BookingQuery, HotelInfo } from './intents';
import { Login } from './intents';
import { Occupation } from '../../../__shared__';
import { Account } from '../../../Profile/components/Settings/Settings.Account';
import { Signup } from '../../../Signup';

const INPUT_TEXT_TYPES = ['text', 'password', 'email', 'number'];

const INTENT_COMPONENT = {
  BOOKING_CREATE: undefined,
  BOOKING_CANCEL: undefined,
  BOOKING_UPDATE: undefined,
  BOOKING_QUERY: BookingQuery,
  FAREWELLS: undefined,
  GREETINGS: undefined,
  HOTEL_INFO: HotelInfo,
  // ! TODO: Disabled for now
  // SIGNUP: Signup,
};

const KEY_ENTER = 'Enter';

const REQUIREMENT_COMPONENT = {
  default: InputText,
  calendar: Calendar,
  occupation: Occupation,
  list: List,
  signup: Signup,
  login: Login,
  profile: Account,
  room: ListRoom,
};

export { INPUT_TEXT_TYPES, INTENT_COMPONENT, KEY_ENTER, REQUIREMENT_COMPONENT };
