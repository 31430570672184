import { dateFormat } from '@mirai/locale';

import { getOccupationTotals } from '../../../../../components/helpers';
import { TYPE } from '../../../../../Core.constants';

export const parseCommon = ({ calendar = [], id, occupation = [], promocode = '', type } = {}) => {
  const { adults = 0, children = 0 } = getOccupationTotals({ occupation });

  return {
    chainId: type === TYPE.CHAIN ? id : undefined,
    checkin: dateFormat(calendar[0], { format: 'DD/MM/YYYY' }),
    checkout: dateFormat(calendar[1], { format: 'DD/MM/YYYY' }),
    coupon: promocode,
    hotelId: type === TYPE.HOTEL ? id : undefined,
    occupation: adults + children,
  };
};
