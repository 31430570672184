import { isDate } from './isDate';

const MS_DAY = 1000 * 60 * 60 * 24;
const MS_MINUTE = 1000 * 60;
export const dateDiff = (from, to) => {
  let years;
  let months;
  let days;

  if (isDate(from) && isDate(to)) {
    const diff_timezone = Math.abs(to.getTimezoneOffset() - from.getTimezoneOffset());
    const diff = to.getTime() - from.getTime() + (to < from ? -1 : 1) * diff_timezone * MS_MINUTE;

    years = to.getFullYear() - from.getFullYear();
    months = years * 12 + to.getMonth() - from.getMonth();
    days = parseInt(diff / MS_DAY, 10);
  }

  return { years, months, days };
};
