import { useDevice, View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../../../__shared__';

export default () => {
  const { isMobile, isDesktop } = useDevice();

  const itemProps = {
    row: true,
    style: {
      gap: 'var(--mirai-ui-space-XS)',
    },
  };
  const iconProps = {
    style: { borderRadius: '50%', height: 'var(--mirai-ui-space-L)', width: 'var(--mirai-ui-space-L)' },
  };
  const textProps = { action: true };

  return (
    <View
      row
      style={{
        backgroundColor: 'var(--mirai-ui-base)',
        borderBottom: !isDesktop ? 'solid var(--mirai-ui-border-width) var(--mirai-ui-content-border)' : undefined,
        gap: 'var(--mirai-ui-space-XL)',
        justifyContent: !isDesktop ? 'space-between' : undefined,
        padding: !isDesktop ? 'var(--mirai-ui-space-XS) var(--mirai-ui-space-M)' : undefined,
      }}
    >
      <View {...itemProps}>
        <Skeleton {...iconProps} />
        <Skeleton {...textProps} words={2} />
      </View>
      <View {...itemProps}>
        <Skeleton {...iconProps} />
        {!isMobile && <Skeleton {...textProps} words={2} />}
      </View>
      <View {...itemProps}>
        <Skeleton {...iconProps} />
        {!isMobile && <Skeleton {...textProps} words={1} />}
      </View>
    </View>
  );
};
