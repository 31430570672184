import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, styles, Text, Tooltip, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Features.module.css';
import { getOccupationLabel, ICON } from '../../../../../helpers';
import { BED_TYPE } from '../../../../Rates.constants';

const Features = ({
  bedDisposition = [],
  features: { showMaxOccupation = false } = {},
  maxOccupation: roomMaxOccupation = 2,
  occupation: roomOccupation = [],
  room = 0,
  size,
  ...others
}) => {
  const { translate } = useLocale();
  const {
    value: { finder: { occupation: finderOccupation = [] } = {} },
  } = useStore();

  const iconProps = { headline: true, level: 3 };
  const textProps = { small: true };

  let occupation = roomOccupation;
  let maxOccupation = roomMaxOccupation;

  if (!showMaxOccupation && finderOccupation[room]) {
    occupation = finderOccupation[room];
    maxOccupation = occupation.reduce((total, { amount } = {}) => (total += amount), 0);
  }

  return (
    <View row className={styles(style.features, others.className)}>
      {size && (
        <View row className={style.item}>
          <Icon {...iconProps} value={ICON.SIZE} />
          <Text {...textProps}>{size}</Text>
        </View>
      )}

      {maxOccupation > 0 && (
        <Tooltip
          text={occupation.map((item) => getOccupationLabel({ ...item, ages: [] }, translate)).join(' + ')}
          className={style.item}
        >
          <View row className={style.item}>
            <Icon
              {...iconProps}
              value={maxOccupation === 1 ? ICON.PERSON : maxOccupation === 2 ? ICON.GROUP : ICON.GROUPS}
            />
            <Text {...textProps}>{maxOccupation}</Text>
          </View>
        </Tooltip>
      )}

      {bedDisposition.length === 1 &&
        bedDisposition[0].map(({ amount = 1, type }) => (
          <View key={`bed:${type}`} row className={style.item}>
            <Icon {...iconProps} value={type === BED_TYPE.DOUBLE ? ICON.BED_DOUBLE : ICON.BED_SINGLE} />
            <Text {...textProps}>{`x${amount}`}</Text>
          </View>
        ))}
    </View>
  );
};

Features.propTypes = {
  bedDisposition: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        type: PropTypes.number,
      }),
    ),
  ),
  features: PropTypes.shape({}),
  maxOccupation: PropTypes.number,
  occupation: PropTypes.arrayOf(
    PropTypes.shape({
      ages: PropTypes.arrayOf(PropTypes.number),
      amount: PropTypes.number,
      type: PropTypes.number,
    }),
  ),
  room: PropTypes.number,
  size: PropTypes.string,
};

Features.displayName = 'Mirai:Core:Rates:Item:Features';

export { Features };
