import { dateDiff, dateFormat, useLocale } from '@mirai/locale';
import { Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { DATE_FORMAT } from './BookingDates.constants';
import { L10N } from './BookingDates.l10n';
import * as style from './BookingDates.module.css';

const BookingDates = ({ from, focus, rangeMinDays, to, ...others }) => {
  const { isMobile } = useDevice();
  const { locale, translate } = useLocale();

  const { days: nights } = dateDiff(from, to || focus);

  return (
    <View row={!isMobile} {...others} className={style.nights}>
      <View row>
        <Text bold={!!from}>
          {from ? dateFormat(from, { locale, ...DATE_FORMAT }) : translate(L10N.LABEL_CHECK_IN)}
        </Text>
        <Text className={style.anchor}> — </Text>
        <Text bold={!!to}>
          {to || (focus && from)
            ? dateFormat(to || focus, { locale, ...DATE_FORMAT })
            : translate(L10N.LABEL_CHECK_OUT)}
        </Text>
      </View>

      {nights && nights > 0 ? (
        <Text>({translate(L10N.LABEL_NIGHTS, { value: nights })})</Text>
      ) : isMobile ? (
        rangeMinDays && (
          <Text small className={style.legend}>
            ({translate(L10N.LABEL_MIN_NIGHTS, { value: rangeMinDays })})
          </Text>
        )
      ) : undefined}
    </View>
  );
};

BookingDates.displayName = 'Mirai:Core:BookingDates';

BookingDates.propTypes = {
  from: PropTypes.any,
  focus: PropTypes.any,
  rangeMinDays: PropTypes.number,
  to: PropTypes.any,
};

export { BookingDates };
