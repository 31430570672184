import { useDevice, View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../__shared__';

const BannerLoyaltySkeleton = ({ ...others }) => {
  const { isMobile } = useDevice();

  return (
    <Skeleton {...others} base>
      <View
        color="accent"
        row
        style={{
          borderBottom: 'solid 1px var(--mirai-ui-content-background)',
          justifyContent: 'space-between',
          padding: 'var(--mirai-ui-space-M)',
        }}
      >
        <View style={{ gap: '0.25rem' }}>
          <Skeleton words={2} />
          <Skeleton action words={13} />
        </View>
        {!isMobile && <Skeleton words={4} />}
      </View>

      {!isMobile && (
        <View row style={{ gap: '4rem', padding: '1.5rem var(--mirai-ui-space-M)' }} wide>
          {Array.from({ length: 4 }).map((_, index) => (
            <View row key={index} style={{ gap: 'var(--mirai-ui-space-M)' }}>
              <Skeleton icon />
              <Skeleton action words={7} />
            </View>
          ))}
        </View>
      )}

      {isMobile && (
        <View row style={{ justifyContent: 'space-between', padding: 'var(--mirai-ui-space-M)' }}>
          <Skeleton action words={4} />
          <Skeleton icon />
        </View>
      )}
    </Skeleton>
  );
};

BannerLoyaltySkeleton.displayName = 'Mirai:Core:BannerLoyalty:Skeleton';

BannerLoyaltySkeleton.propTypes = {};

export default BannerLoyaltySkeleton;
