import style from '../Text.module.css';

export const color = ({ accent, accentDark, accentLight, dark, error, info, light, success, warning } = {}) =>
  accent
    ? style.accent
    : accentDark
    ? style.accentDark
    : accentLight
    ? style.accentLight
    : dark
    ? style.dark
    : error
    ? style.error
    : info
    ? style.info
    : light
    ? style.light
    : success
    ? style.success
    : warning
    ? style.warning
    : undefined;
