import { useStore } from '@mirai/data-sources';
import { DEFAULT_LOCALE, useLocale } from '@mirai/locale';
import { ServiceUser } from '@mirai/services';
import { Button, Form, InputText, Modal, Notification, Pressable, styles, Text } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ENDPOINT } from './BookingQuery.constants';
import { L10N } from './BookingQuery.l10n.js';
import * as style from './BookingQuery.module.css';
import { getErrorText } from './helpers';
import { template, toUrlParams } from '../helpers';

const BookingQuery = ({ ghost, ...others }) => {
  const { translate } = useLocale();
  const { value: { language = DEFAULT_LOCALE.split('-') } = {} } = useStore();

  const [busy, setBusy] = useState(false);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [modal, setModal] = useState(false);
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    setResponseError(undefined);
    setBusy(false);
  }, [form]);

  useEffect(() => {
    setForm({});
    setFormError({ touched: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);
  const handleSubmit = async (form) => {
    setBusy(true);
    setResponseError(undefined);

    const { idHotel, token } =
      (await ServiceUser.booking({ ...form }).catch((error) => {
        setResponseError(error);
      })) || {};

    if (!token) {
      return setBusy(false);
    }

    const url = `${process.env.SERVICE_BOOKING}/${ENDPOINT.BOOKING}${toUrlParams({
      code: token,
      idHotel,
      lang: language,
      pinCode: form.pinCode,
      reservationCode: form.id,
    })}`;

    document.location.href = url;
  };

  const { id, pinCode } = form;
  const { testId } = others;

  return (
    <>
      <Pressable
        onPress={() => setModal(true)}
        dangerouslySetInnerHTML={ghost ? { __html: template(ghost.outerHTML, undefined, translate) } : undefined}
        testId={testId ? `${testId}-button-open-modal` : undefined}
        role="bookingquery"
      >
        {!ghost ? (
          <Text medium role="content" className={style.text}>
            {translate(L10N.BOOKING_QUERY)}
          </Text>
        ) : undefined}
      </Pressable>

      <Modal
        visible={modal}
        title={translate(L10N.TITLE)}
        onClose={() => setModal(false)}
        testId={testId ? `${testId}-modal` : undefined}
      >
        <Form
          {...others}
          validateOnMount
          onChange={setForm}
          onError={setFormError}
          onSubmit={handleSubmit}
          className={style.form}
        >
          <Text className={styles(style.description, style.wide)}>{translate(L10N.DESCRIPTION)}</Text>

          <InputText
            name="id"
            label={translate(L10N.BOOKING_CODE_LABEL)}
            required
            value={id}
            testId={testId ? `${testId}-input-id` : undefined}
          />
          <InputText
            name="pinCode"
            label={translate(L10N.PIN_CODE_LABEL)}
            required
            type="password"
            value={pinCode}
            testId={testId ? `${testId}-input-pinCode` : undefined}
          />

          {!busy && responseError && (
            <Notification small error testId={testId ? `${testId}-notification-error` : undefined}>
              {translate(getErrorText(responseError))}
            </Notification>
          )}

          <Button
            busy={busy}
            disabled={Object.keys(formError).length !== 0}
            large
            wide
            type="submit"
            onPress={() => {}}
            className={style.button}
            testId={testId ? `${testId}-button-cta` : undefined}
          >
            {translate(L10N.CTA)}
          </Button>

          <Text bold small className={style.wide}>
            {translate(L10N.LABEL_RESERVATION_CODE)}
          </Text>
          <Text small className={style.wide}>
            {translate(L10N.LABEL_RESERVATION_CODE_DESCRIPTION)}
          </Text>
        </Form>
      </Modal>
    </>
  );
};

BookingQuery.displayName = 'Mirai:Core:BookingQuery';

BookingQuery.propTypes = {
  ghost: PropTypes.any,
};

export { BookingQuery };
