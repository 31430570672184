import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, createContext } from 'react';

import { LocalAdapter, Storage } from '../Storage';
import { METRICS_DEFAULTS, STORAGE_KEY } from './Metrics.constants';

const store = new Storage({ adapter: LocalAdapter });

const MetricsContext = createContext(METRICS_DEFAULTS);

const useMetrics = () => useContext(MetricsContext);

const MetricsProvider = ({ children, token }) => {
  useEffect(() => {
    if (token) {
      try {
        mixpanel.init(token, { persistence: 'localStorage' });
        // eslint-disable-next-line no-empty
      } catch {}
    }

    const queue = store.get(STORAGE_KEY) || [];
    queue.forEach(({ id, properties }) => mixpanel.track(id, properties));
    try {
      localStorage.removeItem(STORAGE_KEY);
      // eslint-disable-next-line no-empty
    } catch {}
  }, [token]);

  const track = (id, values = {}) => {
    if (!token || !id || typeof id !== 'string') return;

    const queue = store.get(STORAGE_KEY) || [];
    const metricId = id.trim().toUpperCase();
    const properties = { ...values, $mp_api_timestamp_ms: Date.now() };
    store.set(STORAGE_KEY, [...queue, { id: metricId, properties }]);

    mixpanel.track(metricId, properties, () => store.set(STORAGE_KEY, queue));
  };

  const trackRender = (id, properties) => setTimeout(() => track(id, properties), 10);

  return <MetricsContext.Provider value={{ track, trackRender }}>{children}</MetricsContext.Provider>;
};

MetricsProvider.propTypes = {
  children: PropTypes.node,
  token: PropTypes.string,
};

export { MetricsContext, MetricsProvider, useMetrics };
