import { currencyFormat, useLocale } from '@mirai/locale';
import { Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { CURRENCY_FORMAT } from '../ModalTwin.constants';
import { L10N } from '../ModalTwin.l10n';

const Prices = ({
  boards: [{ dayPrice, price } = {}] = [],
  features: { showPricePerNight = false } = {},
  nights,
  priceFactor,
  ...others
}) => {
  const { currency, locale, translate } = useLocale();

  return (
    <View className={others.className}>
      <Text small>{translate(L10N.LABEL_FROM)}</Text>
      <View row>
        <Text bold>{currencyFormat({ ...CURRENCY_FORMAT, currency, locale, value: price / priceFactor })}</Text>
      </View>
      {nights > 1 && (
        <Text small>{`${currencyFormat({
          ...CURRENCY_FORMAT,
          currency,
          locale,
          value: showPricePerNight ? price : dayPrice,
        })} ${translate(showPricePerNight ? L10N.LABEL_FOR_NIGHTS : L10N.LABEL_PER_NIGHT, { nights })}`}</Text>
      )}
    </View>
  );
};

Prices.displayName = 'Mirai:Core:Rates:ModalTwin:Prices';

Prices.propTypes = {
  boards: PropTypes.array,
  features: PropTypes.object,
  nights: PropTypes.number,
  priceFactor: PropTypes.number,
};

export { Prices };
