import PropTypes from 'prop-types';

const LayerContent = ({ children }) => children;

LayerContent.displayName = 'Primitive:Layer:Content';

LayerContent.propTypes = {
  children: PropTypes.node,
};

export { LayerContent };
