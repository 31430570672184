import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceUser } from '@mirai/services';
import { Button, Form, InputText, Notification, Text } from '@mirai/ui';
import React, { useEffect, useState } from 'react';

import { L10N } from './Login.l10n';
import * as style from './Login.module.css';
import { EVENT } from '../../../../../helpers';

const Login = ({ ...others }) => {
  const { translate } = useLocale();
  const { set, value: { club = {}, id } = {} } = useStore();

  const [busy, setBusy] = useState(false);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    setForm({});
    setFormError({ touched: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setResponseError();
    set({ login: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleSubmit = async (value) => {
    setBusy(true);
    setResponseError(undefined);

    const props = { ...value, idClub: club.id, idHotel: id };
    const response = await ServiceUser.login(props).catch((error) => {
      setResponseError(error);
      Event.publish(EVENT.METRICS, { id: `LOGIN:ERROR` });
    });

    if (response) {
      set({ session: response });

      Event.publish(EVENT.LOGGED, { event: EVENT.LOGGED, response });
      Event.publish(EVENT.METRICS, { id: `LOGIN:'SUCCESS` });
    }
    setBusy(false);
  };

  return (
    <Form {...others} onChange={setForm} onError={setFormError} onSubmit={handleSubmit} className={style.form}>
      <Text className={style.description}>{translate(L10N.LABEL_FORM_DESCRIPTION)}</Text>

      <InputText
        autoComplete="true"
        name="email"
        label={translate(L10N.LABEL_EMAIL)}
        required
        type="email"
        value={form.email}
      />
      <InputText
        autoComplete="true"
        name="password"
        label={translate(L10N.LABEL_PASSWORD)}
        required
        type="password"
        value={form.password}
      />

      {!busy && responseError && (
        <Notification error className={style.notification} testId="login-notification">
          {translate(L10N.NOTIFICATION_ERROR)}
        </Notification>
      )}

      <Button
        busy={busy}
        disabled={Object.keys(formError).length !== 0}
        large
        type="submit"
        wide
        onPress={() => {}}
        testId="login-button"
        className={style.button}
      >
        {translate(L10N.ACTION_LOGIN)}
      </Button>
    </Form>
  );
};

Login.displayName = 'Mirai:Core:Chat:Message:Login';

Login.propTypes = {};

export { Login };
