import { LocalAdapter, request, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT, COUNTRY_STORAGE_KEY, DEFAULT_COUNTRY, DEFAULT_LANGUAGE } from './constants';

export const list = async (locale = `${DEFAULT_LANGUAGE}-${DEFAULT_COUNTRY}`) => {
  if (typeof locale !== 'string') return;

  const [language] = locale.split('-');
  if (!language) return;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${COUNTRY_STORAGE_KEY}:list:${language}`;
  let value = storage.get(key);

  if (!value) {
    value = await request({
      hostname: process.env.SERVICE_STATIC,
      endpoint: `/countries/${language}`.toLowerCase(),
    }).catch((error) => {
      // ! TODO: Should call home (sentry, mixpanel, ...)
      // eslint-disable-next-line no-console
      console.error('ServiceCountry.list', error);
    });

    storage.set(key, value);
  }

  return value;
};
