import { request } from '@mirai/data-sources';

import { ENDPOINT, ERROR } from './constants';

export const update = ({ id, code, payment, tracking } = {}) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const params = { cancellationCode: code, id, payment, tracking };
    const { UPDATE: endpoint } = ENDPOINT;

    const response = await request({
      endpoint,
      hostname: process.env.SERVICE_BOOKING,
      method: 'POST',
      ...Object.fromEntries(Object.entries(params).filter(([, value]) => value !== undefined)),
    }).catch((error = {}) => {
      const { payload: { status } = {} } = error;

      return reject(ERROR[status] ? ERROR[status] : ERROR.UNKNOWN);
    });

    if (!response || !response.data) return reject(ERROR.UNKNOWN);

    const { data: { ...values } = {} } = response;

    resolve({ ...payment, ...values });
  });
