/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

export const getToken = async ({ checkin, idHotel } = {}) => {
  if (!checkin || !idHotel) return;

  const response = await request({
    hostname: process.env.SERVICE_HOTELVERSE,
    endpoint: `/checkAvailability?checkin=${checkin}&idHotel=${idHotel}`,
    headers: { 'X-Requested-With': undefined },
  }).catch((error) => {
    // ! TODO: Should call home (sentry, mixpanel, ...)
    // eslint-disable-next-line no-console
    console.error('ServiceHotelVerse.getToken', error);
  });

  return response?.hvaccesstokenhv || undefined;
};
