export const L10N = {
  ACTION_BACK: { id: 'user.action.back_login' },
  ACTION_CHANGE_PASSWORD: { id: 'user.action.change_login_password' },
  ACTION_MANAGE_ACCOUNT: { id: 'user.action.manage_account', defaultMessage: 'Log in' },
  ACTION_MODAL: { id: 'common.action.login', defaultMessage: 'Log in' },
  ACTION_MY_BOOKINGS: { id: 'user.action.my_bookings' },
  ACTION_PROFILE: { id: 'user.action.view_profile' },
  ACTION_RESET: { id: 'user.action.reset' },
  ACTION_SIGNUP: { id: 'user.action.signup' },
  CTA: { id: 'common.action.login' },
  CTA_RESET: { id: 'user.action.cta_reset' },
  CTA_LOGOUT: { id: 'common.action.logout' },
  FORM_DESCRIPTION: { id: 'user.label.form_description' },
  FORM_RESET_DESCRIPTION: { id: 'user.label.form_reset_description' },
  EMAIL_LABEL: { id: 'common.label.email' },
  LEVEL: { id: 'user.label.level' },
  PASSWORD_LABEL: { id: 'common.label.password' },
  TITLE: { id: 'common.action.login' },
  TITLE_RESET: { id: 'user.action.reset' },
  TITLE_WITH_SIGNUP: { id: 'user.label.title_session' },
  NOTIFICATION_ERROR: { id: 'common.notification.error_unknown' },
  NOTIFICATION_FINISH_SIGNUP_TITLE: { id: 'user.notification.activation_success' },
  NOTIFICATION_FINISH_SIGNUP_DESCRIPTION: { id: 'user.notification.finish_signup_description' },
  NOTIFICATION_FINISH_SIGNUP_ERROR: { id: 'user.notification.validation_error' },
  NOTIFICATION_SUCCESS_RESET: { id: 'user.notification.success_reset' },
};
