import { LiaMarkdown as MARKDOWN } from '@react-icons/all-files/lia/LiaMarkdown';
import { MdCheckCircleOutline as SUCCESS } from '@react-icons/all-files/md/MdCheckCircleOutline';
import { MdErrorOutline as ERROR } from '@react-icons/all-files/md/MdErrorOutline';
import { MdOutlineAdd as ADD } from '@react-icons/all-files/md/MdOutlineAdd';
import { MdOutlineArrowBack as LEFT } from '@react-icons/all-files/md/MdOutlineArrowBack';
import { MdOutlineArrowDownward as DOWN } from '@react-icons/all-files/md/MdOutlineArrowDownward';
import { MdOutlineArrowForward as RIGHT } from '@react-icons/all-files/md/MdOutlineArrowForward';
import { MdOutlineArrowUpward as UP } from '@react-icons/all-files/md/MdOutlineArrowUpward';
import { MdOutlineCheck as CHECK } from '@react-icons/all-files/md/MdOutlineCheck';
import { MdOutlineClose as CLOSE } from '@react-icons/all-files/md/MdOutlineClose';
import { MdOutlineExpandLess as EXPAND_LESS } from '@react-icons/all-files/md/MdOutlineExpandLess';
import { MdOutlineExpandMore as EXPAND_MORE } from '@react-icons/all-files/md/MdOutlineExpandMore';
import { MdOutlineFilterList as FILTER } from '@react-icons/all-files/md/MdOutlineFilterList';
import { MdOutlineInfo as INFO } from '@react-icons/all-files/md/MdOutlineInfo';
import { MdOutlinePhotoLibrary as PHOTO_LIBRARY } from '@react-icons/all-files/md/MdOutlinePhotoLibrary';
import { MdOutlineRemove as REMOVE } from '@react-icons/all-files/md/MdOutlineRemove';
import { MdOutlineReplay as REPLAY } from '@react-icons/all-files/md/MdOutlineReplay';
import { MdOutlineSearch as SEARCH } from '@react-icons/all-files/md/MdOutlineSearch';
import { MdOutlineVisibility as EYE_OPEN } from '@react-icons/all-files/md/MdOutlineVisibility';
import { MdOutlineVisibilityOff as EYE_CLOSE } from '@react-icons/all-files/md/MdOutlineVisibilityOff';
import { MdOutlineWarningAmber as WARNING } from '@react-icons/all-files/md/MdOutlineWarningAmber';

export const ICON = {
  // <Calendar>
  LEFT,
  RIGHT,
  UP,
  DOWN,
  // <Check>
  CHECK,
  // <InputOption>
  ADD,
  REMOVE,
  // <InputSelect>
  EXPAND_LESS,
  EXPAND_MORE,
  // <InputText>
  SEARCH,
  EYE_OPEN,
  EYE_CLOSE,
  // <Modal>
  CLOSE,
  // <Notification> & <InputText>
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
  // <Slider>
  PHOTO_LIBRARY,
  REPLAY,
  // <Table>
  FILTER,
  // Filetype
  MARKDOWN,
};
