import { LocalAdapter, Storage } from '@mirai/data-sources';
import { DEFAULT_LOCALE } from '@mirai/locale';

import { DICTIONARY_STORAGE_KEY } from './constants';

export const cache = (locale = DEFAULT_LOCALE) => {
  const [language] = locale.split('-');
  if (!language) return undefined;

  const storage = new Storage({ adapter: LocalAdapter });
  const key = `${DICTIONARY_STORAGE_KEY}:${language}`;

  return storage.get(key);
};
