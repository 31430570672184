import { useStore } from '@mirai/data-sources';
import PropTypes from 'prop-types';
import React from 'react';

import { INTENT } from '../../../Chat.constants';
import { Booking, Card, Typewriter } from '../components';
import * as style from '../Message.module.css';

const BookingQuery = ({ disabled, locale: propLocale, response: { text, value = [] } = {}, onAction }) => {
  const {
    value: { locale: stateLocale },
  } = useStore();

  const locale = propLocale || stateLocale;
  const bookings = Array.isArray(value) ? value : [value?.booking];
  const options = bookings.length > 1;

  return (
    <>
      {text && <Typewriter className={style.text}>{text}</Typewriter>}
      <>
        {bookings.map((booking, index) =>
          React.createElement(
            Card,
            {
              key: index,
              wide: true,
              ...(options
                ? {
                    disabled,
                    onPress: () => onAction({ intent: INTENT.BOOKING_QUERY, context: { booking } }),
                  }
                : undefined),
            },
            <Booking dataSource={booking} locale={locale} />,
          ),
        )}
      </>
    </>
  );
};

BookingQuery.propTypes = {
  disabled: PropTypes.bool,
  locale: PropTypes.string,
  response: PropTypes.shape({
    requirements: PropTypes.any,
    text: PropTypes.string,
    value: PropTypes.any,
  }),
  onAction: PropTypes.func,
};

export { BookingQuery };
