import { Action, Text, View } from '@mirai/ui';
import React from 'react';

export const parseHtml = (html = '', { small } = {}) => {
  const parser = new DOMParser();
  const body = parser.parseFromString(html, 'text/html').querySelector('body');

  const traverseNodes = (nodes = []) => {
    const components = [];

    nodes.forEach((node, index) => {
      const { childNodes, nodeType, tagName, textContent } = node;
      const key = `${tagName}${index}`;

      if (nodeType === Node.TEXT_NODE) {
        components.push(textContent);
      } else if (nodeType === Node.ELEMENT_NODE) {
        if (tagName === 'P') {
          components.push(React.createElement(Text, { key, small, wide: true }, traverseNodes(childNodes)));
        } else if (tagName === 'BR') {
          components.push(React.createElement(View, { key, wide: true }));
        } else if (tagName === 'B') {
          components.push(
            React.createElement(
              Text,
              { key, small, style: { display: 'inline', fontWeight: 'var(--mirai-ui-font-bold-weight)' } },
              traverseNodes(childNodes),
            ),
          );
        } else if (tagName === 'A') {
          const href = node.getAttribute('href');
          components.push(
            React.createElement(
              href ? Action : React.Fragment,
              {
                ...(href ? { href, small, style: { display: 'inline-flex' }, target: '_blank' } : undefined),
                key,
              },
              textContent,
            ),
          );
        }
      } else {
        traverseNodes(childNodes);
      }
    });

    return components;
  };

  return traverseNodes(body.childNodes);
};
