import { translate as translateBase } from '@mirai/locale';

import { OCCUPATION_TYPE } from './constants';
import { getAgeRange } from './getAgeRange';
import { getOccupationLabel } from './getOccupationLabel';

const { GUEST, ADULT, CHILD, BABY } = OCCUPATION_TYPE;

export const getRoomOccupationLabel = ({
  adults = 0,
  babies = 0,
  children = 0,
  dataSource = [],
  translate = translateBase,
} = {}) => {
  const guests = dataSource.find(({ type }) => type === GUEST) ? adults : 0;
  if (!adults && !guests) return '';

  const { age: [minAdults] = [] } = dataSource.find(({ type }) => type === ADULT) || {};
  const { age: [minAge, maxAge] = [] } = dataSource.find(({ type }) => type === CHILD) || {};

  return `${getOccupationLabel({ amount: adults || guests, type: adults ? ADULT : GUEST }, translate)}${
    children > 0 ? `, ${getOccupationLabel({ amount: children, type: CHILD }, translate)}` : ''
  } ${
    minAdults !== undefined || minAge !== undefined || maxAge !== undefined
      ? `(${getAgeRange(children > 0 ? [minAge, maxAge] : [minAdults], translate)})`
      : ''
  }${babies > 0 ? `, ${getOccupationLabel({ amount: babies, type: BABY }, translate)}` : ''}`.trimEnd();
};
