import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '../../locale.definition';

const DISPLAY = {
  DEFAULT: 'symbol',
  NARROW: 'narrowSymbol',
};

export const currencySymbol = (
  currency = DEFAULT_CURRENCY,
  { display = DISPLAY.DEFAULT, locale = DEFAULT_LOCALE, fancy = false } = {},
) => {
  if (!display || !locale) return undefined;

  try {
    const symbol = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: 'currency',
      currency,
      currencyDisplay: display,
    })
      .format(0)
      .replace(new Intl.NumberFormat(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(0), '')
      .trim();

    return display === DISPLAY.NARROW
      ? symbol?.length <= 2
        ? symbol?.length > 1 && fancy
          ? undefined
          : symbol
        : undefined
      : symbol;
  } catch {
    return undefined;
  }
};
