import { TYPE } from '../Occupation.constants';

export const consolidateValue = (value = [], dataSource = [], currentDataSource = []) => {
  let nextValue = [];
  let completed = true;

  value.every((room = [], roomIndex) => {
    let totalAmount = 0;

    room.every(({ amount, ages = [], type } = {}) => {
      totalAmount += amount;

      ages.every((age) => {
        let slot = false;

        dataSource.forEach(({ type: nextType, age: [min = 0, max = 99] = [], fulfill }, dataSourceIndex) => {
          if (slot || typeof type !== 'number') return undefined;

          let { age: [currentMin] = [] } =
            currentDataSource.find(
              ({ age: currentAge, type: currentType }) =>
                currentType === nextType && currentAge?.length && currentAge[0] != min,
            ) || {};
          const sameType = nextType === parseInt(type);
          if (currentMin === null) currentMin = undefined;
          if (
            nextType === TYPE.GUEST ||
            (age >= min && age <= max) ||
            (fulfill && age === undefined && sameType) ||
            (!fulfill && sameType && !currentMin) ||
            (!fulfill && sameType && currentMin && min <= currentMin)
          )
            slot = true;

          if (slot) {
            nextValue[roomIndex] = nextValue[roomIndex] || [];
            nextValue[roomIndex][dataSourceIndex] = nextValue[roomIndex][dataSourceIndex] || {
              ages: [],
              amount: 0,
              type: nextType,
            };

            if (nextType === TYPE.GUEST) {
              nextValue[roomIndex][dataSourceIndex].amount = totalAmount;
            } else {
              nextValue[roomIndex][dataSourceIndex].amount += 1;
            }
            nextValue[roomIndex][dataSourceIndex].ages.push(age);
          }
        });
        if (!slot) completed = false;
        return completed;
      });

      const isCorrectAmount = !nextValue[roomIndex]?.some(({ amount: nextAmount, type: nextType }) => {
        const { max, min } = dataSource.find(({ type: itemType }) => itemType === nextType) || {};
        return nextAmount > max || nextAmount < min;
      });
      if (!isCorrectAmount) completed = false;

      return completed;
    });
    return completed;
  });
  return completed ? nextValue : undefined;
};
