import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Primitive } from '../Primitive';
import style from './Radio.module.css';

const Radio = ({ checked = false, disabled, error = true, name, value = '', onChange = () => {}, ...others }) => {
  const handleChange = (event) => {
    if (disabled) return;

    onChange(value, event);
  };

  return (
    <Primitive role={others.role} tag="radio" className={styles(style.radio, others.className)} style={others.style}>
      <Primitive
        checked={checked}
        disabled={disabled}
        name={name}
        tag="input"
        type="radio"
        value={value}
        onChange={handleChange}
        testId={others.testId}
        className={styles(style.input, error && !disabled && checked === undefined && style.error)}
        aria-label={others['aria-label']}
      />
      <Primitive className={style.checkmark} />
    </Primitive>
  );
};

Radio.displayName = 'Primitive:Radio';

Radio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export { Radio };
