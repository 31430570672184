import { useStore } from '@mirai/data-sources';
import { Action, Pressable, styles, Text, View } from '@mirai/ui';
import React, { useState } from 'react';

import * as style from './Environment.module.css';
import { name, version } from '../../../package.json';
import { IS_JEST } from '../helpers';

export const State = (others) => {
  const { value } = useStore();

  const [expanded, setExpanded] = useState(false);

  const {
    //
    id,
    type,
    // region
    currency,
    language,
    country,
    locale,
    // entities
    calendar,
    club,
    forwarder,
    hotel,
    occupation,
    origin,
    promo,
    session,
    tags,
    // components
    finder,
    deals,
    signup,
  } = value;

  const { Mirai: { environment } = {} } = window;

  const Bubble = () => (
    <Text small>
      {environment === 'production'
        ? '🟢'
        : environment === 'preproduction'
        ? '🟡'
        : environment === 'development'
        ? '🔴'
        : '⚫️'}
    </Text>
  );

  return (
    <>
      <Pressable
        {...others}
        onPress={() => setExpanded(!expanded)}
        className={styles(style.pressableState, others.className)}
      >
        <Bubble />
        <Text bold small>
          {!IS_JEST ? `v${version}` : 'v'}
        </Text>
      </Pressable>

      {expanded && (
        <div className={style.state}>
          <View row className={style.header}>
            <View row className={style.name}>
              <Bubble />
              <Text bold>{name.replace('@', '')}</Text>
              <Text>{environment}</Text>
            </View>

            <Action small onPress={() => setExpanded(false)}>
              Close
            </Action>
          </View>

          {expanded && (
            <Text tiny tag="pre" className={style.code}>
              {JSON.stringify(
                {
                  id,
                  type,
                  // region
                  region: { currency, language, country, locale },
                  // entities
                  entities: {
                    calendar,
                    club,
                    forwarder,
                    hotel,
                    occupation,
                    origin,
                    promo,
                    session,
                    tags,
                  },
                  // components
                  components: {
                    finder,
                    deals,
                    signup,
                  },
                },
                null,
                2,
              )}
            </Text>
          )}
        </div>
      )}
    </>
  );
};
