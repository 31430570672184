import { DEFAULT_LOCALE, COUNTRIES } from '@mirai/locale';

import { themeDirection } from './themeDirection';

const DELIMITER = '-';

export const getLocale = (instance = {}, { lang: urlLanguage } = {}) => {
  const [documentLanguage] = document?.documentElement?.getAttribute('lang')?.toLowerCase().split(DELIMITER) || [];
  const [navigatorLanguage, navigatorCountry] = window.navigator.language?.split(DELIMITER) || [];
  const [instanceLanguage, instanceCountry] = !urlLanguage ? instance.locale?.split(DELIMITER) || [] : [];

  const baseLanguage = urlLanguage || documentLanguage || instanceLanguage || navigatorLanguage;
  const baseCountry =
    instanceCountry || (baseLanguage === navigatorLanguage ? navigatorCountry : undefined) || COUNTRIES[baseLanguage];

  const [language, country] = COUNTRIES[baseLanguage] ? [baseLanguage, baseCountry] : DEFAULT_LOCALE.split(DELIMITER);
  themeDirection(language);

  return {
    // ! TODO: country should be the ip.country
    country,
    language,
    locale: [language, country].join('-'),
  };
};
