import { bookingCancel } from './bookingCancel';
import { bookingResponse } from './bookingResponse';
import { checkoutForm } from './checkoutForm';
import { checkoutResponse } from './checkoutResponse';
import { externalTwin } from './externalTwin';
import { finderReady } from './finderReady';
import { logged } from './logged';
import { ratesAddToCart } from './ratesAddToCart';
import { ratesResponse } from './ratesResponse';

export const ProviderAnalytics = {
  bookingCancel,
  bookingResponse,
  checkoutForm,
  checkoutResponse,
  externalTwin,
  finderReady,
  logged,
  ratesAddToCart,
  ratesResponse,
};
