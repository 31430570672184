import { ServiceRates } from '@mirai/services';

import { getFinderParams } from './getFinderParams';
import { fetchUrlParams } from '../../../helpers';
import { parseToParties, replaceUrlParams } from '../../helpers';

export const fetchRates = async ({
  cart = [],
  club,
  clubDiscount,
  finder = {},
  hotel,
  id,
  locale,
  maxRooms,
  room = 0,
  session,
  strictSearch,
} = {}) => {
  let urlParams = fetchUrlParams(location.search);

  if (!urlParams.checkin || !urlParams.nights || !urlParams.parties) {
    urlParams = { ...urlParams, ...getFinderParams({ ...finder, hotel }) };
    replaceUrlParams(urlParams);
  }

  const { occupation, place } = finder;

  const params = {
    ...urlParams,
    club,
    clubDiscount,
    id,
    locale,
    maxRooms,
    occupation,
    place,
    selectedIds: cart.map(({ roomId } = {}) => roomId).join(','),
    session,
    strictSearch,
  };
  if (room !== undefined) params.parties = parseToParties([occupation[room || 0]]);

  return ServiceRates.get(params);
};
