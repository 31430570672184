export const getConsentMode = (readGoogleData = false) => {
  let granted;

  const consentManagement =
    (window.dataLayer || [])
      .filter((item) => item[0] === 'consent' && item[1] === 'update')
      .map((item) => item[2])
      .pop() || {};

  if (Object.values(consentManagement).length) {
    granted = Object.values(consentManagement).filter((value) => value === 'granted').length >= 1;
  }

  if (readGoogleData && !granted) {
    const {
      ics: {
        entries: { ad_personalization = {}, ad_storage = {}, ad_user_data = {}, analytics_storage = {} } = {},
      } = {},
    } = window.google_tag_data || {};

    const hasValue = (obj = {}) => obj.default !== undefined;

    if (hasValue(ad_personalization) || hasValue(ad_storage) || hasValue(ad_user_data) || hasValue(analytics_storage)) {
      granted =
        Object.values({ ad_personalization, ad_storage, ad_user_data, analytics_storage }).filter((value = {}) =>
          value.update !== undefined ? value.update : value.default,
        ).length >= 1;
    }
  }

  return granted;
};
