import { LocalAdapter, request, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT, COUNTRY_STORAGE_KEY, DEFAULT_COUNTRY } from './constants';

export const info = async (country = DEFAULT_COUNTRY) => {
  if (typeof country !== 'string') return;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${COUNTRY_STORAGE_KEY}:info`;
  let countries = storage.get(key);

  if (!countries) {
    countries = await request({
      hostname: process.env.SERVICE_STATIC,
      endpoint: `/countries/list`,
    }).catch((error) => {
      // ! TODO: Should call home (sentry, mixpanel, ...)
      // eslint-disable-next-line no-console
      console.error('ServiceCountry.info', error);
    });
    storage.set(key, countries);
  }

  return countries[country];
};
