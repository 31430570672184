import { dateDiff, dateFormat } from '@mirai/locale';

import { parseToParties } from '../../helpers';

export const getFinderParams = ({
  place: { id: ids = [], isHotel, value } = {},
  calendar: [checkIn, checkOut] = [],
  occupation = [],
  promocode: clientCode = '',
  hotel: { id } = {},
}) => ({
  chainSelectorValue: value,
  ...(value && !isHotel
    ? { hotelIds: ids.join(',') }
    : { hotelIds: undefined, idtokenprovider: ids.length === 1 ? ids[0] : id }),
  checkin: dateFormat(checkIn, { format: 'DD/MM/YYYY' }),
  nights: dateDiff(checkIn, checkOut).days,
  parties: parseToParties(occupation),
  clientCode,
});
