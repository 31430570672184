import PropTypes from 'prop-types';

const L10N_SHAPE = PropTypes.shape({
  actionCancel: PropTypes.string,
  actionSubmit: PropTypes.string,
  labelMax: PropTypes.string,
  labelMin: PropTypes.string,
});

const PAGINATION_DISPATCHER = 90;

const STORAGE_KEY = 'mirai-ui-table';

export { L10N_SHAPE, PAGINATION_DISPATCHER, STORAGE_KEY };
