import { formatPrice } from './legacy';

export const getRoomPriceRange = function (roomType, currencyInfo) {
  var minRate = null;
  var maxRate = null;

  Object.keys(roomType.occupationOptions).forEach((occupationId) => {
    var occupation = roomType.occupationOptions[occupationId];
    Object.keys(occupation.rates).forEach((rateId) => {
      var rate = occupation.rates[rateId];
      var board = rate.boardOptions[rate.sortedKeysBoards[0]];
      var totalPriceWithTaxes = 0;
      var totalPriceWithoutTaxes = 0;
      board.detail.forEach((detail) => {
        totalPriceWithTaxes += detail.priceWithTaxes;
        totalPriceWithoutTaxes += detail.priceWithoutTaxes;
      });
      if (minRate == null || parseInt(minRate.priceAsLong) > parseInt(board.originalAvgPrice)) {
        minRate = {
          roomTypeName: roomType.name,
          adults: occupation.numAdults,
          childrenFirtsRange: occupation.numFirstRangeChildren,
          childrenSecondRange: occupation.numSecondRangeChildren,
          rateName: rate.name,
          price: formatPrice(board.originalAvgPrice, currencyInfo),
          totalPriceWithTaxes: totalPriceWithTaxes,
          totalPriceWithoutTaxes: totalPriceWithoutTaxes,
          priceAsLong: board.originalAvgPrice,
          boardName: board.boardGroupName,
          completeRateName: board.name,
          cancellationPolicyType: rate.cancellationPolicyTypeEnum,
          id: board.id,
        };
      }
      if (maxRate == null || parseInt(maxRate.priceAsLong) < parseInt(board.originalAvgPrice)) {
        maxRate = {
          roomTypeName: roomType.name,
          adults: occupation.numAdults,
          childrenFirtsRange: occupation.numFirstRangeChildren,
          childrenSecondRange: occupation.numSecondRangeChildren,
          rateName: rate.name,
          price: formatPrice(board.originalAvgPrice, currencyInfo),
          totalPriceWithTaxes: totalPriceWithTaxes,
          totalPriceWithoutTaxes: totalPriceWithoutTaxes,
          priceAsLong: board.originalAvgPrice,
          boardName: board.boardGroupName,
          completeRateName: board.name,
          cancellationPolicyType: rate.cancellationPolicyTypeEnum,
          id: board.id,
        };
      }
    });
  });

  return { minRate, maxRate };
};
