import { CHAT_STORAGE_KEY } from '../Chat.constants';

export const getStorageDomain = ({ booking, checkout, finder, profile, rates, signup } = {}) => {
  const domain = finder
    ? 'FINDER'
    : rates
    ? 'RATES'
    : checkout
    ? 'CHECKOUT'
    : booking
    ? 'BOOKING'
    : profile
    ? 'PROFILE'
    : signup
    ? 'SIGNUP'
    : 'DEFAULT';

  return `${CHAT_STORAGE_KEY}:${domain}`;
};
