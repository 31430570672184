import { IS_NODE } from '../modules';
import { getHeaders, getUrl, shell } from './helpers';
import { HEADERS } from './request.definition';

const SHELL_TITLE = 'request()';
const isomorphicFetch = IS_NODE ? require('isomorphic-fetch') : undefined;

const AbortControllerProxy = () =>
  typeof AbortController === 'undefined'
    ? {
        abort: () => {},
        signal: undefined,
      }
    : new AbortController();

export const request = async ({
  endpoint,
  headers,
  hostname,
  method = 'GET',
  payload = false,
  timeout,
  ...props
} = {}) => {
  const controller = AbortControllerProxy();

  if (timeout) setTimeout(() => controller.abort(), timeout);

  return new Promise((resolve, reject) => {
    const url = IS_NODE ? `${hostname}${endpoint}` : getUrl({ endpoint, hostname });
    const requestHeaders = getHeaders(HEADERS, headers);

    (isomorphicFetch || fetch)(url, {
      headers: requestHeaders,
      method,
      signal: timeout ? controller.signal : undefined,
      ...(['DELETE', 'POST', 'PUT'].includes(method)
        ? {
            body: JSON.stringify(
              props,
              method === 'PUT' ? (key, value) => (value === undefined ? null : value) : undefined,
            ),
          }
        : props),
    })
      .then(async (response) => {
        let value = await response.text();

        if (requestHeaders.Accept === 'application/json' && value.length > 0) value = JSON.parse(value);

        if (response.status >= 400) {
          shell.error(SHELL_TITLE, url, { response });
          reject({ code: response.status, message: (value || {}).message, payload: value });
        } else {
          const { headers, status, statusText } = response;

          shell.info(SHELL_TITLE, url, { response: value });
          resolve(payload ? { ...value, payload: { headers, status, statusText } } : value);
        }
      })
      .catch(({ message = 'Something wrong happened. Try again.', response } = {}) => {
        shell.error(SHELL_TITLE, url, { response, message });
        reject({
          code: response ? response.status : 500,
          message,
        });
      });
  });
};
