/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { ERROR, STATUS } from './constants';

export const postpone = ({ id, idHotel, pinCode, token } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!id || !idHotel || !pinCode || !token) return reject(ERROR.NOT_FILLED);

    const params = {
      cancellationCode: pinCode,
      code: token,
      idReservation: id,
      idtokenprovider: idHotel,
    };

    const response = await request({
      method: 'POST',
      hostname: process.env.SERVICE_BOOKING,
      endpoint: `postpone_reservation.json?${new URLSearchParams(Object.entries(params))}`,
      headers: { 'Content-Type': undefined, 'X-Requested-With': undefined },
    }).catch(() => reject(ERROR.UNKNOWN));

    if (!response || response.status !== STATUS.OK) return reject(ERROR.UNKNOWN);

    resolve(response.data);
  });
