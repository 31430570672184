import { DISCOUNT_TYPE } from '../../../Rates.constants';

export const getDiscount = ({ cart = [], items = [] } = {}) =>
  cart.reduce((total, { discount: { breakdown = [] } = {}, rateId, roomId } = {}) => {
    const { isClub } = items.find(({ id }) => id === roomId)?.rates?.find(({ id }) => id === rateId) || {};
    const current =
      breakdown.find(({ type }) => type === DISCOUNT_TYPE.CLUB || (type === DISCOUNT_TYPE.DEAL && isClub))?.value || 0;

    return total + current;
  }, 0);
