import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { DEFAULT_CONTEXT, DEFAULT_CURRENCY, DEFAULT_LOCALE } from './locale.definition';
import { currencyFormat, dateFormat, translate } from './modules';

const LocaleContext = createContext(DEFAULT_CONTEXT);

const useLocale = () => useContext(LocaleContext);

const LocaleProvider = ({
  children,
  currency: propCurrency = DEFAULT_CURRENCY,
  dictionary,
  locale: propLocale = DEFAULT_LOCALE,
}) => {
  const [currency, setCurrency] = useState(propCurrency);
  const [locale, setLocale] = useState(propLocale);

  useEffect(() => setCurrency(propCurrency), [propCurrency]);

  useEffect(() => setLocale(propLocale), [propLocale]);

  return (
    <LocaleContext.Provider
      value={{
        currency,
        currencyFormat: ({ fancy, symbol, value, ...others } = {}) =>
          currencyFormat({ currency, fancy, locale, symbol, value, ...others }),
        dateFormat: (value, options = {}) => dateFormat(value, { ...options, locale }),
        locale,
        setCurrency,
        setLocale,
        translate: (key = {}, bindings = {}) => translate({ dictionary, key, bindings }),
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node,
  currency: PropTypes.string,
  dictionary: PropTypes.shape({}),
  locale: PropTypes.string.isRequired,
};

export { LocaleContext, LocaleProvider, useLocale };
