import { formatPrice } from '../helpers/legacy';

export const bookingResponse = ({
  instances = [],
  response: { tracking = {} } = {},
  store: { hotel: { id: hotelId } = {} } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { checkin, checkout } = {},
    guestData: { email } = {},
    hotelInfo = {},
    idReservation,
    pendingPayment,
    totalPriceWithoutTaxes,
  } = tracking;

  window.gtag('set', 'user_data', { email });

  if (pendingPayment) return;

  const { currency: hotelCurrency = {} } = hotelInfo;

  const value = {
    currency: hotelCurrency.code,
    items: [{ id: hotelId, start_date: checkin, end_date: checkout }],
    send_to: instances.map(({ conversionLabel, idAdwords } = {}) => `AW-${idAdwords}/${conversionLabel}`),
    transaction_id: idReservation,
    value: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
  };

  window.gtag('event', 'purchase', value);

  return value;
};
