import { TYPE as coreTYPE } from '../../Core.constants';
import { ICON } from '../helpers';

const { CHAIN, HOTEL } = coreTYPE;

const FIELD = {
  PLACE: {
    icon: ICON.LOCATION,
    tabIndex: 0,
  },
  CALENDAR: {
    icon: ICON.CALENDAR,
    tabIndex: 1,
  },
  OCCUPATION: {
    icon: ICON.PEOPLE,
    tabIndex: 2,
  },
  PROMOCODE: {
    icon: ICON.DISCOUNT,
    inputMode: true,
    tabIndex: 3,
  },
};

const VIEW = {
  PLACE: 'place',
  CALENDAR: 'calendar',
  OCCUPATION: 'occupation',
  PROMOCODE: 'promocode',
};

const TYPE = {
  CHAIN,
  HOTEL,
};

export { FIELD, TYPE, VIEW };
