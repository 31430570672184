import { request } from '@mirai/data-sources';
import { parseDate, dateFormat } from '@mirai/locale';

import { IS_JEST } from '../../../../components/helpers';
import { loadScript } from '../../../../helpers';
import { formatPrice } from '../helpers/legacy';

export const bookingResponse = async ({
  response: { tracking = {} } = {},
  store: { hotel: { id: hotelId } = {} } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { checkin, checkout, nights } = {},
    hotelInfo = {},
    idReservation,
    totalNumAdults = 0,
    totalNumChildren = 0,
    totalNumRooms,
    totalPriceWithTaxes,
    totalPriceWithoutTaxes,
  } = tracking;

  const { currency: hotelCurrency = {} } = hotelInfo;

  const value = {
    hotelId,
    transactionId: idReservation,
    checkin: checkin ? dateFormat(parseDate(checkin, 'DD/MM/YYYY'), { format: 'YYYY/MM/DD' }) : undefined,
    checkout: checkout ? dateFormat(parseDate(checkout, 'DD/MM/YYYY'), { format: 'YYYY/MM/DD' }) : undefined,
    nights,
    totalPriceWithTaxes: formatPrice(totalPriceWithTaxes, hotelCurrency),
    totalPriceWithoutTaxes: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    currency: hotelCurrency.code,
    rooms: totalNumRooms,
    guests: totalNumAdults + totalNumChildren,
  };

  window.MiraiMeta = { ...window.MiraiMeta, ...value };

  !IS_JEST &&
    (await loadScript({
      id: 'mirai-pixel',
      src: `${process.env.SERVICE_BOOKING}/miraiConversionTracking?hotelId=${hotelId}&reservationId=${idReservation}&utm_source=`,
    }).catch((error = {}) => {
      request({
        credentials: 'include',
        endpoint: `trackPixelError?o=GTM_MIRAI&t=${idReservation}&n=${error.name}&m=${error.message}`,
        hostname: process.env.SERVICE_BOOKING,
        method: 'GET',
      });
    }));

  return value;
};
