import { useLocale } from '@mirai/locale';
import { Switch, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Filters.module.css';

const BookingOption = ({ caption, checked, field, title, onChange = () => {}, ...others }) => {
  const { translate } = useLocale();

  return (
    <View row className={style.spaceBetween}>
      <View>
        <Text action>{translate(title, others)}</Text>
        {caption && (
          <Text light small>
            {caption}
          </Text>
        )}
      </View>
      <Switch checked={checked} name={`switch:${field}`} onChange={(value) => onChange(field, value)} />
    </View>
  );
};

BookingOption.displayName = 'Mirai:Core:Rates:Filters.BookingOption';

BookingOption.propTypes = {
  caption: PropTypes.any,
  checked: PropTypes.bool,
  field: PropTypes.string,
  title: PropTypes.any,
  onChange: PropTypes.func,
};

export { BookingOption };
