import { styles, Text, Tooltip, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Environment.module.css';

// eslint-disable-next-line react/prop-types
const Variables = ({ values = {}, text }) => {
  const availableValues = Object.entries(values).filter(
    ([, value]) => value !== false && value !== null && value !== 0,
  );

  return availableValues.length ? (
    <Tooltip
      pressable
      text={availableValues.map(([key, value], index) => (
        <Text key={`${text}:${index}`} markdown={false} tiny className={style.variable}>{`${key}: ${JSON.stringify(
          value,
        )}`}</Text>
      ))}
    >
      <Text tiny>{text}</Text>
    </Tooltip>
  ) : null;
};

const Environment = ({
  confidence,
  environment: { entities = {}, sentence = {}, terms = {} } = {},
  intent,
  locale,
  response: { error = false } = {},
  ...others
}) => (
  <View {...others} row className={styles(style.environment, error && style.error, others.className)}>
    <Text bold tiny>
      {`${intent}: ${confidence}`}
    </Text>

    {locale && <Text tiny>{locale}</Text>}
    <Variables text="Entities" values={entities} />
    <Variables text="Sentence" values={sentence} />
    <Variables text="Terms" values={terms} />
  </View>
);

Environment.propTypes = {
  classifications: PropTypes.any,
  confidence: PropTypes.number,
  environment: PropTypes.shape({}),
  intent: PropTypes.string,
  locale: PropTypes.string,
  response: PropTypes.shape({
    error: PropTypes.bool,
  }),
};

export { Environment };
