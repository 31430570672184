import { DEFAULT_LOCALE } from '@mirai/locale';

import { LOCALES } from './constants';

export const getNormalizedLocale = (locale) => {
  if (typeof locale !== 'string') return DEFAULT_LOCALE;
  if (LOCALES.includes(locale)) return locale;

  const [language] = locale.split('-');
  return LOCALES.find((item) => item.startsWith(language)) || DEFAULT_LOCALE;
};
