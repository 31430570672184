const EMOJI_REGEX = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;

export const parseValue = (value, type, { trimSpaces = false } = {}) =>
  value && value.toString().trim().length > 0
    ? type !== 'number'
      ? (trimSpaces ? value.trim() : value).toString().replace(EMOJI_REGEX, '')
      : !isNaN(value)
      ? parseFloat(value, 10)
      : undefined
    : undefined;
