import { useLocale } from '@mirai/locale';
import { Notification, Progress, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { URL } from './FormAdyen.constants';
import { L10N } from './FormAdyen.l10n';
import * as style from './FormAdyen.module.css';
import { loadScript } from '../../../../../helpers/loadScript';

export const FormAdyen = ({ response }) => {
  const { translate } = useLocale();

  const [error, setError] = useState();
  const [ready, setReady] = useState();

  const refContainer = useRef();

  useEffect(() => {
    const { configuration: { environment = 'test' } = {} } = response || {};
    const url = URL.replace('{environment}', environment);

    const exists = document.getElementById('addyenCSS');
    if (!exists) {
      const css = document.createElement('link');
      css.crossOrigin = 'anonymous';
      css.id = 'addyenCSS';
      css.integrity = 'sha384-pC2TKxh6gMtWIUiXzlw5Jnq9ZtnyuPT0ymaHY6skdegLVNDMNiiQ7/NFixA87/Wb';
      css.rel = 'stylesheet';
      css.href = `${url}adyen.css`;

      document.head.appendChild(css);
    }

    let remove;
    (async () => {
      remove = await loadScript({
        async: false,
        crossOrigin: 'anonymous',
        domain: 'payments',
        id: 'addyenScript',
        integrity: 'sha384-PpkKjJdtlCZuAK7HWeMaOwzrQMGgAWThLG+QZe0sxlohYwfVPP+G65HBrWbgwSoE',
        src: `${url}adyen.js`,
        onLoad: handleLoad,
      });
    })();

    return () => {
      const el = document.getElementById('addyenCSS');
      el.parentNode.removeChild(el);
      remove && remove();
    };
  }, [response]);

  useEffect(() => {
    if (!response || !ready) return;

    (async () => {
      const { AdyenCheckout, Dropin } = window.AdyenWeb || {};
      const {
        configuration: {
          amount,
          clientKey,
          countryCode,
          environment = 'test',
          id,
          returnUrl,
          shopperLocale: locale,
          sessionData,
        } = {},
      } = response;

      const checkout = await AdyenCheckout({
        amount,
        clientKey,
        countryCode,
        environment,
        locale,
        returnUrl,
        session: { id, sessionData },
        onPaymentCompleted: (result, component) => {
          // eslint-disable-next-line no-console
          console.info(result, component);
          document.location.href = `${returnUrl}?${new URLSearchParams(result).toString()}`;
        },
        onPaymentFailed: (result, component) => {
          setError(error);
          // eslint-disable-next-line no-console
          console.info(result, component);
        },
        onError: (error, component) => {
          setError(error);
          // eslint-disable-next-line no-console
          console.error(error.name, error.message, error.stack, component);
        },
      });
      new Dropin(checkout, {
        paymentMethodsConfiguration: { card: { hasHolderName: true, holderNameRequired: true } },
      }).mount(refContainer.current);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, response]);

  const handleLoad = async () => {
    setReady(true);
  };

  return (
    <>
      <View ref={refContainer} className={style.container}>
        <Progress indeterminate visible={!ready} />
        {error && (
          <Notification error large wide>
            {translate(L10N.NOTIFICATION_ERROR)}
          </Notification>
        )}
      </View>
    </>
  );
};

FormAdyen.displayName = 'Mirai:Payments:ButtonPayment:FormAdyen';

FormAdyen.propTypes = {
  cards: PropTypes.array,
  response: PropTypes.object,
};
