export const getHistogram = (dataSource = [], resolution = 12) => {
  const values = dataSource
    .filter((value) => value > 0)
    .map((value) => parseInt(value))
    .sort((a, b) => a - b);

  let bins = [];
  let max;
  let maxBinCount;
  let min;

  if (values.length >= resolution) {
    max = Math.max(...values);
    min = Math.min(...values);

    const binSize = (max - min) / resolution;
    bins = Array.from({ length: resolution }, (_, i) => ({
      range: [min + i * binSize, min + (i + 1) * binSize],
      count: 0,
    }));

    values.forEach((value) => {
      if (value >= min && value <= max) {
        const binIndex = Math.min(Math.floor((value - min) / binSize), resolution - 1);
        if (bins[binIndex]) bins[binIndex].count++;
      }
    });

    maxBinCount = Math.max(...bins.map((bin) => bin.count));
  }

  const { length: binsFilled } = bins.filter(({ count } = {}) => count > 0);

  return binsFilled >= resolution / 2 ? { bins, max, maxBinCount, min, values } : {};
};
