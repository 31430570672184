import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Pressable, View } from '../../primitives';
import { Tooltip } from '../Tooltip';
import style from './Button.module.css';

const Button = ({
  busy,
  children,
  disabled,
  large,
  rounded,
  secondary,
  small,
  squared = false,
  transparent = false,
  tooltip,
  wide,
  onPress,
  ...inherit
}) => {
  const { fixed, left, right, top, ...others } = inherit;

  return React.createElement(
    tooltip ? Tooltip : React.Fragment,
    tooltip ? { fixed, left, right, text: tooltip, top } : undefined,
    React.createElement(
      Pressable,
      {
        ...others,
        disabled: disabled || busy,
        tag: 'button',
        onPress,
        className: styles(
          style.button,
          busy && !disabled && style.busy,
          large && style.large,
          small && style.small,
          rounded && style.rounded,
          squared && style.squared,
          secondary && !transparent && !disabled && style.secondary,
          transparent && style.transparent,
          (disabled || busy) && style.disabled,
          wide && style.wide,
          others.className,
        ),
        'aria-label': inherit['aria-label'] || (typeof tooltip === 'string' ? tooltip : undefined),
      },
      busy === undefined ? (
        children
      ) : (
        <>
          <View className={styles(style.busyContainer, busy && style.active)}>
            <View className={style.spinner} />
          </View>
          <View row className={style.children}>
            {children}
          </View>
        </>
      ),
    ),
  );
};

Button.displayName = 'Component:Button';
Button.propTypes = {
  busy: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  rounded: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  squared: PropTypes.bool,
  tooltip: PropTypes.string,
  transparent: PropTypes.bool,
  wide: PropTypes.bool,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onPress: PropTypes.func,
};

export { Button };
