import { translate as translateBase } from '@mirai/locale';

import { L10N } from '../Occupation.l10n';

export const getAgeOptions = ([min = 0, max] = [], translate = translateBase) =>
  typeof min === 'number' && typeof max === 'number' && max > min
    ? {
        emptyOption: translate(L10N.LABEL_AGE),
        options: Array.from({ length: max - min + 1 }, (_, index) => (index + min).toString()),
      }
    : undefined;
