/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { ERROR, STATUS } from './constants';

export const cancel = ({ comments = '', id, option, pinCode, token } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!id || !option || !pinCode || !token) return reject(ERROR.NOT_FILLED);

    const response = await request({
      endpoint: `/v2/booking/${id}?${new URLSearchParams(
        Object.entries({
          comments,
          pinCode,
          option,
          token,
        }),
      )}`,
      hostname: process.env.SERVICE_BOOKING,
      method: 'DELETE',
    }).catch(reject);

    if (response?.status === STATUS.OK) return resolve(response.data);

    reject(ERROR.UNKNOWN);
  });
