import React, { Fragment } from 'react';

import { findMatches } from './helpers';

const regex = new RegExp('{(.+?)}', 'g');

export const translate = ({
  dictionary = {},
  key: { id = '', defaultMessage } = {},
  bindings = {},
  ...others
} = {}) => {
  if (process?.env?.JEST_WORKER_ID && !Object.keys(dictionary).length)
    return defaultMessage || id || others.defaultMessage || others.id || '';

  let value = dictionary[id] || defaultMessage;
  if (value?.includes('{') && Object.keys(bindings).length > 0) value = value.replace(/\n/g, '');

  const complexBindings = [];
  const processComplexBindings = (key, index) => {
    if (value.includes('[object Object]')) {
      value = value.replace(/\[object Object\]/g, `[object ${index} Object] [object ${index} Object]`);
      complexBindings.push(bindings[key]);
    }
  };

  if (value && Object.keys(bindings).length > 0) {
    Object.keys(bindings).forEach((key, index) => {
      value = value.replace(new RegExp(`{${key}}`, 'g'), bindings[key]);
      processComplexBindings(key, index);
    });

    let segments = [...findMatches(value, regex)];
    value = parseValue(value, segments.length);

    const regexBindings = new RegExp(`{(${Object.keys(bindings).join('|')})`, 'g');
    const keys = [...findMatches(value, regexBindings)].map((match) => match[1]);

    keys.forEach((key) => {
      segments = [...findMatches(value, regex)];
      const indexKey = segments.findIndex((result) => result[0].startsWith(`{${key}`));
      if (indexKey < 0) return;

      const segment = segments[indexKey];
      const bindingValue = bindings[key];
      const indexStart = segment[0].includes('=0') ? 0 : 1;
      const firstWord = () => segments[indexKey][0];
      const secondWord = () => segments[indexKey][1];
      const isSelect = segment[0].includes('select');
      const isPlural = segment[0].includes('plural');

      if (isSelect && typeof bindingValue === 'boolean') {
        if (bindingValue) {
          value = value.replace(firstWord(), findMatches(`${secondWord()}}`, regex)[0][1]);
          value = value.replace(`other${segments[indexKey + 1][0]}}`, '');
        } else {
          const text = segments[indexKey + 1][1];
          value = value.replace(`${firstWord()}other`, '');
          value = value.replace(`{${text}}}`, text);
        }
      } else if (isPlural || isSelect) {
        let index = 0;
        let other = true;
        let indexValue = indexStart;

        while (index === 0 || value.includes(`=${indexValue}${segments[indexKey + index][0]}`)) {
          if (indexValue === bindingValue) {
            other = false;
            if (indexValue === indexStart) {
              const text = findMatches(`${secondWord()}}`, regex)[0][1];
              value = value.replace(segments[indexKey + index][0], isPlural ? text.replace('#', bindingValue) : text);
            } else {
              const text = segments[indexKey + index][1];
              value = value.replace(
                `=${indexValue}${segments[indexKey + index][0]}`,
                isPlural ? text.replace('#', bindingValue) : text,
              );
            }
          } else {
            if (indexValue == indexStart) value = value.replace(`${segments[indexKey + index][0]}`, '');
            else value = value.replace(`=${indexValue}${segments[indexKey + index][0]}`, '');
          }
          index++;
          indexValue++;
        }
        segments = [...findMatches(value, regex)];
        if (other) value = value.replace(`other${firstWord()}}`, secondWord().replace('#', bindingValue));
        else value = value.replace(`other${firstWord()}}`, '');
      }

      processComplexBindings(key);
    });
  }
  return getFragments(complexBindings, value) || value || id;
};

const parseValue = (value = '', lengthResults = 0) => {
  [...findMatches(value, new RegExp('}[ ]{1,}other[ ]{1,}{', 'gi'))].forEach((result) => {
    value = value.replace(result[0], '}other{');
  });
  [...findMatches(value, new RegExp('}[ ]{1,}}', 'g'))].forEach((result) => {
    value = value.replace(result[0], '}}');
  });
  [...findMatches(value, new RegExp('[ ]{1,}plural,[ ]{0,}', 'gi'))].forEach((result) => {
    value = value.replace(result[0], 'plural,');
  });
  [...findMatches(value, new RegExp('[ ]{1,}select,[ ]{0,}', 'gi'))].forEach((result) => {
    value = value.replace(result[0], 'select,');
  });
  for (let index = 0; index < lengthResults; index++) {
    [...findMatches(value, new RegExp(`[ ]{0,}=${index}[ ]{1,}{`, 'g'))].forEach((result) => {
      value = value.replace(result[0], `=${index}{`);
    });
  }
  return value;
};

const getFragments = (complexBindings = [], value = '') => {
  let children;
  if (complexBindings.length > 0) {
    let indexComplexBinding = 0;
    children = [];
    value.split(/\[object \d+ Object\]/).forEach((segment, index) => {
      if (segment !== ' ') {
        children.push(<Fragment key={index}>{segment}</Fragment>);
      } else {
        children.push(<Fragment key={index}>{complexBindings[indexComplexBinding]}</Fragment>);
        indexComplexBinding++;
      }
    });
  }

  return children;
};
