/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { getSession } from './helpers';
import { ERROR } from './User.constants';

export const profile = ({ session: propSession } = {}) =>
  new Promise(async (resolve, reject) => {
    const session = propSession || getSession();
    if (!session) return reject(ERROR.NOT_SESSION);

    const response = await request({
      hostname: process.env.SERVICE_USER,
      endpoint: '/club/v1/get_member',
      headers: { authorization: `Bearer ${session.authorization}` },
    }).catch(reject);

    if (!response) return reject(ERROR.UNKNOWN);

    resolve(response.member);
  });
