const CURRENCIES = [
  'AED',
  'AOA',
  'ARS',
  'AUD',
  'BGN',
  'BHD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DKK',
  'DOP',
  'EGP',
  'EUR',
  'GBP',
  'GTQ',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JPY',
  'KRW',
  'KWD',
  'MAD',
  'MXN',
  'MYR',
  'MZN',
  'NOK',
  'NZD',
  'OMR',
  'PEN',
  'PHP',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TND',
  'TRY',
  'USD',
  'VND',
  'ZAR',
];

const CURRENCIES_WITHOUT_DECIMALS = ['KRW', 'JPY', 'IDR', 'ISK', 'VND'];

const CUSTOM_CURRENCY_FORMAT = {
  IDR: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  },
};

const DEFAULT_CURRENCY = 'EUR';

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

const DEFAULT_LOCALE = 'es-ES';

const DEFAULT_CONTEXT = {
  currency: DEFAULT_CURRENCY,
  currencyFormat: ({ currency = DEFAULT_CURRENCY, value = 0 } = {}) => `${value} ${currency}`.trim(),
  dateFormat: (value = new Date()) => value.toISOString(),
  locale: DEFAULT_LOCALE,
  setCurrency: () => {},
  setLocale: () => {},
  translate: ({ id } = {}) => id,
};

const COUNTRIES = {
  ar: 'DZ',
  ca: 'ES',
  cs: 'CZ',
  da: 'DK',
  de: 'DE',
  el: 'GR',
  en: 'GB',
  es: 'ES',
  eu: 'ES',
  fi: 'FI',
  fr: 'FR',
  hu: 'HU',
  it: 'IT',
  ja: 'JP',
  nb: 'NO',
  nl: 'NL',
  no: 'NO',
  pl: 'PL',
  pt: 'PT',
  ru: 'RU',
  sv: 'SE',
  tr: 'TR',
  vi: 'VN',
  zh: 'CN',
};

export {
  COUNTRIES,
  CURRENCIES_WITHOUT_DECIMALS,
  CURRENCIES,
  CUSTOM_CURRENCY_FORMAT,
  DEFAULT_CONTEXT,
  DEFAULT_CURRENCY,
  DEFAULT_DATE_FORMAT,
  DEFAULT_LOCALE,
};
