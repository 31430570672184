import { getCookies } from './getCookies';
import { SHA256 } from '../../helpers';

export const parseUserData = ({ country, email, firstName, lastName, phone, ...others } = {}) => ({
  firstName: SHA256(firstName),
  lastName: SHA256(lastName),
  country,
  email: SHA256(email),
  phone: SHA256(phone),
  ...getCookies(),
  ...others,
});
