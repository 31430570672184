import { useEffect } from 'react';

const DEFAULT = { attributes: true, childList: true, subtree: true };

export const useMutationObserver = (element, callback, options = DEFAULT) => {
  useEffect(() => {
    if (!element) return;

    const observer = new MutationObserver(callback);
    observer.observe(element, options);

    return () => observer.disconnect();
  }, [callback, element, options]);
};
