export const L10N = {
  ACTION_VIEW_MORE: { id: 'common.action.view_more' },
  ACTION_VIEW_HOTEL: { id: 'common.action.view_hotel' },

  LABEL_DISCOUNT: { id: 'finder.label.discount' },
  LABEL_DISTANCE: { id: 'finder.label.distance' },
  LABEL_EXCLUDES_TAXES_FEES: { id: 'finder.label.excludes_taxes_fees' },
  LABEL_FOR_NIGHTS: { id: 'common.label.for_nights' },
  LABEL_INCLUDES_TAXES_FEES: { id: 'finder.label.includes_taxes_fees' },
  LABEL_ONLY_MEMBERS_DISCOUNT: { id: 'finder.label.only_members_discount' },
  LABEL_PER_NIGHT: { id: 'finder.label.per_night' },
};
