const FORMAT = { hour: '2-digit', minute: '2-digit' };
const MINUTES_IN_MILLISECONDS = 60 * 1000;

export const parseTime = (time, locale) => {
  if (!time || !locale) return;

  return new Date(time + new Date(time).getTimezoneOffset() * MINUTES_IN_MILLISECONDS).toLocaleTimeString(
    locale,
    FORMAT,
  );
};
