/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { ERROR } from './User.constants';

const AUTHENTICATION_ERRORS = ['AUTHENTICATION_FAIL', 'PARAMETER_ERROR'];

export const booking = ({ id, pinCode } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!id || !pinCode) return reject(ERROR.NOT_FILLED);

    const response = await request({
      hostname: process.env.SERVICE_BOOKING,
      endpoint: `/login_reservation.json?pinCode=${pinCode}&reservationCode=${id}`,
      headers: { 'X-Requested-With': undefined, 'Content-Type': undefined },
    }).catch(reject);

    if (!response) return reject(ERROR.UNKNOWN);

    const { data: { code: token, idHotel } = {}, status } =
      response?.data === null ? { ...response, data: undefined } : response;

    if (token) resolve({ idHotel, token });
    else if (AUTHENTICATION_ERRORS.includes(status)) reject(ERROR.AUTHENTICATION);
    else reject(ERROR.UNKNOWN);
  });
