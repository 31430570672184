import { getCookies } from './getCookies';

export const parseCustomData = ({ contents = [], hotelCurrency = {}, propertyName, ...others } = {}) => ({
  contentIds: contents.map(({ id }) => id),
  contentName: propertyName,
  contents,
  currency: hotelCurrency.code,
  ...getCookies(),
  ...others,
});
