import { ICON } from '@mirai/ui';
import { FaKitchenSet as KITCHEN } from '@react-icons/all-files/fa6/FaKitchenSet';
import { GiForkKnifeSpoon as CUTLERY } from '@react-icons/all-files/gi/GiForkKnifeSpoon';
import { GiSlippers as SLIPPERS } from '@react-icons/all-files/gi/GiSlippers';
import { IoMdBicycle as BICYCLE } from '@react-icons/all-files/io/IoMdBicycle';
import { MdAir as AIR } from '@react-icons/all-files/md/MdAir';
import { MdAlarm as ALARM } from '@react-icons/all-files/md/MdAlarm';
import { MdBalcony as BALCONY } from '@react-icons/all-files/md/MdBalcony';
import { MdCable as CABLE } from '@react-icons/all-files/md/MdCable';
import { MdCheckCircleOutline as AMENITY } from '@react-icons/all-files/md/MdCheckCircleOutline';
import { MdFreeCancellation as FREE_CANCELLATION } from '@react-icons/all-files/md/MdFreeCancellation';
import { MdKeyboardDoubleArrowUp as KEYBOARD_UP } from '@react-icons/all-files/md/MdKeyboardDoubleArrowUp';
import { MdLockOutline as LOCK } from '@react-icons/all-files/md/MdLockOutline';
import { MdMoreTime as MORE_TIME } from '@react-icons/all-files/md/MdMoreTime';
import { MdOutlineBathtub as BATH } from '@react-icons/all-files/md/MdOutlineBathtub';
import { MdOutlineBeachAccess as BEACH } from '@react-icons/all-files/md/MdOutlineBeachAccess';
import { MdOutlineBedroomBaby as BEDROOM_BABY } from '@react-icons/all-files/md/MdOutlineBedroomBaby';
import { MdOutlineCardGiftcard as CARD_GIFT } from '@react-icons/all-files/md/MdOutlineCardGiftcard';
import { MdOutlineChildFriendly as CHILD } from '@react-icons/all-files/md/MdOutlineChildFriendly';
import { MdOutlineCoffeeMaker as COFFEE_MAKER } from '@react-icons/all-files/md/MdOutlineCoffeeMaker';
import { MdOutlineDesk as DESK } from '@react-icons/all-files/md/MdOutlineDesk';
import { MdOutlineDiscount as DISCOUNT } from '@react-icons/all-files/md/MdOutlineDiscount';
import { MdOutlineDryCleaning as DRY_CLEANING } from '@react-icons/all-files/md/MdOutlineDryCleaning';
import { MdOutlineFitnessCenter as FITNESS } from '@react-icons/all-files/md/MdOutlineFitnessCenter';
import { MdOutlineFreeBreakfast as BREAKFAST } from '@react-icons/all-files/md/MdOutlineFreeBreakfast';
import { MdOutlineGolfCourse as GOLF } from '@react-icons/all-files/md/MdOutlineGolfCourse';
import { MdOutlineIron as IRON } from '@react-icons/all-files/md/MdOutlineIron';
import { MdOutlineLiving as LIVING_ROOM } from '@react-icons/all-files/md/MdOutlineLiving';
import { MdOutlineLocalBar as BAR } from '@react-icons/all-files/md/MdOutlineLocalBar';
import { MdOutlineLocalLaundryService as WASHER } from '@react-icons/all-files/md/MdOutlineLocalLaundryService';
import { MdOutlineLocalParking as PARKING } from '@react-icons/all-files/md/MdOutlineLocalParking';
import { MdOutlineLocalTaxi as TAXI } from '@react-icons/all-files/md/MdOutlineLocalTaxi';
import { MdOutlineMenuBook as MENU } from '@react-icons/all-files/md/MdOutlineMenuBook';
import { MdOutlineMicrowave as MICROWAVE } from '@react-icons/all-files/md/MdOutlineMicrowave';
import { MdOutlineNewspaper as NEWSPAPER } from '@react-icons/all-files/md/MdOutlineNewspaper';
import { MdOutlineOutdoorGrill as BARBECUE } from '@react-icons/all-files/md/MdOutlineOutdoorGrill';
import { MdOutlinePercent as PERCENT } from '@react-icons/all-files/md/MdOutlinePercent';
import { MdOutlinePool as POOL } from '@react-icons/all-files/md/MdOutlinePool';
import { MdOutlineQuestionAnswer as ANSWER } from '@react-icons/all-files/md/MdOutlineQuestionAnswer';
import { MdOutlineRadio as RADIO } from '@react-icons/all-files/md/MdOutlineRadio';
import { MdOutlineRateReview as RATE_REVIEW } from '@react-icons/all-files/md/MdOutlineRateReview';
import { MdOutlineRoomPreferences as ROOM_PREFERENCES } from '@react-icons/all-files/md/MdOutlineRoomPreferences';
import { MdOutlineShower as SHOWER } from '@react-icons/all-files/md/MdOutlineShower';
import { MdOutlineSpa as SPA } from '@react-icons/all-files/md/MdOutlineSpa';
import { MdOutlineSpeaker as SPEAKER } from '@react-icons/all-files/md/MdOutlineSpeaker';
import { MdOutlineStarBorder as STAR } from '@react-icons/all-files/md/MdOutlineStarBorder';
import { MdOutlineSurroundSound as SURROUND } from '@react-icons/all-files/md/MdOutlineSurroundSound';
import { MdOutlineTablet as TABLET } from '@react-icons/all-files/md/MdOutlineTablet';
import { MdOutlineWash as WASH_BASIN } from '@react-icons/all-files/md/MdOutlineWash';
import { MdOutlineWaterDrop as WATER } from '@react-icons/all-files/md/MdOutlineWaterDrop';
import { MdOutlineWbSunny as SUN } from '@react-icons/all-files/md/MdOutlineWbSunny';
import { PiFan as FAN } from '@react-icons/all-files/pi/PiFan';
import { PiFlowerTulip as FLOWER } from '@react-icons/all-files/pi/PiFlowerTulip';
import { PiToilet as TOILET } from '@react-icons/all-files/pi/PiToilet';
import { Ri24HoursLine as TWENTY_FOUR_HOURS } from '@react-icons/all-files/ri/Ri24HoursLine';
import { RiFridgeLine as FRIDGE } from '@react-icons/all-files/ri/RiFridgeLine';
import { RiPhoneLine as PHONE } from '@react-icons/all-files/ri/RiPhoneLine';
import { RiSafe2Line as SAFE } from '@react-icons/all-files/ri/RiSafe2Line';
import { RiShirtLine as CLOTHES } from '@react-icons/all-files/ri/RiShirtLine';
import { RiTempHotLine as HEATING } from '@react-icons/all-files/ri/RiTempHotLine';
import { RiTvLine as TV } from '@react-icons/all-files/ri/RiTvLine';
import { RiWifiLine as WIFI } from '@react-icons/all-files/ri/RiWifiLine';
import { TbAirConditioning as AIR_CONDITIONING } from '@react-icons/all-files/tb/TbAirConditioning';
import { TbScaleOutline as SCALE } from '@react-icons/all-files/tb/TbScaleOutline';
import { TbSofa as SOFA } from '@react-icons/all-files/tb/TbSofa';

export const ICON_AMENITIES = {
  AMENITY,
  1: KITCHEN,
  2: FRIDGE,
  3: WASHER,
  4: MICROWAVE,
  5: IRON,
  6: COFFEE_MAKER,
  7: WASH_BASIN,
  8: LIVING_ROOM,
  9: BALCONY,
  10: BALCONY,
  11: RADIO,
  13: TV,
  14: PHONE,
  15: AIR_CONDITIONING,
  16: BAR,
  17: BAR,
  18: SAFE,
  19: SAFE,
  20: CABLE,
  21: CABLE,
  22: WIFI,
  23: WIFI,
  // 24: 'suelo_moqueta',
  // 25: 'suelo_parquet',
  // 26: 'suelo_madera',
  // 27: 'suelo_marmol',
  // 28: 'suelo_baldosas',
  51: SHOWER,
  52: BATH,
  53: BATH,
  54: BATH,
  55: WATER,
  // 56: 'bidet',
  57: AIR,
  // 58: 'espejo_maquillaje',
  // 59: 'albornoz',
  60: SLIPPERS,
  61: TABLET,
  62: TABLET,
  63: BATH,
  64: BARBECUE,
  65: HEATING,
  67: KITCHEN,
  68: KITCHEN,
  69: DESK,
  // 70: 'horno',
  71: WATER,
  // 72: 'jaima',
  73: FLOWER,
  74: WASHER,
  75: CUTLERY,
  76: POOL,
  77: POOL,
  79: LIVING_ROOM,
  80: SUN,
  // 81: 'tocador',
  82: FAN,
  83: CLOTHES,
  84: BEACH,
  85: BEACH,
  // 86: 'vitroceramica',
  87: BATH,
  90: SOFA,
  91: SOFA,
  92: TOILET,
  93: ALARM,
  // 94: 'camas_balinesas',
  // 95: 'hamacas',
  96: HEATING,
  97: BEACH,
  98: WATER,
  99: BATH,
  100: TWENTY_FOUR_HOURS,
  101: COFFEE_MAKER,
  102: TV,
  103: TAXI,
  // 104: 'docking_station',
  // 105: 'amenities_bulgari',
  106: WASHER,
  // 107: 'hamacas_sumergidas',
  108: SHOWER,
  109: NEWSPAPER,
  111: TV,
  112: SCALE,
  113: TAXI,
  114: PHONE,
  115: BREAKFAST,
  116: GOLF,
  117: SURROUND,
  118: SPEAKER,
  // 119: 'amenities_bano',
  120: TWENTY_FOUR_HOURS,
  121: MENU,

  200: ICON.CHECK,
  201: CHILD,
  202: DRY_CLEANING,
  203: BICYCLE,
  204: RATE_REVIEW,
  205: BEDROOM_BABY,
  206: CARD_GIFT,
  207: STAR,
  208: FREE_CANCELLATION,
  209: FITNESS,
  210: PARKING,
  211: LOCK,
  212: PERCENT,
  213: SPA,
  214: KEYBOARD_UP,
  215: ANSWER,
  216: DISCOUNT,
  217: ROOM_PREFERENCES,
  218: MORE_TIME,
};
