import { useLocale } from '@mirai/locale';
import { Button, Icon, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './Header.l10n';
import * as style from './Header.module.css';
import { ICON } from '../../../helpers';
import { DEFAULT_NAME } from '../../Chat.constants';
import { Avatar } from '../Avatar';

const Header = ({ config: { image, name = DEFAULT_NAME } = {}, onClose }) => {
  const { translate } = useLocale();

  return (
    <View row className={style.header}>
      <View row>
        <Avatar {...{ image }} small className={style.avatar} />
        <View>
          <Text bold headline>
            {name}
          </Text>
          <Text light small>
            {translate(L10N.LABEL_ASSISTANT)}
          </Text>
        </View>
      </View>

      <Button small squared tooltip={translate(L10N.ACTION_CLOSE)} transparent onPress={onClose}>
        <Icon value={ICON.CLOSE} />
      </Button>
    </View>
  );
};

Header.displayName = 'Mirai:Core:Chat.Header';

Header.propTypes = {
  config: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export { Header };
