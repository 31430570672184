/* eslint-disable no-console */
import { IS_JEST } from '../modules';

const METRICS_DEFAULTS = {
  track: (id, properties) => !IS_JEST && console.log('MetricsContext:track', { id, properties }),
  trackRender: (id, properties) => !IS_JEST && console.log('MetricsContext:trackRender', { id, properties }),
};

const STORAGE_KEY = 'MIRAI:METRICS';

export { METRICS_DEFAULTS, STORAGE_KEY };
