import { useStore } from '@mirai/data-sources';
import PropTypes from 'prop-types';
import React from 'react';

import { Card } from './components';
import { getActionIcon } from './helpers';

// ! TODO: Refacto use of intent/response vs lisa.intent/response
const Action = ({ caption, disabled, intent, image, text, type, url, value, onPress, onResponse }) => {
  const { value: { lisa } = {} } = useStore();

  const handlePress = () => {
    if (intent) {
      onPress({ intent, context: value, text });
    } else if (type && value) {
      const { intent, response: { form = {}, value: context = {} } = {} } = lisa;

      const nextForm = { ...form };
      if (type === 'calendar') nextForm.dates = [value.from, value.to];
      else if (type === 'occupation') nextForm.occupation = value.rooms.map(({ occupation }) => occupation);
      else if (type === 'offer') nextForm.offerId = value;

      onResponse({ image, caption, text });
      onPress({ context, intent, form: nextForm, text });
    }
  };

  return (
    <Card
      {...{ caption, disabled, image, text }}
      href={url}
      icon={getActionIcon(url)}
      option={!image && !disabled && !url}
      onPress={!disabled ? handlePress : undefined}
    />
  );
};

Action.propTypes = {
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  image: PropTypes.string,
  intent: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(['calendar', 'occupation', 'offer']),
  url: PropTypes.string,
  value: PropTypes.any,
  onPress: PropTypes.func,
  onResponse: PropTypes.func,
};

export { Action };
