const ERROR = {
  NOT_FILLED: { code: 2, type: 'warn', message: 'Not all fields have been filled out' },
};

const MONTHS = 3;

const RANGE_MAX_DAYS = 30;

const SCROLL_EVENT_MS = 500;

const TAX = 1;
const TAX_FREE = 2;
const TAX_PARTIAL = 3;

export { ERROR, MONTHS, RANGE_MAX_DAYS, SCROLL_EVENT_MS, TAX, TAX_FREE, TAX_PARTIAL };
