import { dataLayerPush } from '../../../helpers';
import { getRatesPriceRange } from '../../helpers';
import { formatPrice } from '../../helpers/legacy';

export const ratesResponse = ({ checkin, checkout, hotelCurrency, rates } = {}) => {
  const bookingValues = { sd: checkin, ed: checkout };
  if (!rates) {
    dataLayerPush({ 'duetto.data': bookingValues, event: 'Denial' });
    return;
  }

  const { minRate, maxRate } = getRatesPriceRange(rates, hotelCurrency);
  dataLayerPush({
    'duetto.data': { lr: minRate?.price, hr: maxRate?.price, cc: hotelCurrency.code, ...bookingValues },
    event: 'duetto.Search',
  });

  dataLayerPush({
    'duetto.data': {
      rates: Object.values(rates).flatMap(({ name: rt, occupationOptions, sortedKeysOccupations }) =>
        Object.values(occupationOptions[sortedKeysOccupations[0]].rates).map(
          ({ name: rc, boardOptions, sortedKeysBoards }) => ({
            r: formatPrice(boardOptions[sortedKeysBoards[0]]?.originalAvgPrice, hotelCurrency),
            rt,
            rc,
          }),
        ),
      ),
      cc: hotelCurrency.code,
      ...bookingValues,
    },
    event: 'duetto.Quote',
  });
};
