import { useLocale } from '@mirai/locale';
import { InputNumber, InputSelect, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { getAgeOptions } from './helpers';
import { TYPE } from './Occupation.constants';
import { L10N } from './Occupation.l10n';
import * as style from './Occupation.module.css';
import { getAgeRange } from '../../helpers';

const OccupationGroup = ({
  age = [],
  fulfill = false,
  max,
  min = 0,
  type = TYPE.ADULT,
  value = {},
  onChange = () => {},
  ...others
}) => {
  const { translate } = useLocale();

  useEffect(() => {}, []);

  const { amount, ages = [] } = value;

  const handleAmount = (nextAmount) => {
    if (nextAmount < amount) ages.pop();
    else ages.push(undefined);
    onChange({ ages, amount: nextAmount, type });
  };

  const handleAge = (nextAge = '', index) => {
    const nextAges = [...ages];
    nextAges[index] = nextAge.length !== 0 ? parseInt(nextAge, 10) : undefined;
    onChange({ ages: nextAges, amount, type });
  };

  return (
    <>
      <InputNumber
        hint={getAgeRange(age, translate)}
        label={translate(L10N.LABEL_OCCUPATION_TYPE[type], { adults: '', children: '', guests: '' })}
        max={max}
        min={min}
        name={`occupation-${type}-${max || ''}`}
        rounded={false}
        step={1}
        value={amount}
        className={styles(style.inputNumber, style.offset)}
        onChange={handleAmount}
        testId={others.testId}
      />
      {fulfill && amount > 0 && (
        <View className={styles(style.ages, style.offset)}>
          <View row className={style.inputs}>
            {Array.from({ length: amount }, (_, index) => (
              <InputSelect
                {...getAgeOptions(age, translate)}
                name={`age-${age}`}
                error={ages[index] === undefined}
                key={`occupation:group:${index}`}
                value={ages[index]?.toString()}
                onChange={(value) => handleAge(value, index)}
                className={style.inputSelect}
                testId={others.testId ? `${others.testId}-select-${index}` : undefined}
              />
            ))}
          </View>
          {[TYPE.CHILD, TYPE.INFANT].includes(type) && (
            <Text light small>
              {translate(L10N.LABEL_AGE_HINT)}
            </Text>
          )}
        </View>
      )}
    </>
  );
};

OccupationGroup.displayName = 'Finder:Occupation:OccupationGroup';

OccupationGroup.propTypes = {
  age: PropTypes.arrayOf(PropTypes.number),
  fulfill: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  type: PropTypes.number.isRequired,
  value: PropTypes.shape({
    amount: PropTypes.number,
    ages: PropTypes.arrayOf(PropTypes.number),
  }),
  onChange: PropTypes.func.isRequired,
};

export { OccupationGroup };
