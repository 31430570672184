// !TODO: improve error handling
const ERROR = {
  UNKNOWN: { code: 1, messages: 'Something wrong happened. Try again', payload: undefined },
  PARAMETER_ERROR: { code: 3, messages: 'Some parameter is incorrect', payload: undefined },
  NOT_AVAILABLE: { code: 4, messages: 'There are not available', payload: undefined },
  NOT_EXISTS: { code: 5, messages: 'There are no exists data for the request', payload: undefined },
};

const KEY_HASH = 'm14357';

export { ERROR, KEY_HASH };
