import { PERFORMANCE } from '../../Core.constants';

const CONSENT_MODE_ANSWERED = [PERFORMANCE.ANALYTICS, PERFORMANCE.COMPARE, PERFORMANCE.GTM, PERFORMANCE.GOOGLE_ADS];

const CONSENT_MODE_REQUIRED = [
  PERFORMANCE.AFFILIRED,
  PERFORMANCE.BING,
  PERFORMANCE.FACEBOOK,
  PERFORMANCE.THE_HOTEL_NETWORK,
];

const KPI_EVENTS = [
  //
  'UNAVAILABILITY:RENDER',
  'RATES:RENDER',
  'RATES:SUBMIT',
  'CHECKOUT:RENDER',
  'CHECKOUT:SUBMIT',
  'BOOKING:RENDER',
];

export { CONSENT_MODE_ANSWERED, CONSENT_MODE_REQUIRED, KPI_EVENTS };
