import { calcNightWithDeals } from './calcNightWithDeals';

export const validate = ({
  dataSource: { calendar: { highlights = [], minNightsWithDeals } = {} } = {},
  error = {},
  value: { calendar: [from, to] = [] } = {},
}) => {
  let valid = true;

  if (!from || !to) valid = false;
  if (from && to && calcNightWithDeals(from, to, highlights) < minNightsWithDeals) valid = false;
  if (error.occupation) valid = false;

  return valid;
};
