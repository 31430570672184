import { useLocale } from '@mirai/locale';
import { InputOption } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Aplazame.l10n';
import { EVENT } from '../../../ButtonPayment/ButtonPayment.constants';

const SCRIPT_ID = 'aplazame_script';

const Aplazame = ({ showErrors, onError = () => {}, ...others }) => {
  const { translate } = useLocale();

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    document.addEventListener(EVENT.APLAZAME_RESPONSE, handleResponse);
    return () => document.removeEventListener(EVENT.APLAZAME_RESPONSE, handleResponse);
  }, []);

  useEffect(() => {
    setError(checked ? undefined : { spanishIdcard: { required: true } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    onError(error || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleResponse = ({ detail: { checkoutId, publicKey = process.env.METHOD_APLAZAME_PUBLICKEY } = {} }) => {
    const exists = document.getElementById(SCRIPT_ID);
    const script = exists || document.createElement('script');
    script.id = SCRIPT_ID;
    script.src = `${process.env.METHOD_APLAZAME_SCRIPT}?public-key=${publicKey}&
      sandbox=${process.env.NODE_ENV !== 'production' ? 'true' : 'false'}`;
    script.async;
    script.defer;
    // eslint-disable-next-line no-undef
    script.onload = () => aplazame && aplazame.checkout(checkoutId, {});

    document.head.appendChild(script);
  };

  return (
    <InputOption
      {...others}
      checked={checked}
      error={!checked && showErrors}
      id="checkout-spanishIdcard"
      name="spanish-idcard"
      label={translate(L10N.LABEL_SPANISH_RESIDENT)}
      required
      showRequired
      onChange={setChecked}
    />
  );
};

Aplazame.propTypes = {
  showErrors: PropTypes.bool,
  onError: PropTypes.func,
};

export { Aplazame };
