import { toUrlParams } from '../../helpers';

export const getProfileUrl = ({
  id,
  forwarder: { profile: url } = {},
  language,
  urlParams: { miraiId, type } = {},
  value,
} = {}) => {
  if (!id || !language || !url) return;

  return `${url}${toUrlParams({
    idtokenprovider: id,
    lang: language,
    miraiId,
    context: value,
    type,
  })}`;
};
