import { currencySymbol } from './currencySymbol';

const narrowSymbols = {};

export const getNarrowSymbol = ({ currency, fancy, locale }) => {
  const key = `${currency}-${locale}-${fancy}`;

  let value = narrowSymbols[key];
  if (value === undefined) {
    narrowSymbols[key] = currencySymbol(currency, { display: 'narrowSymbol', locale, fancy }) || '';
    value = narrowSymbols[key];
  }

  return value;
};
