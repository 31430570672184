import * as style from '../ButtonDispatcher.module.css';

export const determinePosition = ({ mobile, desktop, ...config }, isMobile) => {
  let base = { ...config };

  if (isMobile && mobile) base = { ...mobile };
  else if (!isMobile && desktop) base = { ...desktop };

  const { bottom, left = false, right, top = false } = base;

  return {
    className: [left ? style.left : style.right, top ? style.top : style.bottom],
    style: {
      top: top >= 1 ? top : undefined,
      bottom: bottom >= 1 ? bottom : undefined,
      left: left >= 1 ? left : undefined,
      right: right >= 1 ? right : undefined,
    },
  };
};
