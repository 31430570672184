import { LocalAdapter, request, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT, ENGINE_STORAGE_KEY } from './constants';

export const get = async ({ id, type = 'hotel' } = {}) => {
  if (!id) return;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${ENGINE_STORAGE_KEY}:get:${id}`;
  let value = storage.get(key);

  if (!value) {
    value = await request({
      hostname: process.env.SERVICE_ENGINE,
      endpoint: `/${type.toUpperCase().trim()}/${id}.json`,
    }).catch(() => {});

    storage.set(key, value);
  }

  return value;
};
