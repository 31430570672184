import { useStore } from '@mirai/data-sources';
import { useDevice, View } from '@mirai/ui';
import React from 'react';

import { getFeatures } from './helpers';
import { Skeleton } from '../__shared__';

const RatesSkeleton = () => {
  const { isDesktop, isMobile, isPortrait, isTablet } = useDevice();
  const {
    value: { hotel: { features } = {}, urlParams },
  } = useStore();

  const section = { borderBottom: 'solid 1px var(--mirai-ui-content-background)', gap: '0.5rem', padding: '1rem' };
  const option = { row: true, style: { gap: '0.5rem' } };
  const optionContainer = {
    row: true,
    style: { gap: '0.5rem', justifyContent: 'space-between', marginBottom: '0.5rem' },
  };
  const tab = {
    row: false,
    style: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      border: 'solid 1px var(--mirai-ui-content-background)',
      flex: 1,
      gap: '0.5rem',
      height: '100%',
      padding: '1rem',
    },
  };

  const { columnMode, hideRadio, rowMode } = getFeatures(features, urlParams, { isDesktop, isMobile });

  return (
    <>
      <View
        row
        style={{
          alignItems: 'stretch',
          flexWrap: 'wrap',
          gap: '1rem',
          justifyContent: 'space-between',
          marginTop: isMobile ? 0 : '1rem',
        }}
      >
        {Array.from({ length: isMobile ? 1 : isTablet ? 2 : 3 }).map((_, index) => (
          <Skeleton
            key={index}
            base
            row={rowMode}
            style={{
              boxSizing: 'border-box',
              flex: 1,
              flexBasis:
                isTablet || columnMode
                  ? 'calc(50% - calc(var(--mirai-ui-space-M) / 2))'
                  : isDesktop && !rowMode
                  ? `calc(33.33% - calc(calc(var(--mirai-ui-space-M) * 2) / 3))`
                  : '100%',
              overflow: 'hidden',
            }}
          >
            {React.createElement(
              rowMode ? View : React.Fragment,
              rowMode
                ? {
                    style: {
                      borderRight: 'solid 1px var(--mirai-ui-content-background)',
                      minWidth: '50%',
                    },
                  }
                : undefined,
              <>
                <Skeleton
                  style={{
                    borderRadius: 0,
                    minHeight: !columnMode && !rowMode ? '208px' : `calc(100svh / ${isPortrait ? 5 : 3})`,
                  }}
                />

                <View row style={{ ...section, gap: '1rem', padding: '0.75rem 1rem' }}>
                  <Skeleton small words={2} />
                  <Skeleton small words={1} />
                  <Skeleton small words={2} />
                </View>

                <View style={{ ...section }}>
                  <Skeleton headline level={2} words={2} />
                  <Skeleton action words={3} />
                  <Skeleton action words={1} />
                  <Skeleton action words={2} />
                  <Skeleton action words={1} />
                  <Skeleton action words={2} />
                </View>
              </>,
            )}

            <View style={{ height: '100%' }} wide>
              <View style={{ ...section }}>
                <Skeleton action words={2} />
                <View row={hideRadio}>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <View key={index} {...(hideRadio ? tab : optionContainer)}>
                      <View {...option}>
                        {!hideRadio && <Skeleton icon />}
                        <Skeleton action words={3} />
                      </View>
                      <Skeleton action words={1} />
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ ...section }}>
                <Skeleton action words={1} />
                <View row={hideRadio}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <View key={index} {...(hideRadio ? tab : optionContainer)}>
                      <View {...option}>
                        {!hideRadio && <Skeleton icon />}
                        <Skeleton action words={3} />
                      </View>
                      <Skeleton action words={1} />
                    </View>
                  ))}
                </View>
              </View>

              <View row style={{ alignItems: 'flex-end', justifyContent: 'space-between', padding: '1rem' }}>
                <View style={{ gap: '0.5rem' }}>
                  <Skeleton small words={2} />
                  <Skeleton headline level={2} words={1} />
                  <Skeleton small words={5} />
                  <Skeleton small words={4} />
                  <Skeleton small words={2} />
                </View>

                <Skeleton button words={1} />
              </View>
            </View>
          </Skeleton>
        ))}
      </View>
    </>
  );
};

RatesSkeleton.displayName = 'Mirai:Core:Rates:Skeleton';

RatesSkeleton.propTypes = {};

export default RatesSkeleton;
