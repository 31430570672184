import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Modal, Icon, Notification, ScrollView, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as style from './ModalInfo.module.css';
import { EVENT, getOccupationLabel, ICON, OCCUPATION_TYPE } from '../../../../../helpers';
import { L10N } from '../../Item.l10n';
import { Amenities } from '../Amenities';
import { Slider } from '../Slider';

const ModalInfo = ({
  bedDisposition = [],
  description,
  doubleBedWidth,
  features = {},
  images = [],
  media,
  name,
  occupation: roomOccupation = [],
  room = 0,
  onClose = () => {},
  onViewUrl,
  visible: propVisible,
  ...others
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: { finder: { occupation: finderOccupation = [] } = {} },
  } = useStore();

  const [visible, setVisible] = useState(propVisible);

  useEffect(() => {
    setVisible(propVisible);
    if (propVisible) Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:INFO' });
  }, [propVisible]);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300);
  };

  const { amenities: baseAmenities = [], title } = others;
  const highlightAmenities = [];
  const amenities = [];
  const textProps = { action: true };

  baseAmenities.forEach((item = {}) => {
    item.highlight ? highlightAmenities.push(item) : amenities.push(item);
  });

  const { showMaxOccupation = false } = features;
  const occupation = showMaxOccupation ? roomOccupation : finderOccupation[room];

  return (
    <Modal
      {...{ title, visible }}
      fit
      title={name}
      onClose={handleClose}
      onOverflow={handleClose}
      className={style.modal}
    >
      <ScrollView scrollIndicator scrollTo={visible ? 0.5 : undefined} snap={false} className={style.scrollView}>
        {!isMobile && (
          <Slider
            captions={images.map(({ alt }) => alt)}
            height={400}
            images={images.map(({ large }) => large)}
            media={media}
            metrics="RATES:ITEM:INFO"
            width={738}
            onViewUrl={onViewUrl}
            className={style.slider}
          />
        )}

        {description && (
          <View wide className={style.section}>
            <Text bold>{translate(L10N.LABEL_DESCRIPTION)}</Text>
            <Text {...textProps}>{description}</Text>
          </View>
        )}

        {occupation?.length > 0 && (
          <View wide className={style.section}>
            <Text bold>
              {translate(showMaxOccupation ? L10N.LABEL_MAX_OCCUPATION : L10N.LABEL_SELECTED_OCCUPATION)}
            </Text>
            {occupation.map((item = {}, index) => (
              <Text {...textProps} key={index}>
                {`${getOccupationLabel({ ...item, ages: showMaxOccupation ? item.ages : [] }, translate)} ${
                  !showMaxOccupation && item.type === OCCUPATION_TYPE.CHILD
                    ? `(${translate(L10N.LABEL_YEARS_OLD, { amount: item.ages?.sort().join('-') })})`
                    : ''
                }`}
              </Text>
            ))}
          </View>
        )}

        {bedDisposition.length > 0 && (
          <View wide className={style.section}>
            <Text bold>{translate(L10N.LABEL_BED_DISPOSITION)}</Text>
            <View row className={style.beds}>
              {bedDisposition.map((arrangements = [], index) => (
                <View key={index} row className={[style.box, style.bedDisposition]}>
                  {arrangements.map(({ amount, type }) => (
                    <View key={`${index}-${type}`} row>
                      <Icon headline level={2} value={type === 1 ? ICON.BED_SINGLE : ICON.BED_DOUBLE} />
                      <Text action>{`x${amount}`}</Text>
                    </View>
                  ))}
                </View>
              ))}
            </View>
            {doubleBedWidth && (
              <Notification inline title={!isMobile} wide>
                {`${translate(L10N.LABEL_DOUBLE_BED_WIDTH)}: ${doubleBedWidth}`}
              </Notification>
            )}
          </View>
        )}

        {highlightAmenities.length > 0 && (
          <View wide className={style.section}>
            <Text bold>{translate(L10N.LABEL_TOP_AMENITIES)}</Text>
            <Amenities amenities={highlightAmenities} highlight />
          </View>
        )}

        {amenities.length > 0 && (
          <View wide className={style.section}>
            <Text bold>{translate(L10N.LABEL_MORE_SERVICES)}</Text>
            <Amenities amenities={amenities} {...others} />
          </View>
        )}
      </ScrollView>
    </Modal>
  );
};

ModalInfo.displayName = 'Mirai:Core:Rates:Item:ModalInfo';

ModalInfo.propTypes = {
  bedDisposition: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        type: PropTypes.number,
      }),
    ),
  ),
  description: PropTypes.string,
  doubleBedWidth: PropTypes.string,
  features: PropTypes.shape({}),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      large: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
  ),
  media: PropTypes.shape({
    url3D: PropTypes.string,
    urlVideo: PropTypes.string,
  }),
  name: PropTypes.string,
  occupation: PropTypes.arrayOf(
    PropTypes.shape({
      ages: PropTypes.arrayOf(PropTypes.number),
      amount: PropTypes.number,
      type: PropTypes.number,
    }),
  ),
  room: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onViewUrl: PropTypes.func,
};

export { ModalInfo };
