const DEVICE_XS_HEIGHT = 667;

const FIELD = {
  CALENDAR: 'calendar',
  OCCUPATION: 'occupation',
  PROMOCODE: 'promocode',
};

const INVALID_MIN_RANGE_DEALS = -1;

const TRACKING = 'DEALS';

export { DEVICE_XS_HEIGHT, FIELD, INVALID_MIN_RANGE_DEALS, TRACKING };
