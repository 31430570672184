export const L10N = {
  ACTION_CLUB_VIEW_CONDITIONS: { id: 'finder.action.club_view_conditions' },
  ACTION_LOGIN: { id: 'common.action.login' },
  ACTION_MORE_INFO: { id: 'common.action.more_info' },

  LABEL_CLUB_MEMBERS_EXCLUSIVE: { id: 'finder.label.club_members_exclusive' },
  LABEL_CLUB_CONDITIONS: { id: 'finder.label.club_conditions' },
  LABEL_LOGIN_NOW: { id: 'user.label.login_now' },
  LABEL_ONLY_MEMBERS: { id: 'finder.label.only_members' },
  LABEL_UNLOCK_EXCLUSIVE_MEMBER: { id: 'user.label.unlock_exclusive_member' },
};
