import PropTypes from 'prop-types';
import React from 'react';

const Error = ({ children }) => {
  const { Mirai: { Sentry } = {} } = window || {};

  return React.createElement(Sentry ? Sentry.ErrorBoundary : React.Fragment, null, children);
};

Error.displayName = 'core:components:Error';

Error.propTypes = {
  children: PropTypes.node,
};

export { Error };
