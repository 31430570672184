import { DEFAULT_LANGUAGE, ENDPOINT } from './constants';
import { ERROR, STATUS } from './constants';
import { formData, validate } from './helpers';

export const save = ({
  bookingData = {},
  bookingRequest = {},
  guestData = {},
  payment = {},
  tracking = {},
  locale = DEFAULT_LANGUAGE,
} = {}) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const language = locale || bookingRequest.locale;
    const data = { ...bookingData, ...bookingRequest, ...guestData, ...payment, ...tracking };
    const fieldsRequired = validate(data);
    if (Object.keys(fieldsRequired).length > 0) {
      ERROR.FIELD.payload = fieldsRequired;
      return reject(ERROR.FIELD);
    }
    const { LEGACY: endpoint } = ENDPOINT;

    const response = await formData({
      endpoint,
      hostname: process.env.SERVICE_BOOKING,
      headers: {
        'Accept-Language': language.split('-')[0],
      },
      ...Object.fromEntries(Object.entries(data).filter(([, value]) => value !== undefined)),
    }).catch(reject);

    if (!response) return reject(ERROR.UNKNOWN);

    const {
      status,
      data: { globalFormErrors: [error] = [], fieldFormErrors },
    } = response;

    let responseError;
    if (status === STATUS.FAIL || status === STATUS.PARAMETER_ERROR) {
      if (fieldFormErrors.length > 0) responseError = { ...ERROR.FIELD, payload: fieldFormErrors };
      else if (error) responseError = { ...ERROR.GLOBAL, payload: error };
      else responseError = ERROR.UNKNOWN;
    }

    if (responseError) reject(responseError);
    else resolve(response);
  });
