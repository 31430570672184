import { Theme } from '@mirai/ui';

export const getStyle = () => {
  const { accent, base, content } = Theme.get();

  return {
    colors: {
      icon: base,
      inputs_lines: content,
      button: accent,
      cvv_info: content,
    },
  };
};
