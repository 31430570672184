import { View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../__shared__';

const SPACE = 'var(--mirai-ui-space-M)';

export default () => {
  const inputProps = { wide: true, style: { height: 'var(--mirai-ui-input-min-height)' } };
  const sectionProps = { style: { gap: SPACE, padding: SPACE } };

  return (
    <View
      wide
      style={{
        alignSelf: 'center',
        maxWidth: 'calc(var(--mirai-ui-breakpoint-content) / 2)',
        width: '100%',
      }}
    >
      <View {...sectionProps}>
        <Skeleton headline level={2} words={5} />
        <Skeleton words={9} />
      </View>
      <View style={{ backgroundColor: 'var(--mirai-ui-content-background)', height: 'var(--mirai-ui-border-width)' }} />

      <View {...sectionProps}>
        <Skeleton small words={4} />

        {Array.from({ length: 6 }).map((_, index) => (
          <Skeleton key={index} {...inputProps} />
        ))}

        <Skeleton small words={9} />

        <View row style={{ gap: SPACE }}>
          <Skeleton icon />
          <Skeleton small words={4} />
        </View>

        <View row style={{ gap: SPACE }}>
          <Skeleton icon />
          <Skeleton small words={11} />
        </View>

        <Skeleton button large wide words={1} />
      </View>
    </View>
  );
};
