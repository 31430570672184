import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '../../locale.definition';

const OPTIONS = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
};

const MILLION = 1000000;
const TEN_THOUSAND = 10000;
const THOUSAND = 1000;

export const currencyFancy = (locale = DEFAULT_LOCALE, currency = DEFAULT_CURRENCY, value = 0, symbol = false) => {
  if (isNaN(value) || typeof value !== 'number') return;

  try {
    let fancyValue = 0;
    value >= MILLION
      ? (fancyValue = Intl.NumberFormat(locale, { currency, ...OPTIONS })
          .format(value / MILLION)
          .replace(/([0-9]$)(?!.*\1)/g, 'M')
          .replace(/\d\s/, 'M ')
          .replace(/.0M/, 'M')
          .replace(/,0M/, 'M'))
      : value >= TEN_THOUSAND
      ? (fancyValue = Intl.NumberFormat(locale, { currency, ...OPTIONS })
          .format(value / THOUSAND)
          .replace(/([0-9]$)(?!.*\1)/g, 'K')
          .replace(/\d\s/, 'K ')
          .replace(/.0K/, 'K')
          .replace(/,0K/, 'K'))
      : (fancyValue = Intl.NumberFormat(locale, {
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: 'currency',
        }).format(value));
    if (value >= MILLION && symbol) fancyValue = fancyValue.replace(/(\d{2,})(\.\d+M)$/, '$1M');
    if (value >= TEN_THOUSAND && symbol) fancyValue = fancyValue.replace(/(\d{2,})(\.\d+K)$/, '$1K');
    return fancyValue;
  } catch {
    return undefined;
  }
};
