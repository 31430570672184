import { toUrlParams } from './toUrlParams';
import { REDIRECT_PATH } from '../../Core.constants';

export const getRatesUrl = (params = {}) => {
  const {
    applyClubDiscount,
    chainSelectorValue,
    checkin,
    clientCodeStrictSearch,
    contactCenter,
    currency,
    device: deviceType,
    env,
    home,
    hsri,
    idchain,
    idtokenprovider,
    language,
    nights,
    notification,
    parties,
    promocode,
    rooms,
    variant,
  } = params;
  return `${process.env.SERVICE_BOOKING}/${REDIRECT_PATH}${toUrlParams({
    applyClubDiscount,
    cart: rooms
      ?.split(',')
      ?.map((room) => room.replace(/\|/g, '--').split(':')[0])
      ?.join(','),
    chainSelectorValue,
    checkin,
    clientCode: promocode,
    clientCodeStrictSearch,
    contactCenter,
    currency,
    deviceType,
    env,
    home,
    hsri,
    idchain,
    idtokenprovider,
    lang: language,
    nights,
    notification,
    parties,
    variant,
  })}`;
};
