import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Icon, ICON, Text, View } from '../../primitives';
import { Button } from '../Button';
import { Hint, Label } from '../InputText/partials';
import style from './InputNumber.module.css';

const InputNumber = ({
  disabled,
  hint,
  label,
  max,
  min = 0,
  name,
  required,
  rounded = true,
  showRequired = false,
  step = 1,
  success,
  value = 0,
  onChange = () => {},
  aria: { buttonAdd: ariaAdd = 'Add', buttonRemove: ariaRemove = 'Remove' } = {},
  ...others
}) => (
  <View {...others} tag="input-number" row className={styles(style.inputNumber, others.className)}>
    <View className={style.texts}>
      {label && (
        <Label
          {...{
            disabled,
            label,
            required: showRequired && required,
            requiredText: others.requiredText,
            success,
            value,
          }}
          className={style.label}
        />
      )}
      {hint && <Hint {...{ disabled, hint }} className={style.hint} />}
    </View>
    <Button
      disabled={disabled || value <= min}
      rounded={rounded}
      secondary
      small
      squared
      onPress={() => onChange(value - step, name)}
      aria-label={ariaRemove}
      testId={`${others.testId ? `${others.testId}-` : ''}button-min`}
    >
      <Icon value={ICON.REMOVE} />
    </Button>
    <Text className={styles(style.value, disabled && style.disabled)}>{typeof value === 'number' ? value : min}</Text>
    <Button
      disabled={disabled || value >= max}
      rounded={rounded}
      secondary
      small
      squared
      onPress={() => onChange(value + step, name)}
      aria-label={ariaAdd}
      testId={`${others.testId ? `${others.testId}-` : ''}button-max`}
    >
      <Icon value={ICON.ADD} />
    </Button>
  </View>
);

InputNumber.displayName = 'Component:InputNumber';

InputNumber.propTypes = {
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rounded: PropTypes.bool,
  showRequired: PropTypes.bool,
  step: PropTypes.number,
  success: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
  aria: PropTypes.shape({
    buttonAdd: PropTypes.string,
    buttonRemove: PropTypes.string,
  }),
};

export { InputNumber };
