import React from 'react';

import { getInputErrors } from '../../../helpers';
import { getField } from './getField';

export const getChildrenErrors = ({ children, schema = {}, values = {} } = {}) => {
  const errors = {};

  React.Children.forEach(children, (child) => {
    const { props } = child || {};
    const field = getField(props);

    if (field) {
      const inputErrors = getInputErrors({ ...props, ...schema[field], value: values[field] });
      if (inputErrors) errors[field] = inputErrors;
    }
  });

  return errors;
};
