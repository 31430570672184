import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Modal, ScrollView, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';

import { CardExpanded, CardReduced, Slider } from './components';
import { getUrl } from './helpers';
import { L10N } from './ModalTwin.l10n';
import * as style from './ModalTwin.module.css';
import { ModalTwinSkeleton } from './ModalTwin.Skeleton';
import { debounce } from '../../../../helpers';
import { EVENT } from '../../../helpers';
import { filterItem, select } from '../../helpers';
import { Filters } from '../Filters';

const ModalTwin = ({
  dataSource,
  filters,
  priceFactor,
  visible,
  onClose = () => {},
  onFilter = () => {},
  onSelect = () => {},
  ...others
}) => {
  const { translate } = useLocale();
  const { isMobile } = useDevice();

  const { value: { finder: { place: { id: [hotelId] = [], isHotel } = {} } = {}, hotel = {}, language } = {} } =
    useStore();

  const iframeRef = useRef();
  const itemsRef = useRef([]);

  const [expanded, setExpanded] = useState();
  const [loaded, setLoaded] = useState();
  const [scrollTo, setScrollTo] = useState();
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    const callback = ({ displayName }) => {
      if (displayName !== ModalTwin.displayName) {
        visible && Event.publish(EVENT.MODAL_VISIBLE, { displayName: ModalTwin.displayName, visible: true });
      }
    };

    Event.subscribe(EVENT.MODAL_VISIBLE, callback);
    return () => Event.unsubscribe(EVENT.MODAL_VISIBLE, callback);
  }, [visible]);

  useEffect(() => {
    const callback = (event = {}) => {
      const { data: { isLoaded, roomId } = {}, origin } = event;
      if (origin !== process.env.TWIN_URL) return;

      // eslint-disable-next-line no-console
      console.log('Mensaje recibido:', event);

      debounceSelectId(roomId);

      if (isLoaded) setLoaded(true);
    };

    if (!isMobile && selectedId) setTimeout(() => setScrollTo(itemsRef.current[selectedId]?.offsetTop - 74), 400);

    window.addEventListener('message', callback);

    return () => window.removeEventListener('message', callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    setExpanded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, visible]);

  useEffect(() => {
    if (!loaded) return;

    Event.publish(EVENT.METRICS, { id: 'TWIN_RATES:MODAL_TWIN:LOADED' });

    setTimeout(() => handleChange(0), 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) return;

    iframeRef?.current?.contentWindow?.postMessage(
      {
        rooms: filteredItems.filter(({ soldOut } = {}) => !soldOut).map(({ id } = {}) => id),
        soldOutRooms: filteredItems.filter(({ soldOut } = {}) => soldOut).map(({ id } = {}) => id),
      },
      process.env.TWIN_URL,
    );

    const selectedIndex = filteredItems.findIndex(({ id } = {}) => id === selectedId);
    if (selectedIndex === -1) handleChange(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleChange = (index) => {
    if (index < 0 || index === selectedIndex || index >= filteredItems.length) return;

    const { id } = filteredItems[index];
    if (id !== selectedId) {
      setSelectedId(id);
      iframeRef?.current?.contentWindow?.postMessage({ roomId: id }, process.env.TWIN_URL);
    }
  };

  const handleClose = () => {
    Event.publish(EVENT.METRICS, { id: 'TWIN_RATES:MODAL_TWIN:CLOSE' });

    setSelectedId();
    setLoaded();
    setExpanded();
    setScrollTo();
    onClose();
  };

  const handleSelect = (id) => {
    Event.publish(EVENT.METRICS, { id: 'TWIN_RATES:MODAL_TWIN:SELECT' });

    onSelect(filteredItems.find((item) => item.id === id)?.boards[0]);
    handleClose();
  };

  const debounceSelectId = debounce((roomId) => {
    if (roomId && Number(roomId) !== selectedId) {
      setSelectedId(Number(roomId));
    }
  });

  const { items = [] } = dataSource || {};
  const filteredItems =
    select(items, filters, { priceFactor })?.map((item) => filterItem(item, filters, priceFactor)) || [];
  const selectedIndex = selectedId ? filteredItems.findIndex(({ id } = {}) => id === selectedId) : undefined;
  const url = useMemo(
    () => getUrl({ filteredItems, hotel, hotelId, isHotel, items, language }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hotel, hotelId, isHotel, language],
  );

  return (
    <Modal
      {...others}
      displayName={ModalTwin.displayName}
      fit
      preventDefault={false}
      title={translate(L10N.LABEL_IMMERSIVE_BOOKING)}
      visible={visible}
      onClose={handleClose}
      onOverflow={handleClose}
      className={style.modal}
    >
      {React.createElement(
        isMobile ? React.Fragment : View,
        !isMobile ? { className: style.container, row: true } : undefined,
        <>
          {!isMobile &&
            (loaded ? (
              <View className={style.panel}>
                <ScrollView
                  horizontal={false}
                  scrollIndicator
                  scrollTo={scrollTo}
                  snap={false}
                  className={style.scrollview}
                >
                  {filteredItems.map((item = {}, index) => (
                    <View key={item.id} ref={(el) => (itemsRef.current[item.id] = el)}>
                      {selectedId === item.id ? (
                        <CardExpanded
                          {...{ item, priceFactor, ...others }}
                          onSelect={handleSelect}
                          className={styles(style.card)}
                        />
                      ) : (
                        <CardReduced {...{ item, priceFactor, ...others }} onPress={() => handleChange(index)} />
                      )}
                    </View>
                  ))}
                </ScrollView>
              </View>
            ) : (
              <ModalTwinSkeleton />
            ))}
          <View tag="iframe" ref={iframeRef} src={url} title="modal" className={style.iframe} />
        </>,
      )}

      {loaded && (
        <View className={style.filters}>
          <Filters {...dataSource} {...{ values: filters, priceFactor }} onSubmit={onFilter} />
        </View>
      )}

      {loaded && selectedId !== undefined && isMobile && (
        <Slider index={selectedIndex} onChange={handleChange} className={!expanded && style.reduced}>
          {filteredItems.map((item = {}, index) => {
            const last = index === filteredItems.length - 1;

            return (
              <View key={item.id} className={style.item}>
                <CardReduced
                  {...{ item, last, priceFactor, ...others }}
                  visible={!expanded}
                  onPress={() => !expanded && setExpanded(true)}
                />
                <CardExpanded
                  {...{ item, last, priceFactor, ...others }}
                  visible={expanded}
                  onReduce={() => setExpanded(false)}
                  onSelect={handleSelect}
                />
              </View>
            );
          })}
        </Slider>
      )}
    </Modal>
  );
};

ModalTwin.propTypes = {
  dataSource: PropTypes.object,
  filters: PropTypes.object,
  priceFactor: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  onSelect: PropTypes.func,
};

ModalTwin.displayName = 'Mirai:Core:Rates:ModalTwin';

export { ModalTwin };
