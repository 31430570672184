import { dateDiff, dateFormat } from '@mirai/locale';

import { ACCOMMODATION_TYPE } from '../../../../Core.constants';
import { fetchUrlParams } from '../../../../helpers';
import { dataLayerPush } from '../../helpers';
import { formatPrice } from '../helpers/legacy';

const ACCOMMODATION_TYPE_NAME = Object.keys(ACCOMMODATION_TYPE);

export const bookingCancel = ({
  device: { mobile } = {},
  response: { tracking = {} } = {},
  store: { finder: { calendar = [] } = {}, hotel: { accommodationType, variants = {} } = {}, language } = {},
} = {}) => {
  // ! -- TODO: Try to get all data from store -------------------------------------------------------------------------
  const { idchain, idtokenprovider: hotelId } = fetchUrlParams(location.search);

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    cancelComment,
    cancellationDate,
    cancelReason,
    hotelInfo: { currency: hotelCurrency = {}, propertyName, propertyType } = {},
    idReservation,
    reservationDate,
    totalPriceWithTaxes,
  } = tracking;

  const { days } = dateDiff(...calendar);

  const dataLayer = {
    cancelComment,
    cancellationDate,
    cancelReason,
    chainId: idchain,
    checkin: dateFormat(calendar[0], { format: 'DD/MM/YYYY' }),
    checkout: dateFormat(calendar[1], { format: 'DD/MM/YYYY' }),
    currency: hotelCurrency.code,
    device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
    home: window.location.host,
    idHotel: hotelId,
    language,
    lodgingDenomination: ACCOMMODATION_TYPE_NAME[variants[hotelId]?.accommodationType || accommodationType],
    nights: days,
    propertyName,
    propertyType,
    reservationCode: idReservation,
    reservationDate,
    step: 'CANCELLATION',
    totalPriceWithTaxes: formatPrice(totalPriceWithTaxes, hotelCurrency),
  };

  dataLayerPush({ event: 'mirai.cancelled', 'mirai.data': dataLayer });

  return dataLayer;
};
