/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';
import sha1 from 'js-sha1';

import { getLanguage, parseExternalToken } from '../helpers';
import { ERROR, KEY_HASH } from './constants';

export const get = ({
  clubDiscount,
  locale,
  session: { authorization } = {},
  strictSearch = true,
  userCurrency,
  ...others
} = {}) =>
  new Promise(async (resolve, reject) => {
    const { checkin, idoffers, idtokenprovider: id, nights, place, roomTypeId, ...rest } = others || {};

    const language = locale?.split('-')[0];

    const params = Object.entries({
      clubDiscount,
      checkin,
      strictSearch,
      currency: userCurrency,
      idoffers: strictSearch ? idoffers : undefined,
      roomTypeId: strictSearch ? roomTypeId : undefined,
      nights,
      ...(place && !others.idchain && !others.chainSelectorValue
        ? { idchain: id, chainSelectorValue: place.value }
        : undefined),
      ...rest,
      id: others.hotelIds ? others.idchain : id,
      authorization,
      lang: getLanguage(language),
      ...parseExternalToken(rest),
      cid: sha1(`${KEY_HASH}-${id}-${checkin}-${nights}`),
    }).filter(([, value]) => value !== undefined);

    const response = await request({
      hostname: process.env.SERVICE_BOOKING,
      endpoint: `/v2/booking/rates?${new URLSearchParams(params)}`,
    }).catch((error = {}) => {
      const { code, payload: { data, status } = {}, payload } = error;

      reject(code === 404 ? data : ERROR[status] ? { ...ERROR[status], payload } : ERROR.UNKNOWN);
    });

    //! TODO: improve error handling
    if (!response) {
      return reject({ error: 'Upss, something bad happened!' });
    }

    resolve(response?.data);
  });
