import { dateDiff, dateFormat } from '@mirai/locale';

import { ratesResponse as duettoRatesResponse } from './duetto';
import { getInitEvent, parseRate } from './helpers';
import { getOccupationTotals } from '../../../../components/helpers';
import { getFeatures } from '../../../../components/Rates/helpers';
import { ACCOMMODATION_TYPE } from '../../../../Core.constants';
import { fetchUrlParams } from '../../../../helpers';
import { dataLayerPush } from '../../helpers';
import { getRatesPriceRange } from '../helpers';
import { formatPrice } from '../helpers/legacy';

const ACCOMMODATION_TYPE_NAME = Object.keys(ACCOMMODATION_TYPE);

export const ratesResponse = ({
  device: { mobile } = {},
  response: { calendar: calendarSuggestion, hotels, infantAge, tracking = {} } = {},
  store: {
    currency,
    finder: { calendar = [], occupation = [], place: { title: searchCategory } = {}, promocode = '' } = {},
    hotel: { accommodationType, features = {}, variants = {}, ...hotel } = {},
    id,
    language,
    session,
    type,
    urlParams,
  } = {},
} = {}) => {
  // ! -- TODO: Try to get all data from store -------------------------------------------------------------------------
  const {
    idchain,
    idoffers,
    idtokenprovider: hotelId, // ? TODO: Could we take it from store?
    roomTypeId,
  } = fetchUrlParams(location.search);

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    country,
    hotelInfo: { currency: hotelCurrency = {}, propertyName, propertyType } = {},
    hotelInfoBAR,
    localTaxesIncluded,
    rates,
    taxesIncluded,
    userCurrency: { value: userCurrencyValue } = {},
  } = tracking;

  const { adults = 0, children = 0 } = getOccupationTotals({ infantAge, occupation });
  const { minRate, maxRate } = getRatesPriceRange(rates, hotelCurrency);
  const { days: nights } = dateDiff(...calendar);
  const { showPricePerNight = false } = getFeatures(features, urlParams);

  const step = hotels && !calendarSuggestion && searchCategory ? 'CHAINRESULT' : 'BOOKINGSTEP1';
  const common = {
    ...(!rates && hotelInfoBAR?.length === 0 ? { availability: 'no' } : undefined),
    chainId: idchain,
    checkin: dateFormat(calendar[0], { format: 'DD/MM/YYYY' }),
    checkout: dateFormat(calendar[1], { format: 'DD/MM/YYYY' }),
    coupon: promocode,
    device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
    home: document.location.hostname,
    hotelId,
    idOffer: idoffers ? parseInt(idoffers) : undefined,
    idRoom: roomTypeId ? parseInt(roomTypeId) : undefined,
    language,
    lodgingDenomination: ACCOMMODATION_TYPE_NAME[variants[hotelId]?.accommodationType || accommodationType],
    multiroom: occupation.length > 1,
    numAdults: adults,
    numChildren: children,
    numRooms: occupation.length,
    pricePerNight: showPricePerNight,
    propertyName,
    propertyType,
    ...(!rates && hotelInfoBAR ? { searchCategory } : undefined),
    step,
    userCountry: country,
    userLogged: !!session,
  };

  const dataLayer = rates
    ? {
        ...common,
        currency: hotelCurrency.code,
        exchangeRate: userCurrencyValue && hotelCurrency.value && userCurrencyValue / hotelCurrency.value,
        highestRate: parseRate(maxRate, hotelCurrency, nights),
        localTaxesIncluded,
        lowestRate: parseRate(minRate, hotelCurrency, nights),
        nights,
        ratesByRoom: Object.values(rates).map(({ id, name, occupationOptions = {} } = {}) => ({
          id,
          name,
          occupations: Object.values(occupationOptions).map(
            ({ numAdults = 0, numFirstRangeChildren = 0, numSecondRangeChildren = 0, id, rates = {} } = {}) => ({
              adults: numAdults,
              children: numFirstRangeChildren + numSecondRangeChildren,
              id,
              rates: Object.values(rates).map(({ boardOptions = {}, id, name } = {}) => ({
                boards: Object.values(boardOptions).map(
                  ({ id, name, totalBasePrice, totalLocalTaxes = 0, totalPriceWithTaxes } = {}) => ({
                    id,
                    localTaxes: formatPrice(totalLocalTaxes, hotelCurrency),
                    name,
                    netPrice: formatPrice(totalBasePrice, hotelCurrency),
                    taxes: formatPrice(totalPriceWithTaxes - totalBasePrice, hotelCurrency),
                  }),
                ),
                id,
                name,
              })),
            }),
          ),
        })),
        taxesIncluded,
        userCurrency: currency,
      }
    : hotelInfoBAR?.length > 0
    ? {
        ...common,
        availability: 'yes',
        exchangeRate:
          userCurrencyValue && hotelInfoBAR[0].currency.value && userCurrencyValue / hotelInfoBAR[0].currency.value,
        hotelIds: hotelInfoBAR.map(({ idTokenProvider } = {}) => idTokenProvider).join(','),
        hotelNames: hotelInfoBAR.map(({ hotelName } = {}) => hotelName).join(','),
        lowestRate: formatPrice(
          hotelInfoBAR[0].bestAvailableRate.totalPrice.internalWithTaxes / nights,
          hotelInfoBAR[0].currency,
        ),
      }
    : common;

  dataLayerPush(getInitEvent({ mobile, hotel: { accommodationType, variants, ...hotel }, id, step, type }));
  dataLayerPush({
    event: rates || hotelInfoBAR?.length > 0 ? 'mirai.Availability' : 'mirai.noAvailability',
    'mirai.data': dataLayer,
  });
  duettoRatesResponse({ ...common, hotelCurrency, rates });

  return dataLayer;
};
