import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { styles } from '../../helpers';
import { Select, Text, View } from '../../primitives';
import style from '../InputText/InputText.module.css';
import { Hint, IconState, Label } from '../InputText/partials';
import { Expand } from './partials';

const InputSelect = ({
  caption,
  disabled,
  error,
  hint,
  label,
  showRequired = false,
  showState = true,
  success,
  warning,
  onChange = () => {},
  onEnter = () => {},
  onLeave = () => {},
  ...others
}) => {
  const [focus, setFocus] = useState(false);

  const handleChange = (value, event) => {
    onChange(value, event);
    event.target?.blur();
  };

  const handleEnter = (event) => {
    setFocus(true);
    onEnter(event);
  };

  const handleLeave = (event) => {
    setFocus(false);
    onLeave(event);
  };

  const has = {
    label: !!label,
    value: others.value !== undefined && others.value?.length > 0,
    stateIcon: showState && (error || success || warning),
  };

  const id = label ? others.id || others.name : others.id;

  return (
    <View
      role={others.role}
      tag="input-select"
      className={styles(style.inputContainer, others.className)}
      style={others.style}
    >
      <View
        row
        className={styles(
          style.inputBorder,
          disabled && style.disabled,
          focus && style.focus,
          error ? style.error : success ? style.success : undefined,
        )}
      >
        {caption && (
          <Text action light className={style.caption}>
            {caption}
          </Text>
        )}

        <View wide className={style.content}>
          {label && (
            <Label
              {...{
                disabled,
                error,
                focus,
                for: id,
                label,
                required: showRequired && others.required,
                requiredText: others.requiredText,
                success,
                value: others.value,
              }}
            />
          )}

          <Select
            {...{ disabled, id, ...others }}
            value={others.value || ''}
            className={styles(
              style.input,
              has.label && style.withLabel,
              !!label && !(focus || error || has.value) && style.empty,
            )}
            onChange={handleChange}
            onEnter={handleEnter}
            onLeave={handleLeave}
            aria-label={label}
          />
        </View>

        {!disabled && <Expand />}
        {has.stateIcon && <IconState {...{ error, success, warning }} />}
      </View>

      {hint && <Hint {...{ disabled, error, hint }} />}
    </View>
  );
};

InputSelect.displayName = 'Component:InputSelect';

InputSelect.propTypes = {
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  showRequired: PropTypes.bool,
  showState: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
};

export { InputSelect };
