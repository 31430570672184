import { useDevice } from '@mirai/ui';
import { View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../__shared__';

const SPACE = 'var(--mirai-ui-space-M)';

export default () => {
  const { isDesktop, isMobile } = useDevice();

  const sectionProps = { base: true, style: { gap: SPACE, padding: SPACE } };

  const renderSummary = () => (
    <View
      style={{
        flex: 1,
        gap: SPACE,
        maxWidth: 'calc(var(--mirai-ui-breakpoint-S) - var(--mirai-ui-space-M) * 4)',
      }}
    >
      <Skeleton base>
        <View {...sectionProps}>
          <Skeleton headline={isDesktop} words={2} />
          <View row>
            <View wide>
              <Skeleton words={1} />
              <Skeleton action words={3} />
              <Skeleton small words={2} />
            </View>

            <View wide>
              <Skeleton words={1} />
              <Skeleton action words={3} />
              <Skeleton small words={2} />
            </View>
          </View>

          <View>
            <Skeleton words={2} />
            <Skeleton action words={4} />
            <Skeleton small words={2} />
          </View>

          <View>
            <Skeleton words={1} />
            <Skeleton action words={3} />
            <Skeleton small words={8} />
          </View>

          <View row style={{ justifyContent: 'space-between' }}>
            <Skeleton small words={2} />
            <Skeleton small words={2} />
            <Skeleton small words={2} />
          </View>
        </View>

        <View
          style={{
            backgroundColor: 'var(--mirai-ui-content-background)',
            height: 'var(--mirai-ui-border-width)',
          }}
        />

        <View {...sectionProps}>
          <Skeleton headline={isDesktop} words={2} />
          <View style={{ gap: 'var(--mirai-ui-space-S)' }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <View key={index} row style={{ justifyContent: 'space-between' }}>
                <Skeleton action={index < 3} words={2} />
                <Skeleton action={index < 3} words={1} />
              </View>
            ))}
          </View>
          <Skeleton small words={10} />
        </View>
      </Skeleton>

      {isDesktop && (
        <Skeleton base>
          <Skeleton button large wide words={1} />
          <Skeleton button large wide words={2} />
          <Skeleton button large wide words={2} />
        </Skeleton>
      )}
    </View>
  );

  return (
    <View wide style={{ alignItems: 'center', gap: '2rem' }}>
      <View
        row
        wide
        style={{ alignItems: 'start', alignSelf: 'center', gap: SPACE, maxWidth: 'var(--mirai-ui-breakpoint-content)' }}
      >
        <View style={{ flex: 1, gap: SPACE }}>
          <Skeleton {...sectionProps} row>
            <Skeleton icon />
            <Skeleton action words={12} />
          </Skeleton>

          {!isDesktop && renderSummary()}

          <Skeleton {...sectionProps}>
            <Skeleton headline words={2} />
            {Array.from({ length: 6 }).map((_, index) => (
              <View key={index} row={!isMobile}>
                <View style={{ minWidth: '10vw' }}>
                  <Skeleton words={1} />
                </View>
                <Skeleton words={2} />
              </View>
            ))}
          </Skeleton>

          <Skeleton {...sectionProps}>
            <Skeleton headline={isDesktop} words={2} />

            <View row style={{ gap: SPACE }}>
              <Skeleton style={{ height: 'var(--mirai-ui-space-XXL)', width: 'var(--mirai-ui-space-XXL)' }} />
              <View>
                <Skeleton words={2} />
                <Skeleton action words={2} />
                <Skeleton small words={5} />
              </View>
            </View>
            <View>
              {Array.from({ length: 2 }).map((_, index) => (
                <View
                  key={index}
                  row
                  style={{
                    borderBottom: 'solid var(--mirai-ui-border-width) var(--mirai-ui-content-background)',
                    padding: `${SPACE} 0`,
                  }}
                >
                  <View style={{ width: '40%' }}>
                    <Skeleton action words={4} />
                  </View>
                  <View style={{ width: '30%' }}>
                    <Skeleton action words={2} />
                  </View>
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <Skeleton action words={1} />
                  </View>
                </View>
              ))}
            </View>

            <View row style={{ justifyContent: 'center', gap: SPACE }}>
              <Skeleton icon />
              <Skeleton action words={2} />
            </View>
          </Skeleton>

          <Skeleton {...sectionProps}>
            <View>
              <Skeleton small words={6} />
              <Skeleton small words={24} />
            </View>

            <View>
              <Skeleton small words={2} />
              <Skeleton small words={32} />
            </View>

            <View>
              <Skeleton small words={2} />
              <Skeleton small words={8} />
            </View>

            <View>
              <Skeleton small words={2} />
              <Skeleton small words={6} />
            </View>
          </Skeleton>
        </View>

        {isDesktop && renderSummary()}
      </View>
    </View>
  );
};
