import { Event } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Icon, Slider as SliderBase, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { L10N } from './Slider.l10n';
import * as style from './Slider.module.css';
import { EVENT, ICON } from '../../../../../helpers';

const Slider = ({
  captions = [],
  children,
  height,
  images = [],
  indicator = true,
  media: { url3D, urlVideo } = {},
  metrics = 'RATES:ITEM',
  width,
  onCounter,
  onViewUrl = () => {},
  ...others
}) => {
  const { translate } = useLocale();

  const [sliderIndex, setSliderIndex] = useState(0);

  const sliderProps = { className: [style.slider, others.className], style: { height, maxHeight: height } };

  const handleSliderIndex = (index) => {
    setSliderIndex(index);
    if (index > 0) Event.publish(EVENT.METRICS, { id: `${metrics}:SLIDER`, index });
  };

  // ! TODO: This should be part of the service of rates
  const buttonProps = { rounded: true, squared: true, transparent: true, className: style.button };

  return (
    <View {...sliderProps}>
      <SliderBase
        counter
        {...{ captions, height, images, indicator, width, onCounter }}
        onChange={handleSliderIndex}
        {...sliderProps}
      />

      {children}

      {(url3D || urlVideo) && sliderIndex === 0 && (
        <View row className={style.buttons}>
          {urlVideo && (
            <Button
              {...buttonProps}
              tooltip={translate(L10N.LABEL_VIEW_VIDEO)}
              onPress={() => onViewUrl({ title: translate(L10N.LABEL_VIEW_VIDEO), url: urlVideo })}
            >
              <Icon headline level={2} value={ICON.PLAY} className={style.icon} />
            </Button>
          )}

          {url3D && (
            <Button
              {...buttonProps}
              tooltip={translate(L10N.LABEL_3D_VIEW)}
              onPress={() => onViewUrl({ title: translate(L10N.LABEL_3D_VIEW), url: url3D })}
            >
              <Icon headline level={2} value={ICON.THREE_D_ROTATION} className={style.icon} />
            </Button>
          )}
        </View>
      )}
    </View>
  );
};

Slider.displayName = 'Mirai:Core:Rates:Item:Slider';

Slider.propTypes = {
  captions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  height: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string),
  indicator: PropTypes.bool,
  media: PropTypes.shape({
    url3D: PropTypes.string,
    urlVideo: PropTypes.string,
  }),
  metrics: PropTypes.string,
  width: PropTypes.number,
  onCounter: PropTypes.func,
  onViewUrl: PropTypes.func,
};

export { Slider };
