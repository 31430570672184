import { useLocale } from '@mirai/locale';
import { Pressable, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Prices } from './Prices';
import { L10N } from '../ModalTwin.l10n';
import * as style from '../ModalTwin.module.css';

const CardReduced = ({
  item: { images: [{ thumbnail } = {}] = [], name, soldOut, ...item } = {},
  last = false,
  visible,
  onPress = () => {},
  ...others
}) => {
  const { translate } = useLocale();

  return (
    <Pressable onPress={onPress} className={styles(style.reduced, visible && style.visible)}>
      <View row className={styles(style.card, soldOut ? style.disabled : undefined, last ? style.last : undefined)}>
        <View className={style.image} style={{ backgroundImage: `url(${thumbnail})` }} />
        <View className={style.content}>
          <Text bold className={style.title}>
            {name}
          </Text>
          <View>
            {soldOut ? (
              <Text small>{translate(L10N.LABEL_NOT_AVAILABLE)}</Text>
            ) : (
              <Prices {...{ ...item, ...others }} />
            )}
          </View>
        </View>
      </View>
    </Pressable>
  );
};

CardReduced.displayName = 'Mirai:Core:Rates:ModalTwin:CardReduced';

CardReduced.propTypes = {
  item: PropTypes.object,
  last: PropTypes.bool,
  visible: PropTypes.bool,
  onPress: PropTypes.func,
};

export { CardReduced };
