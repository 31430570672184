const ENDPOINT = {
  EXTERNAL_LOGIN: 'club/v1/forwarder/login',
  EXTERNAL_LOGOUT: 'club/v1/forwarder/logout',
};

const MODE = {
  ACTIVATE: 'ACTIVATE',
  ACTIVATE_ERROR: 'ACTIVATE_ERROR',
  FINISH_SIGNUP: 'FINISH_SIGNUP',
};

const TRACKING = 'SESSION';

const URL_PARAMS = {
  login: [MODE.FINISH_SIGNUP],
};

const SENATOR_CLUB_TOKEN = 'apiData';

export { ENDPOINT, MODE, SENATOR_CLUB_TOKEN, TRACKING, URL_PARAMS };
