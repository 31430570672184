import { UTC } from '@mirai/locale';

const getDateProps = (value = '', delimiter = '-') =>
  value.split(delimiter).map((part) => (part ? parseInt(part, 10) : undefined));

const createDate = (year, month, day) => UTC(new Date(year, month - 1, day, 12, 0, 0));

export const isValidDate = (value = '', { delimiter, min, max } = {}) => {
  const [year, month, day] = getDateProps(value, delimiter);
  if (!year || !month || !day) return false;

  const date = createDate(year, month, day);

  const isValid =
    parseInt(year, 10) === date.getFullYear() &&
    parseInt(month, 10) === date.getMonth() + 1 &&
    parseInt(day, 10) === date.getDate();

  let inRange = true;
  if (min || max) {
    const minTs = min ? UTC(new Date(createDate(...getDateProps(min, delimiter)))) : undefined;
    const maxTs = max ? UTC(new Date(createDate(...getDateProps(max, delimiter)))) : undefined;
    const dateTs = date.getTime();

    const inMinRange = minTs ? dateTs >= minTs : true;
    const inMaxRange = maxTs ? dateTs <= maxTs : true;

    inRange = inMinRange && inMaxRange;
  }

  return isValid && inRange;
};
