const fnv1a = (str) => {
  let hash = 2166136261;
  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i);
    hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
  }
  return (hash >>> 0).toString(36);
};

export const getFingerprint = ({ email } = {}) => {
  const entropy = {
    userAgent: navigator.userAgent,
    screen: `${screen.width}x${screen.height}x${screen.colorDepth}`,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    languages: navigator.languages,
    platform: navigator.platform,
    hardwareConcurrency: navigator.hardwareConcurrency || 'unknown',
    deviceMemory: navigator.deviceMemory || 'unknown',
    touchSupport: navigator.maxTouchPoints > 0,
    canvasHash: (() => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.textBaseline = 'top';
        ctx.font = '14px Arial';
        ctx.fillText('Fingerprint', 2, 15);
        return canvas.toDataURL().slice(-50);
      } catch (e) {
        return 'unavailable';
      }
    })(),
    pluginsHash: (() => {
      try {
        return navigator.plugins ? [...navigator.plugins].map((p) => p.name).join(',') : 'unavailable';
      } catch (e) {
        return 'unavailable';
      }
    })(),
  };

  const str = JSON.stringify({ ...entropy, email });
  return fnv1a(str) + str.length.toString(36);
};
