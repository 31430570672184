export const styles = (...stylesheets) => {
  const value = stylesheets
    .flat(Infinity)
    .filter((value) => value !== false)
    .filter((value) => value !== undefined && value !== null)
    .join(' ')
    .trim();

  return value?.length ? value : undefined;
};
