import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { styles } from '../../helpers';
import { Icon, ICON, Pressable, Text, View } from '../../primitives';
import { Button } from '../Button';
import { InputOption } from '../InputOption';
import { InputText } from '../InputText';
import { Menu } from '../Menu';
import { L10N_SHAPE } from './Table.constants';
import style from './Table.module.css';

const DEFAULT_FORM = { max: undefined, min: undefined, value: undefined, values: [] };

const ColumnFilter = ({
  field,
  l10n: { actionCancel = '$Cancel', actionSubmit = '$Submit', labelMax = '$max', labelMin = '$min' } = {},
  schema = {},
  visible: propVisible = false,
  onFocus = () => {},
  onSubmit = () => {},
  ...others
}) => {
  const [form, setForm] = useState({ ...DEFAULT_FORM });
  const [visible, setVisible] = useState(propVisible);
  const { label, options = {}, type = 'text' } = schema[field] || {};

  useEffect(() => {
    setVisible(propVisible);
  }, [propVisible]);

  useEffect(() => {
    setForm({ ...DEFAULT_FORM, value: type === 'boolean' ? 'false' : undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handlePress = (event) => {
    event.preventDefault();
    setVisible(!visible);
    onFocus(!visible ? field : undefined);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleKeyDown = (event = {}) => {
    if (event.key === 'Enter') handleSubmit(event);
  };

  const handleSubmit = (event = {}) => {
    event.preventDefault();
    setVisible(false);
    onSubmit({ field, type, ...form }, event);
  };

  const { testId } = others;

  return (
    <Menu
      {...others}
      options={[
        {
          children: (
            <Text bold small>
              {label || field}
            </Text>
          ),
          divider: true,
        },
        {
          children:
            type === 'number' ? (
              <View row className={style.items}>
                <InputText
                  label={labelMin}
                  name="min"
                  type="number"
                  value={form.min}
                  onChange={(min) => setForm({ ...form, min })}
                  className={style.input}
                />
                <InputText
                  label={labelMax}
                  name="max"
                  type="number"
                  value={form.max}
                  onChange={(max) => setForm({ ...form, max })}
                  onKeyDown={handleKeyDown}
                  className={style.input}
                />
              </View>
            ) : type === 'boolean' ? (
              <View>
                {[false, true].map((value) => (
                  <InputOption
                    checked={(value && form.value === 'true') || (!value && form.value === 'false') || false}
                    key={value}
                    name="boolean"
                    label={options[value] || value.toString()}
                    value={`boolean-${value.toString()}`}
                    onChange={() => setForm({ ...form, value: value.toString() })}
                  />
                ))}
              </View>
            ) : type === 'options' ? (
              <View wide>
                {Object.entries(options).map(([value, label]) => {
                  const parsedValue = isNaN(value) ? value : parseFloat(value);

                  return (
                    <InputOption
                      checked={form.values.includes(parsedValue) || false}
                      key={value}
                      name={value}
                      label={label}
                      onChange={(active) =>
                        setForm({
                          values: active
                            ? [...form.values, parsedValue]
                            : form.values.filter((item) => item !== parsedValue),
                        })
                      }
                    />
                  );
                })}
              </View>
            ) : (
              <InputText
                name="search"
                type="search"
                value={form.value}
                onChange={(value) => setForm({ value })}
                onKeyDown={handleKeyDown}
                className={style.input}
              />
            ),
          divider: true,
        },
        {
          children: (
            <View row wide className={style.items}>
              <Button secondary wide onPress={handleCancel}>
                {actionCancel}
              </Button>
              <Button
                disabled={
                  type !== 'boolean' && !form.value && !form.min && !form.max && !Object.keys(form.values || {}).length
                }
                wide
                onPress={handleSubmit}
                testId={testId ? `${testId}-submit` : undefined}
              >
                {actionSubmit}
              </Button>
            </View>
          ),
        },
      ]}
      bottom
      centered
      visible={visible}
      className={styles(style.menuFilter, others.className)}
    >
      <Pressable onPress={handlePress} style={{ position: 'relative' }}>
        <Icon value={ICON.FILTER} className={styles(style.icon, !visible && style.disabled)} />
      </Pressable>
    </Menu>
  );
};

ColumnFilter.displayName = 'Component:Table:ColumnFilter';

ColumnFilter.propTypes = {
  field: PropTypes.string.isRequired,
  l10n: L10N_SHAPE,
  schema: PropTypes.shape({}).isRequired,
  visible: PropTypes.bool,
  onFocus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { ColumnFilter };
