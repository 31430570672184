import { LocalAdapter, SessionAdapter, Storage } from '@mirai/data-sources';

const CACHE_LIMIT = 1000 * 60 * 60 * 24 * 7;
const SESSION_STORAGE_LEGACY_ID = 'CLUB';
const SESSION_STORAGE_ID = 'MIRAI:SERVICE:USER:session';

export const fetchSession = (urlParams = {}) => {
  let storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  let session = storage.get(SESSION_STORAGE_ID);

  //TODO: Remove when don`t exists legacy CLUB
  if (!session) {
    storage = new Storage({ adapter: SessionAdapter });
    const {
      access_token: authorization,
      club_name: name,
      email,
      first_name: firstName,
      id_club: id,
      last_name: lastName,
      level,
      subscribed,
    } = storage.get(SESSION_STORAGE_LEGACY_ID) || {};
    session = authorization
      ? { authorization, email, firstName, club: { id: id?.toString(), name }, lastName, level, subscribed }
      : undefined;
  }

  if (!session && urlParams.session) {
    try {
      session = JSON.parse(urlParams.session);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('', error);
    }
  }

  return session?.authorization ? session : undefined;
};
