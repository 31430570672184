import { CARD } from '../../Payment.constants';

const CARDS = {
  VIS: CARD.VISA,
  AMX: CARD.AMERICAN_EXPRESS,
  ECA: CARD.MASTERCARD,
  JCB: CARD.JCB,
  DIS: CARD.DISCOVER,
  DIN: CARD.DINERS_CLUB,
  CUP: CARD.UNIONPAY,
  MAU: CARD.MAESTRO,
};

const ERROR = { error: true };

const PLACEHOLDER_CARD = '0000 0000 0000 0000';

const PLACEHOLDER_CVV = '000';

const SCRIPT_ID = 'script-pci-proxy';

export { CARDS, ERROR, PLACEHOLDER_CARD, PLACEHOLDER_CVV, SCRIPT_ID };
