import { formatPrice } from '../../helpers/legacy';

export const parseRate = ({
  hotelId,
  hotelInfo: { currency = {}, propertyName, propertyType } = {},
  id,
  promocode,
  rate: { boardName, rateName, roomTypeName, totalPriceWithoutTaxes } = {},
  ...others
} = {}) => ({
  ...others,
  coupon: promocode,
  currency: currency.code,
  item_brand: propertyName,
  item_category: 'Habitación',
  item_category2: rateName,
  item_category3: boardName,
  item_category4: propertyType,
  item_category5: hotelId,
  item_name: roomTypeName,
  price: formatPrice(totalPriceWithoutTaxes, currency),
  item_id: `${id}`,
});
