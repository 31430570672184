export const L10N = {
  ACTION_MANAGE_BOOKING: { id: 'user.action.manage_booking' },
  ACTION_MY_BOOKINGS: { id: 'user.action.my_bookings' },
  ACTION_SEARCH: { id: 'user.action.search' },
  ACTION_SEARCH_CTA: { id: 'common.action.search' },
  ACTION_VIEW_HOTEL: { id: 'common.action.view_hotel' },

  LABEL_OPTION_MANAGE: { id: 'user.label.option_manage' },

  LABEL_BASE_PRICE_VALUE: { id: 'common.label.base_price_value' },
  LABEL_CANCELLED: { id: 'user.label.cancelled' },
  LABEL_NEXT_BOOKINGS: { id: 'user.label.next_bookings' },
  LABEL_NO_BOOKINGS: { id: 'user.label.no_bookings' },
  LABEL_PAST_BOOKINGS: { id: 'user.label.past_bookings' },
  LABEL_PRICE_DETAILS: { id: 'finder.label.price_details' },
  LABEL_RESERVATION_CODE: { id: 'common.label.reservation_code' },
  LABEL_RESERVATION_CODE_DESCRIPTION: { id: 'common.label.reservation_code_description' },
  LABEL_SEARCH: { id: 'user.label.search' },
  LABEL_SEARCH_PLACEHOLDER: { id: 'common.label.search' },
};
