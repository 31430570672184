import { HEADERS } from '../request.definition';

export const getHeaders = (base = HEADERS, extension = {}) => {
  const headers = {};
  const extensionKeys = Object.keys(extension);
  const { Mirai: { version } = {} } = window || {};

  Object.entries(base).forEach(([key, value]) => !extensionKeys.includes(key) && (headers[key] = value));
  Object.entries(extension).forEach(([key, value]) => value && (headers[key] = value));

  return { ...(version ? { client: `@mirai/core v${version}` } : {}), ...headers };
};
