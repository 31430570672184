import { dateFormat, UTC } from '@mirai/locale';

import { getFirstDayOfWeek } from './getFirstDayOfWeek';
import { getToday } from './getToday';

export const getWeekDays = (locale) => {
  const today = getToday();
  const weekdays = [];

  const firstDayOfWeek = getFirstDayOfWeek(locale);
  const offsetDays = firstDayOfWeek === 0 ? 0 : today.getDay() === 0 ? -7 + firstDayOfWeek : firstDayOfWeek;
  const date = UTC(new Date(today.setDate(today.getDate() - today.getDay() + offsetDays)));

  Array.from(Array(7).keys()).forEach(() => {
    weekdays.push(dateFormat(date, { locale, weekday: 'short' }).substr(0, 3));
    date.setDate(date.getDate() + 1);
  });

  return weekdays;
};
