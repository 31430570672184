/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { ERROR } from './constants';

const MOCK_AUTHORIZATION = 'dXNlcjE6dXNlcjFQYXNz';

export const list = ({ hotelId } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!hotelId) return reject(ERROR.NOT_FILLED);

    const response = await request({
      hostname: 'https://api.mirai.com/MiraiWebService',
      endpoint: `/offers/${hotelId}`,
      headers: { authorization: `Basic ${MOCK_AUTHORIZATION}` },
    }).catch(reject);
    if (!response || response?.responseCode !== 'OK') return reject(ERROR.UNKNOWN);

    const offers = (response.offers || [])
      .filter(({ offerId } = {}) => !!offerId)
      .map(({ description, offerId: id, offerName: name, photoUrl: image } = {}) => ({ description, id, name, image }));

    resolve({ offers });
  });
