import { translate as translateBase } from '@mirai/locale';
const UNDEFINED_MAX = 99; // ! TODO: Backend team should not return this value.

const L10N = {
  LABEL_AGE_FROM: { id: 'common.label.age_from' },
  LABEL_AGE_RANGE: { id: 'common.label.age_range' },
  LABEL_AGE_UP_TO: { id: 'common.label.age_up_to' },
};

export const getAgeRange = ([min, max] = [], translate = translateBase) =>
  min || max
    ? !min && max
      ? translate(L10N.LABEL_AGE_UP_TO, { years: max })
      : min && (!max || max == UNDEFINED_MAX)
      ? translate(L10N.LABEL_AGE_FROM, { years: min })
      : translate(L10N.LABEL_AGE_RANGE, { min, max })
    : '';
