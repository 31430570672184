import { parseDate, UTC } from '@mirai/locale';

export const testExpire = (expire) => {
  const now = UTC(new Date());

  return (
    !expire ||
    (expire?.length === 5 &&
      expire.indexOf('/') === 2 &&
      UTC(parseDate(`01${expire.slice(0, 2)}${expire.slice(3)}`, 'DDMMYY')) >=
        UTC(new Date(now.getFullYear(), now.getMonth(), 1)))
  );
};
