import { getCardConfig } from './getCardConfig';
import { getCardType } from './getCardType';
import { formatExpire } from '../../../helpers';

const PARSER = [/[^\d]/g, ''];

export const formatValues = (values = {}, cards) => {
  let { card = '', CVV = '', expire = '', name = '' } = values;

  card = card.replace(...PARSER);
  CVV = CVV.replace(...PARSER);
  expire = formatExpire(expire);
  name = name.toUpperCase();

  const { placeholder = {} } = getCardConfig(card);
  const cardArray = card ? [...card] : [];
  card = [...placeholder.card]
    .map((char = '') => (char === ' ' ? char : cardArray.length >= 0 ? cardArray.shift() : ''))
    .join('')
    .trimEnd();

  return { card, CVV, expire, name, type: getCardType(card, cards) };
};
