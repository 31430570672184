import { useEffect, useMemo, useState } from 'react';

import { getNavigator } from '../helpers';
import { Theme } from '../theme';
import { getResolution, sanitizePx } from './helpers';

export const useDevice = () => {
  const [resolution, setResolution] = useState(getResolution());

  useEffect(() => {
    const handleResize = () => setResolution(getResolution());

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return useMemo(() => {
    const { breakpointS = '480px', breakpointM = '1180px' } = Theme.get();

    const isLandscape = resolution.width >= resolution.height;
    const isMobile = resolution.width <= sanitizePx(breakpointS);
    const isTablet = !isMobile && resolution.width < sanitizePx(breakpointM);
    const navigator = getNavigator();

    return {
      ...resolution,
      ...navigator,
      isLandscape,
      isPortrait: !isLandscape,
      isMobile,
      isTablet,
      isDesktop: !isMobile && !isTablet,
    };
  }, [resolution]);
};
