import { translate as translateBase } from '@mirai/locale';

export const template = (sample = '', bindings = {}, translate = translateBase) => {
  if (typeof sample !== 'string' || typeof bindings !== 'object') return;

  let value = sample;

  value = value.replace(/\[\[([^[\]]*)\]\]/g, (match, id) => translate({ id }));

  Object.keys(bindings).forEach((key) => (value = value.replace(new RegExp(`\\[${key}\\]`, 'g'), bindings[key])));

  return value;
};
