import { LocalAdapter, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT, USER_STORAGE_KEY } from '../User.constants';

export const getSession = () => {
  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const value = storage.get(`${USER_STORAGE_KEY}:session`);

  return value?.authorization ? value : undefined;
};
