import { Event, useStore } from '@mirai/data-sources';
import { DEFAULT_LOCALE, LocaleProvider } from '@mirai/locale';
import { ServiceDictionary } from '@mirai/services';
import { useDevice } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { COMPONENTS } from './components';
import { EVENT } from './components/helpers';
import { Notifications } from './Core.Notifications';
import { getContents, getGhost, sanitizeComponent, themeDirection } from './helpers';

const contents = {};
const ghosts = {};

const Core = ({ children, components = [] }) => {
  const { isMobile } = useDevice();
  const {
    set,
    value: { currency, locale = DEFAULT_LOCALE, skeleton = false, ...store },
  } = useStore();

  const [dictionary, setDictionary] = useState(!skeleton ? ServiceDictionary.cache(locale) : undefined);

  useEffect(() => {
    if (skeleton) return;

    fetchDictionary(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (skeleton) return;

    Event.subscribe(EVENT.INTENT_CURRENCY, handleIntentCurrency);
    Event.subscribe(EVENT.INTENT_LOCALE, handleIntentLocale);

    return () => {
      Event.unsubscribe(EVENT.INTENT_CURRENCY, handleIntentCurrency);
      Event.unsubscribe(EVENT.INTENT_LOCALE, handleIntentLocale);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const handleIntentCurrency = ({ currency }) => {
    set({ currency });
  };

  const handleIntentLocale = async ({ locale }) => {
    const [language] = locale.split('-');

    set({ language, locale });

    themeDirection(language);
    fetchDictionary(locale);
  };

  const fetchDictionary = async (locale = DEFAULT_LOCALE) => {
    const cached = ServiceDictionary.cache(locale);
    if (cached) setDictionary(cached);

    const next = await ServiceDictionary.get(locale, false);
    if (next) setDictionary(next);

    return next;
  };

  return (
    <LocaleProvider {...{ currency, dictionary, locale }}>
      <>
        {!skeleton && <Notifications />}

        {components
          .filter(
            ({ desktop, mobile } = {}) =>
              (isMobile && (mobile === undefined || mobile === true) && desktop !== true) ||
              (!isMobile && (desktop === undefined || desktop === true) && mobile !== true),
          )
          .map(({ el, component, ...props } = {}, index) => {
            if (!ghosts[index]) ghosts[index] = getGhost(el);
            if (!contents[index]) contents[index] = getContents(el);
            sanitizeComponent(el);

            return ReactDOM.createPortal(
              React.createElement(COMPONENTS[component], {
                ...props,
                dangerousChildren: contents[index],
                el,
                ghost: ghosts[index],
                ['data-testid']: process.env.NODE_ENV === 'production' ? component : undefined,
              }),
              el,
            );
          })}
      </>
      {children}
    </LocaleProvider>
  );
};

Core.propTypes = {
  children: PropTypes.node,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.oneOf(Object.keys(COMPONENTS)),
      el: PropTypes.any,
    }),
  ),
};

export { Core };
