import { getNavigator } from './getNavigator';

const { safari } = getNavigator();

export const CustomElement = (component, TagElement = HTMLElement, tag) => {
  if (!component || !tag) return;

  const ClassElement = safari ? HTMLElement : TagElement;

  return customElements.define(component, class extends ClassElement {}, { extends: tag });
};
