import { sanitizePrefixes } from '../components/InputPhone/helpers';

export const getInputPhoneErrors = ({ prefixes = [], value = '' } = {}) => {
  if (value.trim().length === 0) return;

  const errors = {};
  const { length } = value.replace(/\D/g, '');
  const withPrefix = prefixes.length > 0;

  if (length < (withPrefix ? 8 : 6)) errors.minLength = true;
  if (withPrefix && !sanitizePrefixes(prefixes).some((item) => value.includes(item))) errors.format = true;

  return Object.keys(errors).length > 0 ? errors : undefined;
};
