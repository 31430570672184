import { useLocale } from '@mirai/locale';
import { InputOption, InputText, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CANCEL_OPTION } from '../Booking.constants';
import { L10N } from '../Booking.l10n';
import * as style from '../Booking.module.css';

const { ANOTHER_HOTEL, BOOKING_CHANGE, CHEAPER_PRICE, NOT_TRAVEL, OTHERS } = CANCEL_OPTION;

const FormCancel = ({ form: { comments, option = NOT_TRAVEL } = {}, onChange = () => {}, ...others }) => {
  const { translate } = useLocale();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleOptionChange(option), []);

  const handleOptionChange = (value) => onChange({ option: value, comments: undefined });

  const handleCommentsChange = (value) => onChange({ comments: value });

  const commentsProps = {
    name: 'comments',
    success: comments?.length > 0,
    value: comments,
    onChange: handleCommentsChange,
  };

  return (
    <>
      <Text className={style.cancel}>{translate(L10N.LABEL_CANCEL_BOOKING_TITLE)}</Text>
      <View {...others}>
        <InputOption
          checked={option === NOT_TRAVEL}
          label={translate(L10N.LABEL_NOT_TRAVEL)}
          name="cancel"
          type="radio"
          value={NOT_TRAVEL}
          onChange={handleOptionChange}
        />
        <InputOption
          checked={option === ANOTHER_HOTEL}
          label={translate(L10N.ACTION_ANOTHER_HOTEL)}
          name="cancel"
          type="radio"
          value={ANOTHER_HOTEL}
          onChange={handleOptionChange}
        />
        {option === ANOTHER_HOTEL && (
          <InputText {...commentsProps} label={translate(L10N.LABEL_COMMENTS_ANOTHER_HOTEL)} />
        )}
        <InputOption
          checked={option === CHEAPER_PRICE}
          label={translate(L10N.LABEL_CHEAPER_PRICE)}
          name="cancel"
          type="radio"
          value={CHEAPER_PRICE}
          onChange={handleOptionChange}
        />
        {option === CHEAPER_PRICE && (
          <InputText {...commentsProps} label={translate(L10N.LABEL_COMMENTS_CHEAPER_PRICE)} />
        )}
        <InputOption
          checked={option === BOOKING_CHANGE}
          label={translate(L10N.ACTION_BOOKING_CHANGE)}
          name="cancel"
          type="radio"
          value={BOOKING_CHANGE}
          onChange={handleOptionChange}
        />
        <InputOption
          checked={option === OTHERS}
          label={translate(L10N.LABEL_OTHERS)}
          name="cancel"
          type="radio"
          value={OTHERS}
          onChange={handleOptionChange}
        />
        {option === OTHERS && <InputText {...commentsProps} label={translate(L10N.LABEL_COMMENTS_OTHERS)} />}
      </View>
    </>
  );
};

FormCancel.displayName = 'Mirai:Core:Booking:FormCancel';

FormCancel.propTypes = {
  form: PropTypes.shape({
    comments: PropTypes.string,
    option: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

export { FormCancel };
