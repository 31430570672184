export const select = ({ dataSource = [], filter = [], limit, schema = {}, search, sort = {} } = {}) => {
  const has = { filter: filter?.length > 0, search: !!search, sort: Object.keys(sort).length > 0 };
  const fields = Object.keys(schema);
  let flatten = [...dataSource];
  let values = [];

  if (has.filter) {
    const filterGrouped = [...filter]
      .filter(({ field }) => fields.includes(field))
      .reduce((group, item = {}) => {
        const itemFound = group.find(({ field }) => item.field === field);

        if (itemFound && item.value) {
          if (!Array.isArray(itemFound.value)) itemFound.value = [itemFound.value];
          itemFound.value.push(item.value);
        } else {
          group.push({ ...item });
        }

        return group;
      }, []);

    flatten = flatten.filter((row = {}) =>
      filterGrouped.every(({ field, max, min, value: filterValue, values: filterValues = [] }) => {
        const fieldKey = schema[field].bind || field;
        const { type } = schema[fieldKey];
        let fieldValue = typeof row[fieldKey] === 'string' ? row[fieldKey].toLowerCase() : row[fieldKey];

        if (type === 'boolean') fieldValue = fieldValue ? 'true' : 'false';
        else if (type !== 'options' && Array.isArray(fieldValue)) fieldValue = fieldValue.join(' ')?.toLowerCase();

        if (fieldValue === undefined) return false;

        return filterValue && type !== 'options'
          ? !Array.isArray(filterValue)
            ? fieldValue.includes(filterValue.toLowerCase())
            : filterValue.some((item) => fieldValue.includes(item.toLowerCase()))
          : type === 'number'
          ? fieldValue >= (min || -Infinity) && fieldValue <= (max || Infinity)
          : type === 'options'
          ? filterValues
              .flat()
              .some((item) => (Array.isArray(fieldValue) ? fieldValue.includes(item) : fieldValue === item))
          : false;
      }),
    );
  }

  if (has.search) {
    flatten.forEach((row, index) => {
      const rowValues = Object.keys(row)
        .filter((key) => !schema[key]?.bind && schema[key]?.type !== 'component')
        .map((key) => row[key]);

      if (JSON.stringify(rowValues).toLowerCase().includes(search.toLowerCase())) values.push(flatten[index]);
    });
  } else {
    values = [...flatten];
  }

  if (has.sort) {
    Object.keys(sort).forEach((key) => {
      const fieldKey = schema[key].bind || key;
      const order = sort[key] ? 1 : -1;

      values.sort(
        ({ [fieldKey]: a } = {}, { [fieldKey]: b } = {}) =>
          (a === undefined || a < b ? -1 : b === undefined || a > b ? 1 : 0) * order,
      );
    });
  }

  return limit ? values.slice(0, limit) : values;
};
