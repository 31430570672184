const HEXCOLOR_REGEX = /^#([0-9A-F]{3}){1,2}$/i;

// -- parsers
const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace(/^#/, ''), 16);

  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

const rgbToHex = ([r, g, b] = []) => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};

// -- color modifiers
const lighten = (rgb, factor = 0) => {
  return rgb.map((color) => Math.max(0, Math.min(255, Math.round(color + factor * (255 - color)))));
};

const darken = (rgb, factor = 0) => {
  return rgb.map((color) => Math.max(0, Math.min(255, Math.round(color * (1 - factor)))));
};

export const palette = (hexColor = '#3978c5') => {
  if (!HEXCOLOR_REGEX.test(hexColor)) return;

  const rgbColor = hexToRgb(hexColor);

  return {
    dark: rgbToHex(darken(rgbColor, 0.4)),
    base: hexColor,
    light: rgbToHex(lighten(rgbColor, 0.4)),
    border: rgbToHex(lighten(rgbColor, 0.6)),
    background: rgbToHex(lighten(rgbColor, 0.9)),
  };
};
