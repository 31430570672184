export const L10N = {
  LABEL_BASE_PRICE_VALUE: { id: 'common.label.base_price_value' },
  LABEL_BASE_PRICE: { id: 'common.label.base_price' },
  LABEL_CHECKOUT_DISCOUNT_0: { id: 'user.label.checkout_discount_0' },
  LABEL_CHECKOUT_DISCOUNT_1: { id: 'user.label.checkout_discount_1' },
  LABEL_CHECKOUT_DISCOUNT_2: { id: 'user.label.checkout_discount_2' },
  LABEL_EXTRAS: { id: 'common.label.extras' },
  LABEL_PRICE_SUMMARY: { id: 'user.label.price_summary' },
  LABEL_TOTAL: { id: 'common.label.total' },

  NOTIFICATION_AGENCY: { id: 'user.notification.commission.agency' },
  NOTIFICATION_CHECKOUT_FEE_1: { id: 'user.notification.checkout_fee_1' },
  NOTIFICATION_CHECKOUT_FEE_2: { id: 'user.notification.checkout_fee_2' },
  NOTIFICATION_CHECKOUT_PRICE_0: { id: 'user.notification.checkout_price_0' },
  NOTIFICATION_CHECKOUT_PRICE_1: { id: 'user.notification.checkout_price_1' },
  NOTIFICATION_CHECKOUT_PRICE_2: { id: 'user.notification.checkout_price_2' },
  NOTIFICATION_CHECKOUT_PRICE_3: { id: 'user.notification.checkout_price_3' },
  NOTIFICATION_CHECKOUT_PRICE_4: { id: 'user.notification.checkout_price_4' },
  NOTIFICATION_CHECKOUT_PRICE_5: { id: 'user.notification.checkout_price_5' },
  NOTIFICATION_CHECKOUT_PRICE_6: { id: 'user.notification.checkout_price_6' },
  NOTIFICATION_CHECKOUT_PRICE_7: { id: 'user.notification.checkout_price_7' },
  NOTIFICATION_CHECKOUT_PRICE_8: { id: 'user.notification.checkout_price_8' },
  NOTIFICATION_CHECKOUT_TAX_0: { id: 'user.notification.checkout_tax_0' },
  NOTIFICATION_CHECKOUT_TAX_2: { id: 'user.notification.checkout_tax_2' },
  NOTIFICATION_CHECKOUT_TAX_3: { id: 'user.notification.checkout_tax_3' },
  NOTIFICATION_CHECKOUT_TAX_4: { id: 'user.notification.checkout_tax_4' },
  NOTIFICATION_CHECKOUT_TAX_5: { id: 'user.notification.checkout_tax_5' },
  NOTIFICATION_CHECKOUT_TAX_6: { id: 'user.notification.checkout_tax_6' },
  NOTIFICATION_CHECKOUT_TAX_7: { id: 'user.notification.checkout_tax_7' },
  NOTIFICATION_CHECKOUT_TAX_8: { id: 'user.notification.checkout_tax_8' },
  NOTIFICATION_CHECKOUT_TAX_9: { id: 'user.notification.checkout_tax_9' },
};
