import { STATE } from './Booking.constants';
import { ERROR } from '../../Core.constants';

const { CANCELLED, COMPLETED, CONFIRMED, POSTPONED } = STATE;

export const L10N = {
  ACTION_ANOTHER_HOTEL: { id: 'user.action.another_hotel' },
  ACTION_BACK_HOME: { id: 'common.action.back_home' },
  ACTION_BACK: { id: 'common.action.back' },
  ACTION_BOOKING_CHANGE: { id: 'user.action.booking_change' },
  ACTION_CANCEL_BOOKING: { id: 'user.action.cancel_booking' },
  ACTION_CANCEL: { id: 'common.action.cancel' },
  ACTION_COMPLETE_NOW: { id: 'user.action.complete_now' },
  ACTION_EDIT_BOOKING: { id: 'user.action.edit_booking' },
  ACTION_MORE_INFO: { id: 'common.action.more_info' },
  ACTION_POSTPONE: { id: 'user.action.postpone' },
  ACTION_PRINT: { id: 'user.action.print' },
  ACTION_RESEND_BOOKING: { id: 'user.action.resend_booking' },
  ACTION_SEND_EMAIL: { id: 'common.action.send_email' },
  ACTION_SEND: { id: 'common.action.send' },
  ACTION_SHOW_OPTIONS: { id: 'user.action.show_options' },
  ACTION_VIEW_HOTEL: { id: 'common.action.view_hotel' },
  ACTION_VIEW_MAP: { id: 'common.action.view_map' },

  LABEL_ACCOMODATION_TYPE: { id: 'common.label.accomodation_type_plural' },
  LABEL_ADDRESS: { id: 'common.label.address' },
  LABEL_AGENCY: { id: 'common.label.agency' },
  LABEL_BASE_PRICE_VALUE: { id: 'common.label.base_price_value' },
  LABEL_BOOKING_EXTRAS: { id: 'user.label.booking_extras' },
  LABEL_BOOKING_INFO: { id: 'user.label.booking_info' },
  LABEL_BOOKING_SUMMARY: { id: 'user.label.booking_summary' },
  LABEL_CANCEL_BOOKING_TITLE: { id: 'user.label.cancel_booking_title' },
  LABEL_CHEAPER_PRICE: { id: 'user.label.cheaper_price' },
  LABEL_CHECK_IN: { id: 'common.label.check_in' },
  LABEL_CHECK_OUT: { id: 'common.label.check_out' },
  LABEL_CHECKIN_TIME: { id: 'common.label.checkin_time' },
  LABEL_CHECKOUT_TIME: { id: 'common.label.checkout_time' },
  LABEL_CITY: { id: 'common.label.city' },
  LABEL_COMMENTS_ANOTHER_HOTEL: { id: 'user.label.comments_another_hotel' },
  LABEL_COMMENTS_CHEAPER_PRICE: { id: 'user.label.comments_cheaper_price' },
  LABEL_COMMENTS_HINT: { id: 'user.label.comments_hint' },
  LABEL_COMMENTS_OTHERS: { id: 'user.label.comments_others' },
  LABEL_COMPANY_ID: { id: 'common.label.company_id' },
  LABEL_COUNTRY: { id: 'common.label.country' },
  LABEL_EDIT_BOOKING_TEXT: { id: 'user.label.edit_booking_text' },
  LABEL_EMAIL_TEXT: { id: 'user.label.email_text' },
  LABEL_EMAIL: { id: 'common.label.email' },
  LABEL_EXTRAS: { id: 'common.label.extras' },
  LABEL_GUEST: { id: 'common.label.guest' },
  LABEL_IDPASSPORT: { id: 'common.label.id_passport' },
  LABEL_NAME: { id: 'common.label.name' },
  LABEL_NIGHT_COUNT: { id: 'common.label.night_count' },
  LABEL_NOT_TRAVEL: { id: 'user.label.not_travel' },
  LABEL_OTHERS: { id: 'common.label.others' },
  LABEL_PHONE: { id: 'common.label.phone' },
  LABEL_PREFERENCES: { id: 'common.label.preferences' },
  LABEL_PROMO_CODE: { id: 'common.label.promo_code' },
  LABEL_RATE: { id: 'common.label.rate' },
  LABEL_REQUIRED: { id: 'common.label.required' },
  LABEL_ROOM_COUNT: { id: 'common.label.room_count' },
  LABEL_ROOM_NUMBER: { id: 'user.label.room_number' },
  LABEL_SERVICE_ERROR: { id: 'common.label.service_error' },
  LABEL_STATEREGION: { id: 'common.label.state_region' },
  LABEL_SUBJECT_TO_AVAILABILITY: { id: 'user.label.subject_to_availability' },
  LABEL_YOUR_RESERVATION: { id: 'user.label.your_reservation' },
  LABEL_ZIPCODE: { id: 'common.label.zip_code' },

  NOTIFICATION: {
    [CANCELLED]: { id: 'common.notification.cancelled' },
    [CONFIRMED]: { id: 'common.notification.confirmed' },
    [COMPLETED]: { id: 'common.notification.completed' },
    [POSTPONED]: { id: 'common.notification.postponed' },
  },
  NOTIFICATION_CANCEL_BOOKING_SUCESS: { id: 'user.notification.cancel_booking_sucess' },
  NOTIFICATION_EDIT_BOOKING_SUCCESS: { id: 'user.notification.edit_booking_success' },
  NOTIFICATION_EMAIL_SUCCESS: { id: 'user.notification.email_success' },
  NOTIFICATION_ERROR: {
    [ERROR.NOT_AVAILABLE]: { id: 'common.notification.error_not_available' },
    [ERROR.PARAMETER_ERROR]: { id: 'common.notification.error_parameter' },
    [ERROR.UNKNOWN]: { id: 'common.notification.error_unknown' },
  },
  NOTIFICATION_PAY_LATER_PREPAY: { id: 'user.notification.pay_later_prepay' },
  NOTIFICATION_PAY_LATER_GUARANTEE: { id: 'user.notification.pay_later_guarantee' },
  NOTIFICATION_POS_ERROR: { id: 'user.notification.pos_error' },
  NOTIFICATION_POS_TITLE_ERROR: { id: 'user.notification.pos_title_error' },
  NOTIFICATION_SSL_ENCRYPTION: { id: 'common.notification.ssl_encryption' },
};
