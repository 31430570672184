import { ServiceCurrency } from '../../Currency';

export const convertCurrency = async (from, to, prices = {}, reverse = true) => {
  if (!from || !to) return undefined;

  const rates = await ServiceCurrency.exchange(to);
  let value = {};

  if (rates) {
    Object.keys(prices).forEach((date) => {
      value[date] = ServiceCurrency.convert(to, from, prices[date], reverse);
    });
  }

  return value;
};
