import { InputOption } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

const Amenity = ({ checked, id, label, onChange = () => {} }) => (
  <InputOption
    checked={checked}
    label={label}
    name={`amenity:${id}`}
    onChange={(nextValue) => onChange(id, nextValue)}
  />
);

Amenity.displayName = 'Mirai:Core:Rates:Filters.Amenity';

Amenity.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export { Amenity };
