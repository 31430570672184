import { L10N as L10N_BOOKING_DATES } from '../../../__shared__/BookingDates/BookingDates.l10n';

const { LABEL_MIN_NIGHTS } = L10N_BOOKING_DATES;

export const L10N = {
  ACTION_SEARCH: { id: 'common.action.search' },

  LABEL_ACCOMMODATION_TYPE: { id: 'common.label.accommodation_type' },
  LABEL_INFO_PRICES: { id: 'finder.label.info_prices' },
  LABEL_MIN_NIGHTS,
};
