export const L10N = {
  COUNTRY_LABEL: { id: 'user.label.country', defaultMessage: '　' },
  COUNTRY_PLACEHOLDER: { id: 'common.label.select', defaultMessage: '　' },
  CTA: { id: 'user.action.signup', defaultMessage: '　' },
  DATEOFBIRTH_LABEL: { id: 'user.label.date_of_birth', defaultMessage: '　' },
  DATEOFBIRTH_ERROR: { id: 'user.label.date_of_birth_error', defaultMessage: '　' },
  DESCRIPTION: { id: 'user.label.description_signup', defaultMessage: '　' },
  EMAIL_LABEL: { id: 'common.label.email', defaultMessage: '　' },
  FIRSTNAME_LABEL: { id: 'common.label.name', defaultMessage: '　' },
  LASTNAME_LABEL: { id: 'common.label.lastname', defaultMessage: '　' },
  LABEL_REQUIRED: { id: 'common.label.required', defaultMessage: '　' },
  PASSWORD_LABEL: { id: 'common.label.password', defaultMessage: '　' },
  PASSWORD_HINT: { id: 'user.label.password_hint', defaultMessage: '　' },
  PRIVACY_LABEL: { id: 'user.label.privacy_policy', defaultMessage: '　' },
  PRIVACY_CONDITIONS_LINK: { id: 'user.action.privacy_conditions' },
  PRIVACY_POLICY_LINK: { id: 'user.action.privacy_policy' },
  SUBSCRIBED_LABEL: { id: 'user.label.subscribe', defaultMessage: '　' },
  TITLE: { id: 'user.label.title_signup', defaultMessage: '　' },
  // Notifications
  SUCCESS_TITLE: { id: 'user.notification.signup_success', defaultMessage: '　' },
  SUCCESS_MESSAGE: { id: 'user.notification.email_sent_success', defaultMessage: '　' },
  NOTIFICATION_ERROR: { id: 'user.notification.validation_error', defaultMessage: '　' },
};
