const DATE_PROPS = { year: 'numeric', month: 'numeric', day: 'numeric' };
const TIME_PROPS = { hour: 'numeric', minute: 'numeric', hour12: false };

export const getTime = (timestamp, locale = 'es-ES') => {
  const date = new Date(timestamp);
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  return date.toLocaleString(locale, { ...TIME_PROPS, ...(!isToday ? DATE_PROPS : undefined) });
};
