import { useLocale } from '@mirai/locale';
import { Button, Modal, Pressable, styles } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { L10N } from './ButtonFinder.l10n.js';
import * as style from './ButtonFinder.module.css';
import { Finder } from '../Finder/Finder';
import { template } from '../helpers';

const ButtonFinder = ({ ghost, text, ...others }) => {
  const { translate } = useLocale();

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const { testId = 'button-finder' } = others;
  const buttonTestId = `${testId}-button-modal`;

  return (
    <>
      {!ghost ? (
        <Button onPress={toggleModal} className={styles(style.button, others.className)} testId={buttonTestId}>
          {text || translate(L10N.ACTION_RESERVE)}
        </Button>
      ) : (
        <Pressable
          onPress={toggleModal}
          className={others.className}
          testId={buttonTestId}
          dangerouslySetInnerHTML={ghost ? { __html: template(ghost.outerHTML, undefined, translate) } : undefined}
        />
      )}

      <Modal
        title={text || translate(L10N.ACTION_RESERVE)}
        visible={modal}
        onClose={toggleModal}
        testId={testId ? `${testId}-modal` : undefined}
      >
        <Finder layout="column" />
      </Modal>
    </>
  );
};

ButtonFinder.displayName = 'Mirai:Core:ButtonFinder';

ButtonFinder.propTypes = {
  ghost: PropTypes.any,
  text: PropTypes.string,
};

export { ButtonFinder };
