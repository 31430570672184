import React from 'react';

import { styles } from '../../../helpers';
import { Icon, ICON } from '../../../primitives';
import { DIRECTION_TYPE, Theme } from '../../../theme';
import style from '../../InputText/InputText.module.css';

const Expand = () => {
  const defaultDirection = Theme.getDirection() === DIRECTION_TYPE.LEFT;

  return (
    <Icon value={ICON.EXPAND_MORE} className={styles(style.icon, style.expand, !defaultDirection && style.reverse)} />
  );
};

Expand.displayName = 'Component:InputSelect.Expand';

Expand.propTypes = {};

export { Expand };
