import { LocalAdapter, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT } from './constants';
import { getStorageKey } from './helpers';

const handleCatch = (error) => {
  // ! TODO: Should call home (sentry, mixpanel, ...)
  // eslint-disable-next-line no-console
  console.error('ServiceFeatures.initialize', error);
};

export const initialize = async () => {
  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const storageKey = getStorageKey();
  const cache = storage.get(storageKey);

  let response;
  if (cache) {
    response = cache;
  } else {
    try {
      response = await fetch(process.env.SERVICE_STATIC + '/features/specs').catch(handleCatch);
      response = await response.json();
      storage.set(storageKey, response);
    } catch (error) {
      handleCatch(error);
    }
  }

  return response;
};
