import { cancel } from './cancel';
import { checkout } from './checkout';
import { confirm } from './confirm';
import { email } from './email';
import { get } from './get';
import { postpone } from './postpone';
import { save } from './save';
import { update } from './update';

export const ServiceBooking = {
  cancel,
  checkout,
  confirm,
  email,
  get,
  postpone,
  save,
  update,
};
