export const loadScript = ({ id, name, onLoad = () => {} }) => {
  const exists = document.getElementById(id);
  if (!exists) {
    const script = document.createElement('script');
    script.id = id;
    script.src = `${process.env.SERVICE_STATIC}/payments/scripts/${name}`;
    script.onload = onLoad;

    document.head.appendChild(script);
  }

  return () => {
    const el = document.getElementById(id);
    if (el) el.parentNode.removeChild(el);
  };
};
