/* eslint-disable no-console */
import { Event, MetricsProvider, StoreProvider } from '@mirai/data-sources';
import { ServiceDictionary, ServiceFeatures } from '@mirai/services';
import React from 'react';
import ReactDOM from 'react-dom';

import { EventManager, PerformanceManager } from './contexts';
import { Core } from './Core';
import { COOKIE, EVENT } from './Core.constants';
import { Error } from './Core.Error';
import {
  checkSession,
  consolidateStore,
  fetchConfig,
  fetchCookies,
  fetchSession,
  fetchUrlParams,
  findComponents,
  findInstance,
  getLocale,
  loadEnvironment,
  loadSentry,
  theme,
} from './helpers';
import { name, version } from '../package.json';
import './helpers/polyfills';

window.Mirai = {
  ...window.Mirai,
  components: undefined,
  config: undefined,
  environment: process.env.NODE_ENV,
  Event,
  services: {
    booking: process.env.SERVICE_BOOKING,
    club: process.env.SERVICE_CLUB,
    dictionary: process.env.SERVICE_DICTIONARY,
    engine: process.env.SERVICE_ENGINE,
    static: process.env.SERVICE_STATIC,
    user: process.env.SERVICE_USER,
  },
  version,
};

window.Mirai.core = async () => {
  Event.publish(EVENT.CORE_EVENTS_READY);

  const isProduction = process.env.NODE_ENV === 'production';
  const isDevelopment = process.env.NODE_ENV === 'development';
  const isPreproduction = process.env.NODE_ENV === 'preproduction';
  const log = `${isProduction ? '🟢' : isPreproduction ? '🟡' : isDevelopment ? '🔴' : '⚫️'} ${name} v${version}`;

  console.log(log, 'starting...');

  // 1. Initialize sentry
  let Sentry = window.Mirai.Sentry;
  if (!Sentry && process.env.SENTRY) {
    Sentry = loadSentry(process.env.SENTRY, version);
    window.Mirai.Sentry = Sentry;
  }

  // 2. Fetch client-side dataSources
  const urlParams = fetchUrlParams(location.search);
  const cookies = fetchCookies(Object.values(COOKIE));
  let session = fetchSession(urlParams);

  if (session && Sentry) Sentry.setUser(session);

  // 3. Analyze environment
  const environment = urlParams.env && isProduction ? loadEnvironment(urlParams.env) : undefined;
  if (environment) return;

  // 4. Find instance
  const instance = findInstance({ urlParams });
  if (!instance) return;

  // 5. Find components
  const components = findComponents();

  window.Mirai = { ...window.Mirai, components };

  // 6. Theming
  theme(urlParams);

  const localeInstance = getLocale(instance, urlParams);

  // 7. 1st render
  window &&
    !window.IS_PLAYWRIGHT &&
    ReactDOM.render(
      <React.StrictMode>
        <Error>
          <StoreProvider
            value={{ id: instance.id, session, skeleton: true, type: instance.type, urlParams, ...localeInstance }}
          >
            <Core {...{ components }} />
          </StoreProvider>
        </Error>
      </React.StrictMode>,
      instance.el,
    );

  // 8. Analyze id to fetch its config
  const config = await fetchConfig({ ...instance, language: localeInstance.language });
  if (!config) return;
  window.Mirai.config = config;

  // 9. Check session
  if (session && !(await checkSession(instance.id, session, config))) session = undefined;

  // 10. Consolidate store
  const store = await consolidateStore({ components, config, cookies, instance, session, urlParams });

  // 11. Initialize features
  await ServiceFeatures.initialize();

  // 12. Initialize dictionary
  if (!ServiceDictionary.cache(store.locale)) await ServiceDictionary.get(store.locale, false);

  // 13. Render components
  ReactDOM.render(
    <React.StrictMode>
      <Error>
        <MetricsProvider token={isProduction ? process.env.METRICS : undefined}>
          <StoreProvider value={store}>
            <PerformanceManager />
            <Core {...{ components }} />
            <EventManager />
          </StoreProvider>
        </MetricsProvider>
      </Error>
    </React.StrictMode>,
    instance.el,
  );

  // 14. Up & Ready
  window.Mirai.ready = true;
  setTimeout(() => Event.publish(EVENT.CORE_READY, window.Mirai), 0);
  console.log(log, '[READY]', window.Mirai);
};

(async () => {
  try {
    await window.Mirai.core();
  } catch (error) {
    window.Mirai.Sentry?.captureException(error);

    throw error;
  }

  const { components = [], ready } = window.Mirai;

  if (ready && components.filter(({ component } = {}) => ['booking', 'checkout', 'rates'].includes(component).length)) {
    window.addEventListener('pageshow', (event) => event.persisted && window.Mirai.core());
  }
})();
