import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Primitive } from '../Primitive';
import { color } from '../Text/helpers';
import style from './Icon.module.css';

const Icon = ({
  accent,
  accentLight,
  accentDark,
  action,
  dark,
  error,
  headline,
  info,
  level = 3,
  light,
  paragraph,
  small,
  success,
  value,
  warning,
  ...others
}) =>
  React.createElement(
    Primitive,
    {
      ...others,
      tag: 'icon',
      className: styles(
        style.icon,
        color({ accent, accentDark, accentLight, dark, error, info, light, success, warning }),
        headline
          ? style[`headline-${level}`]
          : action
          ? style.action
          : small
          ? style.small
          : paragraph
          ? style.paragraph
          : style[`headline-${level}`],
        others.className,
      ),
    },
    value ? React.createElement(value) : undefined,
  );

Icon.displayName = 'Primitive:Icon';

Icon.propTypes = {
  accent: PropTypes.bool,
  accentLight: PropTypes.bool,
  accentDark: PropTypes.bool,
  action: PropTypes.bool,
  dark: PropTypes.bool,
  error: PropTypes.bool,
  headline: PropTypes.bool,
  info: PropTypes.bool,
  level: PropTypes.number,
  light: PropTypes.bool,
  paragraph: PropTypes.bool,
  small: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.func.isRequired,
  warning: PropTypes.bool,
};

export { Icon };
