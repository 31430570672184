import { styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Card.module.css';

const Card = ({ columnMode, disabled, focus, ...others }) => (
  <View
    {...others}
    className={styles(
      style.card,
      disabled && style.disabled,
      !disabled && focus && style.focus,
      columnMode && style.columnMode,
      others.className,
    )}
  />
);

Card.displayName = 'Mirai:Core:Card';

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  columnMode: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
};

export { Card };
