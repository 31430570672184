export const parseValue = (value = '', prefixes = []) => {
  if (!value || !value?.length) return undefined;

  const hasPrefixes = !!prefixes?.length;
  let [prefix, phone] = hasPrefixes ? value.trim().split(' ') || [] : [undefined, value.trim()];

  if (hasPrefixes) {
    if (!prefix.startsWith('+')) {
      phone = prefix;
      prefix = prefixes[0];
    }

    if (phone === undefined) {
      prefixes.forEach((item) => {
        if (prefix.startsWith(item)) phone = prefix.substring(prefix.indexOf(item) + item.length);
      });

      if (phone === undefined) phone = prefix.substring(prefixes[0].length);
    }

    if (!prefixes.includes(prefix)) prefix = prefixes[0];
  }

  return `${prefix || ''} ${phone || ''}`.trim();
};
