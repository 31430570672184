import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, ICON, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './BookingProcess.l10n';
import * as style from './BookingProcess.module.css';
import Skeleton from './BookingProcess.skeleton';
import { EVENT } from '../../../helpers';

const BookingProcess = ({ step: propStep, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const { value: { components = {}, skeleton } = {} } = useStore();

  const [step, setStep] = useState(propStep);

  useEffect(() => {
    const callback = () => setStep(3);

    Event.subscribe(EVENT.PAY_LATER, callback);
    return () => Event.unsubscribe(EVENT.PAY_LATER, callback);
  }, []);

  useEffect(() => {
    setStep(propStep || components.rates ? 1 : components.checkout ? 2 : components.booking ? 4 : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components, propStep]);

  const iconProps = { small: isMobile };
  const textProps = { action: true };
  const is = { paylater: step === 3 };

  return skeleton ? (
    <Skeleton />
  ) : step ? (
    <View {...others} row className={[style.bookingProcess, others.className]}>
      <View row className={style.item}>
        <Text {...textProps} bold className={[style.bullet, style.success]}>
          {step > 1 ? <Icon {...iconProps} value={ICON.CHECK} /> : 1}
        </Text>
        {(!isMobile || step === 1) && (
          <Text {...textProps} bold={step === 1} className={style.label}>
            {translate(L10N.LABEL_CHOOSE_STAY)}
          </Text>
        )}
      </View>

      <View className={style.divider} />

      <View row className={style.item}>
        <Text {...textProps} bold className={styles(style.bullet, step >= 2 && style.success)}>
          {step > 2 ? <Icon {...iconProps} value={ICON.CHECK} /> : 2}
        </Text>

        {(!isMobile || step === 2) && (
          <Text {...textProps} bold={step === 2} className={styles(style.label, step < 2 && style.disabled)}>
            {translate(L10N.LABEL_PERSONAL_DETAILS)}
          </Text>
        )}
      </View>

      <View className={style.divider} />

      {is.paylater && (
        <>
          <View row className={style.item}>
            <Text {...textProps} bold className={styles(style.bullet, step >= 3 && style.success)}>
              {step > 3 ? <Icon {...iconProps} value={ICON.CHECK} /> : 4}
            </Text>

            {(!isMobile || step === 3) && (
              <Text {...textProps} bold={step === 3} className={styles(style.label, step < 3 && style.disabled)}>
                {translate(L10N.LABEL_BOOKING)}
              </Text>
            )}
          </View>
          <View className={style.divider} />
        </>
      )}

      <View row className={style.item}>
        <Text {...textProps} bold className={styles(style.bullet, step >= 3 && style.success)}>
          {step >= 4 ? <Icon {...iconProps} value={ICON.CHECK} /> : is.paylater ? 4 : 3}
        </Text>
        {(!isMobile || step === 3) && (
          <Text {...textProps} bold={step === 4} className={styles(style.label, step < 4 && style.disabled)}>
            {translate(L10N.LABEL_CONFIRMATION)}
          </Text>
        )}
      </View>
    </View>
  ) : null;
};

BookingProcess.displayName = 'Mirai:Core:Header:BookingProcess';

BookingProcess.propTypes = {
  step: PropTypes.number,
};

export { BookingProcess };
