import { FEATURES } from '../Rates.constants';

export const getFeatures = (ratesFeatures = {}, urlParams = {}, { isDesktop } = {}) => {
  const urlFeatures = urlParams.features ? urlParams.features.split(',') : [];
  let features = {};

  Object.values(FEATURES).forEach((feature) => {
    features[feature] = urlFeatures.includes(feature) || ratesFeatures[feature] || false;
  });

  if (features.columnMode) features.columnMode = isDesktop && features.columnMode;

  return features;
};
