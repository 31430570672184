import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceUser } from '@mirai/services';
import { Button, Modal, Notification, Text, View } from '@mirai/ui';
import React, { useState } from 'react';

import { L10N } from './Settings.l10n';
import * as style from './Settings.module.css';

const Signout = () => {
  const {
    value: { club = {}, hotel: { url } = {} },
  } = useStore();
  const { translate } = useLocale();

  const [busy, setBusy] = useState(false);
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState();
  const [responseError, setResponseError] = useState();

  const handleSubmit = async () => {
    setBusy(true);
    setResponseError(undefined);

    const response = await ServiceUser.signout().catch((error) => {
      setResponseError(error);
    });

    setResponse(response);
    setBusy(false);
  };

  const handleClose = () => {
    if (!response) return setModal(false);

    window.location.href = url;
  };

  const handleOpen = () => {
    setModal(true);
  };

  return (
    <View className={style.box}>
      <Text bold headline>
        {translate(L10N.TITLE_SIGNOUT, { club: club.name })}
      </Text>
      <Text>{translate(L10N.DESCRIPTION_SIGNOUT)}</Text>

      <Button secondary wide onPress={handleOpen} testId="button-delete-account">
        {translate(L10N.BUTTON_SIGNOUT)}
      </Button>

      <Modal title={translate(L10N.BUTTON_SIGNOUT)} visible={modal} onClose={handleClose}>
        <View className={style.modal}>
          {!response && <Text>{translate(L10N.DESCRIPTION_SIGNOUT_MODAL)}</Text>}

          {(response || responseError) && (
            <Notification
              large={!!response}
              error={!!responseError}
              success={!!response}
              className={style.notification}
            >
              {translate(responseError ? L10N.ERROR : L10N.SUCCESS_SIGNOUT)}
              {response && (
                <Text small className={style.description}>
                  {translate(L10N.DESCRIPTION_SIGNOUT_MODAL_SUCCESS)}
                </Text>
              )}
            </Notification>
          )}

          {!response ? (
            <View wide className={style.buttons}>
              <Button busy={busy} wide onPress={handleSubmit}>
                {translate(L10N.CTA_SIGNOUT)}
              </Button>
              <Button disabled={busy} transparent wide onPress={handleClose} testId="button-delete-close">
                {translate(L10N.ACTION_SIGNOUT_CANCEL)}
              </Button>
            </View>
          ) : null}
        </View>
      </Modal>
    </View>
  );
};

Signout.displayName = 'Mirai:Core:Profile:Signout';

Signout.propTypes = {};

export { Signout };
