import { View } from '@mirai/ui';
import { useDevice } from '@mirai/ui';
import React from 'react';

import { Field, Skeleton } from '../';

const SPACE = 'var(--mirai-ui-space-M)';
const BORDER_COLOR = 'var(--mirai-ui-content-background)';
const BORDER_WIDTH = 'var(--mirai-ui-border-width)';

export default () => {
  const { isMobile } = useDevice();

  return (
    <View style={{ gap: SPACE, maxWidth: '720px' }}>
      <View
        row
        style={{
          borderBottom: `solid ${BORDER_WIDTH} ${BORDER_COLOR}`,
          justifyContent: 'space-between',
          padding: SPACE,
        }}
      >
        <Skeleton bold headline level={2} words={3} />
        <Skeleton button small words={1} />
      </View>

      {isMobile ? (
        <View wide style={{ gap: SPACE, padding: `0 ${SPACE}` }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Field key={index} />
          ))}
          <Skeleton button wide words={1} style={{ marginBottom: SPACE }} />
        </View>
      ) : (
        <>
          <View style={{ padding: `0 ${SPACE}` }}>
            <View row style={{ gap: SPACE, marginBottom: SPACE }}>
              {Array.from({ length: 2 }).map((_, index) => (
                <Skeleton key={index} wide style={{ height: '20rem' }} />
              ))}
            </View>
            <Skeleton words={4} />
            <Skeleton small words={19} />
          </View>

          <View
            row
            wide
            style={{
              borderTop: `solid ${BORDER_WIDTH} ${BORDER_COLOR}`,
              padding: SPACE,
              gap: SPACE,
            }}
          >
            <Field />
            <Field />
            <View style={{ flex: 1 }} />
            <Skeleton button words={1} />
          </View>
        </>
      )}
    </View>
  );
};
