import { dateDiff, parseDate, UTC } from '@mirai/locale';

import { L10N } from './getButtonPaymentKey.l10n';
import { PAYMENT_METHOD } from '../../helpers';

const { APLAZAME, CARD, PCIPROXY, TPV } = PAYMENT_METHOD;
const { ACTION_SAVE, ACTION_CONTINUE, ACTION_PAY_NOW } = L10N;

export const getButtonPaymentKey = ({ cardValidation, date, method } = {}) =>
  [CARD, PCIPROXY].includes(method) && !cardValidation
    ? ACTION_SAVE
    : (method === CARD && cardValidation) ||
      method === APLAZAME ||
      (method == TPV && date && dateDiff(UTC(new Date()), parseDate(date)).days > 0)
    ? ACTION_CONTINUE
    : ACTION_PAY_NOW;
