export const L10N = {
  LABEL_ALIPAY: { id: 'payments.label.alipay' },
  LABEL_AMAZON_PAY: { id: 'payments.label.amazon' },
  LABEL_APLAZAME: { id: 'payments.label.aplazame' },
  LABEL_BIZUM: { id: 'payments.label.bizum' },
  LABEL_CARD: { id: 'payments.label.card' },
  LABEL_CARD_PREPAY: { id: 'payments.label.card' },
  LABEL_CRIPTAN: { id: 'payments.label.criptan' },
  LABEL_IDEAL: { id: 'payments.label.ideal' },
  LABEL_OXXO: { id: 'payments.label.oxxo' },
  LABEL_PAYLATER: { id: 'payments.label.pay_later' },
  LABEL_PAYMENT_METHODS: { id: 'payments.label.payment_methods' },
  LABEL_PAYPAL: { id: 'payments.label.paypal' },
  LABEL_PCIPROXY: { id: 'payments.label.card' },
  LABEL_SOFORT: { id: 'payments.label.sofort' },
  LABEL_TPV: { id: 'payments.label.tpv' },
  LABEL_TRANSFER: { id: 'payments.label.transfer' },
  LABEL_WECHATPAY: { id: 'payments.label.wechat' },

  LABEL_CAPTION_DEFERRED: { id: 'payments.label.caption_deferred' },
  LABEL_CAPTION_PAYLATER: { id: 'payments.label.caption_pay_later' },
  LABEL_REDIRECT_APLAZAME: { id: 'payments.label.redirect_aplazame' },
  LABEL_REDIRECT_CARD_PREPAYMENT: { id: 'payments.label.redirect_card_prepayment' },
  LABEL_REDIRECT_CARDNET: { id: 'payments.label.redirect_cardnet' },
  LABEL_REDIRECT_MORE_INFO: { id: 'payments.label.redirect_more_info' },
  LABEL_REDIRECT: { id: 'payments.label.redirect' },

  LABEL_DAY: { id: 'common.label.day' },
  LABEL_HOUR: { id: 'common.label.hour' },
};
