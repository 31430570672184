import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button as ButtonBase, Icon, Text, useDevice } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './Filters.l10n';
import * as style from './Filters.module.css';
import { Skeleton } from '../../../__shared__';
import { ICON } from '../../../helpers';

const Button = ({ counter, ...others }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const { value: { skeleton = false } = {} } = useStore();

  return skeleton ? (
    <Skeleton button squared={!isDesktop} className={style.button} words={isDesktop ? 1 : 0} />
  ) : (
    <ButtonBase {...others} secondary squared={!isDesktop} className={style.button}>
      <Icon value={ICON.TUNE} />
      {isDesktop && translate(L10N.LABEL_FILTERS)}
      {counter > 0 && (
        <Text bold tiny className={style.counter}>
          {counter}
        </Text>
      )}
    </ButtonBase>
  );
};

Button.displayName = 'Mirai:Core:Rates:Filters.Button';

Button.propTypes = {
  counter: PropTypes.number,
};

export { Button };
