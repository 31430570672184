export const customMarker = ({ base, content, contentBorder, font, fontBoldWeight, fontSizeAction } = {}) => ({
  icon: {
    // path: 'M -28,-14 h 56 v 28 h -56 z', // Square
    path: 'M -26, -14 a 2,2 0 0 1 2,-2 h 48 a 2,2 0 0 1 2,2 v 26 a 2,2 0 0 1 -2,2 h -48 a 2,2 0 0 1 -2,-2 z', // Rounded
    fillColor: base,
    fillOpacity: 1,
    strokeColor: contentBorder,
    strokeOpacity: 1,
    strokeWeight: 1,
  },

  label: {
    color: content,
    fontFamily: font,
    fontSize: fontSizeAction,
    fontWeight: fontBoldWeight,
  },
});
