import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import * as style from './Form.module.css';

const Form = ({ actionUrl: action, formFields: fields = [], method, ...others }) => {
  const el = useRef();

  useEffect(() => {
    el?.current?.submit();
  }, []);

  return (
    <form ref={el} {...{ action, method }} className={[style.form, others.className]} data-testid={others.testId}>
      {fields.map(({ name, value } = {}) => (
        <input key={name} type="hidden" {...{ name, value }} />
      ))}
    </form>
  );
};

Form.displayName = 'Mirai:Core:ButtonPayment:Form';

Form.propTypes = {
  actionUrl: PropTypes.string,
  formFields: PropTypes.array,
  method: PropTypes.string,
};

export { Form };
