import { useLocale } from '@mirai/locale';
import { Button, Input, Notification, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FIELDS_COUNT } from './FormCheckout.constants';
import { L10N } from './FormCheckout.l10n';
import * as style from './FormCheckout.module.css';
import { getStyle } from './helpers';
import { loadScript } from '../../../../../helpers/loadScript';
import { InputField } from '../InputField';

export const FormCheckout = ({
  publicKey = process.env.PROVIDER_CHECKOUT_PUBLICKEY,
  onError = () => {},
  onSuccess = () => {},
  ...others
}) => {
  const { translate } = useLocale();

  const [busy, setBusy] = useState(false);
  const [focus, setFocus] = useState();
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [ready, setReady] = useState(false);
  const [responseError, setResponseError] = useState();

  useEffect(
    () =>
      loadScript({
        domain: 'payment',
        id: 'checkout',
        src: `${process.env.SERVICE_STATIC}/payments/scripts/framesv2.min.js`,
        onLoad: handleLoad,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!ready) return;

    const { Frames } = window || {};

    const handleBlur = ({ element }) => handleLeave(element);

    const handleFocus = ({ element }) => handleEnter(element);

    const handleValidationChanged = ({ element, isEmpty, isValid }) => {
      handleChange(element, isEmpty ? undefined : 'value');
      handleError(element, !isValid);
    };

    Frames.addEventHandler(Frames.Events.FRAME_BLUR, handleBlur);
    Frames.addEventHandler(Frames.Events.FRAME_FOCUS, handleFocus);
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, handleCardTokenized);
    Frames.addEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, handleCardTokenizedFailed);
    Frames.addEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, handleValidationChanged);

    return () => {
      Frames.removeEventHandler(Frames.Events.FRAME_BLUR, handleBlur);
      Frames.removeEventHandler(Frames.Events.FRAME_FOCUS, handleFocus);
      Frames.removeEventHandler(Frames.Events.CARD_TOKENIZED, handleCardTokenized);
      Frames.removeEventHandler(Frames.Events.CARD_TOKENIZATION_FAILED, handleCardTokenizedFailed);
      Frames.removeEventHandler(Frames.Events.FRAME_VALIDATION_CHANGED, handleValidationChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formError, ready]);

  const handleCardTokenized = ({ token } = {}) => {
    setBusy(false);
    if (!token) return;

    setForm({});
    setFormError({});
    window.Frames?.init({ publicKey, ...getStyle() });
    onSuccess({ name: form?.name, token });
  };

  const handleCardTokenizedFailed = (error = {}) => {
    setBusy(false);
    setResponseError(error);
    window.Frames?.enableSubmitForm();
    onError(error);
  };

  const handleChange = (field, value = '') => {
    setForm({ ...form, [field]: value });
    setFormError({ ...formError, [field]: value.length === 0 });
  };

  const handleError = (field, value) => {
    setFormError({ ...formError, [field]: value });
  };

  const handleEnter = (field) => setFocus((focus) => ({ ...focus, [field]: true }));

  const handleLeave = (field) => setFocus((focus) => ({ ...focus, [field]: false }));

  const handleLoad = () => {
    window.Frames?.init({ publicKey, ...getStyle() });
    setReady(true);
  };

  const handlePress = () => {
    const { Frames } = window || {};
    setBusy(true);
    setResponseError();
    Frames.cardholder = {
      name: form.name,
    };
    Frames.submitCard();
  };

  const hasError =
    Object.values(formError).length < FIELDS_COUNT || Object.values(formError).some((value) => value === true);

  return (
    <View {...others}>
      <Text action className={style.description}>
        {translate(L10N.LABEL_SECURE_PAGE)}
      </Text>

      <InputField
        label={translate(L10N.LABEL_CARD_NAME)}
        error={formError.name}
        focus={focus?.name}
        success={formError.name === false}
        value={form.name}
      >
        <Input
          name="name"
          value={form.name}
          onChange={(value) => handleChange('name', value)}
          onEnter={() => handleEnter('name')}
          onLeave={() => handleLeave('name')}
          className={style.name}
        />
      </InputField>
      <InputField
        label={translate(L10N.LABEL_CARD_NUMBER)}
        error={formError['card-number']}
        focus={focus?.['card-number']}
        success={formError['card-number'] === false}
        value={form?.['card-number']}
      >
        <div className={styles(style.frame, 'card-number-frame')} />
      </InputField>
      <View row>
        <InputField
          label={translate(L10N.LABEL_EXPIRATION)}
          error={formError['expiry-date']}
          focus={focus?.['expiry-date']}
          success={formError['expiry-date'] === false}
          value={form?.['expiry-date']}
        >
          <div className={styles(style.frame, 'expiry-date-frame')} />
        </InputField>

        <InputField
          label={translate(L10N.LABEL_CVV)}
          error={formError['cvv']}
          focus={focus?.['cvv']}
          success={formError['cvv'] === false}
          value={form?.['cvv']}
          className={style.inputCVV}
        >
          <div className={styles(style.frame, 'cvv-frame')} />
        </InputField>
      </View>

      {responseError && (
        <Notification error small wide>
          {translate(L10N.NOTIFICATION_ERROR_CHECKOUT)}
        </Notification>
      )}

      <Button busy={busy} disabled={hasError} large wide onPress={handlePress} className={style.button}>
        {translate(L10N.ACTION_PAY)}
      </Button>
    </View>
  );
};

FormCheckout.displayName = 'Mirai:Payments:ButtonPayment:FormCheckout';

FormCheckout.propTypes = {
  publicKey: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};
