import { useLocale } from '@mirai/locale';
import { ServiceCountry } from '@mirai/services';
import { Action, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ModalAgency } from './Booking.ModalAgency';
import { L10N } from '../Booking.l10n';
import * as style from '../Booking.module.css';
import { Field } from '../components';

const Information = ({ dataSource: { agency = {}, contact = [], id } = {}, ...others }) => {
  const { isDesktop } = useDevice();
  const { locale, translate } = useLocale();

  const [countries, setCountries] = useState({});
  const [modalAgency, setModalAgency] = useState(false);

  useEffect(() => {
    (async () => {
      setCountries((await ServiceCountry.list(locale)) || {});
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleModalAgency = () => {
    setModalAgency(true);
  };

  return (
    <>
      <View {...others} className={styles(style.box, others.className)}>
        <Text bold headline={isDesktop}>
          {translate(L10N.LABEL_BOOKING_INFO)}
        </Text>
        <View>
          <Field label={translate(L10N.LABEL_PROMO_CODE)} value={id} />
          {contact
            .map((value) => Object.entries(value).flat())
            .map(([field, value] = []) => {
              const key = L10N[`LABEL_${field.toUpperCase()}`];

              return (
                <Field
                  key={field}
                  label={key ? translate(key) : field}
                  value={field !== 'country' ? value : countries[value]}
                />
              );
            })}

          {agency.name && (
            <Field
              label={translate(L10N.LABEL_AGENCY, {})}
              value={
                <>
                  {agency.name}
                  <Action underline onPress={handleModalAgency} className={styles(style.linkAgency, style.noPrint)}>
                    {translate(L10N.ACTION_MORE_INFO)}
                  </Action>
                </>
              }
            />
          )}
        </View>
      </View>

      {agency?.name && <ModalAgency {...agency} visible={modalAgency} onClose={() => setModalAgency(false)} />}
    </>
  );
};

Information.displayName = 'Mirai:Core:Booking:Information';

Information.propTypes = {
  dataSource: PropTypes.shape({
    agency: PropTypes.shape({
      address: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
    contact: PropTypes.array,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export { Information };
