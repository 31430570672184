import { useLocale } from '@mirai/locale';
import { Button, Icon, Primitive, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Context } from './Input.Context';
import { L10N } from './Input.l10n';
import * as style from './Input.module.css';
import { ICON } from '../../../helpers';

const Input = ({ disabled = false, form = {}, value: propValue, onValue = () => {}, ...others }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const inputRef = useRef(null);

  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
    if (!propValue) return;

    inputRef.current?.focus();
    setFocus(true);
  }, [propValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyPress = ({ key } = {}) => {
    if (key === 'Enter') handlePress();
  };

  const handlePress = () => {
    setValue('');
    if (isEmpty) return;

    onValue(value);
  };

  const isEmpty = !value || value.trim().length === 0;

  return (
    <View {...others} row className={styles(style.container, focus && style.focus, others.className)}>
      <View wide>
        <Primitive
          autoFocus={isDesktop}
          name="input"
          placeholder={translate(L10N.LABEL_SEND_MESSAGE)}
          ref={inputRef}
          tag="textarea"
          value={value}
          onBlur={() => setFocus(false)}
          onChange={handleChange}
          onFocus={() => setFocus(true)}
          onKeyPress={handleKeyPress}
          className={style.input}
        />
        <Context {...form} />
      </View>

      <Button
        disabled={disabled || !value}
        squared
        tooltip={translate(L10N.ACTION_SEND)}
        top
        onPress={handlePress}
        className={style.button}
      >
        <Icon light={isEmpty} value={ICON.ARROW_UP} />
      </Button>
    </View>
  );
};

Input.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.shape({
    dates: PropTypes.arrayOf(PropTypes.string),
    occupation: PropTypes.arrayOf(PropTypes.any),
  }),
  value: PropTypes.any,
  onValue: PropTypes.func,
};

export { Input };
