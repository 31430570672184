import { translate as translateBase } from '@mirai/locale';

import { OCCUPATION_TYPE } from './constants';
import { getAccommodationLabel } from './getAccommodationLabel';
import { getOccupationLabel } from './getOccupationLabel';

const { ADULT, CHILD, GUEST } = OCCUPATION_TYPE;

export const getOccupationSummary = (rooms = [], translate = translateBase, typeAccommodation) => {
  const getCount = (room, filterType) =>
    room.filter(({ type } = {}) => type === filterType).reduce((previous, { amount = 0 } = {}) => previous + amount, 0);

  let adults = 0;
  let children = 0;
  let guests = 0;

  rooms.forEach((room) => {
    adults += getCount(room, ADULT);
    children += getCount(room, CHILD);
    guests += getCount(room, GUEST);
  });

  const amount = adults || guests;

  const captionAdults =
    amount > 0
      ? typeAccommodation
        ? getOccupationLabel({ amount, type: adults > 0 ? ADULT : GUEST }, translate)
        : amount
      : '';

  const captionChildren =
    children > 0
      ? typeAccommodation
        ? `, ${getOccupationLabel({ amount: children, type: CHILD }, translate)}`
        : ` + ${children}`
      : '';

  const captionAccommodation = typeAccommodation
    ? ` · ${rooms.length > 0 ? `${rooms.length} ` : ''}${getAccommodationLabel(
        { plural: rooms.length > 1, type: typeAccommodation },
        translate,
      )}`
    : '';

  return `${captionAdults}${captionChildren}${captionAccommodation}`;
};
