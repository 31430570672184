import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, Pressable, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { INTENT_ICON } from './Welcome.constants';
import { L10N } from './Welcome.l10n';
import * as style from './Welcome.module.css';
import { ICON } from '../../../helpers';
import { Input } from '../Input';
import { Typewriter } from '../Message/components';

const Welcome = ({ actions = [], onInput }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const { value: { session: { firstName } = {} } = {} } = useStore();

  const [prompts, setPrompts] = useState();
  const [input, setInput] = useState();

  const handlePrompt = ({ text, options = [] }) => {
    if (options.length) {
      setInput(`${text.replaceAll('*', '')} `);
      setPrompts(options);
    } else {
      onInput(text, true);
    }
  };

  // eslint-disable-next-line react/prop-types
  const Option = ({ intent, prompt, text, options } = {}) => (
    <Pressable onPress={() => handlePrompt({ text, options })} className={style.option}>
      {intent && <Icon headline level={2} value={INTENT_ICON[intent] || ICON.TUNE} />}
      <Text action={!prompt || isMobile} medium>
        {text}
      </Text>
    </Pressable>
  );

  const inputProps = { value: input, onValue: onInput };
  const titleProps = { _bold: true, brand: true, headline: true, level: 1 };

  return (
    <View wide className={style.container}>
      <View>
        <Typewriter {...titleProps} bold className={[style.title, style.hi]}>
          {`✦ ${translate(L10N.LABEL_HI)} ${firstName ? `, ${firstName}` : ''}`}
        </Typewriter>
        <Typewriter {...titleProps} className={style.title}>
          {translate(L10N.LABEL_HOW_HELP)}
        </Typewriter>
      </View>

      {!isMobile ? <Input {...inputProps} /> : null}

      <View className={style.content}>
        <View row className={styles(style.actions, !prompts && style.visible)}>
          {actions.map((item = {}, index) => (
            <Option key={`action:${index}`} {...item} />
          ))}
        </View>

        <View row className={styles(style.prompts, prompts && style.visible)}>
          {(prompts || actions)?.map((item = {}, index) => (
            <Option prompt key={`prompt:${index}`} {...item} />
          ))}
        </View>
      </View>

      {isMobile ? (
        <View className={style.input}>
          <Input {...inputProps} />
        </View>
      ) : null}
    </View>
  );
};

Welcome.displayName = 'Mirai:Core:Chat.Welcome';

Welcome.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  onInput: PropTypes.func,
};

export { Welcome };
