/* eslint-disable no-async-promise-executor */
import { LocalAdapter, request, Storage } from '@mirai/data-sources';

import { ServiceCountry } from '../Country';
import { CACHE_LIMIT, COUNTRY_STORAGE_KEY } from '../Country/constants';
import { getLanguage, parseExternalToken } from '../helpers';
import { checkAvailability } from './checkAvailability';
import { COUNTRY_ES, ERROR } from './constants';

export const checkout = async ({ currency, hotel: { id } = {}, language, session = {}, ...others }) => {
  return new Promise(async (resolve, reject) => {
    if (process.env.NODE_ENV === 'production' && id && id !== others.idtokenprovider) return reject(ERROR.NOT_EXISTS);

    try {
      await checkAvailability({ session, ...others });
    } catch (error) {
      return reject(error);
    }

    const params = Object.entries({
      ...others,
      accessClubToken: session.authorization,
      currency,
      lang: getLanguage(language),
      ...parseExternalToken(others),
    }).filter(([, value]) => value !== undefined);

    const response = await request({
      hostname: process.env.SERVICE_BOOKING,
      endpoint: `/v2/booking/checkout?${new URLSearchParams(params).toString()}`,
    }).catch((error) => {
      const { payload: { status } = {}, payload } = error;
      reject(ERROR[status] ? { ...ERROR[status], payload } : ERROR.UNKNOWN);
    });

    if (response) {
      const { data: { countryCode, form } = {}, data } = response;

      let nextForm = {};
      form.forEach((value) => (nextForm = { ...nextForm, ...value }));

      const { dni, phone, secondLastName } = nextForm;

      if (secondLastName) {
        secondLastName.visible = ({ country } = {}) => country === COUNTRY_ES;
      }
      if (dni) {
        dni.visible = ({ country } = {}) => country === COUNTRY_ES;
      }

      Object.entries(nextForm).forEach(([key, { countries = [] } = {}] = []) => {
        if (countries.length > 0) nextForm[key].visible = ({ country } = {}) => countries.includes(country);
      });

      if (phone) {
        await ServiceCountry.info();
        const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
        const countriesCodes = storage.get(`${COUNTRY_STORAGE_KEY}:info`);
        const countriesList = await ServiceCountry.list(language);

        phone.prefixes = Object.entries(countriesList).map(
          ([isoCode, country]) => `${country} ${countriesCodes[isoCode]?.code}`,
        );
        phone.value = countryCode ? countriesCodes[countryCode]?.code : undefined;
      }

      resolve({ ...data, form: nextForm });
    }
  });
};
