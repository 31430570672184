/* eslint-disable no-async-promise-executor */
import { currencyFormat } from '@mirai/locale';

import { ServiceHotel } from '../Hotel';
import { convertCurrency } from '../Pricing/helpers';
import { ERROR } from './constants';

export const get = ({
  calendar: { rangeMaxDays: propMaxNights } = {},
  currency: userCurrency,
  hotel: { id } = {},
  locale,
  occupation: { maxRooms: propMaxRooms } = {},
  offerId,
} = {}) =>
  new Promise(async (resolve, reject) => {
    if (!id || !offerId) return reject(ERROR.NOT_FILLED);

    // ! TODO: make with request when support headers in response
    const response = await fetch(`${process.env.SERVICE_DEALS}/${id}/${offerId}`).catch(reject);

    if (!response) return reject(ERROR.UNKNOWN);

    const value = await response?.json();
    const {
      dates = {},
      maxNights: rangeMaxDays = propMaxNights,
      maxRooms = propMaxRooms,
      minNights: rangeMinDays,
      minNightsWithDeals,
      minRooms = 1,
      occupation: dataSource = [],
      pricesInfo: { guests, nights, taxType, currency: defaultCurrency } = {},
    } = value;

    let country;

    if (!userCurrency) country = response?.headers.get('cloudfront-viewer-country');

    const hotelCurrencies = !userCurrency ? await ServiceHotel.getCurrencies(id) : undefined;
    const currency = userCurrency || (hotelCurrencies && hotelCurrencies[country]) || defaultCurrency;
    let captions = Object.keys(dates)
      .filter((key) => dates[key] > 0)
      .reduce((result, key) => {
        result[key] = dates[key];
        return result;
      }, {});

    if (currency && currency !== defaultCurrency) {
      captions = await convertCurrency(defaultCurrency, currency, captions);
    }

    captions = Object.entries(captions).reduce((result, [key, value]) => {
      result[key] = currencyFormat({ currency, fancy: true, locale, symbol: true, value });
      return result;
    }, {});

    resolve({
      calendar: {
        captions,
        disabledDates: [],
        highlights: Object.keys(dates),
        minNightsWithDeals,
        rangeMaxDays,
        rangeMinDays: minNightsWithDeals || rangeMinDays,
        pricesInfo: {
          guests,
          nights,
          taxType,
          currency,
        },
      },
      occupation: {
        dataSource,
        maxRooms,
        minRooms,
      },
      offerId,
    });
  });
