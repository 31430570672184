import { dateCalc, dateDiff, parseDate, UTC } from '@mirai/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { styles } from '../../helpers';
import { useDevice } from '../../hooks';
import { ScrollView, View } from '../../primitives';
import style from './Calendar.module.css';
import { Month } from './Calendar.Month';
import { Weekdays } from './Calendar.Weekdays';
import { getFirstDateOfMonth, getMonthRef, getScrollTo, getToday } from './helpers';

export const Calendar = ({
  autoScroll = false,
  disabledDates = [],
  disabledPast = true,
  format = 'YYYY/MM/DD',
  from,
  locale,
  range = false,
  rangeMaxDays,
  to,
  value,
  vertical: propVertical = false,
  onChange = () => {},
  onFocus = () => {},
  onNavigation = () => {},
  onScroll = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();

  const [behavior, setBehavior] = useState();
  const [focus, setFocus] = useState();
  const [instance, setInstance] = useState(getFirstDateOfMonth(getToday()));
  const [scrollTo, setScrollTo] = useState();
  const [scrolling, setScrolling] = useState();
  const [selected, setSelected] = useState(range ? [] : undefined);
  const [timestamp, setTimestamp] = useState();
  const [vertical, setVertical] = useState(propVertical || isMobile);

  const el = useRef();

  useEffect(() => {
    setVertical(propVertical || isMobile);
  }, [isMobile, propVertical]);

  useEffect(() => {
    if (!vertical || !el?.current || !value[0]) return;

    setBehavior('auto');
    setScrollTo(getScrollTo(dateDiff(instance, parseDate(value[0], format)).months));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el?.current, vertical]);

  useEffect(() => {
    let date = range ? (value?.length > 0 ? value[0] : undefined) : value;

    if (from && date) {
      const dateFrom = parseDate(from, format);
      const { days } = dateDiff(dateFrom, parseDate(date, format));

      if (days < 0) date = undefined;
    }
    if (!date) return setSelected(range ? [] : undefined);

    date = parseDate(date, format);
    if ((range && (selected.length === 0 || value.length === 2)) || !range) {
      const next = getFirstDateOfMonth(date);
      const { months: diffMonths } = dateDiff(instance, date);

      if (!vertical && !isMobile && (diffMonths >= months || diffMonths < 0)) setInstance(next);
      else if (vertical || isMobile) {
        autoScroll && setScrollTo(getScrollTo(diffMonths));
        setScrolling(false);
      }
    }

    setSelected(range ? [UTC(date), value[1] ? UTC(parseDate(value[1], format)) : undefined] : UTC(date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format, from, range, value]);

  useEffect(() => {
    from && (!value || value.length === 0 || !value[0]) && setInstance(getFirstDateOfMonth(parseDate(from, format)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  useEffect(() => {
    onFocus(focus);
  }, [focus, onFocus]);

  useEffect(() => setFocus(), [value]);

  const handleChange = (date) => {
    setSelected(() => {
      let next;

      if (!range) {
        next = date;
      } else if (selected[1] === undefined && date > selected[0]) {
        next = [selected[0], date];
      } else {
        next = [selected[0]?.getTime() === date.getTime() && selected[1] === undefined ? undefined : date];
        setFocus(undefined);
      }
      onChange(next);

      return next;
    });
  };

  const handleMonth = (month) => {
    setInstance(() => {
      const addMonths = range && value[0] && !value[1] ? (month > 0 ? 1 : -1) : month;
      const next = getFirstDateOfMonth(new Date(instance.getFullYear(), instance.getMonth() + addMonths));

      onNavigation(next);
      return next;
    });
  };

  const handleScroll = (event) => {
    if (vertical) setScrolling(true);

    onScroll(event);
    setBehavior();
    setTimestamp(Date.now());
  };

  const { testId, scrollEventThrottle } = others;
  let { className, months = 2, ...props } = others;

  const today = getToday();
  const instanceTS = instance.getTime();
  const todayMonthTS = getFirstDateOfMonth(today).getTime();
  const disabledPrevious =
    (disabledPast && instanceTS <= todayMonthTS) ||
    (from && instanceTS <= getFirstDateOfMonth(parseDate(from, format)).getTime());

  const disabledNext =
    to && parseDate(to, format).getTime() <= getFirstDateOfMonth(dateCalc(instance, months, 'months')).getTime();

  props = {
    ...props,
    disabledPast,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    disabledDatesTS: useMemo(() => disabledDates.map((date) => parseDate(date, format).getTime()), [disabledDates]),
    focus,
    format,
    from: from ? parseDate(from, format) : undefined,
    locale,
    range,
    role: others.role || 'calendar',
    selected,
    tag: 'calendar',
    timestamp,
    to:
      range &&
      rangeMaxDays &&
      selected[0] &&
      !selected[1] &&
      (!to || dateCalc(selected[0], rangeMaxDays, 'days') < parseDate(to, format))
        ? dateCalc(selected[0], rangeMaxDays, 'days')
        : to
        ? parseDate(to, format)
        : undefined,
    tooltips: !vertical || !scrolling ? props.tooltips : {},
    onChange: handleChange,
    onFocus: !isMobile ? setFocus : undefined,
  };

  if (vertical && to) months = dateDiff(today, parseDate(to, format)).months + 1;

  return (
    <View
      role={others.role}
      tag="calendar"
      className={styles(style.calendar, vertical && style.vertical, className)}
      testId={testId}
    >
      {vertical && <Weekdays locale={locale} vertical={vertical} />}
      <ScrollView
        behavior={behavior}
        horizontal={!vertical}
        scrollTo={scrollTo}
        className={style.scrollview}
        scrollEventThrottle={scrollEventThrottle}
        onScroll={handleScroll}
      >
        {Array.from({ length: months }, (_, index) => (
          <Month
            instance={new Date(instance.getFullYear(), instance.getMonth() + index, 1)} // ! TODO: calc with mirai/locale
            key={index}
            ref={vertical && range ? getMonthRef({ autoScroll, el, index, instance, value }) : undefined}
            vertical={vertical}
            onNext={!vertical && index === months - 1 && !disabledNext ? () => handleMonth(months) : undefined}
            onPrevious={!vertical && index === 0 && !disabledPrevious ? () => handleMonth(-months) : undefined}
            {...props}
            testId={testId}
          />
        ))}
      </ScrollView>
    </View>
  );
};

Calendar.displayName = 'Component:Calendar';

Calendar.propTypes = {
  autoScroll: PropTypes.bool,
  captions: PropTypes.shape({}),
  disabledDates: PropTypes.arrayOf(PropTypes.string),
  disabledPast: PropTypes.bool,
  format: PropTypes.string,
  from: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string,
  months: PropTypes.number,
  range: PropTypes.bool,
  rangeMaxDays: PropTypes.number,
  rangeMinDays: PropTypes.number,
  to: PropTypes.string,
  tooltips: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  vertical: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onNavigation: PropTypes.func,
  onScroll: PropTypes.func,
};
