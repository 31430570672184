import { parseCustomData, parseUserData, sendData } from './helpers';
import { TYPE } from '../../../../Core.constants';
import { formatPrice } from '../helpers/legacy';

export const bookingResponse = async ({
  instances = [],
  response: { tracking = {} } = {},
  store: { hotel: { id: hotelId } = {}, type } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { nights = 0 } = {},
    guestData = {},
    hotelInfo: { currency: hotelCurrency = {}, propertyName } = {},
    idReservation,
    pendingPayment,
    reservedRooms = [],
    totalPriceWithTaxes,
  } = tracking;

  let contents = [];
  let numItems = 0;
  reservedRooms.forEach(({ averagePriceWithoutTaxes = 0, numRoomsToReserve = 1, rateId } = {}) => {
    const quantity = nights * numRoomsToReserve;

    contents.push({ id: rateId, itemPrice: formatPrice(averagePriceWithoutTaxes, hotelCurrency), quantity });
    numItems += quantity;
  });

  const eventName = pendingPayment ? 'BTP' : 'Purchase';
  const commonData = {
    userData: parseUserData(guestData),
    customData: parseCustomData({
      contents,
      hotelCurrency,
      numItems,
      orderId: idReservation,
      propertyName,
      value: formatPrice(totalPriceWithTaxes, hotelCurrency),
    }),
  };

  const events = [eventName, ...(type == TYPE.CHAIN ? [`${eventName}_${hotelId}`] : [])];

  const responses = await Promise.all(
    events.flatMap((eventName) =>
      instances.map(({ idTracking, pixelId }) => sendData({ ...commonData, eventName, idTracking, pixelId })),
    ),
  ).catch(() => {});

  return responses;
};
