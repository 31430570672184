import { IS_NODE } from '../../modules';
import { CookieAdapter as CookieAdapterBase } from './cookie';
import { DomainAdapter as DomainAdapterBase } from './domain';
import { LocalAdapter as LocalAdapterBase } from './local';
import { MemoryAdapter } from './memory';
import { SessionAdapter as SessionAdapterBase } from './session';

export const CookieAdapter = IS_NODE ? MemoryAdapter : CookieAdapterBase;
export const DomainAdapter = IS_NODE ? MemoryAdapter : DomainAdapterBase;
export const LocalAdapter = IS_NODE ? MemoryAdapter : LocalAdapterBase;
export { MemoryAdapter };
export const SessionAdapter = IS_NODE ? MemoryAdapter : SessionAdapterBase;
