import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Text, View } from '../../primitives';
import { DAYS } from './Calendar.constants';
import style from './Calendar.module.css';
import { getWeekDays } from './helpers';

export const Weekdays = ({ locale, vertical }) => {
  const weekdays = getWeekDays(locale);

  return (
    <View forceRow={!vertical} row={vertical} tag="calendar-weekdays" className={style.weekdays}>
      {DAYS.map((day) => (
        <Text key={day} small className={styles(style.cell, style.weekday)}>
          {weekdays[day]}
        </Text>
      ))}
    </View>
  );
};

Weekdays.displayName = 'Component:Calendar:Weekdays';

Weekdays.propTypes = {
  locale: PropTypes.string,
  vertical: PropTypes.bool,
};
