const ACTION = {
  CANCEL: 1,
  EDIT: 2,
  RESEND: 3,
  PRINT: 4,
};

const ACTION_LABEL = {
  [ACTION.CANCEL]: 'CANCEL',
  [ACTION.EDIT]: 'EDIT',
  [ACTION.RESEND]: 'RESEND',
  [ACTION.PRINT]: 'PRINT',
};

const CANCEL_OPTION = {
  NOT_TRAVEL: 1,
  ANOTHER_HOTEL: 2,
  CHEAPER_PRICE: 3,
  BOOKING_CHANGE: 4,
  OTHERS: 5,
};

const FORMAT_CURRENCY = { currency: 'EUR' };

const FORMAT_DATE = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

const FORMAT_PAY_LATER_DATE = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  weekday: 'short',
  hour: '2-digit',
  minute: '2-digit',
};

const ERROR = {
  GENERIC: 1,
  ANONIMIZED: 7,
};

const STATE = {
  CONFIRMED: 1,
  CANCELLED: 2,
  POSTPONED: 3,
  COMPLETED: 4,
  POS_ERROR: 5,
};

const TRACKING = 'BOOKING';

export {
  ACTION,
  ACTION_LABEL,
  CANCEL_OPTION,
  ERROR,
  FORMAT_CURRENCY,
  FORMAT_DATE,
  FORMAT_PAY_LATER_DATE,
  STATE,
  TRACKING,
};
