import { Booking } from './Booking';
import { BookingQuery } from './BookingQuery';
import { ButtonFinder } from './ButtonFinder';
import { Chat } from './Chat';
import { Checkout } from './Checkout';
import { Deals } from './Deals';
import { Environment } from './Environment';
import { Finder } from './Finder';
import { Header } from './Header';
import { Profile } from './Profile';
import { Rates } from './Rates';
import { Rooms } from './Rooms';
import { Session } from './Session';
import { Signup } from './Signup';

export const COMPONENTS = {
  booking: Booking,
  bookingQuery: BookingQuery,
  buttonFinder: ButtonFinder,
  chat: Chat,
  checkout: Checkout,
  deals: Deals,
  environment: Environment,
  finder: Finder,
  header: Header,
  profile: Profile,
  rates: Rates,
  rooms: Rooms,
  session: Session,
  signup: Signup,
};
