import { currencyFormat, useLocale } from '@mirai/locale';
import { Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './PriceBreakdown.module.css';

const Line = ({ bold, currency, name, value, ...others }) => {
  const { locale } = useLocale();

  const props = bold ? { bold: true } : { action: true };

  return (
    <View {...others} row>
      <Text {...props} wide>
        {name}
      </Text>
      <Text {...props} className={style.currency}>
        {currencyFormat({ currency, locale, value })}
      </Text>
    </View>
  );
};

Line.displayName = 'Mirai:User:Checkout.Summary.Line';

Line.propTypes = {
  bold: PropTypes.bool,
  currency: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
};

export { Line };
