import { UTC } from '@mirai/locale';

const DAY_MS = 24 * 60 * 60 * 1000;
const WEEK_MS = 7 * DAY_MS;

export const getFirstDateOfWeek = (week, year, firstDayOfWeek = 1) => {
  const date = UTC(new Date(year, 0, 1, 0));
  const offsetTimeStart = DAY_MS * (date.getDay() - (firstDayOfWeek === 6 ? -1 : firstDayOfWeek));

  return UTC(new Date(date.getTime() + WEEK_MS * (week - 1) - offsetTimeStart));
};
