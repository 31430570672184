import { Event, useStore } from '@mirai/data-sources';
import { parseDate, useLocale } from '@mirai/locale';
import { styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FORMAT_DATE } from './BookingTable.constants';
import { L10N } from './BookingTable.l10n';
import * as style from './BookingTable.module.css';
import { Row } from './BookingTable.Row';
import { EVENT } from '../../helpers';
import { ButtonMore } from '../ButtonMore';

const UNEXPANDED_ITEMS = 3;

const BookingTable = ({ currency, expanded = true, dataSource = [], label, showTotal = false, ...others }) => {
  const { isDesktop } = useDevice();
  const { dateFormat, translate } = useLocale();
  const {
    value: { components = {} },
  } = useStore();

  const [expand, setExpand] = useState(dataSource.length <= UNEXPANDED_ITEMS);

  useEffect(() => setExpand(dataSource.length <= UNEXPANDED_ITEMS), [dataSource]);

  const handlePress = () => {
    setExpand(true);
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:TABLE:VIEW_MORE` });
  };

  const metrics = components.checkout ? 'CHECKOUT' : components.booking ? 'BOOKING' : undefined;
  const rowTotal = showTotal
    ? {
        bold: true,
        price: dataSource.reduce((total, { price = 0 }) => total + price, 0),
        summary: ' ',
        title: translate(L10N.LABEL_TOTAL),
      }
    : undefined;

  return dataSource.length > 0 ? (
    <View {...others} className={styles(style.table, !expand && style.collapsed, others.className)}>
      {label && (
        <Text headline={isDesktop} bold>
          {label}
        </Text>
      )}
      {[...dataSource, rowTotal]
        .filter((item) => !!item)
        .slice(0, expanded || expand ? dataSource.length + (showTotal ? 1 : 0) : UNEXPANDED_ITEMS)
        .map(({ date, title = '', ...item } = {}, index) => (
          <Row {...{ ...item, currency }} title={date ? dateFormat(parseDate(date), FORMAT_DATE) : title} key={index}>
            {!expanded && !expand && index === UNEXPANDED_ITEMS - 1 && (
              <ButtonMore onPress={handlePress} className={style.buttonMore} />
            )}
          </Row>
        ))}
    </View>
  ) : null;
};

BookingTable.displayName = 'Mirai:Core:BookingTable';

BookingTable.propTypes = {
  currency: PropTypes.string,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  expanded: PropTypes.bool,
  label: PropTypes.string,
  showTotal: PropTypes.bool,
};

export { BookingTable };
