import { CookieAdapter, getCookie, Storage } from '@mirai/data-sources';

import { COOKIE } from '../Core.constants';

export const fetchCookies = (cookies = []) => {
  if (!Array.isArray(cookies)) return {};

  let values = {};
  const storage = new Storage({ adapter: CookieAdapter });
  cookies.forEach((cookie) => {
    if (cookie === COOKIE.PESTANA) values = { ...values, pestana: getCookie(cookie) };
    else values = { ...values, ...storage.get(cookie) };
  });

  return values;
};
