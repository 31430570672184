import { ICON } from '../../../helpers';
import { INTENT } from '../../Chat.constants';

const INTENT_ICON = {
  [INTENT.BOOKING_CREATE]: ICON.CALENDAR,
  [INTENT.BOOKING_QUERY]: ICON.LIST,
  [INTENT.BOOKING_CANCEL]: ICON.CALENDAR_CANCEL,
  [INTENT.HOTEL_INFO]: ICON.HOTEL,
  [INTENT.OFFERS]: ICON.OFFER,
  [INTENT.LOGIN]: ICON.ACCOUNT,
};

export { INTENT_ICON };
