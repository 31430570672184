const VALID_PROPS = ['crossOrigin', 'integrity'];

export const loadScript = ({ async = true, domain = 'mirai', id, inline, src, onLoad = () => {}, ...others } = {}) =>
  new Promise((resolve, reject) => {
    const scriptId = `${domain}-${id}`;
    const exists = document.getElementById(scriptId);

    if ((!inline && !src) || exists) return resolve();

    const script = document.createElement('script');
    script.id = scriptId;
    script.async = async;
    Object.keys(others || {})
      .filter((key) => VALID_PROPS.includes(key))
      .forEach((key) => (script[key] = others[key]));

    if (src) script.src = src;
    else if (inline) script.textContent = inline;

    script.onerror = (error) => reject(error);

    script.onload = () => {
      if (src && inline) {
        const inlineScript = document.createElement('script');
        inlineScript.textContent = inline;
        document.head.appendChild(inlineScript);
      }

      onLoad();
      // eslint-disable-next-line no-console
      if (src) console.log(`📜 <script ${scriptId} ${src ? `src=${src} ` : ''}/>`);

      resolve(() => {
        const el = document.getElementById(scriptId);
        if (el) el.parentNode.removeChild(el);
      });
    };

    document.head.appendChild(script);
  });
