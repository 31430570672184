import { decypher } from './cypher';

export const decrypt = (value = '', secret) => {
  if (!secret) return value;

  try {
    return JSON.parse(decypher(value, secret));
  } catch (e) {
    throw Error(`Hash ${value} can't be decrypted.`);
  }
};
