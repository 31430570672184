/* eslint-disable no-async-promise-executor */
import { LocalAdapter, Storage, request } from '@mirai/data-sources';

import { ServiceCountry } from '../Country';
import { CACHE_LIMIT, ERROR, EXTERNAL_SERVICE, USER_STORAGE_KEY } from './User.constants';

export const externalLogin = (token, type) =>
  new Promise(async (resolve, reject) => {
    if (!token || !type) return reject(ERROR.NOT_FILLED);

    const response = await request({
      hostname: process.env.SERVICE_USER,
      endpoint: `/club/v1/${EXTERNAL_SERVICE[type]}/session?token=${token}`,
    }).catch(reject);

    if (response?.accessToken) {
      const { phone } = response;
      let nextPhone = phone;
      if (phone && !phone.startsWith('+')) {
        const country = (
          await request({
            hostname: process.env.SERVICE_CURRENCY,
            endpoint: '/ip/get',
            headers: { 'X-Requested-With': undefined, Authorization: 'Basic YW5vbmltb3VzOmFub25pbW91cw==' },
          })
        )?.country;

        const { code } = (await ServiceCountry.info(country)) || {};
        if (code) nextPhone = `${code}${phone}`;
      }

      const session = {
        address: response.address,
        authorization: response.accessToken,
        club: { id: response.idClub, name: response.clubName },
        country: response.country,
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        level: response.level,
        phone: nextPhone,
        zipcode: response.zipcode,
      };

      const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
      storage.set(`${USER_STORAGE_KEY}:session`, session);

      resolve(session);
    } else {
      reject(ERROR.UNKNOWN);
    }
  });
