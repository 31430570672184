const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));

const cypher = (text, salt) =>
  textToChars(text)
    .map((code) => textToChars(salt).reduce((a, b) => a ^ b, code))
    .map((n) => ('0' + Number(n).toString(16)).substr(-2))
    .join('');

const decypher = (text, salt) =>
  text
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map((code) => textToChars(salt).reduce((a, b) => a ^ b, code))
    .map((charCode) => String.fromCharCode(charCode))
    .join('');

export { cypher, decypher };
