import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Icon, ScrollView, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { L10N } from './Rates.l10n';
import * as style from './Rates.module.css';
import { EVENT, ICON } from '../helpers';

const RoomSelector = ({ cart = [], occupation = [], room: roomIndex, onPress = () => {}, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const itemsRef = useRef([]);
  const {
    value: { finder = {}, hotel: { accommodationType: hotelAccommodationType, variants = {} } = {} },
  } = useStore();

  const [scrollTo, setScrollTo] = useState();

  useEffect(() => {
    setScrollTo(0);
  }, []);

  useEffect(() => {
    const next = roomIndex === 0 ? 0 : itemsRef.current[roomIndex]?.offsetLeft;
    if (next) setScrollTo(next - (isMobile ? 8 : 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomIndex]);

  const handlePress = (index) => {
    onPress(index);
    Event.publish(EVENT.METRICS, { id: 'RATES:ROOM', index });
  };

  const { place: { id: [id] = [], isHotel = true } = {} } = finder;
  const { accommodationType = hotelAccommodationType } = variants[id] || {};
  const itemsWidth =
    itemsRef?.current?.reduce((total, current) => total + current?.offsetWidth || 0, 0) + (8 * occupation.length - 1);
  const containerWidth = itemsRef?.current?.[0]?.parentElement?.offsetWidth || 0;

  return isHotel && occupation.length >= 2 ? (
    <ScrollView
      {...others}
      defaultScrollIndicator
      horizontal
      scrollIndicator={!isMobile && itemsWidth > containerWidth}
      scrollTo={scrollTo}
      snap={false}
      className={styles(style.roomSelector, others.className)}
    >
      {occupation.map((items = [], index) => {
        const guests = items.reduce((total, { amount } = {}) => total + amount, 0);
        const hasCart = index <= roomIndex || cart[index] !== undefined || cart[index - 1] !== undefined;

        return (
          <View key={index} ref={(el) => (itemsRef.current[index] = el)}>
            <Button disabled={!hasCart} secondary={hasCart && index !== roomIndex} onPress={() => handlePress(index)}>
              {`${index === roomIndex && !cart[index] ? `${translate(L10N.LABEL_SELECT)}` : ''} ${translate(
                L10N.LABEL_ACCOMMODATION_TYPE,
                { type: accommodationType },
              )} ${index + 1}`.trim()}

              <Text
                headline
                className={[
                  style.separator,
                  index === roomIndex ? style.active : hasCart ? style.secondary : style.disabled,
                ]}
              >
                |
              </Text>

              <Icon
                headline
                level={2}
                value={guests === 1 || guests > 3 ? ICON.PERSON : guests === 2 ? ICON.GROUP : ICON.GROUPS}
              />
              {guests}
            </Button>
          </View>
        );
      })}
    </ScrollView>
  ) : null;
};

RoomSelector.displayName = 'Mirai:Core:Rates:RoomSelector';

RoomSelector.propTypes = {
  cart: PropTypes.arrayOf(PropTypes.shape({})),
  occupation: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ages: PropTypes.arrayOf(PropTypes.number),
        amount: PropTypes.number,
        type: PropTypes.number,
      }),
    ),
  ),
  room: PropTypes.number,
  onPress: PropTypes.func,
};

export { RoomSelector };
