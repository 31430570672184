import { dateDiff, parseDate, UTC } from '@mirai/locale';

import { L10N } from '../Bookings.l10n';

export const groupDatasource = (dataSource = [], search) => {
  if (!Array.isArray(dataSource)) return;

  const today = UTC(new Date());
  const value = {};

  dataSource
    .filter((item) => (search ? JSON.stringify(item).toLowerCase().includes(search.toLowerCase()) : true))
    .forEach((item) => {
      const { days } = dateDiff(today, parseDate(item.checkIn));
      const key = days >= 0 ? L10N.LABEL_NEXT_BOOKINGS.id : L10N.LABEL_PAST_BOOKINGS.id;

      value[key] = [...(value[key] || []), item];
    });

  return Object.keys(value).length ? value : undefined;
};
