import { useLocale } from '@mirai/locale';
import { Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { L10N } from './FormAddon.l10n';
import * as style from './FormAddon.module.css';
import { getCtaKey } from './helpers';
import { loadScript } from '../../helpers';

const BUTTON = 'addonInitButton';
const IFRAME = 'addonIframe';

export const FormAddon = ({ info: { date } = {}, method, provider, response, ...others }) => {
  const { translate } = useLocale();

  const el = useRef();

  const [ready, setReady] = useState();

  useEffect(() => loadScript({ id: 'addonScript', name: 'rxp-hpp.js', onLoad: handleLoad }), []);

  useEffect(() => {
    if (!response || !ready) return;

    window.RealexHpp?.embedded.init(BUTTON, IFRAME, response.MERCHANT_RESPONSE_URL, {
      ...response,
      CARD_PAYMENT_BUTTON: translate(getCtaKey(date, method, provider)),
    });
    el.current?.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, response]);

  const handleLoad = () => {
    window.RealexHpp?.setHppUrl(process.env.PROVIDER_ADDON_SCRIPT);
    setReady(true);
  };

  return (
    <View {...others}>
      <Text action className={style.description}>
        {translate(L10N.LABEL_SECURE_PAGE)}
      </Text>

      <input hidden id={BUTTON} ref={el} type="submit" />
      <iframe id={IFRAME} className={style.iframe} />
    </View>
  );
};

FormAddon.displayName = 'Mirai:Payments:ButtonPayment:FormAddon';

FormAddon.propTypes = {
  info: PropTypes.shape({
    cardValidation: PropTypes.bool,
    date: PropTypes.instanceOf(Date),
    amount: PropTypes.number,
  }),
  method: PropTypes.string,
  provider: PropTypes.string,
  response: PropTypes.object,
};
