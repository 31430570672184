import { bookingResponse } from './bookingResponse';
import { checkoutResponse } from './checkoutResponse';
import { ratesAddToCart } from './ratesAddToCart';
import { ratesResponse } from './ratesResponse';

export const ProviderFacebook = {
  bookingResponse,
  checkoutResponse,
  ratesAddToCart,
  ratesResponse,
};
