import { parseCommon } from './helpers';

export const finderReady = ({
  device: { mobile } = {},
  instances = [],
  step = 'BOOKINGENTRANCE',
  store: { finder = {}, id, type } = {},
} = {}) => {
  const value = {
    ...parseCommon({ ...finder, id, type }),
    device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
    non_interaction: true,
    step,
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
  };

  window.gtag('event', 'data_search_info', value);

  return value;
};
