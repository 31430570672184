import { LocalAdapter, Storage } from '@mirai/data-sources';

import { FINDER_STORAGE_KEY } from '../../../Core.constants';

const storage = new Storage({ adapter: LocalAdapter });

export const setStorage = (value, id) => {
  if (!value || typeof value !== 'object' || Array.isArray(value)) return;

  storage.set(`${FINDER_STORAGE_KEY}:${id}`, value);

  return storage.get(`${FINDER_STORAGE_KEY}:${id}`);
};
