import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Icon, Pressable, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './ButtonDispatcher.l10n';
import * as style from './ButtonDispatcher.module.css';
import { determinePosition } from './helpers';
import { EVENT, ICON } from '../../../helpers';
import { DEFAULT_NAME } from '../../Chat.constants';
import { Avatar } from '../Avatar';

const DELAY_WELCOME = 10000;
let focused = false;

const ButtonDispatcher = ({
  config: { image, name = DEFAULT_NAME, ...config } = {},
  ready = false,
  visible,
  onPress,
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: { session: { firstName } = {} },
  } = useStore();

  const [focus, setFocus] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const callback = ({ visible: modalVisible }) => {
      setHide(modalVisible);
    };

    Event.subscribe(EVENT.MODAL_VISIBLE, callback);
    return () => Event.unsubscribe(EVENT.MODAL_VISIBLE, callback);
  }, []);

  useEffect(() => {
    if (!ready || focused) return;
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      if (focused) return;
      inactivityTimer = setTimeout(() => {
        setFocus(true);
        clearTimeout(inactivityTimer);
      }, DELAY_WELCOME);
    };

    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('scroll', resetTimer);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer.current);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('scroll', resetTimer);
    };
  }, [ready]);

  useEffect(() => {
    if (focus) focused = true;
  }, [focus]);

  const position = determinePosition(config, { isMobile });

  return (
    <View
      className={styles(style.buttonDispatcher, ready && !visible && !hide && style.visible, ...position.className)}
      style={position.style}
    >
      <View row className={styles(style.welcome, focus && !visible && style.visible)}>
        <View>
          <Text bold brand headline className={style.hi}>
            {`✦ ${translate(L10N.LABEL_HI)}${firstName ? `, ${firstName}` : ''}`}
          </Text>
          <Text markdown={false} medium>
            {translate(L10N.LABEL_GREETINGS, { name })}
          </Text>
        </View>
        {isMobile && (
          <Button icon="close" small squared transparent onPress={() => setFocus(false)}>
            <Icon value={ICON.CLOSE} />
          </Button>
        )}
      </View>

      <Pressable onEnter={() => setFocus(true)} onLeave={() => setFocus(false)} onPress={onPress}>
        <Avatar disabled={visible} image={image} className={style.avatar} />
      </Pressable>
    </View>
  );
};

ButtonDispatcher.displayName = 'Mirai:Core:Chat:ButtonDispatcher';

ButtonDispatcher.propTypes = {
  config: PropTypes.shape({
    bottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    image: PropTypes.string,
    left: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    name: PropTypes.string,
    right: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    top: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }),
  ready: PropTypes.bool,
  visible: PropTypes.bool,
  onPress: PropTypes.func,
};

export { ButtonDispatcher };
