import { LocalAdapter, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT, USER_STORAGE_KEY } from './User.constants';

export const logout = () => {
  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${USER_STORAGE_KEY}:session`;
  storage.remove(key);

  return storage.get(key) === undefined;
};
