import { request } from '@mirai/data-sources';

import { ERROR, VALIDATE_ERROR } from './constants';

export const checkAvailability = ({ session = {}, ...others } = {}) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const params = Object.entries({
      accessClubToken: session?.authorization,
      applyClubDiscount: others.applyClubDiscount,
      checkin: others.checkin,
      clientCode: others.clientCode,
      device: others.device,
      extras: others.extras,
      from: others.from,
      googlecrawler: others.googlecrawler,
      home: others.home,
      hotelDomain: others.hotelDomain,
      hsri: others.hsri,
      idtokenprovider: others.idtokenprovider,
      lang: others.lang,
      nights: others.nights,
      offersAppliedBySelectedRoom: others.offersAppliedBySelectedRoom,
      roomNumberBySelectedRoom: others.roomNumberBySelectedRoom,
      rooms: others.rooms,
      securepage: others.securepage,
      serverNameForSSL: others.serverNameForSSL,
      totalPrice: others.totalPrice,
    }).filter(([, value]) => value !== undefined);

    const response = await request({
      endpoint: `/v2/validate/booking?${new URLSearchParams(params).toString()}`,
      hostname: process.env.SERVICE_BOOKING,
    }).catch(({ payload: { data: { cause, price, currency } = {} } = {} } = {}) =>
      reject({ currency, price, type: VALIDATE_ERROR[cause] }),
    );

    if (!response) return reject(ERROR.UNKNOWN);

    resolve({});
  });
