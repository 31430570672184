import { useLocale } from '@mirai/locale';
import { InputText } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Tpv.l10n';

const Tpv = ({ requiredText, showErrors = false, onChange = () => {}, onError = () => {}, ...others }) => {
  const { translate } = useLocale();

  const [error, setError] = useState({});
  const [value, setValue] = useState();

  useEffect(() => onError(Object.keys(error || {}).length > 0 ? { name: { ...error } } : error), [error, onError]);

  const handleChange = (next, event) => {
    setValue(next);
    onChange({ name: next }, event);
  };

  const handleError = (next = {}) => {
    if (JSON.stringify(next) !== JSON.stringify(error)) setError(next);
  };

  return (
    <InputText
      {...others}
      error={value?.length === 0 || (showErrors && Object.keys(error).length)}
      id="checkout-name"
      label={translate(L10N.LABEL_CARD_NAME)}
      maxLength={48}
      name="name"
      required
      requiredText={requiredText}
      showRequired
      success={value?.length > 0 && !Object.keys(error).length}
      value={value}
      onChange={handleChange}
      onError={handleError}
    />
  );
};

Tpv.displayName = 'Mirai:Payments:Tpv';

Tpv.propTypes = {
  requiredText: PropTypes.string,
  showErrors: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export { Tpv };
