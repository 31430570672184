/* eslint-disable no-async-promise-executor */
import { LocalAdapter, Storage, request } from '@mirai/data-sources';

import { getSession } from './helpers';
import { CACHE_LIMIT, ERROR, USER_STORAGE_KEY } from './User.constants';

const VALID = 'VALID';

export const check = ({ idHotel, session = getSession() } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!idHotel) return reject(ERROR.NOT_FILLED);

    if (!session) return reject(ERROR.NOT_SESSION);

    const response = await request({
      hostname: process.env.SERVICE_BOOKING,
      endpoint: `/get_obj_club_login?accessClubToken=${session.authorization}&idHotel=${idHotel}`,
      //TODO: back have to change the endpoint for can remove this headers
      headers: { 'X-Requested-With': undefined, 'Content-Type': undefined },
    }).catch(reject);

    if (!response) return reject(ERROR.UNKNOWN);

    if (response.status !== VALID) {
      const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
      storage.remove(`${USER_STORAGE_KEY}:session`);

      return resolve(false);
    }
    resolve(true);
  });
