import { Event, useStore } from '@mirai/data-sources';
import { useEffect } from 'react';

import { EVENT } from './EventManager.constants';
import { EVENT as COMPONENTS_EVENT } from '../../components/helpers';

const EventManager = () => {
  const { value: { finder = {}, origin = {}, tags = {} } = {}, set } = useStore();

  useEffect(() => {
    Event.subscribe(EVENT.SET_STORE, handleSetStore);

    return () => Event.unsubscribe(EVENT.SET_STORE, handleSetStore);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finder, origin]);

  useEffect(() => {
    if (!finder) return;

    Event.subscribe(COMPONENTS_EVENT.FINDER_FIELD_CHANGE, handleFinderFieldChange);

    Event.publish(EVENT.FINDER_LOADED, finder);

    return () => Event.unsubscribe(COMPONENTS_EVENT.FINDER_FIELD_CHANGE, handleFinderFieldChange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinderFieldChange = (value) => Event.publish(EVENT.FINDER_CHANGE, value);

  const handleSetStore = ({
    chainSelectorValue: placeId,
    calendar,
    hsri: commissionCode,
    occupation,
    promocode,
  } = {}) => {
    let next = {};

    if (commissionCode) next.origin = { commissionCode };
    if (calendar?.length && calendar?.length === 2) next.finder = { calendar };
    if (occupation?.length) next.finder = { ...next.finder, occupation };
    if (promocode !== undefined && promocode !== null) next.finder = { ...next.finder, promocode };
    if (placeId) next.finder = { ...next.finder, place: tags?.options?.find(({ value } = {}) => value === placeId) };

    set(next);
  };

  return null;
};

export { EventManager };
