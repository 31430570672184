import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { View } from '../../primitives';
import style from './Progress.module.css';

const Progress = ({ indeterminate = false, value = 0, visible = false, ...others }) => (
  <View {...others} tag="progress" className={styles(style.progress, visible && style.visible, others.className)}>
    <View
      tag="progress-value"
      className={styles(style.value, indeterminate && style.indeterminate)}
      style={!indeterminate ? { width: `${value}%` } : undefined}
    />
  </View>
);

Progress.displayName = 'Component:Progress';

Progress.propTypes = {
  indeterminate: PropTypes.bool,
  value: PropTypes.number,
  visible: PropTypes.bool,
};

export { Progress };
