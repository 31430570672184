import { OCCUPATION_TYPE } from '../../../helpers';

const { ADULT, CHILD, GUEST } = OCCUPATION_TYPE;

export const getOccupation = (propGuests) => {
  const {
    adults = 0,
    children = 0,
    guests = 0,
  } = Array.isArray(propGuests)
    ? {
        adults: propGuests.reduce((total, { amount = 0, type } = {}) => total + (type === ADULT ? amount : 0), 0),
        children: propGuests
          .flat()
          .reduce((total, { amount = 0, type } = {}) => total + (type === CHILD ? amount : 0), 0),
        guests: propGuests.reduce((total, { amount = 0, type } = {}) => total + (type === GUEST ? amount : 0), 0),
      }
    : { adults: propGuests ?? 0 };

  return { adults, children, guests };
};
