import { useLocale } from '@mirai/locale';
import { Text } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Countdown.l10n';

const Countdown = ({ l10n = L10N.LABEL_COUNTDOWN, timestamp, ...others }) => {
  const { translate } = useLocale();

  const [value, setValue] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue(calcTime()), []);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(calcTime());
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  const calcTime = () => {
    if (!timestamp) return;

    const currentTime = new Date().getTime();
    const timeDifference = timestamp > currentTime ? timestamp - currentTime : 0;

    return {
      days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeDifference % (1000 * 60)) / 1000),
    };
  };

  const { days, hours, minutes, seconds } = value || {};

  return value ? (
    <Text {...others}>
      {translate(l10n, { date: translate(L10N.LABEL_COUNTDOWN, { days, hours, minutes, seconds }) })}
    </Text>
  ) : null;
};

Countdown.displayName = 'Mirai:Core:Countdown';

Countdown.propTypes = {
  l10n: PropTypes.string,
  timestamp: PropTypes.number,
};

export { Countdown };
