import { formatPrice } from '../helpers/legacy';

export const bookingResponse = ({
  instances: [{ idAffilired } = {}] = [],
  response: { tracking = {} } = {},
  store: { hotel: { id: hotelId } = {} } = {},
} = {}) => {
  if (!window.recV3) return {};

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { checkout } = {},
    hotelInfo: { currency: hotelCurrency = {}, propertyName } = {},
    idReservation,
    totalPriceWithoutTaxes,
  } = tracking;

  const htname = '';
  const pixel = 0;
  const payoutCodes = '';
  const offlineCode = '';

  window.recV3(
    formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    `${idReservation} ${propertyName}`,
    idAffilired,
    hotelId,
    htname,
    pixel,
    payoutCodes,
    offlineCode,
    checkout,
    hotelCurrency.currency,
  );

  return {};
};
