import { useLocale } from '@mirai/locale';
import { Action, Button, InputText, Menu, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { L10N } from './Bookings.l10n';
import * as style from './Bookings.module.css';

const Search = ({ value: propValue = '', onChange = () => {} }) => {
  const { translate } = useLocale();
  const ref = useRef(null);

  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (!ref.current?.contains(target) && !!visible) setVisible(undefined);
    };
    document.addEventListener('click', handleClickOutside, true);

    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [visible]);

  const handleChange = (next, event) => {
    setValue(next);
    if (!next) {
      onChange(next, event);
    }
  };

  const handlePress = (event) => {
    setVisible(false);
    onChange(value, event);
  };

  return (
    <Menu
      options={[
        {
          children: (
            <View ref={ref} className={style.form}>
              <InputText
                name="search"
                type="search"
                placeholder={translate(L10N.LABEL_SEARCH_PLACEHOLDER)}
                value={value}
                onChange={handleChange}
                className={style.input}
              />
              <Button disabled={!value} wide onPress={handlePress} className={style.button} testId="button-search">
                {translate(L10N.ACTION_SEARCH_CTA)}
              </Button>
              <Text bold small>
                {translate(L10N.LABEL_RESERVATION_CODE)}
              </Text>
              <Text small>{translate(L10N.LABEL_RESERVATION_CODE_DESCRIPTION)}</Text>
            </View>
          ),
        },
      ]}
      visible={visible}
      onPress={() => setVisible(false)}
      className={style.search}
      testId="menu-search-bookings"
    >
      <Action onPress={() => setVisible(true)} className={style.actionSearch} testId="show-search">
        {translate(L10N.ACTION_SEARCH)}
      </Action>
    </Menu>
  );
};

Search.displayName = 'Mirai:Core:Profile:Bookings:Search';

Search.propTypes = {
  value: PropTypes.func,
  onChange: PropTypes.func,
};

export { Search };
