import { useLocale } from '@mirai/locale';
import { Modal, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from '../Booking.l10n';
import * as style from '../Booking.module.css';
import { Field } from '../components';

const ModalAgency = ({ address, city, email, id, name, phone, province, ...others }) => {
  const { translate } = useLocale();

  return (
    <Modal {...others} fit title={translate(L10N.LABEL_AGENCY)}>
      <View className={style.modal}>
        <Field label={translate(L10N.LABEL_NAME)} value={name} />
        <Field label={translate(L10N.LABEL_COMPANY_ID)} value={id} />
        <Field
          label={translate(L10N.LABEL_ADDRESS)}
          value={`${address}${city ? `, ${city}` : ''}${province ? `, ${province}` : ''}`}
        />
        <Field label={translate(L10N.LABEL_PHONE)} value={phone} />
        <Field label={translate(L10N.LABEL_EMAIL)} value={email} />
      </View>
    </Modal>
  );
};

ModalAgency.displayName = 'Mirai:Core:Booking:ModalAgency';

ModalAgency.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  province: PropTypes.string,
  onClose: PropTypes.func,
};

export { ModalAgency };
