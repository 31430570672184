const MATCH_SAFARI = /^((?!chrome|android).)*safari/i;
const MATCH_MOBILE =
  /(Mobile|Tablet|iPad|iPod|iPhone|Android|BlackBerry|IEMobile|Silk|Kindle|KF[A-Z]{2,4}|Samsung|Nexus|Nook|webOS|PlayBook)/i;

export const getNavigator = () => {
  const { userAgent = '' } = typeof navigator !== 'undefined' ? navigator : {};

  const ua = userAgent.toLowerCase();
  const safari = Boolean(ua.match(MATCH_SAFARI));

  return {
    userAgent,
    // browsers
    chrome: !safari && Boolean(ua.indexOf('chrome') !== -1),
    firefox: Boolean(ua.indexOf('firefox') !== -1),
    mobile: Boolean(ua.match(MATCH_MOBILE)),
    safari,
    // touch
    touch: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent),
  };
};
