import { getInputDateErrors } from './getInputDateErrors';
import { getInputPhoneErrors } from './getInputPhoneErrors';
import { isValidDate } from './isValidDate';
import { isValidEmail } from './isValidEmail';
import { isValidPhone } from './isValidPhone';

export const getInputErrors = ({
  minLength = 0,
  regexp,
  required,
  test,
  type = 'text',
  value = '',
  ...others
} = {}) => {
  let errors = {};

  // Common
  if (required && (value === undefined || value === false || value.length === 0)) errors.required = true;
  if (minLength > 0 && value.length < minLength) errors.minLength = true;
  if (regexp && regexp.exec && regexp.exec(value) === null) errors.regexp = true;
  if (test && !test(value)) errors.test = true;
  // Email, Phone & Date format
  if (
    (type === 'date' && !isValidDate(value, others)) ||
    (type === 'email' && !isValidEmail(value)) ||
    (type === 'tel' && !isValidPhone(value))
  )
    errors.format = true;
  // Custom Inputs
  if (type === 'inputDate') errors = { ...errors, ...getInputDateErrors({ ...others, value }) };
  if (type === 'inputPhone') errors = { ...errors, ...getInputPhoneErrors({ ...others, value }) };

  return Object.keys(errors).length > 0 ? errors : undefined;
};
