import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { styles } from '../../helpers';
import { Layer, LayerContent, Text, View } from '../../primitives';
import { isPressableInstance } from './helpers';
import style from './Tooltip.module.css';

const Tooltip = ({
  children,
  left: propLeft,
  pressable = false,
  right: propRight,
  Template,
  text,
  timestamp,
  top: propTop,
  visible: propVisible = false,
  onPress = () => {},
  ...inherit
}) => {
  const [visible, setVisible] = useState(propVisible);
  const [position, setPosition] = useState({});
  const contentRef = useRef();
  const ref = useRef();

  const { fixed, testId, ...others } = inherit;
  const { left, right, top = propTop } = position;

  useEffect(() => {
    if (!pressable || !visible) return;

    const handleClickOutside = ({ target } = {}) =>
      !contentRef.current?.contains(target) && !ref.current?.contains(target) && setVisible(false);

    document.addEventListener('click', handleClickOutside, true);

    return () => document.removeEventListener('click', handleClickOutside, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return text || Template ? (
    <Layer
      {...{ fixed, testId, timestamp, top, visible }}
      centered
      forceRender={false}
      left={propLeft}
      right={propRight}
      tag="tooltip"
      onPosition={setPosition}
    >
      {React.createElement(
        pressable ? View : React.Fragment,
        { ...(pressable ? { ref } : undefined) },
        React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            key: index,
            ...child.props,
            onClick: pressable
              ? (event) => {
                  event.stopPropagation();
                  setVisible(!visible);
                  onPress(event, !visible);
                }
              : undefined,
            [isPressableInstance(child) ? 'onEnter' : 'onMouseEnter']: !pressable ? () => setVisible(true) : undefined,
            [isPressableInstance(child) ? 'onLeave' : 'onMouseLeave']: !pressable ? () => setVisible(false) : undefined,
            className: styles(style.dispatcher, child.props.className),
            testId: testId ? `${testId}-dispatcher` : undefined,
          });
        }),
      )}

      <LayerContent>
        <View
          {...others}
          {...(pressable ? { ref: contentRef } : undefined)}
          className={styles(
            style.tooltip,
            (propLeft || propRight) && style.align,
            left && style.left,
            right && style.right,
            top ? style.top : style.bottom,
            others.className,
          )}
        >
          {Template ? (
            <Template />
          ) : (
            <Text bold small className={style.text}>
              {text}
            </Text>
          )}
        </View>
      </LayerContent>
    </Layer>
  ) : (
    <>{children}</>
  );
};

Tooltip.displayName = 'Component:Tooltip';

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  left: PropTypes.bool,
  pressable: PropTypes.bool,
  right: PropTypes.bool,
  Template: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  text: PropTypes.string,
  timestamp: PropTypes.number,
  top: PropTypes.bool,
  visible: PropTypes.bool,
  onPress: PropTypes.func,
};

export { Tooltip };
