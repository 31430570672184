const BANNED_JEST_PROPS = ['is', 'role', 'aria-hidden', 'aria-label'];

const COMMON_PROPS = {
  for: 'htmlFor',
  role: 'data-role',
  testId: 'data-testid',
};

const NATIVE_TAGS = [
  // -- PRIMITIVES -------------------------------------------------------------
  'iframe',
  'img',
  // <Input>
  'input',
  'textarea',
  // <Select>
  'select',
  'option',
  // -- COMPONENTS -------------------------------------------------------------
  // <Table>
  'table',
  'thead',
  'tbody',
  'tr',
  'th',
  'td',
];

const TESTING_PROPS = ['testId'];

export { BANNED_JEST_PROPS, COMMON_PROPS, NATIVE_TAGS, TESTING_PROPS };
