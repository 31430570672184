import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Modal } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { EVENT, TYPE } from './ButtonPayment.constants';
import { L10N } from './ButtonPayment.l10n';
import * as style from './ButtonPayment.module.css';
import { Form } from './components';
import { getComponent, getUrl } from './helpers';
import { PAYMENT_METHOD, PAYMENT_PROVIDER } from '../Payment';

const { AMAZON_PAY, APLAZAME, CARD, PCIPROXY, TPV } = PAYMENT_METHOD;
const { ADDON, ADYEN, CHECKOUT, SIPAY, PAYCOMET } = PAYMENT_PROVIDER;

const PROVIDERS_WITH_MODAL = [ADDON, ADYEN, CHECKOUT, SIPAY, PAYCOMET];

const ButtonPayment = ({
  children,
  config: { currencyDecimals, provider, publicKey, ...config } = {},
  dataSource = {},
  hotel: { id, ...propHotel } = {},
  info = {},
  promise = () => new Promise((resolve) => resolve({})),
  tracking,
  onError = () => {},
  onPress = () => {},
  onSuccess = () => {},
  ...others
}) => {
  const { locale, translate } = useLocale();
  const { value: { forwarder, payment = dataSource || {}, hotel = propHotel || {}, urlParams } = {} } = useStore();

  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState();

  const handleClose = () => {
    setModal(false);
    onError();
  };

  useEffect(() => {
    const { error, orderReference, transactionId } = payment;
    if (orderReference || transactionId) handleSuccess();
    else if (error) onError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  const handlePress = async () => {
    if (onPress() === false) return;

    const { method } = payment || {};
    if ([AMAZON_PAY, PCIPROXY].includes(method)) return document.dispatchEvent(new window.Event(EVENT.CTA_PRESS));

    const requireCardValidation = [CARD, PCIPROXY].includes(method) && cardValidation;
    const withModal = PROVIDERS_WITH_MODAL.includes(provider) && (method === TPV || requireCardValidation);
    const response = !withModal || isModalResponse || method === APLAZAME ? await promise().catch(onError) : true;
    if (!response) return;

    if (withModal || response.type === TYPE.SUBMIT) setResponse(response);

    if (withModal) {
      try {
        Event.publish(EVENT.OPEN_MODAL);
      } catch {
        //123 compare is failed when capture this event.
      }
      setModal(true);
    } else if (method === APLAZAME) {
      document.dispatchEvent(new CustomEvent(EVENT.APLAZAME_RESPONSE, { detail: { ...response, publicKey } }));
    } else {
      const url = getUrl({ response, ...hotel, id, forwarder, locale, tracking, urlParams });
      if (url) window.location.href = url;
    }
  };

  const handleSuccess = async (value) => {
    setModal(false);

    const response = await promise(value).catch(onError);
    if (!response) return;

    if (payment.method === AMAZON_PAY)
      return document.dispatchEvent(new CustomEvent(EVENT.AMAZON_PAY_RESPONSE, { detail: { response } }));

    if (response) {
      const url = getUrl({ ...response, ...hotel, id, forwarder, locale, tracking, urlParams });
      if (url) {
        onSuccess(url);
        window.location.href = url;
      }
    }
  };

  const { method } = payment || {};
  const { cardValidation } = info || {};
  const component = getComponent({ cardValidation, method, provider });
  const isModalResponse = [ADDON, ADYEN].includes(provider);

  return (
    <>
      <Button {...others} onPress={handlePress}>
        {children || translate(L10N.ACTION_PAY_NOW)}
      </Button>

      {response?.type === TYPE.SUBMIT && <Form {...response} />}

      <Modal portal={isModalResponse} title={method} visible={modal} onClose={handleClose}>
        {component &&
          React.createElement(component, {
            ...config,
            currencyDecimals,
            info,
            method,
            provider,
            publicKey,
            response,
            onError,
            onSuccess: handleSuccess,
            className: style.modalContent,
          })}
      </Modal>
    </>
  );
};

ButtonPayment.displayName = 'Mirai:Core:ButtonPayment';

ButtonPayment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  config: PropTypes.shape({
    currencyDecimals: PropTypes.number,
    provider: PropTypes.oneOf(Object.values(PAYMENT_PROVIDER)),
    publicKey: PropTypes.string,
    showCVV: PropTypes.bool,
  }),
  dataSource: PropTypes.shape({
    method: PropTypes.oneOf(Object.values(PAYMENT_METHOD)),
  }),
  hotel: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }),
  info: PropTypes.shape({
    amount: PropTypes.number,
    cardValidation: PropTypes.bool,
    date: PropTypes.instanceOf(Date),
  }),
  promise: PropTypes.func,
  tracking: PropTypes.string,
  onError: PropTypes.func,
  onPress: PropTypes.func,
  onSuccess: PropTypes.func,
};

export { ButtonPayment };
