import { IS_JEST } from '../components/helpers';
import { TRACKING } from '../Core.constants';

const MONALISA_ID = '100379008';

const DEFAULT_PERFORMANCE = [
  {
    type: 1,
    instances: [{ idAnalytics: 'G-EW4M987YRE', propertyId: '337963856', serviceAccount: 'ANALYTICS11', id: 42011 }],
  },
  {
    type: 2,
    instances: [{ idAdwords: '604678444', conversionLabel: 'S0SLCLqv8toBEKzSqqAC', cookiePrefix: 'MIRAI', id: 55146 }],
  },
  {
    type: 3,
    instances: [
      { idGTM: 'GTM-M3CPG48', id: 55265 },
      { idGTM: 'GTM-PZG8RRXL', id: 55266 },
      { idGTM: 'GTM-M3CPG48', id: 55267 },
    ],
  },
  { type: 4, instances: [{ id: 47811 }] },
  { type: 5, instances: [{ pixelId: '999999999', testEventCode: 'TEST9826', id: 55182 }] },
  { type: 6, instances: [{ id: 55179 }] },
  { type: 7, instances: [{ idAffilired: '999999', id: 55178 }] },
  { type: 8, instances: [{ idBing: '5979008', id: 55180 }] },
  { type: 9, instances: [{ id: 42010 }] },
];

export const getPerformance = ({ tracking: { trackingChain, trackingHotels, ...others } = {} } = {}, hotelId) => {
  if (!IS_JEST && process.env.NODE_ENV !== 'production' && hotelId !== MONALISA_ID) return DEFAULT_PERFORMANCE;

  if (process.env.NODE_ENV === 'production' && hotelId === MONALISA_ID) return [];

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  let tracking = others;

  if (trackingChain && trackingHotels) {
    tracking = { ...trackingChain };
    const hotelTracking = hotelId && trackingHotels.find(({ idTokenProvider: id } = {}) => id === parseInt(hotelId));

    hotelTracking &&
      Object.keys(tracking).forEach((key) => {
        tracking[key] = [...tracking[key], ...hotelTracking[key]];
      });
  }

  // ! -- TODO: Choose which one will load gtag
  return Object.keys(tracking).length > 0
    ? Object.keys(tracking)
        .filter((key) => !!TRACKING[key] && tracking[key].length > 0)
        .map((key) => ({
          type: TRACKING[key],
          instances: tracking[key].map(({ configuration = {}, id: idTracking }) => ({
            ...(configuration !== null ? configuration : {}),
            idTracking,
          })),
        }))
    : undefined;
};
