/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { ERROR } from './User.constants';

export const resetPassword = ({ email, idClub, idHotel } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!email) return reject(ERROR.NOT_FILLED);

    const response = await request({
      hostname: process.env.SERVICE_USER,
      endpoint: `/club/v1/recovery?email=${email}&idclub=${idClub}&idhotel=${idHotel}`,
    }).catch(reject);

    response ? resolve(response) : reject(ERROR.UNKNOWN);
  });
