import PropTypes from 'prop-types';
import React from 'react';

import { getIconState, styles } from '../../helpers';
import { Icon, ICON, Pressable, Text, View } from '../../primitives';
import style from './Notification.module.css';

const Notification = ({
  children,
  contrast,
  error,
  info,
  inline = false,
  large,
  small,
  success,
  title,
  warning,
  wide,
  onClose,
  ...others
}) => (
  <View
    {...others}
    row
    tag={`notification${error ? '-error' : warning ? '-warning' : ''}`}
    className={styles(
      style.notification,
      contrast
        ? style.contrast
        : error
        ? style.error
        : warning
        ? style.warning
        : success
        ? style.success
        : info
        ? style.info
        : undefined,
      inline ? style.inline : style.outlined,
      large ? style.large : small ? style.small : undefined,
      wide && style.wide,
      others.className,
    )}
  >
    <Icon headline={large} paragraph={small} level={large ? 1 : 3} value={getIconState({ error, success, warning })} />

    <View className={style.texts}>
      {title && (
        <Text action={small} bold headline={large}>
          {title}
        </Text>
      )}
      {children && (
        <Text action={!small && !large} small={small}>
          {children}
        </Text>
      )}
    </View>

    {!inline && !large && onClose && (
      <Pressable onPress={onClose} testId={others.testId ? `${others.testId}-button-close` : undefined}>
        <Icon headline={large} paragraph={small} level={large ? 1 : 3} value={ICON.CLOSE} />
      </Pressable>
    )}
  </View>
);

Notification.displayName = 'Component:Notification';

Notification.propTypes = {
  children: PropTypes.any.isRequired,
  contrast: PropTypes.bool,
  error: PropTypes.bool,
  info: PropTypes.bool,
  inline: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  success: PropTypes.bool,
  title: PropTypes.string,
  warning: PropTypes.bool,
  wide: PropTypes.bool,
  onClose: PropTypes.func,
};

export { Notification };
