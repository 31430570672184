import { DEFAULT_LOCALE, DEFAULT_DATE_FORMAT } from '../locale.definition';

export const getDateFormat = (locale = DEFAULT_LOCALE) => {
  if (typeof locale !== 'string') return undefined;

  let format;

  if (new Intl.DateTimeFormat(locale).resolvedOptions().locale !== locale) {
    format = DEFAULT_DATE_FORMAT;
  } else {
    format = locale.includes('ar')
      ? 'YYYY/MM/DD'
      : new Intl.DateTimeFormat(locale)
          .formatToParts(new Date())
          .map(({ type, value }) =>
            type === 'day' ? 'DD' : type === 'month' ? 'MM' : type === 'year' ? 'YYYY' : value,
          )
          .join('');
  }

  return format;
};
