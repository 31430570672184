import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { PriceDetails } from './components';
import { L10N } from './Item.l10n';
import * as style from './Item.module.css';
import { getLabelTaxes, getClubRate } from '../../helpers';
import { DISCOUNT_TYPE, MAX_PROMOCODE } from '../../Rates.constants';

const Summary = ({
  availableRates = [],
  features: { showPricePerNight = false } = {},
  id: roomId,
  isSoldOut = false,
  multiRoom = false,
  name: roomName,
  nights = 2,
  priceFactor = 1,
  promocodeForAll,
  selected = {},
  selectedBoard = {},
  taxesType,
  onSelect,
  ...others
}) => {
  const { isMobile } = useDevice();
  const { currencyFormat, translate } = useLocale();
  const { value: { currency, finder: { promocode } = {} } = {} } = useStore();

  const [busy, setBusy] = useState(false);

  useEffect(() => Object.keys(selected).length === 0 && setBusy(false), [selected]);

  const {
    basePrice,
    cancellationId,
    checkoutPrice,
    confidential,
    dayPrice,
    deals,
    discount,
    fees,
    id,
    name,
    originalPrice = 0,
    price = 0,
    rateId,
    taxes,
    totalPriceRaw,
  } = selectedBoard;

  const isSelected = selected.id === id;

  const handlePress = () => {
    if (!multiRoom) setBusy(true);
    onSelect(
      !isSelected
        ? {
            basePrice,
            cancellationId,
            checkoutPrice,
            confidential,
            deals,
            discount,
            fees,
            id,
            name,
            price,
            rateId,
            roomId,
            roomName,
            taxes,
            totalPriceRaw,
          }
        : undefined,
    );
  };

  const { breakdown = [], percentage: discountPercentage = 0 } = discount || {};

  const { percentage: clubPercentage = 0 } = breakdown.find(({ type } = {}) => type === DISCOUNT_TYPE.CLUB) || {};
  const { percentage: promoPercentage = 0 } = breakdown.find(({ type } = {}) => type === DISCOUNT_TYPE.PROMOTION) || {};

  const clubOffer = getClubRate({
    board: selectedBoard,
    rate: availableRates.find(({ id }) => rateId === id),
  });
  const currencyFormatProps = { currency, maximumFractionDigits: 0, minimumFractionDigits: 0 };

  const { testId } = others;

  return (
    <>
      <View className={[style.offset, style.summary]}>
        <View row className={style.tags}>
          {clubOffer && (
            <Text bold small className={[style.tag, style.highlight]}>
              {translate(L10N.LABEL_ONLY_MEMBERS_DISCOUNT, {
                value: !isSoldOut && clubPercentage > 0 ? ` -${Math.round(clubPercentage)}%` : '',
              }).trimEnd()}
            </Text>
          )}
          {!isSoldOut && promocode && promocodeForAll && (
            <Text bold small className={[style.tag, style.highlight]}>
              <Text
                bold
                small
                className={
                  promocode.length > (isMobile ? MAX_PROMOCODE.MOBILE : MAX_PROMOCODE.DESKTOP) && style.truncate
                }
              >
                {promocode.toUpperCase()}
              </Text>
              {promoPercentage ? ` -${Math.round(promoPercentage)}%` : ''}
            </Text>
          )}
        </View>

        <View row className={[style.gap, style.content, isSoldOut && style.end]}>
          {!isSoldOut && (
            <View>
              <View row className={style.price}>
                <Text bold headline level={multiRoom || price >= 10000 ? 3 : 2}>
                  {currencyFormat({ ...currencyFormatProps, value: price / priceFactor })}
                </Text>

                {price !== originalPrice && (
                  <Text action light className={style.lineThrough}>
                    {currencyFormat({ ...currencyFormatProps, value: originalPrice / priceFactor })}
                  </Text>
                )}
              </View>

              {nights > 1 && (
                <Text small className={style.pricePer}>{`${currencyFormat({
                  ...currencyFormatProps,
                  value: showPricePerNight ? price : dayPrice,
                })} ${translate(showPricePerNight ? L10N.LABEL_FOR_NIGHTS : L10N.LABEL_PER_NIGHT, { nights })}`}</Text>
              )}

              {!!taxesType && (
                <Text light small>
                  {translate(getLabelTaxes(taxesType))}
                </Text>
              )}

              {(discountPercentage > 0 || fees > 0 || taxes > 0) && (
                <PriceDetails {...{ board: selectedBoard, taxesType }} className={style.priceDetails} />
              )}
            </View>
          )}

          <Button
            busy={busy}
            disabled={isSoldOut}
            large={isMobile}
            secondary={multiRoom && isSelected}
            onPress={handlePress}
            testId={testId ? `${testId}-select` : undefined}
          >
            {translate(
              isSoldOut
                ? L10N.LABEL_NOT_AVAILABLE
                : multiRoom
                ? isSelected
                  ? L10N.LABEL_DESELECT
                  : L10N.LABEL_SELECT
                : L10N.ACTION_RESERVE,
            )}
          </Button>
        </View>
      </View>
    </>
  );
};

Summary.displayName = 'Mirai:Core:Rates:Item:Summary';

Summary.propTypes = {
  availableRates: PropTypes.arrayOf(PropTypes.any),
  features: PropTypes.shape({
    showPricePerNight: PropTypes.bool,
  }),
  id: PropTypes.number,
  isSoldOut: PropTypes.bool,
  multiRoom: PropTypes.bool,
  name: PropTypes.string,
  nights: PropTypes.number,
  priceFactor: PropTypes.number,
  promocodeForAll: PropTypes.bool,
  selected: PropTypes.shape({}),
  selectedBoard: PropTypes.shape({}),
  taxesType: PropTypes.number,
  onSelect: PropTypes.func,
};

export { Summary };
