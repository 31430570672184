import { DEFAULT_DATE_FORMAT, dateDiff, parseDate } from '@mirai/locale';

export const getInputDateErrors = ({ format = DEFAULT_DATE_FORMAT, max, min, value } = {}) => {
  const errors = {};
  const date = format.length === value?.length ? parseDate(value, format) : undefined;

  if (!date && value?.length) errors.format = true;
  if (date && max && dateDiff(date, parseDate(max, format)).days < 0) errors.max = true;
  if (date && min && dateDiff(date, parseDate(min, format)).days > 0) errors.min = true;

  return Object.keys(errors).length > 0 ? errors : undefined;
};
