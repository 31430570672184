import PropTypes from 'prop-types';

import { ACCOMMODATION_TYPE } from '../../helpers/constants';

const { APARTMENT, ACCOMMODATION, PITCH, ROOM, VILLA } = ACCOMMODATION_TYPE;

const DEFAULT_AMOUNT = 2;

const ERROR = {
  NOT_FILLED: { code: 2, type: 'warn', message: 'Not all fields have been filled out' },
};

const PLACE = {
  [ACCOMMODATION]: 'accommodation',
  [APARTMENT]: 'apartment',
  [PITCH]: 'pitch',
  [ROOM]: 'room',
  [VILLA]: 'villa',
};

const SHAPE = {
  DATASOURCE: PropTypes.shape({
    type: PropTypes.number,
    age: PropTypes.arrayOf(PropTypes.number),
    max: PropTypes.number,
    min: PropTypes.number,
    fulfill: PropTypes.bool,
  }),

  VALUE: PropTypes.shape({
    ages: PropTypes.arrayOf(PropTypes.number),
    amount: PropTypes.number,
    type: PropTypes.number,
  }),
};

const TYPE = {
  GUEST: 1,
  ADULT: 2,
  CHILD: 3,
};

const DATASOURCE = [
  { type: TYPE.ADULT, min: 1, age: [18] },
  { type: TYPE.CHILD, age: [0, 17], fulfill: true },
];

export { DATASOURCE, DEFAULT_AMOUNT, ERROR, PLACE, SHAPE, TYPE };
