import { parseDate, useLocale } from '@mirai/locale';
import { Icon, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Input.module.css';
import { ICON } from '../../../helpers';

const ICON_PROPS = { className: style.icon };
const TEXT_PROPS = { action: true, medium: true };

const Context = ({ dates: [checkin, checkout] = [], id, occupation: [occupation = []] = [] }) => {
  const { isMobile } = useDevice();
  const { dateFormat } = useLocale();

  const dateFormatCompact = { day: 'numeric', month: 'short', weekday: !isMobile ? 'short' : undefined };
  const hasDates = !!checkin;
  const hasOccupation = occupation.length > 0;

  return id || hasDates || hasOccupation ? (
    <View row className={style.context}>
      {id && (
        <View row className={style.field}>
          <Icon {...ICON_PROPS} value={ICON.ASSIGNMENT} />
          <Text {...TEXT_PROPS}>{id}</Text>
        </View>
      )}

      {hasDates && (
        <View row className={style.field}>
          <Icon {...ICON_PROPS} value={ICON.CALENDAR} />
          <Text {...TEXT_PROPS}>{dateFormat(parseDate(checkin), dateFormatCompact)}</Text>
          {checkout && <Text {...TEXT_PROPS}>{` — ${dateFormat(parseDate(checkout), dateFormatCompact)}`}</Text>}
        </View>
      )}

      {hasOccupation && (
        <View row className={style.field}>
          <Icon {...ICON_PROPS} value={ICON.PEOPLE} />
          <Text {...TEXT_PROPS}>
            {occupation.map(({ amount, ages = [] } = {}) => amount || ages.length).join(' + ')}
          </Text>
        </View>
      )}
    </View>
  ) : null;
};

Context.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  occupation: PropTypes.arrayOf(PropTypes.any),
};

export { Context };
