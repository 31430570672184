import { Theme } from '@mirai/ui';

export const getStyle = () => {
  const { inputDisabled, inputFont, inputFontSize, fontWeight, content } = Theme.get();

  return {
    localization: {
      cardNumberPlaceholder: '0000 0000 0000 0000',
      expiryMonthPlaceholder: 'MM',
      expiryYearPlaceholder: 'YY',
      cvvPlaceholder: '000',
    },
    style: {
      base: {
        color: content,
        fontFamily: inputFont,
        fontSize: inputFontSize,
        fontWeight: fontWeight,
        letterSpacing: 0,
      },
      placeholder: {
        base: {
          color: 'transparent',
        },
        focus: {
          color: inputDisabled,
        },
      },
    },
  };
};
