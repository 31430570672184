import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { styles } from '../../helpers';
import { ICON, Icon, Input, Pressable, Text, View } from '../../primitives';
import style from './InputText.module.css';
import { Hint, IconState, Label } from './partials';

const InputText = ({
  caption,
  children,
  disabled,
  error,
  hint,
  icon,
  label,
  markdown,
  multiLine,
  showRequired = false,
  showState = true,
  success,
  type,
  warning,
  onChange = () => {},
  onEnter = () => {},
  onLeave = () => {},
  ...others
}) => {
  const [focus, setFocus] = useState(false);
  const [password, setPassword] = useState(true);
  const [inputMode, setInputMode] = useState(true);

  const handleChange = (value, event) => {
    onChange(value, event);
  };

  const handleEnter = (event) => {
    setFocus(true);
    onEnter(event);
  };

  const handleLeave = (event) => {
    setFocus(false);
    onLeave(event);
  };

  const handlePress = (event) => {
    if (is.password) setPassword(!password);
    else if (is.search) onChange(undefined, event);
  };

  const is = {
    password: type === 'password',
    search: type === 'search',
  };

  const has = {
    icon: !!icon || is.search,
    label: !!label,
    value: others.value !== undefined && (others.value?.length > 0 || typeof others.value === 'number'),
    stateIcon: showState && (error || success || warning),
  };

  const id = label ? others.id || others.name : others.id;

  return (
    <View
      role={others.role}
      tag="input-text"
      className={styles(style.inputContainer, others.className)}
      style={others.style}
    >
      <View
        row
        className={styles(
          style.inputBorder,
          disabled && style.disabled,
          focus && style.focus,
          error ? style.error : undefined,
        )}
      >
        {has.icon && (
          <Icon
            value={icon || ICON.SEARCH}
            className={styles(style.icon, style.left, disabled && style.disabled, error && style.error)}
          />
        )}

        {caption && (
          <Text action light className={style.caption}>
            {caption}
          </Text>
        )}

        <View wide className={style.content}>
          {label && (
            <Label
              {...{
                disabled,
                error,
                focus,
                for: id,
                label,
                required: showRequired && others.required,
                requiredText: others.requiredText,
                success,
                value: others.value,
              }}
            />
          )}

          {inputMode && (
            <Input
              {...{ ...others, disabled, id, multiLine }}
              type={!is.password || password ? type : 'text'}
              value={others.value !== undefined ? others.value : ''}
              className={styles(style.input, has.label && style.withLabel, multiLine && style.multiLine)}
              onChange={handleChange}
              onEnter={handleEnter}
              onLeave={handleLeave}
              aria-label={label}
            />
          )}
          {markdown && !inputMode && (
            <Text
              markdown
              onClick={() => setInputMode(true)}
              className={styles(style.input, has.label && style.withLabel, multiLine && style.multiLine)}
            >
              {others.value}
            </Text>
          )}
        </View>

        {markdown && (
          <Pressable onPress={() => setInputMode(!inputMode)}>
            <Icon value={ICON.MARKDOWN} className={styles(style.icon, inputMode && style.disabled)} />
          </Pressable>
        )}

        {(is.password || (is.search && has.value)) && !disabled && (
          <Pressable tabIndex={-1} onPress={handlePress}>
            <Icon
              value={is.password ? (password ? ICON.EYE_CLOSE : ICON.EYE_OPEN) : ICON.CLOSE}
              className={styles(style.icon, style.password)}
            />
          </Pressable>
        )}

        {children}

        {has.stateIcon && <IconState {...{ error, success, warning }} />}
      </View>

      {hint && <Hint {...{ disabled, error, hint }} />}
    </View>
  );
};

InputText.displayName = 'Component:InputText';

InputText.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hint: PropTypes.string,
  icon: PropTypes.func,
  label: PropTypes.string,
  markdown: PropTypes.bool,
  multiLine: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showRequired: PropTypes.bool,
  showState: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.string,
  warning: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
};

export { InputText };
