import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './FieldOccupation.l10n';
import { prepareRoom } from '../../../helpers/prepareRoom';
import { getAccommodationType, getOccupationSummary, ICON } from '../../helpers';
import { Field } from '../Field';
import { Occupation as OccupationBase } from '../Occupation';
import { SHAPE } from '../Occupation/Occupation.constants';

const FieldOccupation = React.forwardRef(
  (
    {
      compacted,
      error,
      hideLabel,
      hotels = [],
      inline,
      tabIndex,
      value,
      visible,
      warning,
      withWarning,
      onPress,
      onSubmit,
      ...others
    },
    ref,
  ) => {
    const { translate } = useLocale();
    const {
      value: { config: { hotels: dataSource, lodgingDenomination: individual } = {} },
    } = useStore();

    const { minRooms = 1, testId } = others;
    const type = getAccommodationType({ dataSource, hotels, individual });
    const caption = getOccupationSummary(
      value || [...Array(minRooms)].map(() => prepareRoom(others.dataSource)),
      translate,
      type,
    );

    return (
      <Field
        {...others}
        caption={caption}
        compacted={compacted}
        error={error}
        icon={ICON.PEOPLE}
        inline={inline}
        label={!hideLabel ? translate(L10N.LABEL_WHO) : undefined}
        tabIndex={tabIndex}
        visible={visible}
        warning={warning}
        onPress={onPress}
        testId={testId ? `${testId}-field` : undefined}
      >
        <OccupationBase
          {...others}
          ref={ref}
          type={type}
          value={value}
          warning={withWarning}
          onSubmit={onSubmit}
          className={undefined}
          style={undefined}
        />
      </Field>
    );
  },
);

FieldOccupation.displayName = 'Mirai:Core:FieldOccupation';

FieldOccupation.propTypes = {
  compacted: PropTypes.bool,
  error: PropTypes.bool,
  hotels: PropTypes.array,
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  tabIndex: PropTypes.any,
  value: PropTypes.arrayOf(PropTypes.arrayOf(SHAPE.VALUE)),
  visible: PropTypes.bool,
  warning: PropTypes.bool,
  withWarning: PropTypes.bool,
  onPress: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { FieldOccupation };
