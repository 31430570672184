import { request } from '@mirai/data-sources';

import { getFingerprint } from './helpers';

export const action = ({ context, intent, locale, mobile, session = {} } = {}) =>
  request({
    method: 'POST',
    hostname: process.env.SERVICE_LISA,
    endpoint: '/action',
    headers: { authorization: session.authorization },
    context,
    intent,
    locale,
    mobile,
    session,
    fingerprint: getFingerprint(session),
    time: new Date().getHours(),
    timezoneOffset: new Date().getTimezoneOffset(),
  });
