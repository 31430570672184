import { bookingCancel } from './bookingCancel';
import { bookingResponse } from './bookingResponse';
import { checkoutResponse } from './checkoutResponse';
import { finderReady } from './finderReady';
import { ratesResponse } from './ratesResponse';

export const ProviderGTM = {
  bookingCancel,
  bookingResponse,
  checkoutResponse,
  finderReady,
  ratesResponse,
};
