import { useDevice, View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../../../__shared__';

const SPACE = 'var(--mirai-ui-space-M)';
const BORDER_COLOR = 'var(--mirai-ui-content-background)';
const BORDER_WIDTH = 'var(--mirai-ui-border-width)';

export default () => {
  const { isDesktop, isMobile, isTablet } = useDevice();

  return (
    <View wide style={{ gap: SPACE }}>
      <View row style={{ justifyContent: 'space-between' }}>
        <Skeleton headline level={2} words={2} />
        <Skeleton button small words={3} />
      </View>

      <Skeleton headline={isMobile} words={3} />

      <View row={!isMobile} style={{ gap: SPACE, flexWrap: 'wrap' }}>
        {Array.from({ length: 9 }).map((_, index) => (
          <View
            key={index}
            style={{
              border: `solid ${BORDER_WIDTH} ${BORDER_COLOR}`,
              flexBasis: isDesktop
                ? 'calc(33.33% - calc(calc(var(--mirai-ui-space-M) * 2) / 3)) !important'
                : isTablet
                ? 'calc(50% - calc(var(--mirai-ui-space-M) / 2))'
                : undefined,
            }}
          >
            <View style={{ padding: SPACE }}>
              <Skeleton headline words={2} />
              <Skeleton small words={5} />
              <Skeleton small words={2} />
              <Skeleton small words={2} />
            </View>

            <View style={{ backgroundColor: BORDER_COLOR, height: BORDER_WIDTH }} />

            <View row style={{ justifyContent: 'space-between', padding: SPACE }}>
              <View>
                <Skeleton headline level={2} words={1} />
                <Skeleton small words={2} />
              </View>
              <Skeleton button words={1} />
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};
