import { dateDiff, dateFormat, parseDate, UTC } from '@mirai/locale';

import { CALENDAR_FORMAT as format } from '../../../helpers';

const FIRST_FORTNIGHT = 14;

export const calcFromDate = (dates = []) => {
  if (!Array.isArray(dates) || !dates[0]) return;
  const date = parseDate(dates[0], format);
  const month =
    date.getMonth() -
    (date.getDate() <= FIRST_FORTNIGHT &&
    dateDiff(UTC(new Date()), new Date(date.getFullYear(), date.getMonth(), 0)).days > 0
      ? 1
      : 0);

  const value = UTC(new Date(date.getFullYear(), month, 1));

  return dateFormat(value, { format });
};
