import { CURRENCIES, CUSTOM_CURRENCY_FORMAT, DEFAULT_CURRENCY, DEFAULT_LOCALE } from '../locale.definition';
import { currencyFancy, getNarrowSymbol, getSymbol } from './helpers';

const EMPTY_STRING = '';

export const currencyFormat = ({
  currency = DEFAULT_CURRENCY,
  fancy = false,
  locale = DEFAULT_LOCALE,
  symbol = false,
  value = 0,
  ...others
} = {}) => {
  if (
    typeof currency !== 'string' ||
    !CURRENCIES.includes(currency) ||
    typeof fancy !== 'boolean' ||
    typeof locale !== 'string' ||
    typeof symbol !== 'boolean' ||
    typeof value !== 'number'
  )
    return undefined;

  const formattedValue = fancy
    ? currencyFancy(locale, currency, value, symbol)
    : Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        ...CUSTOM_CURRENCY_FORMAT[currency],
        ...others,
      }).format(value);

  return symbol
    ? formattedValue
        .replace(getSymbol({ currency, fancy, locale }), getNarrowSymbol({ currency, fancy, locale }) ?? EMPTY_STRING)
        .trim()
    : formattedValue;
};
