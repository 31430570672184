const NATIVE_TAGS = [
  'iframe',
  'img',
  'input',
  'textarea',
  'select',
  'option',
  'a',
  'button',
  'form',
  'table',
  'thead',
  'tbody',
  'tr',
  'th',
  'td',
];

export const findDangerousCSSRules = () => {
  const { styleSheets } = document;
  let value = [];

  const checkRules = (rules, fileName = 'inline') => {
    const dangerousRules = Array.from(rules)
      .filter((rule) => {
        return (
          rule.selectorText && NATIVE_TAGS.some((selector) => new RegExp(`\\b${selector}\\b`).test(rule.selectorText))
        );
      })
      .map((rule) => ({ selector: rule.selectorText, fileName }));

    value = [...value, ...dangerousRules];

    for (const rule of rules) {
      if (rule.cssRules) checkRules(rule.cssRules, fileName);
    }
  };

  for (const styleSheet of styleSheets) {
    const rules = styleSheet.cssRules || styleSheet.rules;
    if (rules) checkRules(rules, styleSheet.href);
  }

  return value;
};
