export const isComponentFixed = (node) => {
  if (!node) return;

  const getValue = (nodes) => {
    if (!nodes?.length) return;

    for (const node of nodes) {
      const { position } = getComputedStyle(node);

      if (position === 'fixed' || getValue(node.children)) return true;
    }
  };

  return getValue([node]);
};
