import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../../helpers';
import { Text } from '../../../primitives';
import style from '../InputText.module.css';

const DEFAULT_REQUIRED_TEXT = '✱';

const Label = ({
  disabled,
  error,
  focus,
  label,
  required = false,
  requiredText = DEFAULT_REQUIRED_TEXT,
  success,
  value,
  ...others
}) => (
  <Text
    {...others}
    tag="label"
    className={styles(
      style.text,
      others.className || style.label,
      disabled && style.disabled,
      error && style.error,
      focus && style.focus,
      (focus || error || (value !== undefined && (value?.length > 0 || typeof value === 'number'))) && style.withValue,
    )}
  >
    {label}

    {/* {required && !disabled && !(!focus && success) && (
      <Text
        bold={requiredText !== DEFAULT_REQUIRED_TEXT}
        error={!success}
        success={success}
        tiny
        className={styles(
          style.required,
          requiredText === DEFAULT_REQUIRED_TEXT && style.square,
          focus && style.focus,
          success ? style.success : style.error,
        )}
      >
        {requiredText !== DEFAULT_REQUIRED_TEXT ? `(${requiredText})` : requiredText}
      </Text>
    )} */}

    {required && !disabled && !(!focus && success) && (
      <span className={styles(style.required, style.error)}>
        {requiredText !== DEFAULT_REQUIRED_TEXT ? `(${requiredText})` : requiredText}
      </span>
    )}
  </Text>
);

Label.displayName = 'Component:InputText.Label';

Label.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  focus: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  requiredText: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export { Label };
