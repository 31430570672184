const CONTEXT = {
  SETTINGS: 1,
  BOOKINGS: 2,
};

const KEY = {
  profile: CONTEXT.SETTINGS,
  account: CONTEXT.SETTINGS,
  bookings: CONTEXT.BOOKINGS,
};

export { CONTEXT, KEY };
