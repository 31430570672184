import { action } from './action';
import { message } from './message';
import { status } from './status';
import { welcome } from './welcome';

export const ServiceLisa = {
  action,
  message,
  status,
  welcome,
};
