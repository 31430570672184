import { formatExpire } from '../../../helpers';

const PARSER = [/[^\d]/g, ''];
const SLASH = '/';

export const formatValues = (values = {}, { expire: currentExpire = '' } = {}) => {
  let { expire = '', name = '' } = values;

  expire = `${expire.substr(0, 2).replace(...PARSER)}${expire.substr(2, 1) ? SLASH : ''}${expire
    .substr(3, 2)
    .replace(...PARSER)}`;
  name = name.toUpperCase();

  expire = expire.length === 2 ? (expire.length > currentExpire.length ? `${expire}${SLASH}` : expire[0]) : expire;
  expire = formatExpire(expire);

  return { expire, name };
};
