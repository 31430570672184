import { Icon, ICON, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './InputField.module.css';

const DEFAULT_REQUIRED_TEXT = '*';

const InputField = ({
  children,
  error,
  focus,
  hint,
  label,
  requiredText,
  showRequired = false,
  success,
  value,
  ...others
}) => (
  <View {...others} className={styles(style.container, others.className)}>
    <View className={styles(style.border, error && style.error, focus && style.focus)}>
      <View wide className={style.content}>
        {label && (
          <Text
            small
            className={styles(
              style.label,
              (focus || error || (value !== undefined && (value?.length > 0 || typeof value === 'number'))) &&
                style.withValue,
            )}
          >
            {label}
            {showRequired &&
              (requiredText ? (
                !(!focus && success) && (
                  <Text
                    error
                    small={focus || error}
                    className={styles(
                      style.text,
                      style.required,
                      focus && style.focus,
                      success ? style.success : style.error,
                    )}
                  >
                    {`(${requiredText})`}
                  </Text>
                )
              ) : (
                <Text error className={style.defaultRequired}>
                  {DEFAULT_REQUIRED_TEXT}
                </Text>
              ))}
          </Text>
        )}
        {children}
        {(error || success) && (
          <Icon
            value={error ? ICON.ERROR : ICON.SUCCESS}
            className={styles(style.icon, error && style.error, success && style.success)}
          />
        )}
      </View>
    </View>
    {hint && (
      <Text small className={style.hint}>
        {hint}
      </Text>
    )}
  </View>
);

InputField.displayName = 'Mirai:Payments:InputField';

InputField.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  focus: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  requiredText: PropTypes.string,
  showRequired: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.string,
};

export { InputField };
