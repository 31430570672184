const BANNED = ['mobile'];

export const fetchUrlParams = (value = '') => {
  let nextValue = value;
  if (value.includes('?') && value.endsWith('/')) {
    nextValue = value.substring(0, value.length - 1);
  }

  return Object.fromEntries([...new URLSearchParams(nextValue)].filter(([key] = []) => !BANNED.includes(key)));
};
