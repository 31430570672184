/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { ERROR } from './User.constants';

export const signup = ({
  country = 'ES',
  dateOfBirth,
  email,
  firstName,
  idClub,
  idHotel,
  language = 'es',
  lastName,
  password,
  privacy = true,
  source = 'WEBSITE',
  subscribed = false,
  version = 'CORE',
} = {}) =>
  new Promise(async (resolve, reject) => {
    if (!email || !password) return reject(ERROR.NOT_FILLED);

    const form = {
      country,
      dateOfBirth,
      email,
      firstname: firstName,
      idclub: idClub,
      idhotel: idHotel,
      language,
      lastname: lastName,
      password,
      privacy,
      source,
      subscribed,
      version,
    };

    const response = await request({
      method: 'POST',
      hostname: process.env.SERVICE_USER,
      endpoint: '/club/v1/join',
      ...form,
    }).catch(() => {});

    response ? resolve(response) : reject(ERROR.UNKNOWN);
  });
