import { useStore } from '@mirai/data-sources';
import { dateFormat as dateFormatBase, useLocale } from '@mirai/locale';
import { Button, Modal, useDevice } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Hotelverse.l10n';
import * as style from './Hotelverse.module.css';
import { TYPE_OCCUPATION } from '../../../__shared__';
import { addScript } from '../../../helpers';

const Hotelverse = ({
  country,
  hotelverse: { hash, token } = {},
  infantAge,
  items = [],
  multiRoom,
  taxes,
  onSelect = () => {},
} = {}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      currency,
      language,
      finder: { calendar: [checkIn, checkOut] = [], occupation: [occupation = []] = [], promocode = '' } = {},
      urlParams: { idtokenprovider: idHotel } = {},
    } = {},
  } = useStore();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cdn = process.env.HOTELVERSE_CDN;
    const scriptEmbed = open && addScript(`${cdn}/scripts/embed.js`);
    const scriptListener = open && addScript(`${cdn}/scripts/interfaceListener.js`);

    if (open) {
      document.body.classList.add('hotelverse-open');

      window.callbackSelectRoom = ([{ hvIsOr, hvLocator: idHotelverse, hvRoomNumber: roomNumber, resultKey }] = []) => {
        setOpen(false);

        const boardId = resultKey.replace(/\|/g, '--');
        const board = items.flatMap(({ boards } = {}) => boards).find(({ id } = {}) => id === boardId);

        onSelect(board, { idHotelverse, roomNumber, subjectToAvailability: hvIsOr });
      };
    }

    return () => {
      document.body.classList.remove('hotelverse-open');
      if (scriptEmbed) document.head.removeChild(scriptEmbed);
      if (scriptListener) document.head.removeChild(scriptListener);
      window.callbackSelectRoom = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const dateFormat = (date) => dateFormatBase(date, { delimiter: '-', format: 'YYYY-MM-DD' });

  const { btoa } = window;
  const commandInterface = btoa(
    JSON.stringify({
      onSelectRoom: {
        callback: 'callbackSelectRoom',
        enabled: true,
        method: 'PostMessage',
        customParams: [],
      },
    }),
  );

  const { amount: adults = 0 } = occupation.find(({ type } = {}) => type !== TYPE_OCCUPATION.CHILD) || {};
  const { ages: childAges = [] } = occupation.find(({ type } = {}) => type === TYPE_OCCUPATION.CHILD) || {};
  const children = childAges.filter((age) => age > infantAge).length;
  const infants = childAges.filter((age) => age < infantAge).length;
  const search = btoa(
    JSON.stringify({
      checkIn: dateFormat(checkIn),
      checkOut: dateFormat(checkOut),
      paxes: [{ adults, children, infants, childAges }],
      taxIncluded: !!taxes,
      currency,
      country,
      language,
      promocode,
    }),
  );

  return !multiRoom && hash && token ? (
    <>
      <Button secondary wide={isMobile} onPress={() => setOpen(!open)}>
        {translate(L10N.ACTION_HOTEL_VERSE)}
      </Button>

      <Modal
        blur
        fit
        portal
        title={translate(L10N.LABEL_HOTEL_VERSE)}
        visible={open}
        onClose={() => setOpen(false)}
        data-testid="hv-modal"
        className={style.modal}
      >
        {open && (
          <div>
            <div
              id="hv"
              data-hvaccesstokenhv={token}
              data-hvclientid="6"
              data-hvcommandsinterface={commandInterface}
              data-hvcurrency={currency}
              data-hvexthotelid={idHotel}
              data-hvhash={hash}
              data-hvlanguage={language.toUpperCase()}
              data-hvproduct="BookYourRoom"
              data-hvsearch={search}
            ></div>
          </div>
        )}
      </Modal>
    </>
  ) : null;
};

Hotelverse.displayName = 'Mirai:Core:Rates:Hotelverse';

Hotelverse.propTypes = {
  country: PropTypes.string,
  hotelverse: PropTypes.shape({
    hash: PropTypes.string,
    token: PropTypes.string,
  }),
  infantAge: PropTypes.number,
  items: PropTypes.array,
  multiRoom: PropTypes.bool,
  taxes: PropTypes.string,
  onSelect: PropTypes.func,
};

export { Hotelverse };
