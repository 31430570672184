import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Primitive } from '../Primitive';
import style from './View.module.css';

const View = React.forwardRef(({ children, fit, forceRow = false, row, tag = 'view', wide, ...others }, ref) =>
  React.createElement(
    Primitive,
    {
      ...others,
      ref,
      tag,
      className: styles(
        style.view,
        fit && style.fit,
        row && !forceRow && style.row,
        forceRow && style.forceRow,
        wide && style.wide,
        others.className,
      ),
    },
    children,
  ),
);

View.displayName = 'Primitive:View';

View.propTypes = {
  children: PropTypes.node,
  fit: PropTypes.bool,
  forceRow: PropTypes.bool,
  row: PropTypes.bool,
  tag: PropTypes.string,
  wide: PropTypes.bool,
};

export { View };
