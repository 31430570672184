export const getUrl = ({ filteredItems, hotel, hotelId, isHotel, items, language }) => {
  if (!filteredItems || !hotel || !items || !language) return;
  const rooms = filteredItems.filter(({ soldOut } = {}) => !soldOut).map(({ id } = {}) => id);
  const soldOutRooms = items.filter(({ soldOut } = {}) => soldOut).map(({ id } = {}) => id);
  const params = {
    id: isHotel ? hotelId : hotel.id,
    lang: language,
    origin: window.location.origin,
    ...(rooms.length ? { rooms } : undefined),
    ...(soldOutRooms.length ? { soldOutRooms } : undefined),
  };

  return `${process.env.TWIN_URL}/rates/index.html?${new URLSearchParams(Object.entries(params))}`;
};
