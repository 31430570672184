import { request } from '@mirai/data-sources';
import { ServiceHotel } from '@mirai/services';

export const fetchCurrency = async (id) => {
  const { country } =
    (await request({
      hostname: process.env.SERVICE_CURRENCY,
      endpoint: '/ip/get',
      headers: { 'X-Requested-With': undefined, Authorization: 'Basic YW5vbmltb3VzOmFub25pbW91cw==' },
    })) || {};

  return country ? (await ServiceHotel.getCurrencies(id).catch(() => ({})))?.[country] : undefined;
};
