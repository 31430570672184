import React from 'react';

import { CHECKBOX, RADIO, SWITCH } from '../../InputOption';
import { getField } from './getField';

const BOOLEAN_TYPES = [CHECKBOX, RADIO, SWITCH];

export const getChildrenValues = (children) => {
  const values = {};

  React.Children.forEach(children, (child) => {
    const {
      checked = false,
      defaultChecked = false,
      defaultValue = undefined,
      type = undefined,
      value = undefined,
      ...props
    } = child?.props || {};

    const field = getField(props);

    if (field) {
      values[field] = BOOLEAN_TYPES.includes(type)
        ? type !== RADIO
          ? checked || defaultChecked
          : checked
          ? value
          : values[field]
        : value || defaultValue;
    }
  });

  return values;
};
