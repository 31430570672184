export const fieldsRequired = [
  'applyClubDiscount',
  'checkin',
  'clientCodeStrictSearch',
  'clientEmail',
  'clientName',
  'clientPhone',
  'clientSurname',
  'home',
  'idtokenprovider',
  'isAgency',
  'lang',
  'nights',
  'privacyPolicyAccepted',
  'rooms',
  'totalPrice',
];
