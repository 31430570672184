import { useStore } from '@mirai/data-sources';
import { View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { ICON } from '../../../../helpers';
import { Card } from '../components';
import { Typewriter } from '../components';
import * as style from '../Message.module.css';

const HotelInfo = ({
  locale: propLocale,
  response: {
    text,
    value: {
      address: { address, location, postalCode, province } = {},
      checkIn,
      checkOut,
      location: { latitude, longitude } = {},
    } = {},
  } = {},
}) => {
  const {
    value: { locale: stateLocale },
  } = useStore();

  const locale = propLocale || stateLocale;
  const [language = 'en'] = locale.split('-');

  return (
    <>
      <Typewriter className={style.text}>{text}</Typewriter>

      {address && (
        <Card offset={false}>
          {latitude && longitude && (
            <iframe
              src={`https://www.google.com/maps/embed/v1/place?q=${latitude}%2C${longitude}&zoom=15&language=${language}&key=${process.env.GMAP_API}`}
              className={style.map}
            />
          )}
          <View className={style.address}>
            <Typewriter action>{address}</Typewriter>
            <Typewriter light small>{`${location}, ${postalCode} - ${province}`}</Typewriter>
          </View>
        </Card>
      )}

      {(checkIn || checkOut) && <Card icon={ICON.SCHEDULE} text={`${checkIn} - ${checkOut}`} />}
    </>
  );
};

HotelInfo.propTypes = {
  locale: PropTypes.string,
  response: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.shape({}),
  }),
};

export { HotelInfo };
