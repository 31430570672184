import { formatPrice } from '../../helpers/legacy';

export const parseRate = (rate = {}, hotelCurrency = {}, nights = 0) => ({
  averagePrice: rate.price, // ? TODO: Why?
  boardName: rate.boardName,
  occupation: {
    numAdults: rate.adults,
    numChildren: rate.childrenFirtsRange + rate.childrenSecondRange,
    numBabies: rate.babies,
  },
  priceWithoutTaxes: formatPrice(rate.totalPriceWithoutTaxes, hotelCurrency),
  priceWithTaxes: formatPrice(rate.totalPriceWithTaxes, hotelCurrency),
  rateName: rate.rateName,
  roomTypeName: rate.roomTypeName,
  unitaryPriceWithoutTaxes: formatPrice((rate.totalPriceWithoutTaxes / nights).toFixed(0), hotelCurrency),
});
