import { dateFormat as dateFormatBase, DEFAULT_LOCALE, translate as translateBase } from '@mirai/locale';

import { DATE_FORMAT } from '../__shared__/BookingDates/BookingDates.constants';
import { L10N } from '../__shared__/BookingDates/BookingDates.l10n';

export const getCalendarCaption = (
  [from, to] = [],
  translate = translateBase,
  dateFormat = dateFormatBase,
  locale = DEFAULT_LOCALE,
) =>
  `${from ? dateFormat(from, { locale, ...DATE_FORMAT }) : translate(L10N.LABEL_CHECK_IN)} — ${
    to ? dateFormat(to, { locale, ...DATE_FORMAT }) : translate(L10N.LABEL_CHECK_OUT)
  }`;
