const NAMESPACE = '[data-mirai-id]';
const ID_KEY = 'miraiId';
const INSTANCE_URL_PARAMS = ['type', 'hotel'];

export const findInstance = ({ urlParams = {} } = {}) => {
  const [el] = document.querySelectorAll(NAMESPACE);
  if (!el) return undefined;

  const properties = Object.keys(el.dataset).reduce((current, name) => {
    const key = name === ID_KEY ? 'id' : name;

    return { ...current, [key]: el.dataset[name] };
  }, {});

  if (urlParams[ID_KEY]) {
    properties.id = urlParams[ID_KEY];
    INSTANCE_URL_PARAMS.filter((key) => !!urlParams[key]).forEach((key) => (properties[key] = urlParams[key]));
  }

  return properties.id ? { el, ...properties, type: properties.type || 'hotel' } : undefined;
};
