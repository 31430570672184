import { useLocale } from '@mirai/locale';
import { styles, useDevice, Text, Tooltip, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './Field.l10n';
import * as style from './Field.module.css';

const Field = ({ action, children, inline = true, inlineValue = false, label, tooltip, small, value, ...others }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();

  const textProps = { action, small };

  return (
    <View
      {...others}
      className={styles(style.field, inline && isDesktop && style.inline, value && style.withValue, others.className)}
    >
      <Text {...textProps} bold className={styles(style.label)}>
        {label}
      </Text>
      {value && (
        <Text {...textProps} className={styles(style.value, inlineValue && style.inlineValue)}>
          {value}
          {tooltip && (
            <>
              <Tooltip pressable text={tooltip}>
                <Text accent action className={styles(style.tooltip, style.noPrint)}>
                  {translate(L10N.ACTION_MORE_INFO)}
                </Text>
              </Tooltip>
              <Text {...textProps} underlined small className={style.print}>
                {`(${tooltip})`}
              </Text>
            </>
          )}
        </Text>
      )}
      {children}
    </View>
  );
};

Field.displayName = 'Mirai:Core:Booking:Field';

Field.propTypes = {
  action: PropTypes.bool,
  children: PropTypes.node,
  inline: PropTypes.bool,
  inlineValue: PropTypes.bool,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.any,
};

export { Field };
