import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Notification, styles, Theme, View } from '@mirai/ui';
import React, { useEffect, useState } from 'react';

import * as style from './Core.Notifications.module.css';

const EVENT_NAME = 'notification';
let queue = {};

const Notifications = () => {
  const { translate } = useLocale();
  const [, setTimestamp] = useState();
  const {
    value: { session: { authorization } = {} },
  } = useStore();

  useEffect(() => {
    Event.subscribe(EVENT_NAME, handleAdd);
    return () => Event.unsubscribe(EVENT_NAME, handleAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    queue = {};
  }, [authorization]);

  const handleAdd = (notification) => {
    if (!notification || window.IS_PLAYWRIGHT) return;

    const { contrast, success, warning } = notification;
    const id = `${new Date().getTime()}:${Math.random().toFixed(8)}`;

    queue[id] = { ...notification, visible: false };
    forceRender();

    setTimeout(() => {
      queue[id].visible = true;
      forceRender();
    }, parseMotion());

    if (contrast || success || warning) setTimeout(() => handleClose(id), success ? 5000 : 10000);

    return;
  };

  const handleClose = (id) => {
    if (!queue[id]) return;

    setTimestamp(() => {
      queue[id].visible = false;

      setTimeout(() => {
        delete queue[id];
        forceRender();
      }, parseMotion());

      return new Date().getTime();
    });
  };

  const forceRender = () => setTimestamp(new Date().getTime());

  const parseMotion = () => {
    const { motionExpand = '0.3s' } = Theme.get();

    return parseFloat(motionExpand.replace('s')) * 1000;
  };

  const keys = Object.keys(queue);

  return (
    <View className={style.notifications}>
      {keys.sort().map((key, index) => {
        const { defaultMessage, id, visible = false, ...props } = queue[key];

        return (
          <Notification
            {...props}
            key={key}
            wide
            onClose={() => handleClose(key)}
            className={styles(style.item, visible && style.visible)}
            style={{ zIndex: keys.length - index }}
          >
            {translate({ id, defaultMessage })}
          </Notification>
        );
      })}
    </View>
  );
};

Notifications.displayName = 'core:components:Notifications';

Notifications.propTypes = {};

export { Notifications };
