import { LocalAdapter, Storage } from '@mirai/data-sources';
import { CURRENCIES } from '@mirai/locale';

import { CACHE_LIMIT, EXCHANGE_STORAGE_KEY } from './constants';

export const convert = (from, to, amount = 0, reverse = false) => {
  if (!CURRENCIES.includes(from) || !CURRENCIES.includes(to) || !amount || typeof amount !== 'number') return undefined;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  let { [to]: rate } = storage.get(`${EXCHANGE_STORAGE_KEY}:${from}`) || {};

  if (reverse && rate) rate = 1 / rate;

  return rate ? rate * amount : undefined;
};
