import { useLocale } from '@mirai/locale';
import { InputText, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from '../Booking.l10n';
import * as style from '../Booking.module.css';

const FormResend = ({ form: { email } = {}, onChange = () => {}, onError = () => {}, ...others }) => {
  const { translate } = useLocale();

  const handleChange = (value) => {
    onChange({ email: value });
  };

  const handleError = (error) => {
    onError(error);
  };

  return (
    <View {...others}>
      <Text className={style.description}>{translate(L10N.LABEL_EMAIL_TEXT)}</Text>
      <InputText
        name="email"
        label={translate(L10N.LABEL_EMAIL)}
        required
        type="email"
        onChange={handleChange}
        onError={handleError}
        value={email}
      />
    </View>
  );
};

FormResend.displayName = 'Mirai:Core:Booking:FormResend';

FormResend.propTypes = {
  form: PropTypes.shape({
    email: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export { FormResend };
