import { Event } from '@mirai/data-sources';
import { Action } from '@mirai/ui';
import React from 'react';

import { testDNI } from './testDNI';
import { EVENT, parseHtml } from '../../helpers';
import { INPUT, INPUT_TYPE, METRIC } from '../Checkout.constants';
import { L10N } from '../Checkout.l10n';
import * as style from '../Checkout.module.css';

const { HTML, OPTION, PHONE, SELECT } = INPUT;

export const getFieldProps = (
  field = '',
  { input, type, ...props } = {},
  { value, error, language, otherConditions, session, translate = () => {} } = {},
  { name = '', url: { conditions = {} } = {} } = {},
  onPress = () => {},
) => ({
  ...props,
  checked: input === OPTION ? (value !== undefined ? value : props.checked) || false : undefined,
  children: input === HTML && props.children ? parseHtml(props.children, { small: true }) : undefined,
  disabled: (field === 'email' && session) || undefined,
  error: field !== 'phone' ? value?.length > 0 && !!error : value?.length > 5 && !!error,
  hint: translate(L10N[`${field.toUpperCase()}_HINT`]),
  id: field ? `checkout-${field.replace(/\./g, '-')}` : undefined,
  key: field,
  label:
    props.label ||
    translate(L10N[`LABEL_${field.toUpperCase()}`], {
      club: name,
      clubLink: (
        <Action
          href={conditions[language]}
          small
          target="_blank"
          underline
          onPress={() => Event.publish(EVENT.METRICS, { id: `${METRIC}:FORM:VIEW_CLUB_TERMS` })}
          style={{ display: 'inline' }}
        >
          {translate(L10N.LABEL_TERMS_AND_CONDITIONS)}
        </Action>
      ),
      otherConditions: !!otherConditions,
      termsLink: (
        <Action
          small
          onPress={() => {
            onPress();
            Event.publish(EVENT.METRICS, { id: `${METRIC}:FORM:VIEW_TERMS` });
          }}
          style={{ display: 'inline' }}
        >
          {translate(L10N.ACTION_MORE_INFO)}
        </Action>
      ),
    }) ||
    field,
  labelPrefix: field === 'phone' ? translate(L10N.LABEL_PHONE_PREFIX) : undefined,
  name: field,
  requiredText: translate(L10N.LABEL_REQUIRED),
  showRequired: true,
  small: [OPTION, HTML].includes(input),
  success: field !== 'phone' ? !!value && !error : value?.length > 4 && !error,
  test: field === 'dni' ? testDNI : undefined,
  type:
    type === INPUT_TYPE.NUMBER
      ? 'number'
      : type === INPUT_TYPE.EMAIL
      ? 'email'
      : input === PHONE
      ? 'inputPhone'
      : input === OPTION
      ? 'checkbox'
      : undefined,
  value: input === SELECT && value === undefined ? props.options[0] : value || props.value,
  className: style.wide,
});
