import { dateCalc, dateFormat } from '@mirai/locale';

import { MIN_STAY_TYPE } from '../../../../../Core.constants';
import { CALENDAR_FORMAT as format } from '../../../../helpers';

const MAX_DAYS = 30;

export const calcMinStay = ({ from, to, minStayDates, minStayType, rangeMinDays } = {}) => {
  if (to || !from || minStayType === undefined || !minStayDates) return rangeMinDays;

  let value = minStayDates[dateFormat(from, { format })];
  if (minStayType === MIN_STAY_TYPE.CHECK_IN || value === 1) return value;

  for (let index = 0; index < value; index++) {
    const current = minStayDates[dateFormat(dateCalc(from, index) || from, { format })];
    if (current > value) value = current;

    if (index === MAX_DAYS) break;
  }
  return value;
};
