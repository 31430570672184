import { toUrlParams } from '../../helpers';

export const getSignupUrl = ({
  forwarder: { signup: url } = {},
  id,
  language,
  urlParams: { miraiId, type } = {},
} = {}) => {
  if (!id || !language || !url) return;

  return `${url}${toUrlParams({
    idtokenprovider: id,
    lang: language,
    miraiId,
    type,
  })}`;
};
