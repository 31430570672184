const EXTRA_TYPE = {
  ROOM: 0,
  GUEST: 1,
  RANGE: 2,
  UNIT: 3,
};

const FREQUENCY = {
  ONCE: 0,
  NIGHTLY: 1,
};

export { EXTRA_TYPE, FREQUENCY };
