import { useStore } from '@mirai/data-sources';
import { currencyFormat, useLocale } from '@mirai/locale';
import { styles, Notification, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './PriceBreakdown.l10n';
import { Line } from './PriceBreakdown.Line';
import * as style from './PriceBreakdown.module.css';

const PriceBreakdown = ({
  children,
  dataSource: {
    agency: { base: agencyBase, commission, currency: agencyCurrency, net } = {},
    currency,
    notification: propNotification = {},
    price: { base, description, discounts = [], extras, taxes = [], total, value } = {},
    showBasePrice,
  } = {},
  ...others
}) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const { value: { locale, payment } = {} } = useStore();

  const notification = propNotification[payment?.method];
  const {
    currency: infoCurrency,
    error,
    remaining,
    success,
    type,
    total: infoTotal,
    typeFee,
    value: infoValue,
    warning,
  } = notification || {};

  return (
    <View {...others} className={styles(style.priceBreakdown, others.className)}>
      <Text bold headline={isDesktop}>
        {translate(L10N.LABEL_PRICE_SUMMARY)}
      </Text>

      <View wide className={style.lines}>
        <Line currency={currency} name={translate(L10N.LABEL_BASE_PRICE)} value={base} />
        {extras && <Line currency={currency} name={translate(L10N.LABEL_EXTRAS)} value={extras} />}
        {discounts.map(({ type, amount }) => (
          <Line
            currency={currency}
            key={type}
            name={translate(L10N[`LABEL_CHECKOUT_DISCOUNT_${type}`.toUpperCase()])}
            value={-amount}
          />
        ))}
        {taxes.map(({ type = 0, amount, fee = false, ...bindings } = {}, index) => (
          <Line
            currency={currency}
            key={index}
            name={translate(L10N[`NOTIFICATION_CHECKOUT_${fee ? 'FEE' : 'TAX'}_${type}`], { type, ...bindings })}
            value={amount}
          />
        ))}

        <Line bold currency={currency} name={translate(L10N.LABEL_TOTAL)} value={total || value} />
      </View>

      {notification && (
        <Notification inline small {...{ warning, success, error }} className={style.notification}>
          {translate(L10N[`NOTIFICATION_CHECKOUT_PRICE_${type}`], {
            total: currencyFormat({
              currency: infoCurrency,
              locale,
              value: infoTotal,
            }),
            amount: currencyFormat({
              currency: infoCurrency,
              locale,
              value: infoValue,
            }),
            remaining: currencyFormat({
              currency: infoCurrency,
              locale,
              value: remaining,
            }),
            typeFee,
          })}
        </Notification>
      )}

      {agencyBase > 0 && commission > 0 && net > 0 && (
        <Notification inline small warning className={style.notification}>
          {translate(L10N['NOTIFICATION_AGENCY'], {
            base: currencyFormat({ currency: agencyCurrency, locale, value: agencyBase }),
            commission: currencyFormat({ currency: agencyCurrency, locale, value: commission }),
            net: currencyFormat({ currency: agencyCurrency, locale, value: net }),
          })}
        </Notification>
      )}

      {showBasePrice && (base || description) && (
        <Notification inline small warning>
          {`${
            base
              ? translate(L10N.LABEL_BASE_PRICE_VALUE, {
                  value: currencyFormat({ currency, locale, value: base }),
                })
              : ''
          } ${description}`.trim()}
        </Notification>
      )}

      {children}
    </View>
  );
};

PriceBreakdown.displayName = 'Mirai:Core:PriceBreakdown';

PriceBreakdown.propTypes = {
  children: PropTypes.any,
  dataSource: PropTypes.any,
};

export { PriceBreakdown };
