import { L10N } from './Settings.l10n';
import { ICON } from '../../../helpers';

const ACCOUNT_FIELDS = ['firstName', 'lastName', 'dateOfBirth', 'subscribed'];

const CONTEXT = {
  ACCOUNT_DETAILS: 1,
  SECURITY: 2,
  SIGNOUT: 3,
};

const CONTEXTS = [
  { id: CONTEXT.ACCOUNT_DETAILS, icon: ICON.PERSON, label: L10N.LABEL_PERSONAL_DETAILS },
  { id: CONTEXT.SECURITY, icon: ICON.LOCK, label: L10N.LABEL_SECURITY },
];

export { ACCOUNT_FIELDS, CONTEXT, CONTEXTS };
