const DEFAULT_HSRI = '02040';

const ENDPOINT = {
  ADDON_TPV: 'redirectToAOPACS.htm',
  BOOKING: 'forwarder_manage_reservation.htm',
  CRIPTAN: 'redirectToCriptan.htm',
  TPV: 'redirectToTPV.htm',
};

const EVENT = {
  AMAZON_PAY_RESPONSE: 'MIRAI:PAYMENT:AMAZON_PAY:RESPONSE',
  APLAZAME_RESPONSE: 'MIRAI:PAYMENT:APLAZAME:RESPONSE',
  CTA_PRESS: 'MIRAI:PAYMENT:CTA:PRESS',
  OPEN_MODAL: 'MIRAI:PAYMENT:OPEN_MODAL',
};

const ERROR = {
  UNKNOWN: { message: 'Unknown error.' },
};

const TYPE = {
  SUBMIT: 1,
  MODAL: 2,
  REDIRECT: 3,
};

export { DEFAULT_HSRI, ENDPOINT, EVENT, ERROR, TYPE };
