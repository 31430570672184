import PropTypes from 'prop-types';
import React from 'react';

import { getProps, getTag } from './helpers';
import { NATIVE_TAGS } from './Primitive.constants';

const Primitive = React.forwardRef(({ children, className, nativeTag = false, tag = 'primitive', ...others }, ref) => {
  const customTag = !nativeTag && !NATIVE_TAGS.includes(tag) ? getTag(tag) : undefined;

  return React.createElement(
    customTag || tag,
    getProps({
      ...others,
      ...(customTag ? { is: customTag, class: className } : { className }),
      role: others.role || tag,
      ref,
    }),
    children,
  );
});

Primitive.displayName = 'Primitive';

Primitive.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  nativeTag: PropTypes.bool,
  role: PropTypes.string,
  tag: PropTypes.string,
  testId: PropTypes.string,
};

export { Primitive };
