import PropTypes from 'prop-types';
import React, { useContext, useRef, useState, createContext } from 'react';

import { LocalAdapter as adapter, Storage } from '../Storage';
import { STORE_DEFAULTS } from './Store.definition';

const StoreContext = createContext(STORE_DEFAULTS);

const useStore = () => useContext(StoreContext);

const hydrateValue = (storage, value = {}) => {
  const store = new Storage({ adapter }).get(storage) || {};

  return (Object.keys(store).length ? store : undefined) || value;
};

const StoreProvider = ({ children, storage, value: propValue }) => {
  const history = useRef([]);
  const [value, setValue] = useState(hydrateValue(storage, propValue));

  const set = (next = {}) => {
    setValue(() => {
      const nextValue = { ...value, ...next };

      history.current = [...history.current, { ...next }];
      if (storage) new Storage({ adapter }).set(storage, nextValue);

      return nextValue;
    });
  };

  const remove = () => {
    setValue({});
  };

  return (
    <StoreContext.Provider
      value={{
        history: history.current,
        set,
        remove,
        value,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node,
  storage: PropTypes.string,
  value: PropTypes.shape({}),
};

export { StoreContext, StoreProvider, useStore };
