import { TYPE } from '../Core.constants';

export const getClub = ({ config = {}, type, urlParams = {} } = {}) => {
  let {
    accomodation: {
      club: { authentication, banner, discount: discountEnabled = false, id, name, rates = false, url = {} } = {},
      variants = [],
    } = {},
  } = config;
  let active = !!id;
  if (!active) return;

  let { applyClubDiscount, idtokenprovider: idHotel = '' } = urlParams;
  let discount = applyClubDiscount ? applyClubDiscount === 'true' : undefined;

  const nextVariants = variants.map(({ club, id } = {}) => {
    if (club && Object.keys(club).length === 0) return { active: false, id };

    const { banner: variantBanner, discount: variantDiscount, rates: variantRates } = club || {};

    return {
      active: true,
      banner: { ...banner, ...variantBanner },
      discount:
        variantDiscount !== undefined
          ? variantRates !== undefined
            ? variantRates
              ? variantDiscount
              : undefined
            : rates
            ? variantDiscount
            : undefined
          : variantRates ?? rates
          ? discountEnabled
          : undefined,
      discountEnabled: variantRates ?? rates,
      id,
    };
  });

  if (type === TYPE.CHAIN) {
    const variant = nextVariants.find(({ id = '' } = {}) => id.toString() === idHotel.toString());
    if (variant) {
      active = variant.active;
      discount = discount ?? variant.discount;
      discountEnabled = variant.discountEnabled;
    }
  }
  discount = discount ?? rates;

  return {
    active,
    authentication,
    banner,
    discount: active && discountEnabled ? discount : undefined,
    id,
    name,
    url,
    variants: nextVariants,
  };
};
