const store = {};

export class MemoryAdapter {
  constructor() {
    return this;
  }

  get(key) {
    return store[key];
  }

  set(key, value) {
    if (value) store[key] = value;
    else delete store[key];
  }
}
