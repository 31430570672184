export const externalTwin = ({ instances = [] } = {}) => {
  const value = {
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
    step: 'TWIN',
  };

  window.gtag('event', 'open_twin', value);

  return value;
};
