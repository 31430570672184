import { request } from '@mirai/data-sources';

import { getLanguage } from '../helpers';
import { ENDPOINT, ERROR } from './constants';

export const confirm = ({
  dataSource: { agency, payment: { info: { amountRaw = 0, deferredDays } = {} } = {} } = {},
  form = {},
  payment,
  session = {},
  ...others
} = {}) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const { env: environment, lang, ...urlParams } = others;

    const params = {
      ...urlParams,
      accessClubToken: session?.authorization,
      deferredDays,
      isAgency: !!agency,
      lang: getLanguage(lang),
      amountRaw,
      referer: btoa(encodeURIComponent(window.location.href)),
      form,
      payment,
    };
    const { CHECKOUT: endpoint } = ENDPOINT;

    const response = await request({
      endpoint,
      hostname: process.env.SERVICE_BOOKING,
      method: 'POST',
      ...Object.fromEntries(Object.entries(params).filter(([, value]) => value !== undefined)),
    }).catch((error = {}) => {
      const { payload: { status } = {} } = error;

      return reject(ERROR[status] ? ERROR[status] : ERROR.UNKNOWN);
    });

    if (!response || !response.data) return reject(ERROR.UNKNOWN);

    const { data: { ...values } = {} } = response;

    resolve({
      ...payment,
      ...values,
      environment,
    });
  });
