import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, InputText, styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { INPUT_TEXT_TYPES, KEY_ENTER, REQUIREMENT_COMPONENT } from './Message.constants';
import {} from './Message.constants';
import { L10N } from './Message.l10n';
import * as style from './Message.module.css';
import { EVENT } from '../../../helpers';

const HIDE_SUBMIT_TYPES = ['login', 'occupation', 'profile', 'room', 'signup'];
const FORM_TYPES = ['calendar', 'login', 'occupation', 'profile', 'signup', 'text', 'password', 'email', 'number'];

const Requirement = ({
  disabled = false,
  requirement: { defaultValue, name, required, type, ...component } = {},
  onResponse = () => {},
  onRetry = () => {},
  onSubmit = () => {},
  ...others
}) => {
  const { dateFormat, translate } = useLocale();
  const { value: { lisa, locale } = {} } = useStore();

  const [value, setValue] = useState();
  const [valid, setValid] = useState();

  useEffect(() => {
    if (type !== 'login') return;
    Event.subscribe(EVENT.LOGGED, onRetry);
    return () => Event.unsubscribe(EVENT.LOGGED, onRetry);
  }, [onRetry, type]);

  const is = { [type]: !!type, text: INPUT_TEXT_TYPES.includes(type) };

  const handleChange = (value) => {
    let next = value;

    if (is.calendar) {
      const [from, to] = value || [];
      const format = 'DD/MM/YYYY';
      next = [from ? dateFormat(from, { format }) : undefined, to ? dateFormat(to, { format }) : undefined];
      setValid(!!from && !!to);
    }

    setValue(next);
  };

  const handleKeyPress = (event) => {
    if (event.key === KEY_ENTER) handleSubmit();
  };

  const handleSubmit = () => {
    const { intent, response: { form = {}, value: context = {} } = {} } = lisa;
    if (intent === 'PROFILE') return onResponse({ text: translate(L10N.NOTIFICATION_UPDATE_SUCCESS) });

    onSubmit({
      intent,
      context,
      form: { ...form, [name]: is.text && !required && !value ? ' ' : value },
    });

    if (is.text) onResponse({ text: value });
  };

  return (
    <View
      {...others}
      className={styles(
        style.requirement,
        FORM_TYPES.includes(type) && style.form,
        is.calendar && style.calendar,
        disabled && style.disabled,
        others.className,
      )}
    >
      {React.createElement(REQUIREMENT_COMPONENT[type] || InputText, {
        ...component,
        ...(is.calendar ? { format: 'DD/MM/YYYY', range: true } : {}),
        lisa: true,
        hint: is.promocode ? translate(L10N.LABEL_PROMOCODE_HINT) : undefined,
        locale,
        placeholder: is.text ? translate(L10N.LABEL_TYPE_HERE) : undefined,
        text: translate(L10N.ACTION_CONTINUE),
        value: value || defaultValue,
        onChange: handleChange,
        onError: (errors) => setValid(!errors),
        onKeyPress: !disabled && is.text ? handleKeyPress : undefined,
        onSubmit: !disabled ? handleSubmit : undefined,
        onValid: () => setValid(true),
      })}

      {!HIDE_SUBMIT_TYPES.includes(type) && !disabled && (
        <View row wide className={style.footer}>
          <Button disabled={disabled || (required && !valid)} onPress={handleSubmit}>
            {translate(L10N.ACTION_CONTINUE)}
          </Button>
        </View>
      )}
    </View>
  );
};

Requirement.propTypes = {
  disabled: PropTypes.bool,
  requirement: PropTypes.shape({}),
  onResponse: PropTypes.func,
  onRetry: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { Requirement };
