import { ACCOMMODATION_TYPE } from '../Core.constants';

export const getAccommodationType = (denomination, hotelId) => {
  const base = typeof denomination === 'object' && hotelId ? denomination[hotelId] : denomination;
  let accommodationType = ACCOMMODATION_TYPE.DEFAULT;

  if (base === 'ROOMS') accommodationType = ACCOMMODATION_TYPE.ROOM;
  else if (base === 'APARTMENTS') accommodationType = ACCOMMODATION_TYPE.APARTMENT;
  else if (base === 'VILLAS') accommodationType = ACCOMMODATION_TYPE.VILLA;
  else if (base === 'PITCHES') accommodationType = ACCOMMODATION_TYPE.PITCH;
  else if (base === 'ACCOMMODATIONS') accommodationType = ACCOMMODATION_TYPE.ACCOMMODATION;

  return accommodationType;
};
