import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { styles } from '../../helpers';
import { View } from '../../primitives';
import style from './Slider.module.css';

const Item = ({ height, image, width }) => {
  const [portrait, setPortrait] = useState();

  useEffect(() => {
    if (!image || portrait !== undefined) return;

    const img = new Image();
    img.src = image;
    img.onload = () => {
      setPortrait(img.width / img.height < 1);
    };

    return () => {
      if (img) img.onload = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <View
      tag="image"
      className={styles(style.image, portrait && style.portrait)}
      style={{ backgroundImage: image ? `url(${image})` : undefined, height, width }}
    />
  );
};

Item.propTypes = {
  height: PropTypes.number,
  image: PropTypes.string,
  width: PropTypes.number,
};

export { Item };
