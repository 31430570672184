import { translate as translateBase } from '@mirai/locale';

import { getAccommodationLabel, getGuestLabel } from '../../../../helpers';
import { EXTRA_TYPE, FREQUENCY } from '../ModalExtras.constants';
import { L10N } from '../ModalExtras.l10n';

const { GUEST, RANGE, ROOM, UNIT } = EXTRA_TYPE;
const { NIGHTLY, ONCE } = FREQUENCY;

export const getCaption = (
  {
    accommodationType,
    amount,
    frequency,
    guestType,
    inputGuests = false,
    type,
    valueAmount: guests = 0,
    valueNights: nights = 0,
  } = {},
  translate = translateBase,
) => {
  const labelAccommodation = getAccommodationLabel({ plural: true, type: accommodationType }, translate).toLowerCase();
  const labelGuest = getGuestLabel({ plural: guests > 1, type: guestType }, translate);
  const labelNights = translate(L10N.LABEL_NIGHT_COUNT, { value: nights });
  let result = '';

  if (type === UNIT && frequency === NIGHTLY) {
    result = translate(L10N.LABEL_FOR_TYPE_EXTRA, { value: labelNights });
  } else if (type === ROOM && frequency === NIGHTLY && !inputGuests)
    result = translate(L10N.LABEL_FOR_TYPE_EXTRA, {
      value: `${labelNights}${amount > 1 ? `, ${amount} ${labelAccommodation}` : ''}`,
    });
  else if (type === ROOM && frequency === ONCE) {
    result = amount > 1 ? translate(L10N.LABEL_FOR_TYPE_EXTRA, { value: `${amount} ${labelAccommodation}` }) : '';
  } else if ([GUEST, RANGE].includes(type) && frequency === ONCE) {
    result = translate(L10N.LABEL_FOR_TYPE_EXTRA, { value: `${guests} ${labelGuest}` });
  } else if (type === GUEST && frequency === NIGHTLY) {
    result = translate(L10N.LABEL_FOR_TYPE_EXTRA, { value: `${guests}  ${labelGuest},  ${labelNights}` });
  }

  return result;
};
