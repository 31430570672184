import { isDate } from './isDate';

export const UTC = (value) => {
  if (!isDate(value)) return undefined;

  const date = new Date(value.getFullYear(), value.getMonth());
  date.setDate(value.getDate());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};
