import { DEFAULT_DATE_FORMAT } from '@mirai/locale';

const DELIMITERS = ['/', '.', '-'];

export const formatValue = (nextValue = '', format = DEFAULT_DATE_FORMAT, stateValue = '') => {
  const delimiter = DELIMITERS.find((item) => format.includes(item)) || DELIMITERS[0];
  const increase = nextValue.length > stateValue.length;
  let result;

  if (increase) {
    nextValue = nextValue.replace(/[^\d]/g, '');
    if (nextValue === stateValue) return;

    result = '';
    let index = 0;
    Array.from(format).forEach((char, charIndex) => {
      if (char !== delimiter) {
        const nextChar = nextValue.substr(index, 1);
        if (!nextChar) return;

        result += nextChar;
        if (format.substr(charIndex + 1, 1) === delimiter) result += delimiter;
        index++;
      }
    });
  } else {
    if (stateValue.charAt(stateValue.length - 1) === delimiter) result = nextValue.slice(0, -1);
    else result = nextValue;
  }

  return result !== undefined ? result.substr(0, format.length) : undefined;
};
