import { Theme } from '@mirai/ui';

export const getStyle = () => {
  const { content, font, inputDisabled, inputFontSize } = Theme.get();

  return {
    styles: {
      '*': {
        color: content,
        fontFamily: font,
        fontSize: inputFontSize,
        letterSpacing: 0,
      },
      '*::placeholder': 'color:' + inputDisabled,
      cardNumber: 'padding: 0;',
      cvv: 'padding: 0;',
    },
  };
};
