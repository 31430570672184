import { LocalAdapter, Storage, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Notification } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  BUTTON_ID,
  DEFAULT_LANGUAGE,
  ERRORS,
  LANGUAGES,
  OPTIONS_AUTHORIZE,
  OPTIONS_BUTTON,
  OPTIONS_WALLET,
  ORDER_REFERENCE_KEY,
  WALLET_ID,
  SCRIPT_ID,
} from './AmazonPay.constants';
import { L10N } from './AmazonPay.l10n';
import * as style from './AmazonPay.module.css';
import { getScriptUrl } from './helpers';
import { EVENT } from '../../../ButtonPayment/ButtonPayment.constants';

const error = new URLSearchParams(document.location.search).get('amazonPayErrorCode');
const storage = new Storage({ adapter: LocalAdapter });

const AmazonPay = ({
  clientId = process.env.METHOD_AMAZON_CLIENT_ID,
  region,
  sellerId = process.env.METHOD_AMAZON_SELLER_ID,
  onError = () => {},
}) => {
  const { locale, translate } = useLocale();
  const { value: { payment = {} } = {}, set } = useStore();

  const [flow, setFlow] = useState();
  const [orderReference, setOrderReference] = useState(error ? storage.get(ORDER_REFERENCE_KEY) : undefined);
  const [ready, setReady] = useState();

  useEffect(() => {
    window.onAmazonLoginReady = handleLoginReady;
    window.onAmazonPaymentsReady = handlePaymentsReady;

    const exists = document.getElementById(SCRIPT_ID);
    if (!exists) {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.async = 'async';
      script.src = getScriptUrl({ clientId, region, sellerId });

      document.head.appendChild(script);

      setReady(true);
    }

    return () => {
      const el = document.getElementById(SCRIPT_ID);
      el.parentNode.removeChild(el);
      window.onAmazonLoginReady = undefined;
      window.onAmazonPaymentsReady = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ready) return;

    document.addEventListener(EVENT.CTA_PRESS, handleButtonPress);
    document.addEventListener(EVENT.AMAZON_PAY_RESPONSE, handleResponse);

    if (!orderReference) onError({ error: true });

    return () => {
      document.removeEventListener(EVENT.CTA_PRESS, handleButtonPress);
      document.removeEventListener(EVENT.AMAZON_PAY_RESPONSE, handleResponse);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow, orderReference, ready]);

  useEffect(() => {
    if (!error) return;
    onError({ authorizeError: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleButtonPress = () => {
    // eslint-disable-next-line no-undef
    OffAmazonPayments.initConfirmationFlow(sellerId, orderReference, (confirmationFlow) => {
      setFlow(confirmationFlow);
      set({ payment: { ...payment, orderReference } });
    });
  };

  // eslint-disable-next-line no-undef
  const handleLoginReady = () => amazon.Login.setClientId(clientId);

  const handleOnComplete = () => {
    // eslint-disable-next-line no-undef
    new OffAmazonPayments.Widgets.Wallet({
      amazonOrderReferenceId: orderReference,
      sellerId,
      ...OPTIONS_WALLET,
      onError: () => {
        // eslint-disable-next-line no-undef
        amazon.Login.logout();
        document.querySelector(`#${WALLET_ID}`)?.firstChild?.remove();
      },
      onOrderReferenceCreate: (orderReferenceProp) => {
        const orderReference = orderReferenceProp.getAmazonOrderReferenceId();
        setOrderReference(orderReference);
        storage.set(ORDER_REFERENCE_KEY, orderReference);
        onError({});
      },
      onPaymentSelect: () => onError({}),
    }).bind(WALLET_ID);
  };

  const handlePaymentsReady = () => {
    // eslint-disable-next-line no-undef
    OffAmazonPayments.Button(BUTTON_ID, sellerId, {
      language: LANGUAGES[locale] ?? DEFAULT_LANGUAGE,
      ...OPTIONS_BUTTON,
      authorization: () => {
        // eslint-disable-next-line no-undef
        amazon.Login.authorize(OPTIONS_AUTHORIZE).onComplete(handleOnComplete);
      },
    });
    if (orderReference) handleOnComplete();
  };

  const handleResponse = ({ detail: { response } = {} }) => (response ? flow.success() : flow.error());

  return (
    <>
      <div id={BUTTON_ID} className={style.button}></div>
      <div id={WALLET_ID} className={style.wallet}></div>
      {error && (
        <Notification error small wide className={style.error}>
          {translate(ERRORS[error] || L10N.NOTIFICATION_AMAZON_UNKNOWN_ERROR)}
        </Notification>
      )}
    </>
  );
};

AmazonPay.displayName = 'Mirai:Payments:AmazonPay';

AmazonPay.propTypes = {
  clientId: PropTypes.string,
  region: PropTypes.string,
  sellerId: PropTypes.string,
  onError: PropTypes.func,
};

export { AmazonPay };
