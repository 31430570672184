export const generateId = (input = Math.random()) => {
  const source = JSON.stringify(input);
  let value = 0;

  for (let i = 0; i < source.length; i++) {
    value += source.charCodeAt(i);
    value += value << 10;
    value ^= value >> 6;
  }
  value += value << 3;
  value ^= value >> 11;
  value += value << 15;

  return value >>> 0;
};
