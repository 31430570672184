import { useDevice } from '@mirai/ui';
import { View } from '@mirai/ui';
import React from 'react';

import { Skeleton } from '../__shared__';

const SPACE = 'var(--mirai-ui-space-M)';

export default () => {
  const { isMobile, isDesktop } = useDevice();

  const inputProps = { wide: true, style: { height: 'var(--mirai-ui-input-min-height)' } };
  const sectionProps = { base: true, style: { gap: SPACE, padding: SPACE } };
  const fieldsetProps = { row: true, wide: true, style: { gap: SPACE } };

  const renderSummary = () => (
    <Skeleton base style={{ flex: 1, maxWidth: 'calc(var(--mirai-ui-breakpoint-S) - var(--mirai-ui-space-M) * 2)' }}>
      <View
        style={{
          borderBottom: 'solid var(--mirai-ui-border-width) var(--mirai-ui-content-background)',
          gap: SPACE,
          padding: SPACE,
        }}
      >
        <Skeleton headline={isDesktop} words={2} />
        <View row>
          <View wide>
            <Skeleton words={1} />
            <Skeleton action words={3} />
            <Skeleton small words={2} />
          </View>

          <View wide>
            <Skeleton words={1} />
            <Skeleton action words={3} />
            <Skeleton small words={2} />
          </View>
        </View>

        <View>
          <Skeleton words={2} />
          <Skeleton action words={4} />
          <Skeleton small words={2} />
        </View>

        <View>
          <Skeleton words={1} />
          <Skeleton action words={3} />
          <Skeleton small words={8} />
        </View>

        <Skeleton words={3} />
      </View>

      <View style={{ gap: SPACE, padding: SPACE }}>
        <Skeleton headline={isDesktop} words={2} />

        <View style={{ gap: 'var(--mirai-ui-space-S)' }}>
          {Array.from({ length: 4 }).map((_, index) => (
            <View key={index} row style={{ justifyContent: 'space-between' }}>
              <Skeleton action={index < 3} words={2} />
              <Skeleton action={index < 3} words={1} />
            </View>
          ))}
        </View>

        {!isMobile && (
          <>
            <Skeleton button wide large words={2} />

            <View style={{ alignItems: 'center' }}>
              <Skeleton small words={7} />
            </View>
          </>
        )}
      </View>
    </Skeleton>
  );

  return (
    <View style={{ alignItems: 'start' }} wide>
      <View
        row
        wide
        style={{
          alignItems: 'start',
          alignSelf: 'center',
          gap: SPACE,
          maxWidth: 'var(--mirai-ui-breakpoint-content)',
        }}
      >
        <View style={{ flex: 1, gap: SPACE }}>
          {!isDesktop && renderSummary()}

          <Skeleton {...sectionProps}>
            <Skeleton headline={isDesktop} words={2} />
            <Skeleton small words={5} />

            {Array.from({ length: 3 }).map((_, index) => (
              <View key={index} {...fieldsetProps} wide>
                <Skeleton {...inputProps} />
              </View>
            ))}
            <Skeleton {...inputProps} />
            <Skeleton small words={13} />
            {Array.from({ length: 2 }).map((_, index) => (
              <View key={index} row style={{ gap: SPACE }}>
                <Skeleton icon />
                <Skeleton small words={15} />
              </View>
            ))}
          </Skeleton>

          <Skeleton {...sectionProps}>
            <Skeleton headline={isDesktop} words={2} />
            <Skeleton words={2} />
            <Skeleton {...inputProps} />
            <Skeleton {...inputProps} />
            <View {...fieldsetProps}>
              <Skeleton {...inputProps} />
              <Skeleton {...inputProps} />
            </View>
          </Skeleton>

          <Skeleton {...sectionProps}>
            <Skeleton headline={isDesktop} words={2} />
            <Skeleton wide style={{ height: 'calc(var(--mirai-ui-input-min-height) * 2)' }} />
          </Skeleton>

          <Skeleton {...sectionProps}>
            <View>
              <Skeleton small words={6} />
              <Skeleton small words={24} />
            </View>

            <View>
              <Skeleton small words={2} />
              <Skeleton small words={32} />
            </View>

            <View>
              <Skeleton small words={2} />
              <Skeleton small words={8} />
            </View>

            <View>
              <Skeleton small words={2} />
              <Skeleton small words={6} />
            </View>
          </Skeleton>
        </View>

        {isDesktop && renderSummary()}
      </View>
    </View>
  );
};
