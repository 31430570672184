const SCRIPT_ID = 'script-gmap';

export const loadGMaps = () =>
  new Promise((resolve, reject) => {
    if (document.getElementById(SCRIPT_ID)) return resolve();

    const script = document.createElement('script');

    script.id = SCRIPT_ID;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GMAP_API}&callback`;
    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
