import { useLocale } from '@mirai/locale';
import { styles, Text } from '@mirai/ui';
import React from 'react';

import { L10N } from './TextRequiredFields.l10n';
import * as style from './TextRequiredFields.module.css';

const TextRequiredFields = ({ ...others }) => {
  const { translate } = useLocale();

  return (
    <Text small {...others} className={styles(style.text, others.className)}>
      <span className={style.asterisk}>*</span> {translate(L10N.CAPTION)}
    </Text>
  );
};

TextRequiredFields.displayName = 'Mirai:Component:TextRequiredFields';

TextRequiredFields.propTypes = {};

export { TextRequiredFields };
