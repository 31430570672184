export const L10N = {
  BOOKING_CODE_LABEL: { id: 'user.label.booking_code' },
  BOOKING_QUERY: { id: 'user.action.booking_query' },
  CTA: { id: 'user.action.booking_query_cta' },
  DESCRIPTION: { id: 'user.label.form_description' },
  LABEL_RESERVATION_CODE: { id: 'common.label.reservation_code' },
  LABEL_RESERVATION_CODE_DESCRIPTION: { id: 'common.label.reservation_code_description' },
  NOTIFICATION_ERROR_AUTHENTICATION: { id: 'user.notification.authentication_error' },
  NOTIFICATION_ERROR_NETWORK: { id: 'common.notification.network_error' },
  PIN_CODE_LABEL: { id: 'user.label.pincode' },
  TITLE: { id: 'user.label.booking_query_title' },
};
