import { LocalAdapter, request, Storage } from '@mirai/data-sources';
import { CURRENCIES } from '@mirai/locale';

import { CACHE_LIMIT, EXCHANGE_STORAGE_KEY } from './constants';

export const exchange = async (currency) => {
  if (!CURRENCIES.includes(currency)) return undefined;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${EXCHANGE_STORAGE_KEY}:${currency}`;
  const cache = storage.get(key);

  if (cache) return cache;

  const response = await request({
    hostname: process.env.SERVICE_CURRENCY,
    endpoint: `/getCurrencyInfo?currency=${currency}`,
    headers: {},
  }).catch((error) => {
    // ! TODO: Should call home (sentry, mixpanel, ...)
    // eslint-disable-next-line no-console
    console.error('ServiceCurrency.exchange', error);
  });

  if (response) {
    // * Don't save the currency of the request
    delete response[currency];
    storage.set(key, response);
  }

  return response;
};
