export const getHotels = (dataSource = [], id) => {
  if (!id) return;

  let hotels;
  for (const { ids = [], tagId, tags = [] } of dataSource) {
    if ((!tagId || tagId !== id) && tags.length > 0) {
      hotels = getHotels(tags, id);

      if (hotels?.length > 0) break;
    } else if (tagId === id) {
      hotels = ids;
      break;
    }
  }
  return hotels;
};
