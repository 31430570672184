import { METHOD, PROVIDER } from '../../Payment/Payment.constants';
import { FormAddon, FormAdyen, FormCheckout, FormPaycomet, FormSipay } from '../components';

const { CARD, PCIPROXY, TPV } = METHOD;
const { ADDON, ADYEN, CHECKOUT, SIPAY, PAYCOMET } = PROVIDER;

export const getComponent = ({ cardValidation = false, method, provider } = {}) => {
  let component;

  const isValidPayment = method === TPV || ([CARD, PCIPROXY].includes(method) && cardValidation);

  if (provider === ADDON && isValidPayment) component = FormAddon;
  else if (provider === ADYEN && isValidPayment) component = FormAdyen;
  else if (provider === CHECKOUT && isValidPayment) component = FormCheckout;
  else if (provider === SIPAY && isValidPayment) component = FormSipay;
  else if (provider === PAYCOMET && isValidPayment) component = FormPaycomet;

  return component;
};
