import PropTypes from 'prop-types';
import React from 'react';

import { RatesExplorer } from '../__shared__/RatesExplorer';

const Rooms = ({ id: roomId, ...others }) => <RatesExplorer {...{ roomId, ...others }} />;

Rooms.displayName = 'Mirai:Core:Rooms';

Rooms.propTypes = {
  id: PropTypes.string,
};

export { Rooms };
