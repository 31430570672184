import { LocalAdapter, Storage } from '@mirai/data-sources';
import { CURRENCIES, parseDate, isDate } from '@mirai/locale';

import { ServiceHotel } from '../Hotel';
import { CACHE_LIMIT, PRICING_STORAGE_KEY } from './constants';
import { convertCurrency, sanitizePrices } from './helpers';

export const getStatic = async (id, from, months = 1, userCurrency) => {
  if (!id || !from || months < 0 || !isDate(parseDate(from)) || (userCurrency && !CURRENCIES.includes(userCurrency)))
    return;

  const date = from.slice(3).split('/').join('');
  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${PRICING_STORAGE_KEY}:static:${id}:${date}:${months}`;
  let value = storage.get(key);

  if (!value) {
    // ! TODO: Should use @mirai/data-sources request
    const response = await fetch(`${process.env.SERVICE_PRICING}/${id}/${date}.json`).catch((error) => {
      // ! TODO: Should call home (sentry, mixpanel, ...)
      // eslint-disable-next-line no-console
      console.error('ServicePricing.getStatic', error);
    });
    value = await response?.json();

    let { country } = value;
    if (!userCurrency) country = response?.headers.get('cloudfront-viewer-country');

    value = { ...value, country, prices: sanitizePrices(value?.prices) };
    storage.set(key, value);
  }

  // ! TODO: Should call this service before getStatic()
  const hotelCurrencies = !userCurrency ? await ServiceHotel.getCurrencies(id) : undefined;
  const customCurrency = userCurrency || (hotelCurrencies && hotelCurrencies[value.country]);

  const { currency, prices } = value || {};
  if (customCurrency && customCurrency !== currency) {
    value = {
      ...value,
      currency: customCurrency,
      prices: await convertCurrency(currency, customCurrency, prices),
    };
  }

  return value;
};
