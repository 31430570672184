import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Icon, ICON, Text, View } from '../../primitives';
import { DIRECTION_TYPE, Theme } from '../../theme';
import { Button } from '../Button';
import { VISIBLE_WEEKS } from './Calendar.constants';
import style from './Calendar.module.css';
import { Week } from './Calendar.Week';
import { Weekdays } from './Calendar.Weekdays';
import { getHeader, getWeekNumber } from './helpers';

export const Month = React.forwardRef(
  (
    {
      disabledPast,
      focus,
      format,
      from,
      instance,
      locale,
      range = false,
      selected,
      timestamp,
      to,
      vertical,
      onChange = () => {},
      onFocus = () => {},
      onNext,
      onPrevious,
      ...others
    },
    ref,
  ) => {
    const defaultDirection = Theme.getDirection() === DIRECTION_TYPE.LEFT;
    const weekNumber = getWeekNumber(instance);
    const { testId } = others;

    return (
      <View tag="calendar-month" ref={ref} className={styles(style.month, others.className)}>
        <View forceRow className={style.header}>
          {onPrevious && (
            <Button small squared transparent onPress={onPrevious} testId={testId ? `${testId}-previous` : undefined}>
              <Icon value={defaultDirection ? ICON.LEFT : ICON.RIGHT} />
            </Button>
          )}
          <Text bold className={style.title}>
            {getHeader(instance, locale)}
          </Text>
          {onNext && (
            <Button small squared transparent onPress={onNext} testId={testId ? `${testId}-next` : undefined}>
              <Icon value={defaultDirection ? ICON.RIGHT : ICON.LEFT} />
            </Button>
          )}
        </View>

        {!vertical && <Weekdays locale={locale} />}

        {VISIBLE_WEEKS.map((week) => (
          <Week
            {...{
              ...others,
              disabledPast,
              focus,
              format,
              from,
              locale,
              range,
              selected,
              timestamp,
              to,
            }}
            key={week}
            month={instance.getMonth()}
            number={weekNumber + week}
            year={instance.getFullYear()}
            onFocus={onFocus}
            onPress={onChange}
          />
        ))}
      </View>
    );
  },
);

Month.displayName = 'Component:Calendar:Month';

Month.propTypes = {
  captions: PropTypes.shape({}),
  focus: PropTypes.any,
  instance: PropTypes.any,
  selected: PropTypes.any,
  disabledDates: PropTypes.arrayOf(PropTypes.string),
  disabledPast: PropTypes.bool,
  format: PropTypes.string,
  from: PropTypes.instanceOf(Date),
  locale: PropTypes.string,
  range: PropTypes.bool,
  timestamp: PropTypes.number,
  to: PropTypes.instanceOf(Date),
  tooltips: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  vertical: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
};
