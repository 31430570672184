import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../../helpers';
import { Text } from '../../../primitives';
import style from '../InputText.module.css';

const Hint = ({ disabled, error, hint, ...others }) => (
  <Text
    small
    className={styles(style.text, style.hint, disabled && style.disabled, error && style.error, others.className)}
  >
    {hint}
  </Text>
);

Hint.displayName = 'Component:InputText.Hint';

Hint.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hint: PropTypes.string,
};

export { Hint };
