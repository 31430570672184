const DEFAULT_VALUE = '';

export const toUrlParams = (values = {}) => {
  if (typeof values !== 'object') return DEFAULT_VALUE;

  let params = Object.fromEntries(Object.entries(values).filter((value) => value[1] !== undefined));

  if (process.env.NODE_ENV === 'development') params.env = 'dev';
  else if (process.env.NODE_ENV === 'preproduction') params.env = 'pre';
  else if (process.env.NODE_ENV !== 'production') params.env = process.env.NODE_ENV;

  let urlParams = DEFAULT_VALUE;
  if (Object.keys(params).length > 0) urlParams = `?${new URLSearchParams(Object.entries(params))}`;

  return urlParams;
};
