import { DEFAULT_AMOUNT, TYPE } from '../components/__shared__/Occupation/Occupation.constants';

const { CHILD } = TYPE;

export const prepareRoom = (dataSource = []) => {
  const room = [];

  dataSource.forEach(({ max, min = 0, type }, index) => {
    if (min || type !== CHILD) {
      const amount = type !== CHILD && (max > 1 || max === undefined) && DEFAULT_AMOUNT >= min ? DEFAULT_AMOUNT : min;
      room[index] = {
        ages: Array.from({ length: amount }, () => undefined),
        amount,
        type,
      };
    }
  });

  return room;
};
