import { getCardConfig } from './getCardConfig';
import { CARD } from '../../../Payment.constants';

export const getCardType = (card, cards = []) => {
  const { maxLength = {} } = getCardConfig(card);
  if (!card || maxLength.card !== card.length) return;

  const cardTypes = {
    [CARD.AMERICAN_EXPRESS]: /^3[47][0-9]{13}$/,
    [CARD.DINERS_CLUB]: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    [CARD.DISCOVER]: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    [CARD.JCB]: /^(?:2131|1800|35\d{3})\d{11}$/,
    [CARD.MAESTRO]: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
    [CARD.MASTERCARD]: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
    [CARD.UNIONPAY]: /^(62[0-9]{14,17})$/,
    [CARD.VISA]: /^4[0-9]{12}(?:[0-9]{3})?$/,
  };

  for (const cardType in cardTypes) {
    if (card.replaceAll(' ', '').match(cardTypes[cardType]) && cards.includes(cardType)) {
      return cardType;
    }
  }
};
