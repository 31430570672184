import { dateDiff, dateFormat } from '@mirai/locale';

import { getOccupationTotals } from '../../../../components/helpers';
import { ACCOMMODATION_TYPE } from '../../../../Core.constants';
import { fetchUrlParams } from '../../../../helpers';
import { dataLayerPush } from '../../helpers';
import { formatPrice } from '../helpers/legacy';

const ACCOMMODATION_TYPE_NAME = Object.keys(ACCOMMODATION_TYPE);

export const checkoutResponse = ({
  device: { mobile } = {},
  response: { infantAge, tracking = {} } = {},
  store: {
    currency,
    finder: { calendar = [], occupation = [], promocode = '' } = {},
    hotel: { accommodationType, variants = {} } = {},
    language,
    session,
  } = {},
} = {}) => {
  // ! -- TODO: Try to get all data from store -------------------------------------------------------------------------
  const { idchain, idtokenprovider: hotelId } = fetchUrlParams(location.search);

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { userCurrency: { value: userCurrencyValue } = {} } = {},
    country,
    hotelInfo: { currency: hotelCurrency = {}, propertyName, propertyType } = {},
    reservedExtras = {},
    reservedRooms = [],
    totalExtrasTaxes,
    totalExtrasWithTaxes,
    totalExtrasWithoutTaxes,
    totalPriceWithTaxes,
    totalPriceWithTaxesWithoutExtras,
    totalPriceWithoutTaxes,
    totalPriceWithoutTaxesWithoutExtras,
    totalTaxes,
  } = tracking;

  const { adults = 0, children = 0 } = getOccupationTotals({ infantAge, occupation });
  const { days: nights } = dateDiff(...calendar);
  const checkin = dateFormat(calendar[0], { format: 'DD/MM/YYYY' });
  const checkout = dateFormat(calendar[1], { format: 'DD/MM/YYYY' });

  const rooms = reservedRooms.map(
    ({
      averagePriceWithoutTaxes,
      daysPriceBoard = [],
      defaultBoardDescription,
      numRoomsToReserve,
      occupation: { numAdults, numBabies, totalChildren: numChildren } = {},
      rateId,
      rateName,
      roomTypeName,
    } = {}) => {
      let priceWithTaxes = 0;
      let priceWithoutTaxes = 0;
      daysPriceBoard.forEach((day) => {
        priceWithTaxes += day.priceWithTaxes;
        priceWithoutTaxes += day.priceWithoutTaxes;
      });

      return {
        boardName: defaultBoardDescription,
        numRooms: numRoomsToReserve,
        occupation: { numAdults, numChildren, numBabies },
        rateId,
        rateName,
        roomName: roomTypeName,
        priceWithTaxes,
        priceWithoutTaxes,
        unitaryPriceWithoutTaxes: formatPrice(averagePriceWithoutTaxes, hotelCurrency),
      };
    },
  );

  const extras = Object.values(reservedExtras).map(
    ({
      amount,
      id,
      name,
      nightsToApply,
      totalPriceWithTaxes,
      totalPriceWithoutTaxes,
      unitaryPriceWithTaxes,
      unitaryPriceWithoutTaxes,
    } = {}) => ({
      amount,
      extraId: id,
      extraName: name,
      nightsToApply,
      totalPriceWithTaxes,
      totalPriceWithoutTaxes,
      unitaryPriceWithTaxes,
      unitaryPriceWithoutTaxes,
    }),
  );

  const dataLayer = {
    chainId: idchain,
    checkin,
    checkout,
    coupon: promocode,
    currency: hotelCurrency.code,
    device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
    event: 'init',
    exchangeRate: userCurrencyValue && hotelCurrency.value && userCurrencyValue / hotelCurrency.value,
    home: document.location.hostname,
    hotelId,
    language,
    lodgingDenomination: ACCOMMODATION_TYPE_NAME[variants[hotelId]?.accommodationType || accommodationType],
    nights,
    numAdults: adults,
    numChildren: children,
    numRooms: occupation.length,
    products: { rooms, extras, checkin, checkout },
    propertyName,
    propertyType,
    step: 'CLIENTFORM',
    totalExtrasTaxes: formatPrice(totalExtrasTaxes, hotelCurrency),
    totalExtrasWithTaxes: formatPrice(totalExtrasWithTaxes, hotelCurrency),
    totalExtrasWithoutTaxes: formatPrice(totalExtrasWithoutTaxes, hotelCurrency),
    totalPriceWithTaxesWithExtras: formatPrice(totalPriceWithTaxes, hotelCurrency),
    totalPriceWithTaxesWithoutExtras: formatPrice(totalPriceWithTaxesWithoutExtras, hotelCurrency),
    totalPriceWithoutTaxesWithExtras: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    totalPriceWithoutTaxesWithoutExtras: formatPrice(totalPriceWithoutTaxesWithoutExtras, hotelCurrency),
    totalTaxes: formatPrice(totalTaxes, hotelCurrency),
    userCountry: country,
    userCurrency: currency,
    userLogged: !!session,
  };

  dataLayerPush(dataLayer);

  return dataLayer;
};
