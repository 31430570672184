import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Primitive } from '../Primitive';
import { color, parseMarkdown } from './helpers';
import style from './Text.module.css';

const Text = ({
  accent,
  accentLight,
  accentDark,
  action,
  bold,
  brand,
  capitalize,
  children,
  dark,
  error,
  headline,
  info,
  level = 3,
  light,
  markdown = true,
  medium = false,
  small,
  success,
  tag = 'text',
  tiny,
  underline,
  upperCase,
  warning,
  wide,
  ...others
}) =>
  React.createElement(
    Primitive,
    {
      ...others,
      tag,
      className: styles(
        style.text,
        bold && style.bold,
        medium && !bold && style.medium,
        brand && style.brand,
        capitalize && style.capitalize,
        color({ accent, accentDark, accentLight, dark, error, info, light, success, warning }),
        headline
          ? style[`headline-${level}`]
          : action
          ? style.action
          : small
          ? style.small
          : tiny
          ? style.tiny
          : style.paragraph,
        underline && style.underline,
        upperCase && style.upperCase,
        wide && style.wide,
        others.className,
      ),
    },
    markdown
      ? Array.isArray(children)
        ? children.map((value) => parseMarkdown(value))
        : parseMarkdown(children, tag)
      : children,
  );

Text.displayName = 'Primitive:Text';

Text.propTypes = {
  accent: PropTypes.bool,
  accentLight: PropTypes.bool,
  accentDark: PropTypes.bool,
  action: PropTypes.bool,
  bold: PropTypes.bool,
  brand: PropTypes.bool,
  capitalize: PropTypes.bool,
  children: PropTypes.any,
  dark: PropTypes.bool,
  error: PropTypes.bool,
  headline: PropTypes.bool,
  info: PropTypes.bool,
  level: PropTypes.number,
  light: PropTypes.bool,
  markdown: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  success: PropTypes.bool,
  tag: PropTypes.string,
  tiny: PropTypes.bool,
  underline: PropTypes.bool,
  upperCase: PropTypes.bool,
  warning: PropTypes.bool,
  wide: PropTypes.bool,
};

export { Text };
