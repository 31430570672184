import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Action, Notification } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { L10N } from './NotificationRequiredFields.l10n';
import * as style from './NotificationRequiredFields.module.css';
import { EVENT } from '../../helpers';

const OFFSET = 16;

const NotificationRequiredFields = ({ form = 'form', values = {}, ...others }) => {
  const { translate } = useLocale();
  const {
    value: { components = {} },
  } = useStore();

  useEffect(() => {
    if (metrics) {
      const fields = Object.keys(values);
      Event.publish(EVENT.METRICS, { id: `${metrics}:ERROR_REQUIRED_FIELDS`, fields, total: fields.length });
      fields.forEach((key) => Event.publish(EVENT.METRICS, { id: `${metrics}:ERROR_REQUIRED_FIELDS:${key}` }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handlePress = (id) => {
    const el = document.querySelector(id);
    el && window.scrollTo({ top: el.getBoundingClientRect().top + window.scrollY - OFFSET, behavior: 'smooth' });
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:ERROR_REQUIRED_FIELDS:GO_TO_FIELD`, field: id });
  };

  const metrics = components.checkout
    ? 'CHECKOUT'
    : components.signup
    ? 'SIGNUP'
    : components.profile
    ? 'PROFILE'
    : undefined;

  const totalErrors = Object.keys(values).length;
  const [firstError] = Object.keys(values);

  return totalErrors ? (
    <Notification {...others} row error wide>
      {translate(L10N.NOTIFICATION_FIX_FORM_ERRORS, {
        amount: totalErrors,
        link: (
          <Action
            underline
            onPress={() => handlePress(`#${form}-${firstError.replace(/\./g, '-')}`)}
            className={style.field}
          >
            {translate(L10N.ACTION_FIX_FIRST_ERROR)}
          </Action>
        ),
      })}
    </Notification>
  ) : null;
};

NotificationRequiredFields.displayName = 'Mirai:Core:NotificationRequiredFields';

NotificationRequiredFields.propTypes = {
  form: PropTypes.string,
  values: PropTypes.shape({}),
};

export { NotificationRequiredFields };
