import { LocalAdapter, request, Storage } from '@mirai/data-sources';
import { DEFAULT_LOCALE } from '@mirai/locale';

import { CACHE_LIMIT, CLUB_STORAGE_KEY } from './constants';

export const get = async ({ id, language = DEFAULT_LOCALE.split[0] } = {}) => {
  if (!id || typeof id !== 'string') return;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${CLUB_STORAGE_KEY}:get:${id}:${language}`;
  let value = storage.get(key);

  if (!value) {
    value = await request({
      hostname: process.env.SERVICE_CLUB,
      endpoint: `/club/v1/get_info_club?idTokenProvider=${id}&lang=${language}`,
      headers: { 'X-Requested-With': undefined }, // ! TODO: Should be fixed by backend team
    }).catch((error) => {
      const { payload } = error || {};
      if (payload?.result === 'FAILURE' && payload?.cause === 'NON_EXISTENT_CLUB') return;

      throw error;
    });

    value && storage.set(key, value);
  }

  return value;
};
