import { ICON } from '../../../../helpers';

export const getActionIcon = (url = '') =>
  url.includes('tel:')
    ? ICON.PHONE
    : url.includes('mailto:')
    ? ICON.MAIL
    : url.includes('http:') || url.includes('https:')
    ? ICON.URL
    : undefined;
