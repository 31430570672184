import { ServiceUser } from '@mirai/services';

const MIRAI = 1;
const PESTANA = 3;

export const checkSession = async (idHotel, session, config = {}) => {
  const { club: { club: { authentication: { type = MIRAI } = {}, idClub } = {} } = {} } = config;

  const response = await ServiceUser.check({ idHotel, session });

  if (!response && type === PESTANA && idClub)
    document.location.href = `${process.env.SERVICE_USER}/club/v1/forwarder/expiration?idClub=${idClub}`;

  return response;
};
