import { formatPrice } from '../helpers/legacy';

export const bookingResponse = ({ instances = [], response: { tracking = {} } = {}, store = {} } = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { checkin, checkout, occupation, coupon = '', nights } = {},
    hotelInfo = {},
    idReservation,
    pendingPayment,
    reservedExtras = {},
    reservedRooms = [],
    totalPriceCommission,
    totalPriceWithoutTaxes,
    totalTaxes,
  } = tracking;

  const { currency: hotelCurrency = {}, propertyName, propertyType } = hotelInfo;
  const { urlParams: { idHotel } = {} } = store;

  const commonProps = {
    affiliation: propertyName,
    coupon,
    currency: hotelCurrency.code,
    item_brand: propertyName,
  };

  const rooms = reservedRooms.map(
    ({ averagePriceWithoutTaxes, defaultBoardDescription, numRoomsToReserve, rateId, rateName, roomTypeName }) => ({
      ...commonProps,
      item_category: 'Habitación',
      item_category2: rateName,
      item_category3: defaultBoardDescription,
      item_category4: propertyType,
      item_category5: idHotel,
      item_name: roomTypeName,
      price: formatPrice(averagePriceWithoutTaxes, hotelCurrency),
      quantity: numRoomsToReserve * nights,
      item_id: `${rateId}`,
    }),
  );

  const extras = Object.keys(reservedExtras)
    .map((key) => reservedExtras[key])
    .map(({ id, name, multiplierPrice, priceWithoutTaxes, type }) => ({
      ...commonProps,
      item_category: 'Extra',
      item_category2: type,
      item_name: name,
      price: formatPrice(priceWithoutTaxes, hotelCurrency),
      quantity: multiplierPrice,
      item_id: `${id}`,
    }));

  const value = {
    affiliation: propertyName,
    coupon,
    currency: hotelCurrency.code,
    items: [...rooms, ...extras],
    shipping: totalPriceCommission,
    tax: formatPrice(totalTaxes, hotelCurrency),
    transaction_id: `Reservation code ${idReservation}`,
    value: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
  };

  window.gtag('event', pendingPayment ? 'BTP' : 'purchase', value);
  window.gtag('event', 'data_search_info', {
    checkin,
    checkout,
    hotelId: idHotel,
    coupon,
    non_interaction: true,
    occupation,
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
    step: 'CONFIRMATION',
  });

  return value;
};
