import { IS_JEST, IS_PLAYWRIGHT } from '../../../helpers';
import { BANNED_JEST_PROPS, COMMON_PROPS, TESTING_PROPS } from '../Primitive.constants';

export const getProps = (props = {}) => {
  const TESTING = IS_JEST || IS_PLAYWRIGHT;
  const values = {};

  Object.entries(props)
    .filter(([key]) => !((!TESTING && TESTING_PROPS.includes(key)) || (TESTING && BANNED_JEST_PROPS.includes(key))))
    .forEach(([key, value]) => (values[COMMON_PROPS[key] || key] = value));

  return values;
};
