import { MemoryAdapter } from './memory';

export class LocalAdapter {
  constructor() {
    try {
      this.local = localStorage;
      // eslint-disable-next-line no-empty
    } catch {}

    if (!this.local) this.memory = new MemoryAdapter();

    return this;
  }

  get(key) {
    return this.local ? this.local.getItem(key) : this.memory.get(key);
  }

  set(key, value) {
    if (!this.local) {
      this.memory.set(key, value);
      return;
    }

    if (value) this.local.setItem(key, value);
    else this.local.removeItem(key);
  }
}
