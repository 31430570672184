import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, Pressable, styles, Text, useDevice, View } from '@mirai/ui';
import React, { useState } from 'react';

import { Account } from './Settings.Account';
import { CONTEXT, CONTEXTS } from './Settings.constants';
import * as style from './Settings.module.css';
import { Password } from './Settings.Password';
import { Signout } from './Settings.Signout';
import Skeleton from './Settings.Skeleton';

const Settings = () => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const { value: { skeleton = false } = {} } = useStore();

  const [context, setContext] = useState(CONTEXT.ACCOUNT_DETAILS);

  const { label } = CONTEXTS.find((item = {}) => item.id === context);

  return !skeleton ? (
    <View row={!isMobile} className={style.settings}>
      <View className={style.menu}>
        {CONTEXTS.map(({ id, icon, label }) => (
          <Pressable
            key={id}
            onPress={() => setContext(id)}
            className={styles(style.option, context === id && style.active)}
            testId={`profile-context-${id}`}
          >
            <Icon value={icon} />
            <Text>{translate(label)}</Text>
          </Pressable>
        ))}
      </View>
      <View wide className={style.form}>
        <Text bold headline level={2}>
          {translate(label)}
        </Text>
        {context === CONTEXT.ACCOUNT_DETAILS && <Account />}
        {context === CONTEXT.SECURITY && (
          <>
            <Password />
            <Signout />
          </>
        )}
      </View>
    </View>
  ) : (
    <Skeleton />
  );
};

Settings.displayName = 'Mirai:Core:Profile:Settings';

Settings.propTypes = {};

export { Settings };
