import { translate as translateBase } from '@mirai/locale';

import { OCCUPATION_TYPE } from './constants';
import { getAgeRange } from './getAgeRange';
import { getGuestLabel } from './getGuestLabel';

const { GUEST } = OCCUPATION_TYPE;

export const getOccupationLabel = (
  { abbreviation = false, ages: [min, max] = [], amount, type = GUEST } = {},
  translate = translateBase,
) =>
  `${amount > 0 ? `${amount} ` : ''}${getGuestLabel({ abbreviation, plural: amount > 1, type }, translate)}${
    min !== undefined || max !== undefined ? ` (${getAgeRange([min, max], translate)})` : ''
  }`;
