import { Theme } from '../../../theme';
import { VISIBLE_WEEKS } from '../Calendar.constants';

export const getScrollTo = (months) => {
  const { length: weeks } = VISIBLE_WEEKS;
  const { calendarCell = '48px' } = Theme.get();
  const [cellHeight = 48] = calendarCell.split('px');

  return months ? weeks * parseInt(cellHeight) * months : -1;
};
