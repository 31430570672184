import { LocalAdapter, request, Storage } from '@mirai/data-sources';
import { CURRENCIES, parseDate, isDate } from '@mirai/locale';

import { CACHE_LIMIT, PRICING_STORAGE_KEY } from './constants';
import { sanitizePrices } from './helpers';

export const get = async (id, from, months = 1, currency) => {
  if (!id || !from || months < 0 || !isDate(parseDate(from)) || (currency && !CURRENCIES.includes(currency)))
    return undefined;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${PRICING_STORAGE_KEY}:${id}:${from}:${months}:${currency || ''}`;
  const cache = storage.get(key);

  let response;

  const baseURL = `getAvaCalendar?idHotel=${id}&startDate=${from}&numberOfMonths=${months}`;

  if (cache) {
    response = cache;
  } else {
    response = await request({
      hostname: process.env.SERVICE_PRICING,
      endpoint: currency ? `${baseURL}&currency=${currency}` : baseURL,
      headers: {},
    }).catch((error) => {
      // ! TODO: Should call home (sentry, mixpanel, ...)
      // eslint-disable-next-line no-console
      console.error('ServicePricing.get', error);
    });

    const { prices = {} } = response || {};
    const healthyPrices = sanitizePrices(prices);

    storage.set(key, { ...response, prices: healthyPrices });
  }

  return response;
};
