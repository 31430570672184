import { Event } from '@mirai/data-sources';
import { Modal, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import * as style from './ModalUrl.module.css';
import { EVENT } from '../../../../../helpers';

const ModalUrl = ({ title, url, visible, onClose = () => {}, ...others }) => {
  useEffect(() => {
    if (visible) Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:URL', title, url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleClose = () => {
    Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:URL:CLOSE' });
    onClose();
  };

  const handleLoad = () => {
    Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:URL:LOAD' });
  };

  return (
    <Modal
      {...others}
      fit
      title={title}
      visible={visible}
      onClose={handleClose}
      onOverflow={handleClose}
      className={style.modal}
    >
      <View tag="iframe" onLoad={handleLoad} src={url} title="modal" className={style.content} />
    </Modal>
  );
};

ModalUrl.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalUrl.displayName = 'Mirai:Core:Rates:Item:ModalUrl';

export { ModalUrl };
