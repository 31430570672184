import { useLocale } from '@mirai/locale';
import { Button, Icon, styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './ButtonMore.l10n';
import * as style from './ButtonMore.module.css';
import { ICON } from '../../helpers';

const ButtonMore = ({ text, onPress, ...others }) => {
  const { translate } = useLocale();

  const { testId = 'button-more', ...rest } = others;

  return (
    <View {...rest} wide className={styles(style.container, others.className)}>
      <Button transparent onPress={onPress} testId={testId}>
        <Icon value={ICON.EXPAND_MORE} />
        {text || translate(L10N.ACTION_VIEW_MORE)}
      </Button>
    </View>
  );
};

ButtonMore.displayName = 'Mirai:Core:ButtonMore';

ButtonMore.propTypes = {
  text: PropTypes.string,
  onPress: PropTypes.func,
};

export { ButtonMore };
