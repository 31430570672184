import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { getInputErrors, styles } from '../../helpers';
import { Primitive } from '../Primitive';
import style from './Select.module.css';

const EMPTY = '';

const Select = ({
  disabled,
  disabledOptions = [],
  emptyOption,
  options = [],
  value,
  values = [],
  onChange,
  onEnter,
  onError,
  onLeave,
  ...others
}) => {
  useEffect(() => {
    const errors = getInputErrors({ ...others });
    if (errors && onError) onError(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, event) => {
    onError && onError(getInputErrors({ ...others, value }));
    onChange && onChange(value, event);
  };

  return React.createElement(
    Primitive,
    {
      ...others,
      disabled,
      tag: 'select',
      value,
      ...(!disabled
        ? {
            onChange: (event) => handleChange(event.target.value, event),
            onFocus: onEnter,
            onBlur: onLeave,
          }
        : {}),
      className: styles(style.select, value === EMPTY && style.empty, others.className),
      'aria-label': others['aria-label'] || value,
    },
    <>
      {emptyOption && (
        <Primitive tag="option" value={EMPTY} aria-label={emptyOption}>
          {emptyOption}
        </Primitive>
      )}
      {options.map((option, index) => (
        <Primitive
          tag="option"
          disabled={disabledOptions[index]}
          key={option}
          value={values[index] || option}
          aria-label={option}
        >
          {option}
        </Primitive>
      ))}
    </>,
  );
};

Select.displayName = 'Primitive:Select';

Select.propTypes = {
  disabled: PropTypes.bool,
  disabledOptions: PropTypes.arrayOf(PropTypes.bool),
  emptyOption: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  values: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onError: PropTypes.func,
  onLeave: PropTypes.func,
};

export { Select };
