import { useStore } from '@mirai/data-sources';
import PropTypes from 'prop-types';
import React from 'react';

import { Account } from './Session.Account';
import { Login } from './Session.Login';

const Session = ({ ghost, ...others }) => {
  const {
    value: { session, club },
  } = useStore();

  const [ghostLogin, ghostAccount] = ghost?.children || [];

  return club
    ? React.createElement(session ? Account : Login, { ...others, ghost: session ? ghostAccount : ghostLogin })
    : null;
};

Session.displayName = 'Mirai:Core:Session';

Session.propTypes = {
  ghost: PropTypes.any,
};

export { Session };
