import { getUrl, shell } from './helpers';

const SHELL_TITLE = 'requestFile()';

export const requestFile = ({ endpoint, hostname, method = 'GET', ...others } = {}) =>
  new Promise((resolve, reject) => {
    const url = getUrl({ endpoint, hostname });

    fetch(url, { method, ...others })
      .then(async (response) => {
        if (response.status >= 400) {
          shell.error(SHELL_TITLE, url, { response });
          reject({ code: response.status });
        } else {
          shell.info(SHELL_TITLE, url, { response });
          resolve(response.blob());
        }
      })
      .catch(({ message = 'Something wrong happened. Try again.', response } = {}) => {
        shell.error(SHELL_TITLE, url, { response, message });
        reject({
          code: response ? response.status : 500,
          message,
        });
      });
  });
