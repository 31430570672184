import { Event, useStore } from '@mirai/data-sources';
import { currencyFormat, dateDiff, parseDate, useLocale } from '@mirai/locale';
import { ServiceBooking } from '@mirai/services';
import { Action, Text, Notification, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ButtonPayment, Countdown, NotificationRequiredFields, Payment } from '../../__shared__';
import { EVENT } from '../../helpers';
import { FORMAT_PAY_LATER_DATE, STATE } from '../Booking.constants';
import { L10N } from '../Booking.l10n';
import * as style from '../Booking.module.css';
import { getButtonPaymentKey } from '../helpers';

const BookingPayment = ({ dataSource: { state, ...dataSource } = {}, onError = () => {}, ...others }) => {
  const { isMobile } = useDevice();
  const { locale, translate } = useLocale();
  const {
    value: {
      hotel = {},
      payment: storePayment = {},
      urlParams: { code, idHotel: id, tracking, utm_medium, utm_source } = {},
    },
  } = useStore();

  const { method } = storePayment;

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState({});
  const [visiblePayment, setVisiblePayment] = useState(false);
  const [requiredFields, setRequiredFields] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const { payment: { info: { time: { value } = {} } = {} } = {} } = dataSource || {};
      const now = new Date().getTime();

      if (value < now && !window.IS_PLAYWRIGHT) window.location.reload();
    }, 1000);

    return () => clearInterval(interval);
  }, [dataSource]);

  useEffect(() => {
    const isPayLater = (utm_medium === 'email' && utm_source === 'confirmation') || state === STATE.POS_ERROR;

    setVisiblePayment(isPayLater);
    isPayLater && Event.publish(EVENT.PAY_LATER, { utm_medium, utm_source });
  }, [utm_source, utm_medium, state]);

  const handleError = () => {
    setBusy(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setRequiredFields(error);
  };

  const handleSubmit = (value) => {
    if (hasErrors) return handleError();

    setRequiredFields();
    onError();
    const { id } = dataSource;
    return ServiceBooking.update({ code, id, payment: { ...storePayment, ...value }, tracking }).catch((error) => {
      setBusy(false);
      onError(error);
    });
  };

  const handlePress = () => {
    if (hasErrors) {
      handleError();
      return false;
    }

    setBusy(true);
  };

  const { currency, payment = {}, price: { prepayment = {} } = {} } = dataSource || {};
  const { info: { time = {} } = {} } = payment;
  const hasRequiredFields = Object.keys(requiredFields || {}).length > 0;
  const hasErrors = Object.keys(error || {}).length > 0;
  const { currency: paymentCurrency, value } = prepayment[method] || {};
  const { days } = dateDiff(new Date(), new Date(time?.value));
  const { info: { amount } = {} } = payment;
  const isPOSError = state === STATE.POS_ERROR;
  const l10n = isPOSError
    ? L10N.NOTIFICATION_POS_ERROR
    : amount
    ? L10N.NOTIFICATION_PAY_LATER_PREPAY
    : L10N.NOTIFICATION_PAY_LATER_GUARANTEE;

  return (
    <>
      <Notification
        {...others}
        error={isPOSError}
        title={isPOSError ? translate(L10N.NOTIFICATION_POS_TITLE_ERROR) : undefined}
        warning
        wide
      >
        <View row={!isMobile} className={style.notificationPayment}>
          {React.createElement(
            days >= 3 ? Text : Countdown,
            { action: true, l10n, timestamp: time?.value },
            translate(l10n, {
              date: new Date(time?.value).toLocaleDateString(locale, FORMAT_PAY_LATER_DATE),
            }),
          )}
          {!visiblePayment && (
            <Action onPress={() => setVisiblePayment(true)} className={[style.action, style.noPrint]}>
              {translate(L10N.ACTION_COMPLETE_NOW)}
            </Action>
          )}
        </View>
      </Notification>

      {hasRequiredFields && <NotificationRequiredFields form="checkout" values={requiredFields} />}

      {visiblePayment && (
        <Payment
          {...{
            ...payment,
            config: { ...payment.config, publicKey: payment.config?.publicKey[method] },
            info: { ...payment.info, date: payment.info?.date ? parseDate(payment.info?.date) : undefined },
          }}
          currency={currency}
          requiredText={translate(L10N.LABEL_REQUIRED)}
          showErrors={hasRequiredFields}
          onChange={() => setRequiredFields()}
          onError={setError}
          className={style.noPrint}
        >
          <ButtonPayment
            {...{
              ...payment,
              config: { ...payment.config, publicKey: payment.config?.publicKey[method] },
            }}
            busy={busy}
            hotel={{ ...hotel, id }}
            wide={isMobile}
            promise={handleSubmit}
            onError={handleError}
            onPress={handlePress}
          >
            {translate(getButtonPaymentKey({ ...payment.config, ...payment.info, method, payLater: true }), {
              amount: currencyFormat({ currency: paymentCurrency, locale, value }),
            })}
          </ButtonPayment>
        </Payment>
      )}
    </>
  );
};

BookingPayment.displayName = 'Mirai:Core:Booking:BookingPayment';

BookingPayment.propTypes = {
  dataSource: PropTypes.any,
  onError: PropTypes.func,
};

export { BookingPayment };
