import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Action, Modal, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { L10N } from './BookingTerms.l10n.js';
import * as style from './BookingTerms.module.css';
import { Field } from '../../Booking/components';
import { EVENT } from '../../helpers/constants.js';
import { parseHtml } from '../../helpers/parseHtml';

const BookingTerms = ({
  dataSource: { cancelationPolicy, otherConditions, paymentsConditions, hotel: { privacyPolicy } = {} } = {},
  small,
  ...others
}) => {
  const { translate } = useLocale();
  const {
    value: { components = {} },
  } = useStore();
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(true);
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:TERMS:CANCELLATION_INFO` });
  };

  const handlePrivacyPolicy = () => {
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:TERMS:PRIVACY_POLICY` });
  };

  const metrics = components.checkout ? 'CHECKOUT' : components.booking ? 'BOOKING' : undefined;
  const fieldProps = { inline: false, small };

  return (
    <>
      <View {...others} className={styles(style.box, style.others, others.className)}>
        {paymentsConditions && (
          <Field
            {...fieldProps}
            label={translate(L10N.LABEL_PAYMENT_TERMS)}
            value={parseHtml(paymentsConditions, { small })}
          />
        )}

        {cancelationPolicy && (
          <Field {...fieldProps} label={translate(L10N.LABEL_CANCELLATION_POLICY)} value={cancelationPolicy.summary}>
            {cancelationPolicy?.detail && (
              <Action small={small} underline onPress={handleModal} className={style.noPrint}>
                {translate(L10N.ACTION_MORE_INFO)}
              </Action>
            )}
          </Field>
        )}

        {privacyPolicy && (
          <Field
            {...fieldProps}
            label={translate(L10N.LABEL_PRIVACY_POLICY)}
            value={translate(L10N.LABEL_PRIVACY_POLICY_DESCRIPTION, {
              link: (
                <Action
                  href={privacyPolicy}
                  target="_blank"
                  small={small}
                  underline
                  onPress={handlePrivacyPolicy}
                  className={styles(style.linkPrivacy, style.noPrint)}
                >
                  {translate(L10N.ACTION_MORE_INFO)}
                </Action>
              ),
            })}
          />
        )}
        {otherConditions && (
          <Field
            {...fieldProps}
            label={translate(L10N.LABEL_OTHERS_TERMS)}
            value={parseHtml(otherConditions, { small })}
          />
        )}
      </View>

      {cancelationPolicy?.detail && (
        <Modal title={translate(L10N.LABEL_CANCELLATION_POLICY)} visible={modal} onClose={() => setModal(false)}>
          <Text className={style.modal}>{cancelationPolicy.detail}</Text>
        </Modal>
      )}
    </>
  );
};

BookingTerms.displayName = 'Mirai:Core:BookingTerms';

BookingTerms.propTypes = {
  dataSource: PropTypes.shape({
    cancelationPolicy: PropTypes.shape({
      summary: PropTypes.string,
      details: PropTypes.string,
    }),
    payment: PropTypes.shape({}),
    paymentsConditions: PropTypes.string,
  }),
  small: PropTypes.bool,
};

export { BookingTerms };
