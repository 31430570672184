import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceFeatures } from '@mirai/services';
import { Button, styles, Theme, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Hotelverse } from './components';
import { FEATURES } from './Rates.constants';
import { L10N } from './Rates.l10n';
import * as style from './Rates.module.css';
import { EVENT, opacity } from '../helpers';

const Header = ({
  children,
  dataSource = {},
  multiRoom,
  onMapView = () => {},
  onSelect = () => {},
  onTwin = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const { value: { finder: { place: { id: [hotelId] = [], isHotel } = {} } = {}, hotel } = {} } = useStore();

  const handlePress = () => {
    Event.publish(EVENT.EXTERNAL_TWIN, { event: EVENT.EXTERNAL_TWIN });
    onTwin(true);
  };

  const { contentBackground } = Theme.get();
  const { hotelverse: { hash, token } = {}, hotels = [], items = [] } = dataSource;
  const hasHotelverse = items.length > 0 && !multiRoom && hash !== undefined && token !== undefined;
  const twin = items.length > 0 && !multiRoom && ServiceFeatures.get(FEATURES.TWIN, isHotel ? hotelId : hotel.id);

  return children || hasHotelverse || hotels.length > 1 || twin ? (
    <View
      row
      className={styles(style.header, style.blur, multiRoom && !isMobile && style.sticky, others.className)}
      style={{ backgroundColor: opacity(contentBackground, 0.66) }}
    >
      {children}

      {hasHotelverse && <Hotelverse {...{ ...dataSource, items, multiRoom, onSelect, ...others }} />}
      {twin && (
        <Button secondary onPress={handlePress} className={isMobile && style.wide}>
          {translate(L10N.ACTION_IMMERSIVE_BOOKING)}
        </Button>
      )}
      {hotels.length > 1 && (
        <Button secondary wide={isMobile} onPress={() => onMapView(hotels)}>
          {translate(L10N.ACTION_SHOW_ON_MAP)}
        </Button>
      )}
    </View>
  ) : null;
};

Header.displayName = 'Mirai:Core:Rates:Header';

Header.propTypes = {
  children: PropTypes.node,
  dataSource: PropTypes.shape({}),
  multiRoom: PropTypes.bool,
  onMapView: PropTypes.func,
  onSelect: PropTypes.func,
  onTwin: PropTypes.func,
};

export { Header };
