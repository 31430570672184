import { LocalAdapter, Storage } from '@mirai/data-sources';
import { request } from '@mirai/data-sources';
import { DEFAULT_LOCALE } from '@mirai/locale';

import { DICTIONARY_STORAGE_KEY } from './constants';

const handleError = (error) => {
  // ! TODO: Should call home (sentry, mixpanel, ...)
  // eslint-disable-next-line no-console
  console.error('ServiceDictionary.get', error);
};

export const get = async (locale = DEFAULT_LOCALE, legacy = true) => {
  const [language] = locale.split('-');
  if (!language) return undefined;

  let dictionary = await request({
    hostname: process.env.SERVICE_DICTIONARY,
    endpoint: `/dictionary?locale=${locale}&legacy=${legacy}`,
  }).catch(handleError);

  if (!Object.values(dictionary || {}).length) dictionary = undefined;

  const storage = new Storage({ adapter: LocalAdapter });
  storage.set(`${DICTIONARY_STORAGE_KEY}:${language}`, dictionary);

  return dictionary;
};
