import { Slider as SliderBase, styles } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { CARD_WIDTH, SCROLL_DELAY } from '../ModalTwin.constants';
import * as style from '../ModalTwin.module.css';

const Slider = ({ children, index, onChange = () => {}, ...others }) => (
  <SliderBase
    index={index}
    width={CARD_WIDTH}
    scrollDelay={SCROLL_DELAY}
    showButtons={false}
    onChange={onChange}
    className={styles(style.slider, others.className)}
  >
    {children}
  </SliderBase>
);

Slider.displayName = 'Mirai:Core:Rates:ModalTwin:Slider';

Slider.propTypes = {
  children: PropTypes.array,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

export { Slider };
