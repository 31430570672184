import { useLocale } from '@mirai/locale';
import { Button, Icon, Pressable, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Prices } from './Prices';
import { ICON } from '../../../../helpers';
import { Preview } from '../../Item/Item.Preview';
import { CARD_WIDTH } from '../ModalTwin.constants';
import { L10N } from '../ModalTwin.l10n';
import * as style from '../ModalTwin.module.css';

const CardExpanded = ({
  item: { id, soldOut, ...item } = {},
  last = false,
  visible,
  onReduce = () => {},
  onSelect = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  return (
    <View className={styles(style.expanded, visible && style.visible)}>
      <View
        className={styles(
          style.card,
          soldOut ? style.disabled : undefined,
          last ? style.last : undefined,
          others.className,
        )}
      >
        {isMobile && (
          <Pressable onPress={() => onReduce()} className={style.collapse}>
            <Icon value={ICON.CLOSE_FULLSCREEN} />
          </Pressable>
        )}
        <View className={style.preview}>
          <Preview key={id} {...{ ...item, id, soldOut }} isSoldOut={soldOut} showActions={false} width={CARD_WIDTH} />
        </View>
        <View row className={style.footer}>
          <Prices {...{ ...item, ...others }} className={soldOut && style.hidden} />
          <Button disabled={soldOut} small={isMobile} onPress={soldOut ? undefined : () => onSelect(id)}>
            {translate(soldOut ? L10N.LABEL_NOT_AVAILABLE : L10N.ACTION_SEE_OPTIONS)}
          </Button>
        </View>
      </View>
    </View>
  );
};

CardExpanded.displayName = 'Mirai:Core:Rates:ModalTwin:CardExpanded';

CardExpanded.propTypes = {
  item: PropTypes.object,
  last: PropTypes.bool,
  visible: PropTypes.bool,
  onReduce: PropTypes.func,
  onSelect: PropTypes.func,
};

export { CardExpanded };
