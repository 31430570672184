const COLORS = ['accent'];

const DEBOUNCE_TIMEOUT_CACHE = 2000;

const DIRECTION_TYPE = {
  LEFT: 0,
  RIGHT: 1,
};
const DIRECTION = {
  [DIRECTION_TYPE.LEFT]: {
    'text-align': 'left',
    'text-direction': 'ltr',
    'row-direction': 'row',
  },
  [DIRECTION_TYPE.RIGHT]: {
    'text-align': 'right',
    'text-direction': 'rtl',
    'row-direction': 'row-reverse',
  },
};
const DOMAIN = '--mirai-ui-';

export { COLORS, DEBOUNCE_TIMEOUT_CACHE, DIRECTION, DIRECTION_TYPE, DOMAIN };
