import { CustomElement } from '../helpers';

CustomElement('ui-checkbox', HTMLDivElement, 'div');
CustomElement('ui-icon', HTMLDivElement, 'span');
CustomElement('ui-layer', HTMLDivElement, 'div');
CustomElement('ui-layer-content', HTMLDivElement, 'div');
CustomElement('ui-pressable', HTMLButtonElement, 'button');
CustomElement('ui-primitive', HTMLDivElement, 'div');
CustomElement('ui-radio', HTMLDivElement, 'div');
// CustomElement('ui-select', HTMLSelectElement, 'select');
// CustomElement('ui-option', HTMLOptionElement, 'option');
CustomElement('ui-scrollview', HTMLDivElement, 'div');
CustomElement('ui-switch', HTMLDivElement, 'div');
CustomElement('ui-view', HTMLDivElement, 'div');
CustomElement('ui-text', HTMLSpanElement, 'span');
CustomElement('ui-h1', HTMLSpanElement, 'h1');
CustomElement('ui-h2', HTMLSpanElement, 'h2');
CustomElement('ui-h3', HTMLSpanElement, 'h3');
