import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceBooking } from '@mirai/services';
import { Action, Notification, Progress, styles, useDevice, View } from '@mirai/ui';
import React, { useEffect, useState } from 'react';

import { METRIC } from './Checkout.constants';
import { L10N } from './Checkout.l10n';
import * as style from './Checkout.module.css';
import Skeleton from './Checkout.skeleton';
import { Confirmation, Form } from './partials';
import { ERROR, VALIDATE_ERROR } from '../../Core.constants';
import { BookingDetails, BookingSummary, BookingTerms, Footer, NotificationRequiredFields } from '../__shared__';
import { ModalAgency } from '../Booking/partials/Booking.ModalAgency';
import { Header } from '../Header';
import { EVENT, getRatesUrl, replaceUrlParams } from '../helpers';

const Checkout = ({ ...others }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      currency,
      finder = {},
      hotel,
      language,
      locale,
      session,
      skeleton = false,
      urlParams: { posError: tpvError, ...urlParams } = {},
      variant,
    },
  } = useStore();

  const [busy, setBusy] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [error, setError] = useState();
  const [requiredFields, setRequiredFields] = useState();
  const [modalAgency, setModalAgency] = useState(false);
  const [responseError, setResponseError] = useState();
  const [validateError, setValiadeError] = useState();

  useEffect(() => {
    try {
      const { place, promocode } = finder;
      const { meta, applyClubDiscount, extras, nights, rooms = '' } = urlParams;

      const metrics = {
        tpvError: !!tpvError,
        // -- type
        meta: meta === 'true',
        // -- selection
        chain: !!place,
        nights: parseInt(nights),
        rooms: rooms.split(',').length || 1,
        loyalty: !!applyClubDiscount,
        extras: extras ? JSON.parse(extras)?.length : 0,
        promocode: !!promocode,
        session: !!session,
      };
      Event.publish(EVENT.METRICS, { id: `${METRIC}:RENDER`, ...metrics });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Metrics.error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (skeleton) return;

    (async () => await fetch())();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, session]);

  useEffect(() => {
    if (!validateError) return;

    const { EXTRA_CHANGED, NOT_ENOUGH_ROOMS, NOT_ENOUGH_ROOMS_FOR_OFFER, NO_SELECTED_ROOMS, PRICE_CHANGED } =
      VALIDATE_ERROR;
    (async () => {
      const { price, type } = validateError;

      if (type === PRICE_CHANGED && price) {
        replaceUrlParams({ totalPrice: price });
        await fetch(price);
      } else if ([EXTRA_CHANGED, NOT_ENOUGH_ROOMS, NOT_ENOUGH_ROOMS_FOR_OFFER, NO_SELECTED_ROOMS].includes(type)) {
        document.location.href = getRatesUrl({
          currency,
          language,
          notification: type,
          promocode: finder.promocode || '',
          variant,
          ...urlParams,
        });
      } else {
        setResponseError(ERROR.UNKNOWN);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateError]);

  const fetch = async (price) => {
    setBusy(true);
    const response = await ServiceBooking.checkout({
      currency,
      hotel,
      language,
      locale,
      session,
      ...urlParams,
      ...(price ? { totalPrice: price } : undefined),
    }).catch((error) => {
      if (error.type)
        setValiadeError(validateError?.price && error?.price ? { type: VALIDATE_ERROR.URL_GENERIC } : error);
      else setResponseError(error);
      Event.publish(EVENT.METRICS, { id: `${METRIC}:ERROR`, error });
    });
    setBusy(false);
    setValiadeError();
    setDataSource(response);
    if (response) {
      const betterPrice = price && price < urlParams.totalPrice;
      Event.publish(EVENT.CHECKOUT_RESPONSE, { event: EVENT.CHECKOUT_RESPONSE, response });
      Event.publish(EVENT.METRICS, {
        id: `${METRIC}:READY`,
        agency: !!response.agency,
        betterPrice,
        payment: !!response.payment,
      });
      if (betterPrice !== undefined) {
        Event.publish(EVENT.NOTIFICATION, {
          ...(betterPrice ? { success: true } : { warning: true }),
          defaultMessage: translate(betterPrice ? L10N.NOTIFICATION_BETTER_PRICE : L10N.NOTIFICATION_PRICE_CHANGED),
        });
      }
    }
  };

  const handleModalAgency = () => {
    Event.publish(EVENT.METRICS, { id: `${METRIC}:AGENCY:INFO` });
    setModalAgency(true);
  };

  const handleErrorConfirmation = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setRequiredFields(error);
  };

  const { agency = {}, payment } = dataSource || {};
  const response = dataSource !== undefined;
  const hasRequiredFields = Object.keys(requiredFields || {}).length > 0;

  return (
    <>
      <Header />

      <View role="checkout" tag="checkout" testId={others.testId} className={styles(style.container, others.className)}>
        <Progress indeterminate visible={busy || (!response && !responseError)} className={style.progress} />
        {response ? (
          <View row={isDesktop} className={style.content}>
            <View wide className={style.main}>
              {hasRequiredFields && (
                <NotificationRequiredFields form="checkout" schema={dataSource?.form} values={requiredFields} />
              )}

              {(agency.name || !payment || tpvError) && (
                <Notification error={tpvError} small info wide className={styles(style.agency, others.className)}>
                  {!tpvError && !payment && `${translate(L10N.NOTIFICATION_NO_CARD)} `}
                  {!tpvError &&
                    agency.name &&
                    translate(L10N.NOTIFICATION_AGENCY, {
                      agency: agency.name,
                      link: (
                        <>
                          <Action underline small onPress={handleModalAgency} className={styles(style.action)}>
                            {translate(L10N.ACTION_MORE_INFO)}
                          </Action>
                        </>
                      ),
                    })}
                  {tpvError && translate(L10N.NOTIFICATION_UNKNOWN_POS_ERROR)}
                </Notification>
              )}

              {!isDesktop && (
                <BookingSummary box dataSource={dataSource}>
                  <BookingDetails dataSource={dataSource} expanded />
                </BookingSummary>
              )}

              <Form dataSource={dataSource} showErrors={hasRequiredFields} onError={setError} />

              {isDesktop && <BookingDetails dataSource={dataSource} className={style.box} />}

              <BookingTerms dataSource={dataSource} small />
            </View>

            <Confirmation
              busy={busy}
              dataSource={dataSource}
              error={Object.keys(error || {}).length > 0}
              onError={handleErrorConfirmation}
              onValid={() => setRequiredFields()}
            />
          </View>
        ) : responseError ? (
          <Notification error large>
            {translate(L10N.NOTIFICATION_ERROR[responseError?.code || ERROR.UNKNOWN])}
          </Notification>
        ) : (
          <Skeleton />
        )}
      </View>
      {agency.name && <ModalAgency {...agency} visible={modalAgency} onClose={() => setModalAgency(false)} />}

      <Footer />
    </>
  );
};

Checkout.displayName = 'Mirai:Core:Checkout';

Checkout.propTypes = {};

export { Checkout };
