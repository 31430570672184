import { dateFormat, parseDate } from '@mirai/locale';

export const getMonthRef = ({ autoScroll, el, index, instance, value } = {}) => {
  const date = value?.length && value[0] && value[1] && parseDate(value[0]);

  return date &&
    !el.current &&
    !autoScroll &&
    dateFormat(new Date(date.getFullYear(), date.getMonth(), 1)) ===
      dateFormat(new Date(instance.getFullYear(), instance.getMonth() + index, 1))
    ? el
    : undefined;
};
