import { Theme } from '@mirai/ui';

import { findDangerousCSSRules } from './findDangerousCSSRules';
import { IS_JEST } from '../components/helpers';

let cssTheme;
if (!IS_JEST) cssTheme = require('bundle-text:../theme.css');

const LEGACY_ACCENT_VARIABLE = '--corporative-color';

export const theme = ({ accent = '', findDangerousCSS = false } = {}) => {
  // Import css variables from theme.css
  if (cssTheme) {
    const style = document.createElement('style');
    const head = document.head;
    style.textContent = cssTheme;
    head.insertBefore(style, head.firstChild);
  }

  // Generate accent palette using legacy
  let legacyAccent = getComputedStyle(document.documentElement).getPropertyValue(LEGACY_ACCENT_VARIABLE);
  if (legacyAccent) {
    legacyAccent = legacyAccent.replace(/^"|"$/g, '').trim();
    Theme.generatePalette('accent', legacyAccent);
  }

  if (accent.length === 6) Theme.generatePalette('accent', `#${accent}`);

  // Dangerous CSS rules
  if (findDangerousCSS) {
    const cssRules = findDangerousCSSRules();
    // eslint-disable-next-line no-console
    if (cssRules.length) console.warn('WARNING: css rules ending with html tags', cssRules);
  }
};
