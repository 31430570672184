import { Event, useStore } from '@mirai/data-sources';
import { currencyFormat, useLocale } from '@mirai/locale';
import { Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { L10N } from './OTAPrices.l10n';
import * as style from './OTAPrices.module.css';
import { Skeleton } from '../../../__shared__';
import { EVENT } from '../../../helpers';

const OTAPrices = ({ ...others }) => {
  const { isMobile } = useDevice();
  const { currency, locale, translate } = useLocale();
  const ref = useRef(null);
  const { value: { finder: { calendar, occupation, place, promocode } = {} } = {} } = useStore();

  const [dataSource, setDataSource] = useState();
  const [fetching, setFetching] = useState(true);
  const [otas, setOtas] = useState([]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      localStorage.setItem(
        'cmprm_screens_override',
        '[{ "type": "StartsWith", "path": "http://local.mirai.com:8080", "id_screen": 1233 }]',
      );
    }

    const handleHeaderReady = (event) => {
      const { detail: { currency, is_hide: disabled = false, price } = {} } = event;
      if (disabled) {
        window.removeEventListener('cmprm_event_rc_header_ready', handleHeaderReady);
        window.removeEventListener('cmprm_event_rc_ota_ready', handleOTAReady);
      }
      setDataSource({ currency, disabled, price });
    };

    const handleOTAReady = (event) => {
      const { detail: { currency, id_ota: id, ota_name: name, price } = {} } = event;

      setOtas((prevOtas) => [...prevOtas, { currency, id, name, price }]);
    };

    window.addEventListener('cmprm_event_rc_header_ready', handleHeaderReady);
    window.addEventListener('cmprm_event_rc_ota_ready', handleOTAReady);

    return () => {
      window.removeEventListener('cmprm_event_rc_header_ready', handleHeaderReady);
      window.removeEventListener('cmprm_event_rc_ota_ready', handleOTAReady);
    };
  }, []);

  useEffect(() => {
    setFetching(true);
    let timeout = setTimeout(() => setFetching(false), 5000);
    if (otas.length === 1 && ref.current) {
      const { top, left, bottom, right } = ref.current.getBoundingClientRect();
      const { innerHeight, innerWidth } = window;
      const { clientHeight, clientWidth } = document.documentElement;

      const visible =
        top >= 0 && left >= 0 && bottom <= (innerHeight || clientHeight) && right <= (innerWidth || clientWidth);

      Event.publish(EVENT.METRICS, { id: 'OTAPRICES:RENDER', visible });
    }

    return () => clearTimeout(timeout);
  }, [otas]);

  useEffect(() => {
    const initialize = () => {
      setDataSource();
      setOtas([]);
    };

    Event.subscribe(EVENT.RATES_FILTER_CLUB, initialize);
    initialize();

    return () => Event.unsubscribe(EVENT.RATES_FILTER_CLUB, initialize);
  }, [calendar, currency, occupation, place, promocode]);

  const currencyFormatProps = { currency, locale, minimumFractionDigits: 0, maximumFractionDigits: 0 };
  const { disabled = false } = dataSource || {};

  return dataSource && !disabled ? (
    <View {...others} ref={ref} row={!isMobile} className={style.container}>
      <View row className={style.title}>
        <Text>{translate(L10N.LABEL_OUR_BEST_ONLINE_RATE)}</Text>
        <Text accent bold headline>
          {currencyFormat({ ...currencyFormatProps, value: dataSource.price })}
        </Text>
      </View>
      <View row className={style.items}>
        {[...otas]
          .sort((a, b) => a.price - b.price)
          .map(({ id, name, price }) => (
            <View key={id} className={style.item}>
              <Text small>{name}</Text>
              <Text action={isMobile}>{currencyFormat({ ...currencyFormatProps, value: price })}</Text>
            </View>
          ))}

        {fetching && (
          <>
            {Array.from({ length: 4 - otas.length }, (_, index) => (
              <View key={`skeleton:${index}`} className={style.item}>
                <Skeleton small>OTA Name</Skeleton>
                <Skeleton action={isMobile} bold>
                  ???$
                </Skeleton>
              </View>
            ))}
          </>
        )}
      </View>
    </View>
  ) : null;
};

OTAPrices.displayName = 'Mirai:Core:OTAPrices';

OTAPrices.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  columnMode: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  rowMode: PropTypes.bool,
};

export { OTAPrices };
