import { dateDiff, parseDate, UTC } from '@mirai/locale';

import { L10N } from './getButtonPaymentKey.l10n';
import { PAYMENT_METHOD, PAYMENT_PROVIDER } from '../../helpers';

const { BIZUM, CARD, TPV } = PAYMENT_METHOD;
const { ACTION_BOOK_NOW, ACTION_NEXT, ACTION_PAY_NOW } = L10N;

export const getButtonPaymentKey = ({ cardValidation, date, method, provider } = {}) =>
  [BIZUM, TPV].includes(method)
    ? date && dateDiff(UTC(new Date()), parseDate(date)).days > 0
      ? ACTION_BOOK_NOW
      : ACTION_PAY_NOW
    : cardValidation && method === CARD && provider === PAYMENT_PROVIDER.ADDON
    ? ACTION_NEXT
    : ACTION_BOOK_NOW;
