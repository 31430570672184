/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { getLanguage } from '../helpers';

export const extras = ({
  clubDiscount,
  currency,
  idtokenprovider: id,
  locale,
  offersAppliedBySelectedRoom,
  price,
  rooms,
  session: { authorization } = {},
  ...others
} = {}) =>
  new Promise(async (resolve, reject) => {
    if (!currency || !locale) return reject();

    const language = locale?.split('-')[0];
    const params = Object.entries({
      id,
      clubDiscount,
      currency,
      ...others,
      authorization,
      lang: getLanguage(language),
      rooms,
      offersAppliedBySelectedRoom,
      price,
    }).filter(([, value]) => value !== undefined);

    const response = await request({
      hostname: process.env.SERVICE_BOOKING,
      endpoint: `/v2/booking/extras?${new URLSearchParams(params)}`,
    }).catch((error) => {
      reject(error);
    });

    //! TODO: improve error handling
    if (!response || response.result === 'EXCEPTION') return reject({ error: 'Upss, something bad happened!' });

    resolve(response.data);
  });
