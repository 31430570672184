import { L10N } from './AmazonPay.l10n';

const BUTTON_ID = 'amazonPayButton';

const DEFAULT_LANGUAGE = 'es-ES';

const ERRORS = {
  amazonAbandoned: L10N.NOTIFICATION_AMAZON_INVALID_PAYMENT_METHOD,
  amazonRejected: L10N.NOTIFICATION_AMAZON_UNKNOWN_ERROR,
  failure: L10N.NOTIFICATION_AMAZON_UNKNOWN_ERROR,
  invalidPaymentMethod: L10N.NOTIFICATION_AMAZON_INVALID_PAYMENT_METHOD,
  paymentMethodNotAllowed: L10N.NOTIFICATION_AMAZON_PAYMENT_METHOD_NOT_ALLOWED,
  transactionTimedOut: L10N.NOTIFICATION_AMAZON_UNKNOWN_ERROR,
  unknownError: L10N.NOTIFICATION_AMAZON_UNKNOWN_ERROR,
};

const LANGUAGES = {
  de: 'de-DE',
  en: 'en-GB',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
};

const OPTIONS_AUTHORIZE = {
  popup: true,
  scope: 'payments:widget profile payments:shipping_address',
};

const OPTIONS_BUTTON = {
  color: 'Gold',
  size: 'small',
  type: 'LwA',
};

const OPTIONS_WALLET = {
  design: {
    designMode: 'smartphoneCollapsible',
  },
};

const ORDER_REFERENCE_KEY = 'MIRAI:PAYMENT:AMAZON_PAY:ORDER_REFERENCE';

const SCRIPT_ID = 'script-amazon-pay';

const WALLET_ID = 'amazonPayWidget';

export {
  BUTTON_ID,
  DEFAULT_LANGUAGE,
  ERRORS,
  LANGUAGES,
  OPTIONS_AUTHORIZE,
  OPTIONS_BUTTON,
  OPTIONS_WALLET,
  ORDER_REFERENCE_KEY,
  SCRIPT_ID,
  WALLET_ID,
};
