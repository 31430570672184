import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Pressable } from '../../primitives';
import style from './Action.module.css';

const Action = ({
  bold,
  children,
  disabled,
  href,
  paragraph,
  small,
  target,
  tiny,
  underline,
  wide,
  onPress,
  ...others
}) =>
  React.createElement(
    Pressable,
    {
      ...others,
      disabled,
      href,
      tag: href ? 'a' : 'action',
      onPress: (event) => {
        onPress && onPress(event);
        if (href && target) window.open(href, target);
        else if (href) window.location = href;
      },
      className: styles(
        style.action,
        bold && style.bold,
        disabled && style.disabled,
        paragraph ? style.paragraph : small ? style.small : tiny ? style.tiny : undefined,
        underline && style.underline,
        wide && style.wide,
        others.className,
      ),
    },
    children,
  );

Action.displayName = 'Component:Action';

Action.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  paragraph: PropTypes.bool,
  small: PropTypes.bool,
  target: PropTypes.string,
  tiny: PropTypes.bool,
  underline: PropTypes.bool,
  wide: PropTypes.bool,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onPress: PropTypes.func,
};

export { Action };
