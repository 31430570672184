import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Icon, Pressable, Text, View } from '../../primitives';
import style from './Menu.module.css';

const Option = ({ children, disabled, divider, icon, label, value, onPress, ...others }) => (
  <Pressable
    {...others}
    className={styles(style.option, value && style.value, divider && style.divider, disabled && style.disabled)}
    onPress={!disabled && value ? onPress : undefined}
  >
    <View row wide className={style.content}>
      {icon && <Icon value={icon} className={style.icon} />}
      {label && <Text className={style.label}>{label}</Text>}
      {children}
    </View>
  </Pressable>
);

Option.displayName = 'Component:Menu:Option';

Option.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPress: PropTypes.func,
};

export { Option };
