import { TYPE } from '../Occupation.constants';

const { GUEST, ADULT, CHILD } = TYPE;

export const toParties = (occupations = []) => {
  const parties = [];
  occupations.forEach((occupation = []) => {
    const party = { adults: 0, children: [] };
    occupation.forEach(({ type, amount, ages }) => {
      party.adults += type === GUEST || type === ADULT ? amount : 0;
      if (type === CHILD) {
        party.children = [...party.children, ...ages];
      }
    });
    if (party.adults > 0) {
      parties.push(party);
    }
  });
  return parties;
};
