export const getRoomNumber = (data) => {
  if (!data) return;

  try {
    const [{ roomNumber: [roomNumber] = [] } = {}] = JSON.parse(data) || [];

    return roomNumber;
  } catch (error) {
    return;
  }
};
