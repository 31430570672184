import React from 'react';

import { getTag } from '../../Primitive/helpers';

const LINK_REGEXP = /\[(.*?)\]\((.*?)\)/;
const MARKDOWN_REGEXP = /(\*\*|\*|_|~~|\[|\{)(.+?)(?:\1|\)|\})/g;
const SPACE_REGEXP = /\s/g;

const STYLE = {
  BINDING: { style: { color: 'var(--mirai-ui-accent)', fontWeight: 'bold' } },
  BOLD: { style: { fontWeight: 'bold' } },
  ITALIC: { style: { fontStyle: 'italic' } },
  LINE_THROUGH: { style: { textDecoration: 'line-through' } },
  LINK: { style: { textDecoration: 'underline', color: 'var(--mirai-ui-accent)' } },
  UNDERLINE: { style: { textDecoration: 'underline' } },
};

export const parseMarkdown = (value, tag = 'text') => {
  if (
    !value ||
    typeof value !== 'string' ||
    value.match(SPACE_REGEXP) === null ||
    value.match(MARKDOWN_REGEXP) === null
  ) {
    return value;
  }

  const tokens = [];
  let lastIndex = 0;
  let match;

  while ((match = MARKDOWN_REGEXP.exec(value)) !== null) {
    const [raw, start, text] = match;

    if (value.slice(lastIndex, match.index)) tokens.push({ text: value.slice(lastIndex, match.index) });

    if (start === '**') tokens.push({ text, ...STYLE.BOLD });
    else if (start === '*') tokens.push({ text, ...STYLE.ITALIC });
    else if (start === '_') tokens.push({ text, ...STYLE.UNDERLINE });
    else if (start === '~~') tokens.push({ text, ...STYLE.LINE_THROUGH });
    else if (start === '{') tokens.push({ text: raw, ...STYLE.BINDING });
    else if (start === '[') {
      const [, title, href] = raw.match(LINK_REGEXP);
      tokens.push({ text: title, href, ...STYLE.LINK });
    } else {
      tokens.push({ text: raw });
    }

    lastIndex = MARKDOWN_REGEXP.lastIndex;
  }

  if (value.slice(lastIndex)) tokens.push({ text: value.slice(lastIndex) });

  return tokens.map(({ text, ...props }, index) =>
    Object.keys(props).length
      ? React.createElement(props.href ? 'a' : getTag(tag), { key: index, ...props }, text)
      : text,
  );
};
