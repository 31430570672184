/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { getLanguage } from '../helpers/getLanguage';
import { ERROR } from './constants';

export const get = ({ id, locale, token, tracking, url } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!id || !locale || !token) return reject(ERROR.NOT_FILLED);

    const params = {
      token,
      tracking,
      url,
      lang: getLanguage(locale.split('-')[0]),
    };
    const response = await request({
      hostname: process.env.SERVICE_BOOKING,
      endpoint: `/v2/booking/${id}?${new URLSearchParams(
        Object.entries(params).filter(([, value]) => value !== undefined),
      )}`,
    }).catch((error) => {
      const { payload: { status, data: payload } = {} } = error;
      return reject(ERROR[status] ? { ...ERROR[status], payload } : ERROR.UNKNOWN);
    });

    resolve(response?.data);
  });
