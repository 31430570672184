import { STORAGE_KEY } from '../Table.constants';

export const Storage = {
  get: (store) => {
    if (!store) return store;

    try {
      return JSON.parse(localStorage.getItem(`${STORAGE_KEY}:${store}`));
    } catch (error) {
      return undefined;
    }
  },

  set: (store, value) => {
    if (!store) return store;

    try {
      const key = `${STORAGE_KEY}:${store}`;
      if (value) localStorage.setItem(key, JSON.stringify(value));
      else localStorage.removeItem(key);
    } catch (error) {
      throw Error(error);
    }
  },
};
