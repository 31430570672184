import { parseCommon, parseItems } from './helpers';
import { formatPrice } from '../helpers/legacy';

export const checkoutResponse = ({
  instances = [],
  response: { tracking = {} } = {},
  store: { finder = {}, id, type } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { hotelInfo = {}, reservedExtras = {}, reservedRooms = [], totalPriceWithoutTaxes } = tracking;

  const { currency: hotelCurrency = {} } = hotelInfo;

  const value = {
    coupon: finder?.promocode || '',
    currency: hotelCurrency.code,
    items: parseItems({ finder, hotelInfo, id, reservedExtras, reservedRooms, type }),
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
    value: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
  };

  window.gtag('event', 'begin_checkout', value);
  window.gtag('event', 'data_search_info', {
    ...parseCommon({ ...finder, id, type }),
    non_interaction: true,
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
    step: 'CLIENTFORM',
  });

  return value;
};
