import * as Sentry from '@sentry/react';

export const loadSentry = (dsn, release) => {
  if (!dsn || !release) return;

  Sentry.init({
    allowUrls: [/https?:\/\/((static)\.)?mirai\.com/],
    beforeSend(event, hint) {
      // eslint-disable-next-line no-console
      console.log({ event, hint });

      if (hint !== null) {
        const { originalException: { message = '', stack = '' } = {} } = hint || {};
        if (
          message === `Cannot read properties of null (reading 'style')` &&
          stack?.includes('HTMLLIElement.changeBackground')
        ) {
          return null;
        }
      }
      return event;
    },
    dsn,
    release,
    ignoreErrors: [
      /^Object captured as exception with keys: code, message$/,
      /^null is not an object (evaluating 'document.getElementById("cartAbandonment123_send_mail_offer_button").click')$/,
      /^Java exception was raised during method invocation$/,
      /^a.target.className.includes is not a function$/,
      /^undefined is not an object (evaluating 'window.webkit.messageHandlers')$/,
      /^Java bridge method invocation error$/,
    ],
    integrations: [
      new Sentry.Replay({ networkDetailAllowUrls: /mirai/g }),
      new Sentry.GlobalHandlers({ onerror: false, onunhandledrejection: false }),
    ],
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  return Sentry;
};
