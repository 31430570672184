/* eslint-disable no-console */
import { IS_JEST } from '../modules';

const STORE_DEFAULTS = {
  history: [],
  remove: () => !IS_JEST && console.log('StoreContext:remove'),
  set: (value = {}) => !IS_JEST && console.log('StoreContext:set', value),
  value: {},
};

export { STORE_DEFAULTS };
