import { currencySymbol } from './currencySymbol';

const symbols = {};

export const getSymbol = ({ currency, fancy, locale }) => {
  const key = `${currency}-${locale}-${fancy}`;

  let value = symbols[key];
  if (value === undefined) {
    symbols[key] = currencySymbol(currency, { locale, fancy }) || '';
    value = symbols[key];
  }

  return value;
};
