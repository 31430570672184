const CARD_CONFIG = {
  DEFAULT: {
    placeholder: {
      card: '0000 0000 0000 0000',
      CVV: '000',
    },
    maxLength: { card: 19, CVV: 3 },
  },
  AMERICAN_EX: {
    placeholder: {
      card: '0000 000000 00000',
      CVV: '0000',
    },
    maxLength: { card: 17, CVV: 4 },
  },
};

export { CARD_CONFIG };
