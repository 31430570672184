import PropTypes from 'prop-types';
import React from 'react';

import { Icon, ICON, Pressable, Text, View } from '../../primitives';
import style from './Table.module.css';

const Filter = ({ filter = [], schema = {}, onRemove = () => {}, ...others }) => {
  const fields = Object.keys(schema);

  return (
    <View {...others} row tag="table-filter" wide className={style.filters}>
      {filter
        .sort((a, b) => a.field >= b.field)
        .map(({ field, max, min, value, values } = {}, index) => {
          const { label = field, options: schemaOptions = {} } = schema[field] || {};

          return fields.includes(field) ? (
            <View row key={index} className={style.item}>
              <Text light tiny>
                {label.toLowerCase()}
              </Text>
              {value && (
                <Text bold small>
                  {value}
                </Text>
              )}
              {min && (
                <Text bold small>
                  {`${max ? '' : '≥ '}${min}`}
                </Text>
              )}
              {max && (
                <Text bold small>
                  {`${min ? ' - ' : '≤ '}${max}`}
                </Text>
              )}
              {values &&
                values.map((option, index) => (
                  <Text bold key={option} small>
                    {schemaOptions[option]}
                    {index < values.length - 1 ? ', ' : ''}
                  </Text>
                ))}

              <Pressable onPress={() => onRemove(index)} className={style.buttonRemove}>
                <Icon action value={ICON.CLOSE} />
              </Pressable>
            </View>
          ) : null;
        })}
    </View>
  );
};

Filter.displayName = 'Component:Table:Filter';

Filter.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({})),
  schema: PropTypes.shape({}).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export { Filter };
