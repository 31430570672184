/* eslint-disable no-async-promise-executor */
import { LocalAdapter, Storage, request } from '@mirai/data-sources';

import { profile } from './profile';
import { CACHE_LIMIT, ERROR, USER_STORAGE_KEY } from './User.constants';

export const login = ({ email, idClub, password } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!email || !idClub || !password) return reject(ERROR.NOT_FILLED);

    const form = { email, idClub, password };

    const response = await request({
      method: 'POST',
      hostname: process.env.SERVICE_USER,
      endpoint: '/club/v1/user/login',
      ...form,
    }).catch(reject);

    if (response?.accessToken) {
      let session = {
        authorization: response.accessToken,
        club: {
          name: response.clubName,
          id: response.idClub,
        },
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        level: response.level,
        subscribed: response.subscribed,
      };

      const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
      storage.set(`${USER_STORAGE_KEY}:session`, session);

      const { country, dateOfBirth } = (await profile({ session }).catch(reject)) || {};
      session = { ...session, country, dateOfBirth };
      storage.set(`${USER_STORAGE_KEY}:session`, session);
      resolve(session);
    } else {
      reject(ERROR.UNKNOWN);
    }
  });
