import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceFeatures } from '@mirai/services';
import { Action, Text, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { Amenities, Features, Slider } from './components';
import { L10N } from './Item.l10n';
import * as style from './Item.module.css';
import { EVENT } from '../../../helpers';
import { FEATURES } from '../../Rates.constants';

const GAP_TABLET = 7;
const GAP_NOT_TABLET = 1;
let memoizedWidth = 374;

const Preview = ({
  availability = 0,
  features: { columnMode = false, ...features } = {},
  images = [],
  isSoldOut = false,
  media,
  name: title,
  showActions = true,
  width: propWidth,
  onMoreInfo,
  onViewUrl = () => {},
  ...others
}) => {
  const { height, isMobile, isPortrait, isTablet, width } = useDevice();
  const { translate } = useLocale();
  const ref = useRef(null);
  const {
    value: {
      finder: { place: { id: [hotelId] = [], isHotel } = {} } = {},
      hotel: { warningAvailability = 9999, ...hotel } = {},
      language,
    } = {},
  } = useStore();

  useEffect(() => {
    const { clientWidth } = ref.current || {};
    if (clientWidth && !propWidth) memoizedWidth = clientWidth - (isTablet ? GAP_TABLET : GAP_NOT_TABLET);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet, width]);

  const handleSliderCounter = () => {
    onMoreInfo && onMoreInfo();
    Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:SLIDER:COUNTER' });
  };

  const hasTwin = others.id && ServiceFeatures.get(FEATURES.TWIN, isHotel ? hotelId : hotel.id);

  return (
    <View ref={ref} className={styles(style.preview, !showActions && style.withRadius)}>
      <Slider
        height={columnMode ? height / (isPortrait ? 5 : 3) : 208}
        images={images.map(({ large }) => large)}
        media={showActions ? media : undefined}
        title={title}
        width={propWidth || memoizedWidth}
        onCounter={!isMobile ? handleSliderCounter : undefined}
        onViewUrl={onViewUrl}
        className={style.slider}
      />

      <View row className={[style.offset, style.spaceBetween, style.details]}>
        <Features {...{ features, ...others }} />

        {isSoldOut === false && availability <= warningAvailability && (
          <Text bold error small>
            {translate(L10N.LABEL_REMAINING_ITEMS, { amount: availability })}
          </Text>
        )}
      </View>

      <View className={[style.offset, style.gap]} style={{ flex: 1 }}>
        <Text bold brand headline>
          {title}
        </Text>

        {hasTwin && showActions && (
          <Action
            onPress={() => {
              Event.publish(EVENT.METRICS, { id: 'TWIN_RATES:ITEM:TWIN' });
              Event.publish(EVENT.EXTERNAL_TWIN, { event: EVENT.EXTERNAL_TWIN });
              onViewUrl({
                title,
                url: `https://twin.mirai.com/rates/index.html?id=${isHotel ? hotelId : hotel.id}&room=${
                  others.id
                }&lang=${language}`,
              });
            }}
          >
            <Text bold small className={style.tag3D}>
              3D
            </Text>
            {translate(L10N.LABEL_MIRAI_TWIN)}
          </Action>
        )}

        <Amenities highlight preview {...others} />

        {showActions && (
          <View row>
            <Action onPress={onMoreInfo}>{translate(L10N.ACTION_MORE_INFO)}</Action>
          </View>
        )}
      </View>
    </View>
  );
};

Preview.displayName = 'Mirai:Core:Rates:Item:Preview';

Preview.propTypes = {
  availability: PropTypes.number,
  features: PropTypes.shape({
    columnMode: PropTypes.bool,
  }),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      large: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
  ),
  isSoldOut: PropTypes.bool,
  media: PropTypes.shape({
    url3D: PropTypes.string,
    urlVideo: PropTypes.string,
  }),
  name: PropTypes.string,
  showActions: PropTypes.bool,
  width: PropTypes.number,
  onMoreInfo: PropTypes.func,
  onViewUrl: PropTypes.func,
};

export { Preview };
