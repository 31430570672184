import { parseDate, useLocale } from '@mirai/locale';
import { Action, Icon, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { LIMIT_INCLUDED } from './RateDetails.constants';
import * as style from './RateDetails.module.css';
import { ICON } from '../../../../../helpers';
import { L10N } from '../../Item.l10n';
import { Countdown } from '../Countdown';
import { TooltipDates } from '../TooltipDates';

const RateDetails = ({
  compacted = false,
  countdown,
  included = [],
  restrictions = [],
  selectedBoard: { offerDates = [] } = {},
  onOfferInfo,
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  const caption = { small: true };
  const icon = { paragraph: true };

  const has = {
    included: included.length > 0,
    restrictions: restrictions.length > 0,
  };
  return has.included || has.restrictions || countdown > 0 ? (
    <View className={!compacted && style.content}>
      {countdown && <Countdown timestamp={countdown} />}

      {has.restrictions &&
        restrictions.map(({ variableOffer, value } = {}, index) => (
          <View key={index} row className={style.row}>
            <Text warning {...caption}>
              {value}
            </Text>
            {variableOffer && offerDates.length > 0 && (
              <TooltipDates
                captions={offerDates.map(() => translate(L10N.LABEL_INCLUDED))}
                values={offerDates.map((date) => parseDate(date))}
                warning
              />
            )}
          </View>
        ))}

      {!compacted && has.included && (
        <>
          {included.slice(0, LIMIT_INCLUDED).map((item, index) => (
            <View key={index} row className={style.row}>
              <Icon light value={ICON.CHECK} {...icon} />
              <Text light={!isMobile} {...caption}>
                {item}
              </Text>
            </View>
          ))}

          {included.length > LIMIT_INCLUDED && (
            <Action {...caption} onPress={onOfferInfo}>
              {translate(L10N.ACTION_VIEW_MORE)}
            </Action>
          )}
        </>
      )}
    </View>
  ) : null;
};

RateDetails.displayName = 'Mirai:Core:Rates:Item:RateDetails';

RateDetails.propTypes = {
  compacted: PropTypes.bool,
  countdown: PropTypes.any,
  included: PropTypes.arrayOf(PropTypes.any),
  restrictions: PropTypes.arrayOf(PropTypes.any),
  selectedBoard: PropTypes.shape({}),
  onOfferInfo: PropTypes.func,
};

export { RateDetails };
