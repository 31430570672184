import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, ICON, styles, Text, Tooltip, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MAX_VISIBLE_DETAILS } from './BookingDetails.constants';
import { L10N } from './BookingDetails.l10n';
import * as style from './BookingDetails.module.css';
import { BookingTable, ButtonMore } from '..';
import { EVENT, getRoomNumber, getRoomOccupationLabel } from '../../helpers';

const BookingDetails = ({ dataSource = {}, expanded: propExpanded = false, ...others }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      config: { accomodation: { occupation = [], variants = [] } = {} } = {},
      components = {},
      hotel: { id: hotelId } = {},
      urlParams: { roomNumberBySelectedRoom } = {},
    },
  } = useStore();

  const [expanded, setExpanded] = useState(propExpanded);

  useEffect(() => {
    const { details: [{ items = [] } = {}] = [], extras = [] } = dataSource;
    setExpanded(!isDesktop || (items.length <= MAX_VISIBLE_DETAILS && extras.length === 0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExpanded(propExpanded);
  }, [propExpanded]);

  const handlePress = () => {
    setExpanded(true);
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:DETAILS:VIEW_MORE` });
  };

  const metrics = components.checkout ? 'CHECKOUT' : components.booking ? 'BOOKING' : undefined;
  const { currency, details = [], extras = [] } = dataSource;
  const propsTable = { currency };
  const roomNumber = getRoomNumber(roomNumberBySelectedRoom);
  const multiRoom = details.length > 1;

  return (
    <View {...others} className={styles(style.details, others.className)}>
      <Text bold headline={isDesktop}>
        {translate(L10N.LABEL_BOOKING_DETAILS)}
      </Text>

      {details
        .slice(0, expanded ? undefined : 1)
        .map(
          (
            {
              amount,
              extras = [],
              image,
              items = [],
              observations = [],
              occupation: { adults, babies, children } = {},
              rate,
              type,
            } = {},
            key,
          ) => (
            <View key={key} wide>
              <View row className={style.item}>
                <View className={style.image} style={{ backgroundImage: `url(${image})` }} />
                <View>
                  <View row className={style.inline}>
                    <Text bold>
                      {`${type}${roomNumber ? ` (${translate(L10N.LABEL_ROOM_NUMBER, { roomNumber })})` : ''} ${
                        amount > 1 ? ` (x${amount})` : ''
                      }`}
                    </Text>
                    {roomNumber && (
                      <Tooltip pressable text={translate(L10N.LABEL_SUBJECT_TO_AVAILABILITY)}>
                        <Icon value={ICON.INFO} />
                      </Tooltip>
                    )}
                  </View>
                  <Text action>{rate.replace(`${type}:`, '').trim()}</Text>
                  <Text light small>
                    {getRoomOccupationLabel({
                      adults,
                      babies,
                      children,
                      dataSource: variants.find(({ id }) => id === Number(hotelId))?.occupation || occupation,
                      translate,
                    })}
                  </Text>
                </View>
              </View>

              <BookingTable
                {...propsTable}
                dataSource={items.slice(0, expanded ? undefined : MAX_VISIBLE_DETAILS)}
                showTotal={multiRoom && expanded}
              />

              {expanded && (
                <>
                  <BookingTable {...propsTable} dataSource={extras} label={translate(L10N.LABEL_EXTRAS)} />

                  {observations.length > 0 && (
                    <View className={style.observations}>
                      <Text bold small>
                        {translate(L10N.LABEL_OBSERVATIONS)}
                      </Text>
                      {observations.map((item, index) => (
                        <Text key={index} small>
                          {item}
                        </Text>
                      ))}
                    </View>
                  )}
                </>
              )}
            </View>
          ),
        )}

      {expanded && <BookingTable {...propsTable} dataSource={extras} label={translate(L10N.LABEL_BOOKING_EXTRAS)} />}

      {isDesktop && !expanded && <ButtonMore onPress={handlePress} className={style.buttonMore} testId="button-more" />}
    </View>
  );
};

BookingDetails.displayName = 'Mirai:Core:BookingDetails';

BookingDetails.propTypes = {
  dataSource: PropTypes.shape({}),
  expanded: PropTypes.bool,
};

export { BookingDetails };
