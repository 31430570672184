import { OCCUPATION_TYPE } from './constants';

const { CHILD } = OCCUPATION_TYPE;

export const getOccupationTotals = ({ infantAge = 0, occupation = [] } = {}) => {
  const occupationItems = occupation
    .flat()
    .filter(({ type } = {}) => type === CHILD)
    .reduce((totalAges = [], { ages = [] }) => [...totalAges, ...ages], []);

  return {
    adults: occupation.flat().reduce((total, { amount, type } = {}) => total + (type !== CHILD ? amount : 0), 0),
    babies: occupationItems.filter((age) => age < infantAge).length,
    children: occupationItems.filter((age) => age >= infantAge).length,
  };
};
