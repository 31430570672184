import { LocalAdapter, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT, HOTEL_STORAGE_KEY } from './constants';

const handleCatch = (error) => {
  // ! TODO: Should call home (sentry, mixpanel, ...)
  // eslint-disable-next-line no-console
  console.error('ServiceHotel.getCurrencies', error);
};

export const getCurrencies = async (id) => {
  if (!id || typeof id !== 'string') return undefined;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const key = `${HOTEL_STORAGE_KEY}:currencies:static:${id}`;
  let value = storage.get(key);

  if (!value) {
    try {
      // ! TODO: Should use @mirai/data-sources request
      const response = await fetch(`${process.env.SERVICE_HOTEL}/${id}-currencies.json`).catch(handleCatch);
      value = await response.json();
      storage.set(key, value);
    } catch (error) {
      handleCatch(error);
    }
  }

  return value;
};
