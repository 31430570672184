import { dateDiff, UTC } from '@mirai/locale';

import { METHOD, PROVIDER } from '../Payment.constants';
import { L10N } from '../Payment.l10n';

const { APLAZAME, CARD, TRANSFER, OXXO, PAYLATER, PCIPROXY, TPV } = METHOD;
const { ADDON, CARDNET, REDSYS, STRIPE } = PROVIDER;

export const getCaption = ({ amount, cardValidation = false, date, method, provider } = {}) => {
  const { days } = amount > 0 && date ? dateDiff(UTC(new Date()), date) : {};
  let caption = L10N.LABEL_REDIRECT;

  if ([OXXO, TRANSFER].includes(method)) caption = L10N.LABEL_REDIRECT_MORE_INFO;
  else if (method === TPV && provider === CARDNET) caption = L10N.LABEL_REDIRECT_CARDNET;
  else if (method === TPV && [ADDON, REDSYS, STRIPE].includes(provider) && days > 0)
    caption = L10N.LABEL_CAPTION_DEFERRED;
  else if (method === APLAZAME) caption = L10N.LABEL_REDIRECT_APLAZAME;
  else if (method === PAYLATER) caption = L10N.LABEL_CAPTION_PAYLATER;
  else if ([CARD, PCIPROXY].includes(method))
    caption = cardValidation ? L10N.LABEL_REDIRECT_CARD_PREPAYMENT : undefined;

  return caption;
};
