import { LocalAdapter, Storage } from '@mirai/data-sources';

import { CACHE_LIMIT, COUNTRY_STORAGE_KEY, DEFAULT_COUNTRY, DEFAULT_LANGUAGE } from './constants';
import { info } from './info';
import { list } from './list';

export const codes = async (locale = `${DEFAULT_LANGUAGE}-${DEFAULT_COUNTRY}`) => {
  if (!(await info()) || !(await list(locale))) return;

  const [language] = locale.split('-');
  if (!language) return;

  const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
  const countriesCodes = storage.get(`${COUNTRY_STORAGE_KEY}:info`);
  const countriesList = storage.get(`${COUNTRY_STORAGE_KEY}:list:${language}`);

  return [
    ...new Set(
      Object.entries(countriesCodes)
        .map(([countryCode, { code }]) => `${countriesList[countryCode]} ${code}`)
        .sort(),
    ),
  ];
};
