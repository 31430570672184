import { translate as translateBase } from '@mirai/locale';

import { IS_JEST } from './isJest';

const defaultMessage = !IS_JEST ? '　　　' : undefined;

const L10N = {
  LABEL_GUEST_TYPE: { id: 'common.label.guest_type', defaultMessage },
  LABEL_GUEST_TYPE_ABBREVIATION: { id: 'common.label.guest_type_abbreviation', defaultMessage },
  LABEL_GUEST_TYPE_PLURAL: { id: 'common.label.guest_type_plural', defaultMessage },
};

export const getGuestLabel = ({ abbreviation = false, plural = false, type } = {}, translate = translateBase) =>
  translate(
    abbreviation ? L10N.LABEL_GUEST_TYPE_ABBREVIATION : plural ? L10N.LABEL_GUEST_TYPE_PLURAL : L10N.LABEL_GUEST_TYPE,
    { type },
  );
