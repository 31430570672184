/* eslint-disable no-async-promise-executor */
import { request } from '@mirai/data-sources';

import { ERROR, STATUS } from './constants';

export const email = ({ email, id, idHotel, pinCode } = {}) =>
  new Promise(async (resolve, reject) => {
    if (!email || !id || !idHotel || !pinCode) return reject(ERROR.NOT_FILLED);

    const params = {
      cancellationCode: pinCode,
      clientEmail: email,
      idReservation: id,
      idtokenprovider: idHotel,
    };

    const response = await request({
      endpoint: `/resend_reservation.json?${new URLSearchParams(Object.entries(params))}`,
      hostname: process.env.SERVICE_BOOKING,
      method: 'POST',
    }).catch(reject);

    if (response?.status === STATUS.OK) return resolve({});

    reject(ERROR.UNKNOWN);
  });
