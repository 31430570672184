import { dateCalc, dateFormat } from '@mirai/locale';

import { CALENDAR_FORMAT, getOccupationTotals } from '../../helpers';
import { TAX, TAX_FREE, TAX_PARTIAL } from '../components/Calendar/Calendar.constants';
import { TYPE } from '../Finder.constants';

const TAXES = [TAX, TAX_FREE, TAX_PARTIAL];

export const parseCalendarProps = (
  calendar = {},
  id,
  occupation = [],
  type = TYPE.HOTEL,
  unavailability,
  variants = [],
) => {
  const {
    maxDays = 365,
    maxNights: rangeMaxDays = 31,
    nightsForDeal: rangeMinDays,
    prices = false,
    pricesWithTaxes: pricesLegend,
  } = type === TYPE.HOTEL ? calendar : { ...calendar, ...(variants.find((item) => item.id == id)?.calendar || {}) };

  const { adults, children } = getOccupationTotals({ occupation });
  const showPrices = prices && (!unavailability || (occupation.length === 1 && adults === 2 && children === 0));

  return {
    rangeMaxDays,
    rangeMinDays,
    pricesLegend: TAXES.includes(pricesLegend) ? pricesLegend : showPrices ? TAX : undefined,
    showPrices,
    to: dateFormat(dateCalc(new Date(), maxDays, 'days'), { format: CALENDAR_FORMAT }),
  };
};
