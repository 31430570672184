import { View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Environment.module.css';
import { State } from './Environment.State';
import { SelectEnvironment } from '../__shared__';

export const Environment = ({
  currencies: showCurrencies = false,
  locale: showLocale = true,
  state: showState = true,
  ...others
}) =>
  showLocale || showCurrencies || showState ? (
    <View row testId={others.testId} className={[style.container, others.className]}>
      {showCurrencies && <SelectEnvironment name="currency" small testId="select-currency" />}
      {showLocale && <SelectEnvironment name="locale" small testId="select-locale" />}
      {showState && <State />}
    </View>
  ) : null;

Environment.displayName = 'Mirai:Core:Environment';

Environment.propTypes = {
  currencies: PropTypes.bool,
  locale: PropTypes.bool,
  state: PropTypes.bool,
};
