import Cookies from 'js-cookie';

export class CookieAdapter {
  constructor() {}

  get(key) {
    try {
      return Cookies.get(key);
    } catch (error) {
      return undefined;
    }
  }

  set(key, value, expires = Infinity, path = '/', domain = '') {
    if (value) Cookies.set(key, value, { expires, path, domain });
    else Cookies.remove(key, { path: path || '/', domain: domain || '' });
  }
}
