const DATA_ATTRIBUTE = 'data-ghost';

export const getGhost = (el = {}) => {
  const [child] = el.querySelectorAll ? el.querySelectorAll(`[${DATA_ATTRIBUTE}]`) : [];
  let ghost;

  if (child) {
    child.removeAttribute(DATA_ATTRIBUTE);
    ghost = child;
    child.remove();
  }

  return ghost;
};
