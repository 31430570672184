import { MemoryAdapter } from './adapters';
import { decrypt, encrypt } from './helpers';

// eslint-disable-next-line no-undef
const state = new WeakMap();

export class Storage {
  constructor(props = {}) {
    const { adapter: Adapter = MemoryAdapter, cache, defaults = {}, secret } = props;
    const adapter = new Adapter();

    state.set(this, { adapter, cache, secret });
    Object.keys(defaults).forEach((key) => this.set(key, defaults[key]));

    return this;
  }

  get(key, ignoreCache = false) {
    const { adapter, cache, secret } = state.get(this);

    let value = adapter.get(key);
    if (!value) return undefined;

    value = secret ? decrypt(value, secret) : JSON.parse(value);

    if (cache) {
      const { timestamp, data } = value;
      const cacheAlive = timestamp ? Math.abs(timestamp - new Date().getTime()) < cache : false;

      if (cacheAlive || ignoreCache) value = data;
      else {
        value = undefined;
        this.remove(key);
      }
    }

    return value;
  }

  set(key, value, ...rest) {
    const { adapter, cache, secret } = state.get(this);

    const data = cache ? { timestamp: new Date().getTime(), data: value } : value;
    return adapter.set(key, secret ? encrypt(data, secret) : JSON.stringify(data), ...rest);
  }

  has(key) {
    return this.get(key) !== undefined;
  }

  remove(key, config = {}) {
    const { adapter } = state.get(this);

    return adapter.set(key, undefined, undefined, config.path, config.domain);
  }
}
