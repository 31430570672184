import { DEFAULT_DATE_FORMAT } from '@mirai/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getInputDateErrors } from '../../helpers';
import { InputText } from '../InputText';
import { formatValue } from './helpers';

const InputDate = ({
  format = DEFAULT_DATE_FORMAT,
  max,
  min,
  placeholder,
  value: propValue = '',
  onChange = () => {},
  onError = () => {},
  ...others
}) => {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const handleChange = (nextValue, event) => {
    const next = formatValue(nextValue, format, value);
    if (next === value) return;

    setValue(next);
    onChange(next, event);

    const errors = getInputDateErrors({ format, max, min, value: next, required: others.required });
    if (Object.keys(errors || {}).length > 0) onError(errors);
  };

  return (
    <InputText
      {...others}
      maxLength={format.length}
      placeholder={placeholder || format}
      tag="input-date"
      value={value}
      onChange={handleChange}
    />
  );
};

InputDate.displayName = 'Component:InputDate';

InputDate.propTypes = {
  format: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export { InputDate };
