import { useLocale } from '@mirai/locale';
import { Button, Notification, ScrollView, Text, View, styles } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { checkRoom, consolidateValue, getScrollHeight } from './helpers';
import { TYPE } from './Occupation.constants';
import { DATASOURCE, ERROR, PLACE, SHAPE } from './Occupation.constants';
import { OccupationGroup } from './Occupation.Group';
import { L10N } from './Occupation.l10n';
import * as style from './Occupation.module.css';
import { prepareRoom } from '../../../helpers/prepareRoom';
import { ACCOMMODATION_TYPE } from '../../helpers';

const Occupation = React.forwardRef(
  (
    {
      dataSource = DATASOURCE,
      error,
      maxRooms,
      minRooms = 1,
      text,
      type = ACCOMMODATION_TYPE.ROOM,
      value = dataSource ? [...Array(minRooms)].map(() => prepareRoom(dataSource || DATASOURCE)) : [],
      warning,
      onChange = () => {},
      onError = () => {},
      onSubmit,
      onValid = () => {},
      onWarning = () => {},
      ...others
    },
    ref,
  ) => {
    const { translate } = useLocale();
    const itemsRef = useRef([]);

    const [scrollEnd, setScrollEnd] = useState();
    const [withError, setWithError] = useState(error);
    const [withWarning, setWithWarning] = useState(false);
    const [currentDataSource, setCurrentDataSource] = useState([]);

    useEffect(() => {
      if (JSON.stringify(dataSource) !== JSON.stringify(currentDataSource)) setWithWarning(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDataSource, dataSource]);

    useEffect(() => {
      let nextValue = consolidateValue(value, dataSource, currentDataSource);
      if (!nextValue) {
        setWithWarning(true);
        onWarning();
        nextValue = [prepareRoom(dataSource)];
      } else if (error && checkRoom(nextValue, dataSource)) {
        handleValid();
      }
      if (JSON.stringify(dataSource) !== JSON.stringify(currentDataSource)) setCurrentDataSource(dataSource);
      // ! TODO: Check why doesnt work witn non-hotels
      if (JSON.stringify(nextValue) !== JSON.stringify(value)) handleChange(nextValue);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, value]);

    useEffect(() => {
      setWithError(error);
      const roomIndex = value.findIndex((room) =>
        room.some(({ ages = [], type } = {}) => type === TYPE.CHILD && ages.some((age) => age === undefined)),
      );
      if (roomIndex < 0) return;

      const scrollHeight = getScrollHeight({
        hasAges: true,
        roomIndex,
        rooms: itemsRef.current?.map((el) => el?.scrollHeight),
      });
      if (scrollHeight >= 0) setScrollEnd(scrollEnd !== scrollHeight ? scrollHeight : scrollEnd + 0.1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
      if (warning === undefined) return;

      setWithWarning(warning);
    }, [warning]);

    const handleAddRoom = () => {
      const { parentNode: { scrollHeight } = {} } = (itemsRef.current?.length > 0 && itemsRef.current[0]) || {};
      if (scrollHeight) setScrollEnd(scrollHeight);

      handleChange([...value, prepareRoom(dataSource)]);
      setWithWarning(false);
    };

    const handleRemoveRoom = (event, roomIndex, groupValue) => {
      event.stopPropagation();
      scroll(roomIndex, groupValue);

      handleChange(value.filter((_, index) => roomIndex !== index));
      itemsRef.current = [];
      setWithWarning(false);
    };

    const handleIndividual = (roomIndex, groupIndex, groupValue) => {
      dataSource[groupIndex].fulfill && scroll(roomIndex, groupValue);

      const nextValue = [...value];
      nextValue[roomIndex] = [...nextValue[roomIndex]] || [];
      nextValue[roomIndex][groupIndex] = groupValue.amount === 0 ? undefined : groupValue;

      handleChange(nextValue);
      setWithWarning(false);
    };

    const handleChange = (nextValue) => {
      onChange(nextValue);
      checkRoom(nextValue, dataSource) ? handleValid() : onError(ERROR.NOT_FILLED);
    };

    const handleSubmit = () => {
      setWithWarning(false);
      onSubmit(value);
    };

    const handleValid = () => {
      setWithError(false);
      onValid();
    };

    const scroll = (roomIndex, groupValue) => {
      const scrollHeight = getScrollHeight({
        hasAges: groupValue?.ages?.length > 0,
        roomIndex,
        rooms: itemsRef.current?.map((el) => el?.scrollHeight),
      });
      if (scrollHeight >= 0) setScrollEnd(scrollEnd !== scrollHeight ? scrollHeight : scrollEnd + 0.1);
    };

    const { testId } = others;
    const isValid = checkRoom(value, dataSource);

    return (
      <View {...others} role="occupation" ref={ref} className={styles(style.container, others.className)}>
        {(withError || withWarning) && (
          <Notification error={withError} small warning={withWarning} className={style.notification}>
            {translate(withError ? L10N.NOTIFICATION_ERROR_UNFILLED_FIELDS : L10N.NOTIFICATION_WARNING_OCCUPATION)}
          </Notification>
        )}
        <ScrollView scrollTo={scrollEnd} snap={false} className={style.scrollview}>
          {value.map((room = [], roomIndex) => (
            <View key={`room:${roomIndex}`} ref={(el) => (itemsRef.current[roomIndex] = el)} wide>
              <View key={`room:header:${roomIndex}`} row className={style.header}>
                <Text action bold>
                  {translate({ id: `${L10N.LABEL_PLACE_TYPE_COUNT.id}.${PLACE[type]}` }, { roomIndex: roomIndex + 1 })}
                </Text>
                {value.length > minRooms && (
                  <Button
                    preventDefault
                    small
                    transparent
                    onPress={(event) => handleRemoveRoom(event, roomIndex, room[roomIndex])}
                    testId={testId ? `${testId}-remove-room-${roomIndex}` : undefined}
                  >
                    {translate(L10N.ACTION_REMOVE)}
                  </Button>
                )}
              </View>
              {dataSource.map((group, groupIndex) => (
                <OccupationGroup
                  {...group}
                  error={error}
                  key={`room:${roomIndex}:${groupIndex}`}
                  value={room[groupIndex]}
                  onChange={(value) => handleIndividual(roomIndex, groupIndex, value)}
                  testId={`group-${groupIndex}`}
                />
              ))}
            </View>
          ))}
        </ScrollView>

        <View row className={styles(style.footer)}>
          <Button
            disabled={maxRooms && value.length >= maxRooms}
            preventDefault
            secondary
            className={style.buttonAdd}
            onPress={handleAddRoom}
            testId={testId ? `${testId}-add-room` : undefined}
          >
            {translate({ id: `${L10N.ACTION_ADD_PLACE_TYPE.id}.${PLACE[type]}` })}
          </Button>
          {onSubmit && (
            <Button
              disabled={!isValid}
              className={style.buttonSubmit}
              preventDefault
              onPress={handleSubmit}
              testId={testId ? `${testId}-submit` : undefined}
            >
              {text || translate(L10N.ACTION_SEARCH)}
            </Button>
          )}
        </View>
      </View>
    );
  },
);

Occupation.displayName = 'Mirai:Core:Occupation';

Occupation.propTypes = {
  dataSource: PropTypes.arrayOf(SHAPE.DATASOURCE),
  error: PropTypes.bool,
  maxRooms: PropTypes.number,
  minRooms: PropTypes.number,
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ACCOMMODATION_TYPE)),
  value: PropTypes.arrayOf(PropTypes.arrayOf(SHAPE.VALUE)),
  warning: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onValid: PropTypes.func,
  onWarning: PropTypes.func,
};

export { Occupation };
