import { SHA256 } from '../helpers';
import { formatPrice } from '../helpers/legacy';

const PURCHASE = 'purchase';

export const bookingResponse = ({ instances = [], response: { tracking = {} } = {} } = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    guestData: { email } = {},
    hotelInfo: { currency: hotelCurrency = {} } = {},
    totalPriceWithoutTaxes,
  } = tracking;

  const value = {
    event_label: PURCHASE,
    event_category: PURCHASE,
    revenue_value: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    currency: hotelCurrency.code,
  };

  instances.forEach(({ idBing = '' } = {}) => {
    const uetq = `uetq_${idBing}`;

    window[uetq] = window[uetq] || [];
    window[uetq].push('set', { pid: { em: SHA256(email) } });
    window[uetq].push('event', PURCHASE, value);
  });

  return value;
};
