import { TYPE_OCCUPATION } from '../components/__shared__';
import { getOccupationDataSource } from '../components/Finder/helpers';

const { GUEST, ADULT, CHILD } = TYPE_OCCUPATION;

export const toOccupation = ({ chainSelectorValue, occupation, parties, places }) => {
  const value = [];
  let decodedParties = [];
  try {
    decodedParties = JSON.parse(window.atob(parties)) || [];
    // eslint-disable-next-line no-empty
  } catch {}

  const dataSource = getOccupationDataSource({
    occupation: occupation.dataSource,
    place: Number(chainSelectorValue),
    places,
    variants: occupation.variants,
  });

  decodedParties.forEach(({ adults = 0, children = [] } = {}, roomIndex) => {
    children = children.map((item) => (item == null ? undefined : item));

    const rooms = [];
    const checked = [];
    dataSource?.forEach(({ type, age: [, max] = [] } = {}, index) => {
      if (adults > 0 && [ADULT, GUEST].includes(type)) {
        rooms[index] = { ages: Array(adults).fill(max), amount: adults, type };
      } else if (type === CHILD) {
        children
          .filter((_, index) => !checked.includes(index))
          .forEach((age, childIndex) => {
            const room = rooms[index] || { ages: [], amount: 0, type };
            room.amount += 1;
            room.ages.push(age);
            rooms[index] = room;
            checked.push(childIndex);
          });
      }
    });
    value[roomIndex] = rooms.length ? rooms : undefined;
    if (value[roomIndex] && children.length > 0 && dataSource[0].type === GUEST) {
      value[roomIndex][0].amount = value[roomIndex][0].amount + children.length;
      children.forEach(() => value[roomIndex][0].ages.push(undefined));
    }
  });

  return value.filter((item) => !!item).length ? value : undefined;
};
