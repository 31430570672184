import { ICON as ICON_BASE } from '@mirai/ui';
import { GrThreeD as THREE_D } from '@react-icons/all-files/gr/GrThreeD';
import { LuBedDouble as BED_DOUBLE } from '@react-icons/all-files/lu/LuBedDouble';
import { LuBedSingle as BED_SINGLE } from '@react-icons/all-files/lu/LuBedSingle';
import { LuHotel as HOTEL } from '@react-icons/all-files/lu/LuHotel';
import { Md3DRotation as THREE_D_ROTATION } from '@react-icons/all-files/md/Md3DRotation';
import { MdAccessAlarm as ALARM } from '@react-icons/all-files/md/MdAccessAlarm';
import { MdCloseFullscreen as CLOSE_FULLSCREEN } from '@react-icons/all-files/md/MdCloseFullscreen';
import { MdMailOutline as MAIL } from '@react-icons/all-files/md/MdMailOutline';
import { MdMoreVert as MORE } from '@react-icons/all-files/md/MdMoreVert';
import { MdOutlineAccountCircle as ACCOUNT } from '@react-icons/all-files/md/MdOutlineAccountCircle';
import { MdOutlineAddToPhotos as ADD_EXTRAS } from '@react-icons/all-files/md/MdOutlineAddToPhotos';
import { MdOutlineArrowUpward as ARROW_UP } from '@react-icons/all-files/md/MdOutlineArrowUpward';
import { MdOutlineAssignment as ASSIGNMENT } from '@react-icons/all-files/md/MdOutlineAssignment';
import { MdOutlineAutorenew as CLEAR } from '@react-icons/all-files/md/MdOutlineAutorenew';
import { MdOutlineCalendarToday as CALENDAR } from '@react-icons/all-files/md/MdOutlineCalendarToday';
import { MdOutlineCurrencyExchange as CURRENCY } from '@react-icons/all-files/md/MdOutlineCurrencyExchange';
import { MdOutlineEditCalendar as CALENDAR_EDIT } from '@react-icons/all-files/md/MdOutlineEditCalendar';
import { MdOutlineEventBusy as CALENDAR_BUSY } from '@react-icons/all-files/md/MdOutlineEventBusy';
import { MdOutlineFreeCancellation as CALENDAR_CANCEL } from '@react-icons/all-files/md/MdOutlineFreeCancellation';
import { MdOutlineGroup as GROUP } from '@react-icons/all-files/md/MdOutlineGroup';
import { MdOutlineGroups as GROUPS } from '@react-icons/all-files/md/MdOutlineGroups';
import { MdOutlineLanguage as LANGUAGE } from '@react-icons/all-files/md/MdOutlineLanguage';
import { MdOutlineLink as LINK } from '@react-icons/all-files/md/MdOutlineLink';
import { MdOutlineListAlt as LIST } from '@react-icons/all-files/md/MdOutlineListAlt';
import { MdOutlineLocationOn as LOCATION } from '@react-icons/all-files/md/MdOutlineLocationOn';
import { MdOutlineLock as LOCK } from '@react-icons/all-files/md/MdOutlineLock';
import { MdOutlineLogout as LOGOUT } from '@react-icons/all-files/md/MdOutlineLogout';
import { MdOutlineMinimize as MINIMIZE } from '@react-icons/all-files/md/MdOutlineMinimize';
import { MdOutlineOutput as URL } from '@react-icons/all-files/md/MdOutlineOutput';
import { MdOutlinePageview as SITE } from '@react-icons/all-files/md/MdOutlinePageview';
import { MdOutlinePeopleAlt as PEOPLE } from '@react-icons/all-files/md/MdOutlinePeopleAlt';
import { MdOutlinePersonOff as PERSON_OFF } from '@react-icons/all-files/md/MdOutlinePersonOff';
import { MdOutlinePhone as PHONE } from '@react-icons/all-files/md/MdOutlinePhone';
import { MdOutlinePlayCircle as PLAY } from '@react-icons/all-files/md/MdOutlinePlayCircle';
import { MdOutlinePrint as PRINT } from '@react-icons/all-files/md/MdOutlinePrint';
import { MdOutlineRefresh as REFRESH } from '@react-icons/all-files/md/MdOutlineRefresh';
import { MdOutlineSchedule as SCHEDULE } from '@react-icons/all-files/md/MdOutlineSchedule';
import { MdOutlineTune as TUNE } from '@react-icons/all-files/md/MdOutlineTune';
import { MdOutlineWorkspacePremium as PREMIUM } from '@react-icons/all-files/md/MdOutlineWorkspacePremium';
import { MdPersonOutline as PERSON } from '@react-icons/all-files/md/MdPersonOutline';
import { MdPhotoSizeSelectSmall as SIZE } from '@react-icons/all-files/md/MdPhotoSizeSelectSmall';
import { MdUnfoldMore as UNFOLD } from '@react-icons/all-files/md/MdUnfoldMore';
import { TbDiscount as DISCOUNT } from '@react-icons/all-files/tb/TbDiscount.mjs';
import { TbDiscountCheck as OFFER } from '@react-icons/all-files/tb/TbDiscountCheck';

import { ICON_AMENITIES } from './ICON.AMENITIES';

export const ICON = {
  ...ICON_BASE,
  ACCOUNT,
  ADD_EXTRAS,
  ALARM,
  ARROW_UP,
  ASSIGNMENT,
  BED_DOUBLE,
  BED_SINGLE,
  CALENDAR,
  CALENDAR_BUSY,
  CALENDAR_CANCEL,
  CALENDAR_EDIT,
  CLEAR,
  CLOSE_FULLSCREEN,
  CURRENCY,
  DISCOUNT,
  GROUP,
  GROUPS,
  HOTEL,
  LANGUAGE,
  LINK,
  LIST,
  LOCATION,
  LOCK,
  LOGOUT,
  MAIL,
  MINIMIZE,
  MORE,
  OFFER,
  PEOPLE,
  PERSON,
  PERSON_OFF,
  PHONE,
  PLAY,
  PREMIUM,
  PRINT,
  REFRESH,
  SCHEDULE,
  THREE_D,
  THREE_D_ROTATION,
  TUNE,
  // SUPPORT_AGENT,
  SITE,
  SIZE,
  UNFOLD,
  URL,
  // Amenities & Features,
  ...ICON_AMENITIES,
};
