import { Event } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as style from './Item.module.css';
import { Preview } from './Item.Preview';
import { Rates } from './Item.Rates';
import { Summary } from './Item.Summary';
import { Card } from '../../../__shared__';
import { EVENT } from '../../../helpers';
import { getClubInclude, getClubRate } from '../../helpers';
import { DISCOUNT_TYPE } from '../../Rates.constants';

const Item = React.forwardRef(
  (
    {
      boards = [],
      cancellations = [],
      features = {},
      filters = {},
      metaBoard,
      multiRoom,
      priceFactor = 1,
      rates: propRates = [],
      selected,
      onSelect = () => {},
      ...others
    },
    ref,
  ) => {
    const { translate } = useLocale();

    const [selectedBoard, setSelectedBoard] = useState();

    useEffect(() => {
      if (Object.keys(filters).length) updateSelectedBoard();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
      if (!selectedBoard) updateSelectedBoard();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boards]);

    useEffect(() => {
      if (!metaBoard || metaBoard.roomId !== others.id || boards.length < 1) return;

      setSelectedBoard(boards.find(({ id } = {}) => id === metaBoard.id));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaBoard]);

    useEffect(() => {
      if (!selected) return;

      const nextBoard = boards.find((board) => board.id === selected.id);
      if (nextBoard) setSelectedBoard(nextBoard);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const updateSelectedBoard = () => {
      const [{ id: cancellationId } = {}] = cancellations;
      const [{ id: rateId } = {}] = rates;
      const nextBoard = boards.find((board) => board.cancellationId === cancellationId && board.rateId === rateId);

      if (nextBoard) setSelectedBoard(nextBoard);
    };

    const handleBoard = (board) => {
      clearSelection();
      setSelectedBoard(board);
      Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:BOARD', board: board.id });
    };

    const handleCancellation = (id) => {
      clearSelection();
      setSelectedBoard(boards.find(({ cancellationId }) => cancellationId === id));
      Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:CANCELLATION', cancellation: id });
    };

    const handleRate = ({ id, cancellationId }) => {
      clearSelection();
      setSelectedBoard(boards.find((board) => board.cancellationId === cancellationId && board.rateId == id));
      Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:OFFER', offer: id });
    };

    const findPrice = (id, dataSource = []) => (dataSource.find((item = {}) => item.id === id) || {}).price || 0;

    const clearSelection = () => {
      multiRoom && selected?.id === id && onSelect(undefined);
    };

    const { columnMode, showAllBoards, showAllOffers, showOffersFirst } = features;
    const { id, cancellationId, rateId } = selectedBoard || {};
    const cancellationPrice = findPrice(cancellationId, cancellations);
    const rates = propRates.map(({ included = [], ...rate } = {}) => {
      const clubInclude = getClubInclude(
        getClubRate({
          board: boards.filter(({ rateId } = {}) => rateId === rate.id)[0],
          rate,
        }),
      );

      return { ...rate, included: clubInclude && included.length ? [...included, translate(clubInclude)] : included };
    });
    const ratePrice = findPrice(rateId, rates);
    const visibleBoards = boards.filter((board) => board.cancellationId === cancellationId && board.rateId === rateId);

    const isSoldOut =
      others.soldOut &&
      (rates.length === 0 ||
        (Object.values(others.cart || {}).filter(({ roomId } = {}) => roomId === selectedBoard?.roomId).length > 0 &&
          selected?.id != id));

    const props = {
      ...others,
      availableBoards: boards,
      availableRates: rates,
      boards: showAllBoards
        ? boards
            .map((board) => {
              const selectedBoard = board.cancellationId === cancellationId && board.rateId === rateId;
              let { increment } = board;

              if (!selectedBoard) {
                const boardCancellationPrice = findPrice(board.cancellationId, cancellations);
                const boardRatePrice = findPrice(board.rateId, rates);
                increment = increment - (cancellationPrice + ratePrice) + boardCancellationPrice + boardRatePrice;
              }

              return { ...board, disabled: !selectedBoard, increment };
            })
            .filter((board) => {
              if (!board.disabled) return true;

              const visible = !visibleBoards.find((item) => item.name === board.name);
              if (visible) visibleBoards.push(board);

              return visible;
            })
            .sort((a, b) => a.increment - b.increment)
        : visibleBoards,
      cancellations,
      features,
      isSoldOut,
      multiRoom,
      priceFactor,
      promocodeForAll: boards.every(
        ({ confidential, discount: { breakdown = [] } = {} } = {}) =>
          breakdown.find(({ type }) => type === DISCOUNT_TYPE.PROMOTION) ||
          (breakdown.find(({ type }) => type === DISCOUNT_TYPE.DEAL) && confidential),
      ),
      rates: showOffersFirst
        ? rates
            .map((rate) => ({ ...rate, price: rate.price + findPrice(rate.cancellationId, cancellations) }))
            .sort((a, b) => a.price - b.price)
        : showAllOffers
        ? rates
            .map((rate) => {
              const selectedCancellation = rate.cancellationId === cancellationId;
              const price = selectedCancellation
                ? rate.price
                : rate.price - cancellationPrice + findPrice(rate.cancellationId, cancellations);

              return { ...rate, disabled: !showOffersFirst && !selectedCancellation, price };
            })
            .sort((a, b) => a.price - b.price)
        : rates.filter((rate) => rate.cancellationId === cancellationId),
      selected,
      selectedBoard,
      onSelect,
    };

    return (
      <Card
        {...{ columnMode }}
        disabled={isSoldOut}
        focus={(selected && selected.id === id) || (metaBoard && boards.find((item) => metaBoard?.id === item.id))}
      >
        <View ref={ref} className={[style.card, others.className]}>
          <Preview {...props} />

          {!isSoldOut && (
            <Rates {...props} onBoard={handleBoard} onCancellation={handleCancellation} onRate={handleRate} />
          )}
          <Summary {...props} />
        </View>
      </Card>
    );
  },
);

Item.displayName = 'Mirai:Core:Rates:Item';

Item.propTypes = {
  boards: PropTypes.arrayOf(PropTypes.shape({})),
  cancellations: PropTypes.arrayOf(PropTypes.shape({})),
  features: PropTypes.shape({}),
  filters: PropTypes.shape({}),
  metaBoard: PropTypes.shape({
    id: PropTypes.string,
    roomId: PropTypes.number,
  }),
  multiRoom: PropTypes.bool,
  priceFactor: PropTypes.number,
  rates: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.shape({
    id: PropTypes.string,
  }),
  showAllOffers: PropTypes.bool,
  onSelect: PropTypes.func,
};

export { Item };
