import {
  ProviderAffilired,
  ProviderAnalytics,
  ProviderBing,
  ProviderFacebook,
  ProviderGoogleAds,
  ProviderGTM,
  ProviderMirai,
} from './providers';
import { EVENT as COMPONENTS_EVENT } from '../../components/helpers';
import { PERFORMANCE } from '../../Core.constants';

const EVENTS = {
  // -- Finder ✅
  [COMPONENTS_EVENT.FINDER_READY]: {
    [PERFORMANCE.GTM]: ProviderGTM.finderReady,
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.finderReady,
  },

  // -- <Rates> ✅
  [COMPONENTS_EVENT.RATES_RESPONSE]: {
    [PERFORMANCE.GTM]: ProviderGTM.ratesResponse,
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.ratesResponse,
    [PERFORMANCE.FACEBOOK]: ProviderFacebook.ratesResponse,
  },

  // -- Add to cart ✅
  [COMPONENTS_EVENT.RATES_ADD_TO_CART]: {
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.ratesAddToCart,
    [PERFORMANCE.FACEBOOK]: ProviderFacebook.ratesAddToCart,
  },

  // -- <Checkout> ✅
  [COMPONENTS_EVENT.CHECKOUT_RESPONSE]: {
    [PERFORMANCE.GTM]: ProviderGTM.checkoutResponse,
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.checkoutResponse,
    [PERFORMANCE.FACEBOOK]: ProviderFacebook.checkoutResponse,
  },

  [COMPONENTS_EVENT.CHECKOUT_FORM]: {
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.checkoutForm,
  },

  // -- <Booking> ☑️
  [COMPONENTS_EVENT.BOOKING_RESPONSE]: {
    [PERFORMANCE.GTM]: ProviderGTM.bookingResponse,
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.bookingResponse,
    [PERFORMANCE.GOOGLE_ADS]: ProviderGoogleAds.bookingResponse,
    [PERFORMANCE.MIRAI]: ProviderMirai.bookingResponse,
    [PERFORMANCE.BING]: ProviderBing.bookingResponse,
    [PERFORMANCE.AFFILIRED]: ProviderAffilired.bookingResponse,
    [PERFORMANCE.FACEBOOK]: ProviderFacebook.bookingResponse,
  },

  [COMPONENTS_EVENT.BOOKING_CANCEL]: {
    [PERFORMANCE.GTM]: ProviderGTM.bookingCancel,
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.bookingCancel,
  },

  // -- Session ✅
  [COMPONENTS_EVENT.LOGGED]: {
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.logged,
  },

  // -- External Twin ✅
  [COMPONENTS_EVENT.EXTERNAL_TWIN]: {
    [PERFORMANCE.ANALYTICS]: ProviderAnalytics.externalTwin,
  },
};

export { EVENTS };
