import { useStore } from '@mirai/data-sources';
import { parseDate, useLocale } from '@mirai/locale';
import { Icon, Pressable, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Option, RateDetails, TooltipDates } from './components';
import { L10N } from './Item.l10n';
import * as style from './Item.module.css';
import { ICON } from '../../../helpers';
import { DISCOUNT_TYPE, MAX_PROMOCODE } from '../../Rates.constants';

const Rates = ({
  availableBoards = [],
  availableRates = [],
  boards = [],
  cancellations = [],
  features: {
    columnMode,
    hideRadio = false,
    hideStandaloneBoard = false,
    showOffersFirst = false,
    showRateIncluded = false,
  } = {},
  id: idItem,
  priceFactor = 1,
  promocodeForAll,
  rates = [],
  selectedBoard = {},
  uniqueOption = false,
  onBoard,
  onCancellation,
  onCancellationInfo,
  onRate,
  onOfferInfo,
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  const { value: { finder: { promocode } = {} } = {} } = useStore();

  const { cancellationId, rateId, id: boardId } = selectedBoard;

  const has = {
    cancellations: cancellations.length > 0,
    boards: boards.length > (hideStandaloneBoard ? 1 : 0),
    inlineBoards: hideRadio && boards.length > 1 && boards.length <= (columnMode ? 3 : 2),
    inlineCancellations: hideRadio && cancellations.length > 1 && cancellations.length <= (columnMode ? 3 : 2),
    rates: availableRates.length > 0,
  };

  const cancellationsFiltered = cancellations.filter(
    ({ id }) => !showOffersFirst || (showOffersFirst && (id === cancellationId || !has.rates)),
  );

  const renderCancellations = () =>
    has.cancellations && (
      <View className={[style.offset, style.gap]}>
        <Text bold>{translate(L10N.LABEL_CANCELLATION_POLICY)}</Text>

        {React.createElement(
          has.inlineCancellations ? View : React.Fragment,
          has.inlineCancellations ? { row: true, className: style.inlineOptions } : undefined,
          cancellationsFiltered.map(({ highlight, id, name, caption, price, ...cancellation }) => (
            <Option
              key={id}
              caption={caption}
              checked={id === cancellationId}
              inline={has.inlineCancellations}
              name={`Cancellation ${idItem}`}
              outlined={hideRadio}
              value={!showOffersFirst ? price / priceFactor : undefined}
              onPress={!uniqueOption ? () => onCancellation(id) : undefined}
            >
              <Text action medium={highlight} className={style.optionText}>
                {highlight ? (
                  <Text action medium={highlight} className={styles(highlight && style.tag)}>
                    {name}
                  </Text>
                ) : (
                  name
                )}

                <Pressable onPress={() => onCancellationInfo({ ...selectedBoard, cancellation })}>
                  <Icon paragraph value={ICON.INFO} />
                </Pressable>
              </Text>
            </Option>
          )),
        )}
      </View>
    );

  return (
    <>
      {!showOffersFirst && renderCancellations()}

      {has.rates && (
        <View className={[style.offset, style.gap]}>
          <Text bold>{translate(L10N.LABEL_OFFER)}</Text>
          {rates.map(
            ({ id, cancellationId, countdown, description, disabled, included, name, price, restrictions }) => {
              const rate = rates.find((rate) => rate.id === id);
              const board = availableBoards.filter(({ rateId } = {}) => rateId === id)[0];
              const { confidential, discount: { breakdown = [] } = {} } = board || {};
              const isPromocode =
                promocode &&
                !promocodeForAll &&
                breakdown.find(
                  ({ type } = {}) => type === DISCOUNT_TYPE.PROMOTION || (type === DISCOUNT_TYPE.DEAL && confidential),
                );
              const { percentage: promoPercentage = 0 } =
                breakdown.find(({ type } = {}) => type === DISCOUNT_TYPE.PROMOTION) || {};
              const { percentage: offerPercentage = 0 } =
                breakdown.find(({ type } = {}) => type === DISCOUNT_TYPE.DEAL) || {};

              return (
                <Option
                  key={id}
                  caption={
                    <RateDetails
                      {...{
                        countdown,
                        included: showRateIncluded ? included : undefined,
                        restrictions,
                        selectedBoard:
                          selectedBoard.rateId === id
                            ? selectedBoard
                            : availableBoards.filter(({ rateId } = {}) => rateId === id)[0],
                        onOfferInfo: () => onOfferInfo({ ...rate, availableBoards }),
                      }}
                    />
                  }
                  checked={id === rateId}
                  disabled={disabled}
                  name={`Rate ${idItem}`}
                  outlined={hideRadio}
                  value={price / priceFactor}
                  onPress={!uniqueOption ? () => onRate({ id, cancellationId }) : undefined}
                >
                  {rates.length > 0 && (
                    <Text medium={hideRadio} action className={style.optionText}>
                      <View row className={style.tags}>
                        {offerPercentage > 0 && (
                          <Text action medium className={style.tag}>
                            {translate(L10N.LABEL_DISCOUNT, { value: `${Math.round(offerPercentage)}%` })}
                          </Text>
                        )}
                      </View>

                      {name}

                      {(description || included.length > 0) && (
                        <Pressable onPress={() => onOfferInfo({ ...rate, availableBoards })}>
                          <Icon paragraph value={ICON.INFO} />
                        </Pressable>
                      )}
                      {isPromocode && (
                        <Text bold small className={styles(style.tag, style.highlight)}>
                          <Text
                            bold
                            small
                            className={
                              promocode.length > (isMobile ? MAX_PROMOCODE.MOBILE : MAX_PROMOCODE.DESKTOP) &&
                              style.truncate
                            }
                          >
                            {promocode.toUpperCase()}
                          </Text>
                          {promoPercentage ? ` -${Math.round(promoPercentage)}%` : ''}
                        </Text>
                      )}
                    </Text>
                  )}
                </Option>
              );
            },
          )}
        </View>
      )}

      {showOffersFirst && renderCancellations()}

      {has.boards && (
        <View className={styles(style.offset, style.gap)}>
          <Text bold>{translate(L10N.LABEL_ACCOMMODATION_PLAN)}</Text>

          {React.createElement(
            has.inlineBoards ? View : React.Fragment,
            has.inlineBoards ? { row: true, className: style.inlineOptions } : undefined,
            boards.map((board = {}) => {
              const { disabled, id, increment, name, variants = [] } = board;

              return (
                <Option
                  key={id}
                  checked={id === boardId}
                  disabled={disabled}
                  inline={has.inlineBoards}
                  name={`Board ${idItem}`}
                  outlined={hideRadio}
                  title={name}
                  value={has.boards ? increment / priceFactor : undefined}
                  onPress={!uniqueOption ? () => onBoard(board) : undefined}
                >
                  {variants.length > 0 && (
                    <TooltipDates
                      captions={variants.map(({ name } = {}) => name)}
                      values={variants.map(({ value } = {}) => parseDate(value))}
                    />
                  )}
                </Option>
              );
            }),
          )}
        </View>
      )}

      <View />
    </>
  );
};

Rates.propTypes = {
  availableBoards: PropTypes.arrayOf(PropTypes.any),
  availableRates: PropTypes.arrayOf(PropTypes.any),
  boards: PropTypes.arrayOf(PropTypes.any),
  cancellations: PropTypes.arrayOf(PropTypes.any),
  features: PropTypes.shape({
    columnMode: PropTypes.bool,
    hideStandaloneBoard: PropTypes.bool,
    showOffersFirst: PropTypes.bool,
    showRateIncluded: PropTypes.bool,
  }),
  id: PropTypes.number,
  priceFactor: PropTypes.number,
  promocodeForAll: PropTypes.bool,
  rates: PropTypes.arrayOf(PropTypes.any),
  selectedBoard: PropTypes.shape({}),
  uniqueOption: PropTypes.bool,
  onBoard: PropTypes.func,
  onCancellation: PropTypes.func,
  onCancellationInfo: PropTypes.func,
  onChange: PropTypes.func,
  onOfferInfo: PropTypes.func,
  onRate: PropTypes.func,
};

Rates.displayName = 'Mirai:Core:Rates:Item:Rates';

export { Rates };
