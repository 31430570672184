import { bookingResponse as duettoBookingResponse } from './duetto';
import { ACCOMMODATION_TYPE } from '../../../../Core.constants';
import { fetchUrlParams } from '../../../../helpers';
import { dataLayerPush } from '../../helpers';
import { SHA256 } from '../helpers';
import { formatPrice } from '../helpers/legacy';

const ACCOMMODATION_TYPE_NAME = Object.keys(ACCOMMODATION_TYPE);

export const bookingResponse = ({
  device: { mobile } = {},
  response: { tracking = {} } = {},
  store: { currency, hotel: { accommodationType, variants = {} } = {}, language, session } = {},
} = {}) => {
  // ! -- TODO: Try to get all data from store -------------------------------------------------------------------------
  const { idchain, idHotel: hotelId } = fetchUrlParams(location.search);

  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { checkin, checkout, country, coupon: promocode = '', nights } = {},
    guestData: { country: nationality, email, firstName, lastName } = {},
    hotelInfo: { hotelCity, hotelProvince, currency: hotelCurrency = {}, propertyName, propertyType } = {},
    idReservation: reservationCode,
    reservationDate,
    reservedExtras = {},
    reservedRooms = [],
    step,
    totalPriceCommission,
    totalExtrasTaxes,
    totalExtrasWithTaxes,
    totalExtrasWithoutTaxes,
    totalNumAdults,
    totalNumChildren,
    totalNumRooms,
    totalPriceWithTaxes,
    totalPriceWithTaxesWithoutExtras,
    totalPriceWithoutTaxes,
    totalPriceWithoutTaxesWithoutExtras,
    totalTaxes,
  } = tracking;

  const rooms = reservedRooms.map(
    ({
      averagePriceWithoutTaxes,
      daysPriceBoard = [],
      defaultBoardDescription,
      numRoomsToReserve,
      occupation: { numAdults, numBabies, totalChildren: numChildren } = {},
      rateId,
      rateName,
      roomTypeName,
    } = {}) => {
      let priceWithTaxes = 0;
      let priceWithoutTaxes = 0;
      daysPriceBoard.forEach((day) => {
        priceWithTaxes += day.priceWithTaxes;
        priceWithoutTaxes += day.priceWithoutTaxes;
      });

      return {
        boardName: defaultBoardDescription,
        numRooms: numRoomsToReserve,
        occupation: { numAdults, numChildren, numBabies },
        rateId,
        rateName,
        roomName: roomTypeName,
        priceWithTaxes,
        priceWithoutTaxes,
        unitaryPriceWithoutTaxes: formatPrice(averagePriceWithoutTaxes, hotelCurrency),
      };
    },
  );

  const extras = Object.values(reservedExtras).map(
    ({
      amount,
      id,
      name,
      nightsToApply,
      totalPriceWithTaxes,
      totalPriceWithoutTaxes,
      unitaryPriceWithTaxes,
      unitaryPriceWithoutTaxes,
    } = {}) => ({
      amount,
      extraId: id,
      extraName: name,
      nightsToApply,
      totalPriceWithTaxes,
      totalPriceWithoutTaxes,
      unitaryPriceWithTaxes,
      unitaryPriceWithoutTaxes,
    }),
  );

  const dataLayer = {
    chainId: idchain,
    checkin,
    checkout,
    city: hotelCity,
    cityZone: '',
    coupon: promocode,
    currency: hotelCurrency.code,
    device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
    email,
    emailSHA256: SHA256(email),
    event: 'init',
    home: document.location.hostname,
    hotelId,
    language,
    lodgingDenomination: ACCOMMODATION_TYPE_NAME[variants[hotelId]?.accommodationType || accommodationType],
    name: firstName,
    nights,
    numAdults: totalNumAdults,
    numChildren: totalNumChildren,
    numRooms: totalNumRooms,
    products: { rooms, extras, checkin, checkout },
    propertyName,
    propertyType,
    reservationCode,
    reservationDate,
    state_provice: hotelProvince,
    step,
    surname: lastName,
    nationality,
    totalCommission: formatPrice(totalPriceCommission, hotelCurrency),
    totalExtrasTaxes: formatPrice(totalExtrasTaxes, hotelCurrency),
    totalExtrasWithoutTaxes: formatPrice(totalExtrasWithoutTaxes, hotelCurrency),
    totalExtrasWithTaxes: formatPrice(totalExtrasWithTaxes, hotelCurrency),
    totalPrice: formatPrice(totalPriceWithTaxes, hotelCurrency),
    totalPriceWithoutTaxes: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    totalPriceWithoutTaxesWithExtras: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    totalPriceWithoutTaxesWithoutExtras: formatPrice(totalPriceWithoutTaxesWithoutExtras, hotelCurrency),
    totalPriceWithTaxesWithExtras: formatPrice(totalPriceWithTaxes, hotelCurrency),
    totalPriceWithTaxesWithoutExtras: formatPrice(totalPriceWithTaxesWithoutExtras, hotelCurrency),
    totalTaxes: formatPrice(totalTaxes, hotelCurrency),
    transactionAffiliation: propertyName,
    transactionId: 'Reservation code ' + reservationCode,
    transactionProducts: [
      ...reservedRooms.map((room = {}) => ({
        sku: room.defaultMediumName,
        name: propertyName,
        category: room.defaultBoardDescription,
        price: formatPrice(room.averagePriceWithoutTaxes, hotelCurrency),
        quantity: nights * room.numRoomsToReserve,
      })),
      ...Object.values(reservedExtras).map((extra) => ({
        sku: extra.name,
        name: propertyName,
        category: 'Extra',
        price: formatPrice(extra.unitaryPriceWithoutTaxes, hotelCurrency),
        quantity: extra.multiplierPrice,
      })),
    ],
    transactionShipping: parseFloat(totalPriceCommission),
    transactionTax: formatPrice(totalTaxes, hotelCurrency),
    transactionTotal: formatPrice(totalPriceWithoutTaxes, hotelCurrency),
    userCountry: country,
    userCurrency: currency,
    userLogged: !!session,
  };

  dataLayerPush(dataLayer);
  duettoBookingResponse({ checkin, checkout, hotelCurrency, reservedRooms, ...tracking });

  return dataLayer;
};
