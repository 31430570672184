import { DATASOURCE as OCCUPATION, TYPE } from '../../__shared__/Occupation/Occupation.constants';

const { GUEST, ADULT, CHILD } = TYPE;

const findOccupation = (variants = [], id) =>
  variants.find(({ id: variantId = '' }) => variantId.toString() === id)?.occupation;

export const getOccupationDataSource = ({ occupation = OCCUPATION, place, places = [], variants = [] } = {}) => {
  const { id: [placeId] = [], isHotel, level } = places.find(({ value }) => value === place) || {};
  let dataSource;

  if (isHotel) {
    dataSource = findOccupation(variants, placeId);
  } else {
    const placeIndex = places.findIndex((item) => item.value === place);
    let children = [];

    places
      .filter((_, index) => index > placeIndex)
      .some((item) => {
        if (item.level > level) children.push(item);
        return item.level === level;
      });

    if (!children.length) children = places;

    if (children.length === 1) {
      dataSource = findOccupation(variants, children[0].id[0]);
    } else {
      const types = {};
      const group = {};

      children.forEach(({ id: [id] = [] }) => {
        (findOccupation(variants, id) || occupation).forEach((item) => {
          const { age: [minAge, maxAge] = [], max, min, type } = item;

          if (
            !types[type] ||
            (type === ADULT && minAge > types[type].age[0]) ||
            (type === CHILD && maxAge > types[type].age[1])
          )
            types[type] = { ...item };

          group[type] = group[type] || { min: 99, max: 0 };
          if (min < group[type].min) group[type].min = min;
          if (max > group[type].max) group[type].max = max;
        });
      });

      if (Object.values(types).length) {
        if (types[CHILD]) types[CHILD].age = [types[CHILD].age[0], types[ADULT].age[0] - 1];

        if (types[ADULT]) delete types[GUEST];

        Object.keys(types).forEach((type) => (types[type] = { ...types[type], ...group[type] }));

        dataSource = Object.values(types);
      }
    }
  }

  return dataSource || occupation;
};
