const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

const HOSTNAME_STORE_KEY = 'mirai:request:hostName';

const PROXY_STORE_KEY = 'mirai:request:proxy';

export { HEADERS, HOSTNAME_STORE_KEY, PROXY_STORE_KEY };
