import { getRoomPriceRange } from './getRoomPriceRange';

export const getRatesPriceRange = (roomTypes = {}, currencyInfo = {}) => {
  let min;
  let max;

  Object.keys(roomTypes).forEach((roomType) => {
    const { minRate, maxRate } = getRoomPriceRange(roomTypes[roomType], currencyInfo);

    if (minRate != null && (!min || parseInt(minRate.priceAsLong) < parseInt(min.priceAsLong))) min = minRate;
    if (maxRate != null && (!max || parseInt(maxRate.priceAsLong) > parseInt(max.priceAsLong))) max = maxRate;
  });

  return { minRate: min || {}, maxRate: max || {} };
};
