import { L10N } from './Filters.l10n';

const BOOKING_OPTIONS = [
  { field: 'promocode', title: L10N.LABEL_SHOW_ONLY_PROMOTION },
  { field: 'offer', title: L10N.LABEL_SHOW_ONLY_DEALS },
  { field: 'roomType', title: L10N.LABEL_SHOW_ONLY_ROOM },
  { field: 'freeCancellation', title: L10N.LABEL_FREE_CANCELLATION },
];

const DEFAULT_FORM = {
  amenities: [],
  bedType: undefined,
  board: undefined,
  clubDiscount: undefined,
  freeCancellation: undefined,
  offer: undefined,
  priceRange: undefined,
  promocode: undefined,
  roomType: undefined,
  sizeRange: undefined,
};

const MAX_AMENITIES = 8;

const MAX_BOARDS = 4;

const MIN_BOARDS = 2;

export { BOOKING_OPTIONS, DEFAULT_FORM, MAX_AMENITIES, MAX_BOARDS, MIN_BOARDS };
