import { useLocale } from '@mirai/locale';
import { Notification, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { BUTTON_PROPS, SCRIPT_ID } from './FormSipay.constants';
import { L10N } from './FormSipay.l10n';
import * as style from './FormSipay.module.css';
import { getStyle } from './helpers';

export const FormSipay = ({
  currencyDecimals = 2,
  info: { cardValidation = false, amount = 0 } = {},
  publicKey = process.env.PROVIDER_SIPAY_PUBLICKEY,
  onError = () => {},
  onSuccess = () => {},
  ...others
}) => {
  const { currency, translate } = useLocale();

  const [ready, setReady] = useState(false);
  const [success, setSuccess] = useState({});

  useEffect(() => {
    const exists = document.getElementById(SCRIPT_ID);
    const script = exists || document.createElement('script');
    script.id = SCRIPT_ID;
    script.src = process.env.PROVIDER_SIPAY_SCRIPT;
    script.onload = handleLoad;

    document.head.appendChild(script);

    return () => {
      const el = document.getElementById(SCRIPT_ID);
      el.parentNode.removeChild(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.FastpayCallback = handleSuccess;

    return () => (window.FastpayCallback = undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, success]);

  useEffect(() => {
    const { name, token, type } = success || {};

    if (type === 'success') onSuccess({ name, token });
    else onError({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleLoad = () => {
    const el = document.getElementById(BUTTON_PROPS.id);

    window.Fastpay.loadButton(el);

    window.Fastpay.customize(getStyle());

    el.click();

    setReady(true);
  };

  const handleSuccess = ({ type, request_id: token, payload: { cadholder_name: name } = {} }) =>
    setSuccess({ name, token, type });

  return (
    <View {...others} className={styles(style.container, ready && style.ready, others.className)}>
      {ready ? (
        <Text action>{translate(L10N.LABEL_SECURE_PAGE)}</Text>
      ) : (
        <Notification inline large warning wide>
          {translate(L10N.NOTIFICATION_LOADING)}
        </Notification>
      )}

      <button
        {...BUTTON_PROPS}
        data-amount={cardValidation ? 1 : (amount * Math.pow(10, currencyDecimals)).toFixed()}
        data-currency={currency}
        data-callback="FastpayCallback"
        data-paymentbutton={translate(cardValidation ? L10N.ACTION_AUTHORIZE : L10N.ACTION_PAY)}
        data-key={publicKey}
        className={style.button}
      />
    </View>
  );
};

FormSipay.displayName = 'Mirai:Payments:ButtonPayment:FormSipay';

FormSipay.propTypes = {
  currencyDecimals: PropTypes.number,
  info: PropTypes.shape({
    amount: PropTypes.number,
    cardValidation: PropTypes.bool,
    date: PropTypes.instanceOf(Date),
  }),
  publicKey: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};
